import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setCurrentPage, getLicense } from 'Store/Actions/appActions';
import { getAuditLog, getExcelAuditLog } from 'Store/Actions/auditLogActions';
import { permissions } from '../../../Common/Constant';
import { useAppSelector } from 'Store/hooks';
import { Layout } from 'access_ctrl-ui';
import { Filter, Page, User } from 'Interfaces';
import { RootState } from 'Store/Reducers';
import { AuditLogResponse } from 'Interfaces/AuditLogResponse';
import AuditLogTable from 'Features/AuditLog/AuditLogTable';
import FeatureDisabled from '../Feature';
import { features } from 'Common/Constant/Features';
interface Props {
  getAuditLog: (filter: Filter) => Promise<AuditLogResponse>;
  fetchingLoggedInUser: boolean;
  fetchingLogs: boolean;
  loggedInUser: User;
  setCurrentPage: (pageInfo: Page) => void;
}
const AuditLog: React.FC<Props> = ({ getAuditLog, loggedInUser, fetchingLoggedInUser, fetchingLogs, setCurrentPage }) => {
  const [viewAuditLogsAction, setViewAuditLogsAction] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const { license } = useAppSelector(state => state.app);

  const dispatch = useDispatch();
  useEffect(() => {
    if (license) {
      for (const [key, value] of Object.entries(license.Features)) {
        if (value.Enabled === true && key.toLowerCase() === features.auditLog) {
          setShowPage(true);
        }
      }
    }
  }, [license]);

  useEffect(() => {
    if (!license) {
      getLicense(dispatch)();
    }
  }, [license]);

  useEffect(() => {
    setCurrentPage({ name: 'Audit Log' });
  }, [setCurrentPage]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.Options && loggedInUser.Options['permissions.actions']) {
      const actions = JSON.parse(loggedInUser.Options['permissions.actions'].Value);

      setViewAuditLogsAction(actions.includes(permissions.viewAuditLog) || actions.includes(permissions.all));
    } else if (loggedInUser) {
      setViewAuditLogsAction(true);
    }
  }, [loggedInUser]);

  return (
    <Layout.Wrapper>
      <Layout.Section minHeight='15rem'>
        {
          showPage
            ? (
              <>
                <AuditLogTable
                  loading={fetchingLogs || fetchingLoggedInUser}
                  viewAuditLogsAction={viewAuditLogsAction}
                  showFilters
                />
              </>
            )
            : (
              <>
                <FeatureDisabled name='Audit Log' />
              </>
            )
        }
      </Layout.Section>
    </Layout.Wrapper>
  );
};

export default connect(({ app, auditLog }: RootState) => ({
  fetchingLogs: auditLog.fetchingLogs,
  loggedInUser: app.loggedInUser,
  fetchingLoggedInUser: app.fetchingLoggedInUser,
  fetchingExcelAuditLog: auditLog.fetchingExcelAuditLog
}), dispatch => (
  {
    getExcelAuditLog: getExcelAuditLog(dispatch),
    getAuditLog: getAuditLog(dispatch),
    setCurrentPage: setCurrentPage(dispatch)
  }
))(AuditLog);
