import { User } from 'Interfaces';

export default function userUserHasAccess(user: User, currentDate: Date): boolean {
  let hasAccess = true;

  if (user && user.Options) {
    const accessFrom = user.Options['access.from'] && user.Options['access.from'].Value ? new Date(user.Options['access.from'].Value) : undefined;
    const accessTo = user.Options['access.to'] && user.Options['access.to'].Value ? new Date(user.Options['access.to'].Value) : undefined;

    if (accessFrom && accessFrom > currentDate) {
      hasAccess = false;
    }
    if (accessTo && accessTo < currentDate) {
      hasAccess = false;
    }
  }
  return hasAccess;
}
