import { History } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createAppMiddleware } from './app';

const createMiddlewares = (history:History) => {
  return [
    routerMiddleware(history),
    createAppMiddleware(history),
    thunk
  ];
};

export default createMiddlewares;
