import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import EditOptionsModal from 'Components/Modals/EditOptionsModal';
import defaultDeviceOptions from '../../Common/defaultDeviceOptions';
import { Device, ModalOptions } from 'Interfaces';
import { RootState } from 'Store/Reducers';
import { getDeviceOptions, postDeviceOption } from 'Store/Actions/optionsActions';
import { loadingMessages, statusMessages } from '../../Common/messages';
import { toggleModal } from 'Store/Actions/appActions';
import { ChangedInputs } from 'Interfaces/ChangedInputs';
import { JTokenType, ModalTypes } from 'Common/Enums';
import { refreshCurrentDevice } from 'Store/Actions/deviceActions';
import { addSuccessMessage } from 'Store/Actions/feedbackMessage';

interface Props {
  currentDevice: Device | null;
  getDeviceOptions: (deviceId: string) => Promise<void>;
  open: boolean;
  optionsForDevice: ModalOptions;
  postDeviceOption: (deviceId: string, key: string, value: string | null, tokenType: JTokenType) => Promise<void>;
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
  refresh: ()=> void;
  addSuccessMessage: (content: string, autoClose?: boolean) => void;
}

export const EditDeviceOptionsModal:React.FC<Props> = ({
  currentDevice,
  getDeviceOptions,
  open,
  optionsForDevice,
  postDeviceOption,
  toggleModal,
  refresh,
  addSuccessMessage
}) => {
  const [loadedOptions, setOptionsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setOptionsLoaded(false);
      if (currentDevice?.Id) {
        getDeviceOptions(currentDevice.Id).then(() => {
          setOptionsLoaded(true);
        });
      }
    }
  }, [currentDevice, getDeviceOptions, open]);

  useEffect(() => {
    setOptionsLoaded(true);
  }, [optionsForDevice]);

  const onSubmit = async (changedInputs: ChangedInputs) => {
    setLoading(true);
    const promises = [];
    for (const [key, value] of Object.entries(changedInputs)) {
      const { type, value: val } = value;
      if (currentDevice?.Id) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        promises.push(postDeviceOption(currentDevice.Id, key, val !== null && val !== undefined ? val.toString() : null, type));
      }
    }
    await Promise.all(promises);
    addSuccessMessage(statusMessages.OPTIONS_UPDATED);
    refresh();
    setLoading(false);
  };

  const onClose = () => {
    toggleModal(ModalTypes.EDIT_DEVICE_OPTIONS_MODAL, false);
  };

  return (
    <EditOptionsModal
      options={optionsForDevice}
      onSubmit={onSubmit}
      onClose={onClose}
      open={open}
      saving={loading}
      loadedOptions={loadedOptions}
      header='Edit device options'
      defaultOptions={defaultDeviceOptions}
      loadingMessage={loadingMessages.LOADING_DEVICE_OPTIONS}
    />
  );
};

export default connect(({ modalsOpen, options, device }: RootState) => ({
  open: modalsOpen[ModalTypes.EDIT_DEVICE_OPTIONS_MODAL],
  currentDevice: device.currentDevice,
  optionsForDevice: options.optionsForDevice
}), dispatch => ({
  addSuccessMessage: addSuccessMessage(dispatch),
  toggleModal: toggleModal(dispatch),
  getDeviceOptions: getDeviceOptions(dispatch),
  postDeviceOption: postDeviceOption(dispatch),
  refresh: refreshCurrentDevice(dispatch)
}))(EditDeviceOptionsModal);
