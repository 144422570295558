import { Button, Form, FormInput, Layout, Modal, sizes } from 'access_ctrl-ui';
import useRoutineTypes from 'Features/Routines/Hooks/useRoutineTypes';
import { saveRoutine, updateRoutine } from 'Features/Routines/routineSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'Store/hooks';
import styled from 'styled-components';
import { DailyRoutine, RoutineType, DayType, TriggerTime } from '../../Interfaces';

import AddTrigger from './AddTrigger';
import RoutineTypeList from './RoutineTypeList';
const StyledNoRoutines = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
`;
interface InputState {
  Id?: string;
  Name?: string;
  Description?: string;
  Type?: RoutineType;
  Days?: DayType[];
  Times?: TriggerTime[]
}
interface Props {
  open: boolean;
  edit?: boolean;
  close: () => void;
  title: string;
}

const HandleRoutineModal:React.FC<Props> = ({ open, close, edit, title }) => {
  const { currentRoutine } = useAppSelector(state => state.routine) ?? {};
  const { amount, routines } = useRoutineTypes() ?? {};
  const [state, setState] = useState<InputState>(((edit && currentRoutine) ? currentRoutine : { Type: RoutineType.DailyInspection }));
  const dispatch = useDispatch();

  const onSubmit = async () => {
    const { Name, Description, Days, Times, Id } = state || {};
    if (state && Name && Description && Days && Times) {
      if (edit && Id) {
        dispatch(updateRoutine({ ...state as DailyRoutine, Type: RoutineType.DailyInspection }));
        setTimeout(() => {
          close();
        }, 500);
        return;
      }
      const newRoutine:Omit<DailyRoutine, 'Id'> = {
        Name: Name,
        Type: RoutineType.DailyInspection,
        Description: Description,
        Times: Times ?? [],
        Days: Days ?? []

      };
      dispatch(saveRoutine(newRoutine));
      setTimeout(() => {
        close();
      }, 500);
    }
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    if (name) {
      setState(state => ({
        ...state,
        [name]: value
      }));
    }
  };
  /*   const onMessageChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value) {
      setState(state => ({
        ...state,
        Activities: [{
          id: '',
          messages: [{
            text: value
          }],
          target: ActivityTarget.TRANSMITTER,
          transmitterActivites: ''
        }]
      }));
    }
  }; */
  const onSelect = (type: RoutineType) => {
    setState(state => ({
      ...state,
      Type: type
    }));
  };
  const addTrigger = useCallback((times: TriggerTime[], days: DayType[]) => {
    setState(state => ({
      ...state,
      Days: days,
      Times: times
    }));
  }, []);
  const validate = ():boolean => {
    const { Name, Description, Type, Days, Times } = state || {};
    const hasTriggers = Days && Days?.length > 0 && Times && Times?.length > 0;
    return !(Name && Description && hasTriggers && Type);
  };
  const { Name, Description, Type } = state || {};

  useEffect(() => {
    if (amount === 1 && routines) {
      setState(state => ({
        ...state,
        Type: routines[0] as RoutineType
      }));
    }
  }, [amount, routines]);
  const { Days, Times } = state;
  return (
    <Modal
      showConfirmModal={false}
      open={open}
      confirmClose={close}
      closeIcon
      size='tiny'
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>{title}</Layout.Modal.Header>
        <Layout.Modal.Content>
          <Layout.Row spacing={sizes.lg} padding={sizes.lg}>
            <RoutineTypeList onSelect={onSelect} selected={Type} />
          </Layout.Row>
          {Type
            ? (
              <Form onSubmit={onSubmit}>
                <Layout.Row spacing={sizes.lg} padding={sizes.lg}>
                  <FormInput
                    value={Name}
                    fluid
                    label='Name'
                    key='Name'
                    onChange={onChange}
                    name='Name'
                    required
                  />
                </Layout.Row>
                <Layout.Row spacing={sizes.lg} padding={sizes.lg}>
                  <FormInput
                    value={Description}
                    fluid
                    label='Description'
                    key='Description'
                    onChange={onChange}
                    name='Description'
                    required
                  />
                </Layout.Row>
                <Layout.Row spacing={sizes.lg} padding={sizes.lg}>
                  <AddTrigger onSave={addTrigger} days={Days} times={Times} />
                </Layout.Row>
                <Layout.Modal.ButtonContainer>
                  <Button type='submit' disabled={validate()}>Save</Button>
                </Layout.Modal.ButtonContainer>
              </Form>
            )
            : (<StyledNoRoutines><h2>No routine selected</h2></StyledNoRoutines>)}
        </Layout.Modal.Content>
      </Layout.Modal.Wrapper>
    </Modal>
  );
};
export default HandleRoutineModal;
