import { Dispatch } from 'redux';
import { ACLChangedEvent } from '../../Common/Constant';
import { DeviceActionTypes } from '../ActionEnums';
import { Device, KeyStringDictionary } from 'Interfaces';
import { addErrorMessage, addSuccessMessage } from './feedbackMessage';
import { postDeviceLogItem } from './operationLogActions';
import { statusMessages, apiCallErrorMessages } from '../../Common/messages';
import { softwareUpdateApi, webApi } from '../../Common/api';
import { OperationLogActions } from '../ActionTypes';
import store from 'Store/store';
import { getVersion, translateSoftwareNames } from 'Util/handleVersions';
import { deleteDeviceOption, postDeviceOption } from './optionsActions';
import { RoutinesOptionKey } from 'Features/Routines/Interfaces';

/**
  Add a new device
  @param device The new device
  @return The request promise
*/
export const addDevice = (dispatch: Dispatch) => (device: Partial<Device>) => {
  dispatch({
    type: DeviceActionTypes.ADD_DEVICE_START
  });

  return webApi.post('inventory', device).then((response) => {
    getAllDevices(dispatch)(false);
    dispatch({ type: DeviceActionTypes.ADD_DEVICE_SUCCESS });
    addSuccessMessage(dispatch)(statusMessages.ADD_DEVICE_SUCCESS);
    return response;
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.ADD_DEVICE_ERROR_MESSAGE, error);
    dispatch({
      type: DeviceActionTypes.FAILED_ADDING_DEVICE
    });
  });
};

/**
  Get all devices
  @param withUsers Set to true if all users should be included when getting devices
  @return The request promise
*/
export const getAllDevices = (dispatch: Dispatch) => (withUsers = true, withOptions?:boolean) => {
  let options = {};
  if (withUsers || withOptions) {
    const params = (withUsers ? 'users ' : '') + (withOptions ? 'options' : '');
    options = {
      params: {
        include: params.trim()
      }
    };
  }

  dispatch({
    type: DeviceActionTypes.FETCHING_ALL_DEVICES
  });

  return webApi.get('inventory', options).then(inventoryResponse => {
    dispatch({
      type: DeviceActionTypes.GOT_ALL_DEVICES,
      payload: inventoryResponse.data
    });
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_ALL_DEVICES_ERROR_MESSAGE, error);
    dispatch({
      type: DeviceActionTypes.FAILED_FETCHING_ALL_DEVICES
    });
  });
};

export const getDeviceCount = (dispatch: Dispatch) => () => {
  return webApi.get<number>('inventory/count').then(response => {
    const { data } = response;
    if (data) {
      dispatch({
        type: DeviceActionTypes.GOT_DEVICE_COUNT,
        payload: data
      });
    }
  });
};

/**
  Get data for a single device
  @param deviceId The ID of the device to get
  @param withUsers Set to true if users should be included
  @return The request promise
*/
export const getDevice = (dispatch: Dispatch) => (deviceId: string, withUsers = true, withOptions = false) => {
  let options = {};
  if (withUsers || withOptions) {
    const params = (withUsers ? 'users ' : '') + (withOptions ? 'options' : '');
    options = {
      params: {
        include: params.trim()
      }
    };
  }

  dispatch({
    type: DeviceActionTypes.FETCHING_SINGLE_DEVICE
  });

  return webApi.get(`inventory/${deviceId}`, options).then(inventoryItemResponse => {
    dispatch({
      type: DeviceActionTypes.GOT_SINGLE_DEVICE,
      payload: inventoryItemResponse.data
    });
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_SINGLE_DEVICE_ERROR_MESSAGE, error);
    dispatch({
      type: DeviceActionTypes.FAILED_FETCHING_SINGLE_DEVICE
    });
  });
};

/**
  Refresh currentDevice
  @return void
*/
export const refreshCurrentDevice = (dispatch: Dispatch) => () => {
  const options = {
    params: {
      include: 'users, options'
    }
  };

  dispatch({
    type: DeviceActionTypes.REFRESHING_CURRENT_DEVICE
  });
  // Get current device from store
  const device = store.getState().device.currentDevice;
  if (device && device.Id) {
    webApi.get(`inventory/${device.Id}`, options).then(inventoryItemResponse => {
      dispatch({
        type: DeviceActionTypes.REFRESHED_CURRENT_DEVICE,
        payload: inventoryItemResponse.data
      });
    }).catch((error) => {
      addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_SINGLE_DEVICE_ERROR_MESSAGE, error);
      dispatch({
        type: DeviceActionTypes.FAILED_REFRESHING_CURRENT_DEVICE
      });
    });
  } else {
    dispatch({
      type: DeviceActionTypes.NO_CURRENT_DEVICE
    });
  }
};
/**
  Delete a device specified by device ID
  @param deviceId The ID of the device to delete
  @return The request promise
*/
export const deleteDevice = (dispatch: Dispatch) => (deviceId: string) => {
  dispatch({
    type: DeviceActionTypes.DELETE_DEVICE_START
  });

  return webApi.delete(`inventory/${deviceId}`).then(() => {
    dispatch({
      type: DeviceActionTypes.DELETE_DEVICE_SUCCESS
    });
    addSuccessMessage(dispatch)(statusMessages.DELETE_DEVICE_SUCCESS);
    getAllDevices(dispatch)(false);
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.DELETE_DEVICE_ERROR_MESSAGE, error);
    dispatch({
      type: DeviceActionTypes.FAILED_DELETING_DEVICE
    });
  });
};

/**
  Update a device
  @param device The new device data
  @return The request promise
*/
export const updateDevice = (dispatch: Dispatch) => (device: Device) => {
  dispatch({
    type: DeviceActionTypes.UPDATE_DEVICE_START
  });

  return webApi.put('inventory', device).then(response => {
    dispatch({
      type: DeviceActionTypes.UPDATE_DEVICE_SUCCESS,
      payload: response.data
    });

    addSuccessMessage(dispatch)(statusMessages.UPDATE_DEVICE_SUCCESS);
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.UPDATE_DEVICE_ERROR_MESSAGE, error);
    dispatch({
      type: DeviceActionTypes.FAILED_UPDATING_DEVICE
    });
  });
};

/**
  Set .ACL log to Priority=LcuTerminationRequested to schedule restart of the device LCU
  @param {string} deviceId The ID of the device LCU to restart
  @param {boolean} showMessage = true Show success message
*/
export const restartLCU = (dispatch: Dispatch<OperationLogActions>) => (deviceId: string, showMessage = true) => {
  return postDeviceLogItem(dispatch)(deviceId, '.ACL', { Priority: ACLChangedEvent.LcuTerminationRequested }).then(() => {
    showMessage && addSuccessMessage(dispatch)(statusMessages.DEVICE_RESTARTING);
  });
};

/**
  Set .ACL log to Priority=LcuSoftwareRestartRequested to schedule restart of the software
  @param {string} deviceId The ID of the device LCU to restart software on
  @param {boolean} showMessage = true Show success message
*/
export const restartSoftware = (dispatch: Dispatch<OperationLogActions>) => (deviceId: string, showMessage = true) => {
  return postDeviceLogItem(dispatch)(deviceId, '.ACL', { Priority: ACLChangedEvent.LcuSoftwareRestartRequested }).then(() => {
    showMessage && addSuccessMessage(dispatch)(statusMessages.SOFTWARE_RESTARTING);
  });
};

/**
 * Set .ACL Log to Priority=LcuUpdateRequested to schedule update of the device LCU
 * @param deviceId The ID of the device LCU to update
 */
export const updateLCU = (dispatch: Dispatch<OperationLogActions>) => (deviceId: string) => {
  postDeviceLogItem(dispatch)(deviceId, '.ACL', { Priority: ACLChangedEvent.LcuUpdateRequested }).then(() => {
    addSuccessMessage(dispatch)(statusMessages.DEVICE_UPDATING);
  });
};

/**
  Get distinct values for Column B, used for autocomplete
  @return The request promise
*/
export const getDistinctColBValues = (dispatch: Dispatch) => () => {
  dispatch({ type: DeviceActionTypes.GETTING_DISTINCT_COL_B_VALUES });

  return webApi.get('inventory/B').then(response => {
    dispatch({
      type: DeviceActionTypes.GOT_DISTINCT_COL_B_VALUES,
      payload: response.data
    });
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_GETTING_DISTINCT_COL_B_VALUES_ERROR_MESSAGE, error);
  });
};

/**
  Get distinct values for Column C, used for autocomplete
  @return {Promse<void>} The request promise
*/
export const getDistinctColCValues = (dispatch: Dispatch) => () => {
  dispatch({ type: DeviceActionTypes.GETTING_DISTINCT_COL_C_VALUES });

  return webApi.get('inventory/C').then(response => {
    dispatch({
      type: DeviceActionTypes.GOT_DISTINCT_COL_C_VALUES,
      payload: response.data
    });
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_GETTING_DISTINCT_COL_C_VALUES_ERROR_MESSAGE, error);
  });
};

/**
 * Fetch latest software versions for devices
 * @param {string[]} [versions] - versions to get, if no version provided
 * @returns {Promise<void>} a promise for the request
 */
export const getSoftwareVersions = (dispatch: Dispatch) => (versions?:string[]) => {
  dispatch({
    type: DeviceActionTypes.FETCHING_LATEST_SOFTWARE_VERSIONS_START
  });
  const versionsToUpdate = versions || Object.keys(store.getState().device.latestSoftwareVersions);
  const updates:Promise<void>[] = [];
  // TODO: Fix this workaround when naming of endpoint is completed
  const fetchedVersions:KeyStringDictionary = {};
  versionsToUpdate.forEach(update => {
    updates.push(softwareUpdateApi.get(`Inventory/latestblobname/${translateSoftwareNames(update)}`).then(response => {
      const { data } = response;
      if (data) {
        fetchedVersions[update] = getVersion(data);
      }
    }));
  });

  return Promise.all(updates).then(() => {
    dispatch({
      type: DeviceActionTypes.FETCHING_LATEST_SOFTWARE_VERSIONS_SUCCESS,
      payload: fetchedVersions
    });
  });
};
export const updateRoutinesForDevice = (dispatch: Dispatch) => (deviceIds: string[], routineIds: string[], oldRoutines = [] as string[]) => {
  const updates:Promise<void>[] = [];
  deviceIds.forEach(device => {
    oldRoutines.forEach((_, index) => {
      updates.push(deleteDeviceOption(dispatch)(device, `${RoutinesOptionKey}.${index}`));
    });
    updates.push(deleteDeviceOption(dispatch)(device, `${RoutinesOptionKey}`));
    routineIds.forEach((routineId:string, index:number) => {
      updates.push(postDeviceOption(dispatch)(device, `${RoutinesOptionKey}.${index}`, routineId));
    });
  });
  return Promise.all(updates).then(() => {
    addSuccessMessage(dispatch)('Routines added to devices');
  }).catch((error) => {
    addErrorMessage(dispatch)('Failed to add routines to devices', error);
  });
};
/**
 *  Adds multiple uses to a device
 * @param {string} deviceId - Id of the device to update
 * @param {string[]} userIds - Ids to add to device
 * @returns {Promise<void>} - The request promise
 */
export const addMultipleUsers = (dispatch: Dispatch) => (deviceId:string, userIds:string[]):Promise<void> => {
  dispatch({ type: DeviceActionTypes.ADDING_MULTIPLE_USERS_START });
  return webApi.post(`inventory/${deviceId}/userIds`, userIds).then(() => {
    dispatch({ type: DeviceActionTypes.ADDING_MULTIPLE_USERS_END });
  }).catch(err => {
    dispatch({ type: DeviceActionTypes.ADDING_MULTIPLE_USERS_END });
    addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_ADDING_MULTIPLE_USERS_TO_DEVICE, err);
  });
};
