import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Edit2, Copy } from 'react-feather';

import { toggleModal } from 'Store/Actions/appActions';
import { columnBName, columnCName } from '../../../Common/messages';

import { HeaderRow, InfoPopup, Button, InfoBit, Label } from 'access_ctrl-ui';
import { Device } from 'Interfaces';
import { ModalTypes } from 'Common/Enums';
import { useAppSelector } from 'Store/hooks';
import { getUpdateIcon } from 'Util/handleVersions';
import { getRoutines } from 'Features/Routines/routineSlice';
import RoutineList from 'Features/Routines/Components/RoutineList';
import { deviceHasRoutine } from 'Util/deviceHasRoutine';
import useCheckInput from 'Hooks/useCheckInput';
import DeviceStatusItem from 'Components/Global/DeviceStatusItem';
import { features } from 'Common/Constant/Features';
import { clipBoardMessages } from 'Common/messages';
import { addSuccessMessage } from 'Store/Actions/feedbackMessage';
import useAvailableShortcuts from 'Hooks/useAvailableShortcuts';
import ShortcutList from 'Components/Global/ShortcutList';

const InfoBitWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledLink = styled.div`
  &&& {
    svg {
      color: ${({ theme }) => theme.colorPrimary};
      cursor: pointer;

      :hover {
        color: ${({ theme }) => theme.textColorLinkHover};
      }
    }
  }
`;

const StyledShortcutList = styled(ShortcutList)`
  flex: 0 1 100%;
  padding: ${({ theme }) => theme.sizes.md};
`;

const StyledInfoContainer = styled.div`
  padding: 1rem;
  flex: 0 1 50%;
`;
interface Props {
  currentDevice?: Device;
  deviceAdmin: boolean;
  SWVersion: string;
}

const InfoPane:React.FC<Props> = ({ currentDevice, deviceAdmin, SWVersion }) => {
  const showDeviceDetails = useCheckInput('show');
  const [showRoutines, setShowRoutines] = useState(false);
  const { latestSoftwareVersions } = useAppSelector(state => state.device);
  const deviceLogs = useAppSelector(state => state.operationlog.deviceLogs);
  const { license } = useAppSelector(state => state.app);
  const dispatch = useDispatch();

  const { routines } = useAppSelector((state) => state.routine);

  const shortcuts = useAvailableShortcuts('device', currentDevice?.Id, license);

  useEffect(() => {
    dispatch(getRoutines());
  }, [dispatch]);

  useEffect(() => {
    if (license) {
      for (const [key, value] of Object.entries(license.Features)) {
        if (value.Enabled === true && key.toLowerCase() === features.dailyInspection) {
          setShowRoutines(true);
        }
      }
    }
  }, [license]);

  const onEdit = () => {
    toggleModal(dispatch)(ModalTypes.EDIT_DEVICE_MODAL);
  };

  const onCopyClientSecret = () => {
    navigator.clipboard.writeText(currentDevice?.ClientSecret ?? '');
    addSuccessMessage(dispatch)(clipBoardMessages.TEXT_ADDED_TO_CLIPBOARD);
  };

  const width = '50%';

  if (!currentDevice) {
    return (<p>Loading...</p>);
  }

  return (
    <>
      <HeaderRow>
        <h2>Info</h2>
        <InfoPopup descriptionText='General info for this device' />
        {
          deviceAdmin &&
            <Button onClick={onEdit}><Edit2 />Edit</Button>
        }
      </HeaderRow>
      <InfoBitWrapper>
        <InfoBit header='Device name' info={currentDevice.Name} width={width} />
        <InfoBit header={columnBName} info={currentDevice.B} width={width} />
        <StyledInfoContainer>
          <Label label='Status' />
          <DeviceStatusItem deviceId={currentDevice.Id} deviceLogs={deviceLogs} />
        </StyledInfoContainer>
        <InfoBit header={columnCName} info={currentDevice.C} width={width} />
        {
          showRoutines &&
            <StyledInfoContainer>
              <RoutineList label='Daily Inspections' data={routines?.filter(x => deviceHasRoutine(currentDevice, x))} messageError='Not set' />
            </StyledInfoContainer>
        }
        {
          showDeviceDetails &&
            <>
              <InfoBit header='Software version' info={SWVersion} width={width} icon={getUpdateIcon(currentDevice, latestSoftwareVersions)} />
              <InfoBit header='Id' info={currentDevice.Id} width={width} />
              <StyledLink onClick={onCopyClientSecret}><InfoBit header='Client Secret' info={currentDevice.ClientSecret} width={width} icon={<Copy size={20} />} /></StyledLink>
            </>
        }
        <StyledShortcutList shortcuts={shortcuts} label='Device Data' />
      </InfoBitWrapper>
    </>
  );
};

export default InfoPane;
