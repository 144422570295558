import React, { useEffect, useState, useRef, useCallback, FC } from 'react';
import { connect } from 'react-redux';
import { Ref, Message, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { removeFeedbackMessage } from 'Store/Actions/feedbackMessage';

import { FeedbackMessage } from 'Interfaces';
import { Statuses } from 'types';

const MessageWrapper = styled.div`
  div.status-message {
    opacity: 1;
    transition: opacity 0.5s ease;

    &.fade-out {
      opacity: 0;
    }
  }

  & div.content {
    word-break: break-word;
  }
`;

const statusTypes: Statuses = {
  success: {
    color: 'green',
    icon: 'thumbs up'
  },
  warning: {
    color: 'yellow',
    icon: 'warning'
  },
  error: {
    color: 'red',
    icon: 'warning sign'
  }
};

interface Props {
  message: FeedbackMessage;
  removeFeedbackMessage: (id: string) => void;
}

export const StatusMessage: FC<Props> = ({ message, removeFeedbackMessage }) => {
  const [visible, setVisible] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  // TODO: Type messageRef correctly
  const messageRef = useRef<HTMLElement>(null);

  const handleAutoDismiss = useCallback(() => {
    setFadeOut(true);

    if (messageRef.current) {
      messageRef.current.addEventListener('transitionend', () => {
        setVisible(false);
        removeFeedbackMessage(message.id);
      }, { once: true });
    }
  }, [message, removeFeedbackMessage]);

  const handleDismiss = () => {
    setVisible(false);
    removeFeedbackMessage(message.id);
  };

  useEffect(() => {
    if (message && message.autoClose) {
      const timeout = setTimeout(() => {
        handleAutoDismiss();
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [handleAutoDismiss, message]);

  if (!visible || !message) return null;

  const fadeOutClass = fadeOut ? 'fade-out' : '';

  return (
    <MessageWrapper>
      <Ref innerRef={messageRef}>
        <Message
          color={statusTypes[message.type].color}
          icon
          onDismiss={handleDismiss}
          className={'status-message ' + fadeOutClass}
        >
          <Icon name={statusTypes[message.type].icon} />
          <Message.Content>{message.content}</Message.Content>
        </Message>
      </Ref>
    </MessageWrapper>
  );
};

export default connect(null, dispatch => ({
  removeFeedbackMessage: removeFeedbackMessage(dispatch)
}))(StatusMessage);
