import { DefaultDeviceOptions, Option } from 'Interfaces';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'Store/hooks';
/**
 * Sort options alhabetically and order read only options first
 * @param {DefaultDeviceOptions} defaultOptions - options to sort
 */
function useSortedOptions(defaultOptions?:DefaultDeviceOptions):[string, Option][] {
  const { Options: optionsFromState } = useAppSelector(state => state.device.currentDevice) ?? {};
  const [options, setOptions] = useState<[string, Option][]>([]);
  useMemo(() => {
    const combinedOptions = _.merge({}, defaultOptions, optionsFromState as DefaultDeviceOptions);
    const sortedOptions = Object.entries(combinedOptions).sort().sort((a, b) => {
      // Put readonly options at the top
      if (a[1]?.ReadOnly) {
        return -1;
      }
      return 0;
    });
    setOptions(sortedOptions);
  }, [defaultOptions, optionsFromState]);
  return options;
}
export default useSortedOptions;
