import { PaginationProps, ServerSideTable, ServerSideTableColumnType, TableColumnType, TableLink, TableRowType, TableSortDirectionType } from 'access_ctrl-ui';
import { durationFromTime, formatDate } from '../../../../Util/formatDate';
import { Check, X } from 'react-feather';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TableFilter from '../../../../Components/Global/TableFilter';
import { Receipt } from '../Interfaces';
import { FilterTypes } from 'Common/Enums/FilterTypes';
import { FilterProps } from 'Interfaces/FilterProps';
import { useAppSelector } from 'Store/hooks';
import { getAllUsers } from 'Store/Actions/userActions';
import { getAllDevices } from 'Store/Actions/deviceActions';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ReceiptsOrderByColumn } from 'Common/Enums/ReceiptsOrderByColumn';
import { DropdownItemProps } from 'semantic-ui-react';
import { RoutineStatus } from 'Features/Routines/Interfaces';
import FeatureDisabled from 'Components/Pages/Feature';
import { features } from 'Common/Constant/Features';

const TableWrapper = styled.div`
  height: 100%;
`;

const StyledXIcon = styled(X)`
  color: ${({ theme }) => theme.textColorError};
`;

const StyledCheckIcon = styled(Check)`
  color: ${({ theme }) => theme.colorPrimary};
`;

const tableDataColumns: ServerSideTableColumnType[] = [
  {
    name: 'Timestamp',
    key: 'timestamp',
    order: { asc: ReceiptsOrderByColumn.TimestampAsc.toString(), desc: ReceiptsOrderByColumn.TimestampDesc.toString() }
  },
  {
    name: 'Device',
    key: 'deviceName',
    order: { asc: ReceiptsOrderByColumn.InventoryNameAsc.toString(), desc: ReceiptsOrderByColumn.InventoryNameDesc.toString() }
  },
  {
    name: 'Routine',
    key: 'routineName',
    order: { asc: ReceiptsOrderByColumn.RoutinesNameAsc.toString(), desc: ReceiptsOrderByColumn.RoutinesNameDesc.toString() }
  },
  {
    name: 'Duration',
    key: 'duration',
    order: { asc: ReceiptsOrderByColumn.DurationAsc.toString(), desc: ReceiptsOrderByColumn.DurationDesc.toString() }
  },
  {
    name: 'Inspection Confirmed',
    key: 'confirmed',
    order: { asc: ReceiptsOrderByColumn.StatusAsc.toString(), desc: ReceiptsOrderByColumn.StatusDesc.toString() }
  },
  {
    name: 'Certified By',
    key: 'createdBy',
    order: { asc: ReceiptsOrderByColumn.CreatedByNameAsc.toString(), desc: ReceiptsOrderByColumn.CreatedByNameDesc.toString() }
  }
];

/**
 * This component renders a filterable table with all receipts
 * @returns A filterable table with all receipts
 */
const ReceiptTable: React.FC<{ receipts?: Receipt[], loading: boolean, showFilters?: boolean, receiptsPerPage?: number, exportFnc?: () => Promise<TableRowType[]>, hideRoutineFilter?: boolean}> = ({ receipts, hideRoutineFilter, loading, showFilters, receiptsPerPage, exportFnc }) => {
  const [tableData, setTableData] = useState<TableRowType[]>([] as TableRowType[]);
  const [currentTablePage, setCurrentTablePage] = useState(1);

  const { allUsers } = useAppSelector(state => state.user);
  const { devices } = useAppSelector(state => state.device);
  const { nbrOfReceipts } = useAppSelector(state => state.receipts);
  const { routines } = useAppSelector(state => state.routine);
  const dispatch = useDispatch();
  const [showPage, setShowPage] = useState(false);
  const { license } = useAppSelector(state => state.app);

  const location = useLocation();
  const history = useHistory();

  const dropdownItemsConfirmed: DropdownItemProps[] = [
    {
      text: 'yes',
      value: RoutineStatus.Ok.toString(),
      key: 'confirmed_true'
    },
    {
      text: 'no',
      value: RoutineStatus.NotPerformed.toString(),
      key: 'confirmed_false'
    }
  ];

  /**
   * Filters props managing FILTER UX
   */
  const [filters, setFilters] = useState<FilterProps[]>([
    { filterKey: 'timestamp', label: 'Date', filterType: FilterTypes.DATE_RANGE },
    { filterKey: 'deviceId', label: 'Device', filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'Select devices...' },
    { filterKey: 'routineIds', label: 'Routine', filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'Select routines...', hidden: hideRoutineFilter },
    { filterKey: 'duration', label: 'Duration', filterType: FilterTypes.TIME, placeholder: '00h 12m 24s' },
    { filterKey: 'createdBy', label: 'Certified By', filterType: FilterTypes.DROPDOWN_LIST },
    { filterKey: 'confirmed', label: 'Confirmed', filterType: FilterTypes.DROPDOWN_LIST, dropDownItems: dropdownItemsConfirmed }
  ]);

  /**
   * Fetch all users and devices
   */
  useEffect(() => {
    getAllUsers(dispatch)();
    getAllDevices(dispatch)();
  }, [dispatch]);
  useEffect(() => {
    if (license) {
      for (const [key, value] of Object.entries(license.Features)) {
        if (value.Enabled === true && key.toLowerCase() === features.dailyInspection) {
          setShowPage(true);
        }
      }
    }
  }, [license]);

  /**
   * Update dropdownItems in device filter
   */
  useEffect(() => {
    const newFilters = [...filters];
    devices && (newFilters[1].dropDownItems = devices.map(device => {
      return { text: `${device.Name?.toLowerCase()}`, value: device.Id, key: device.Id };
    })).sort((a, b) => (a.text > b.text) ? 1 : -1);
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  /**
     * Update dropdownItems in users filter
     */
  useEffect(() => {
    const newFilters = [...filters];
    allUsers && (newFilters[4].dropDownItems = allUsers.map(user => {
      return { text: `${user?.FirstName?.toLowerCase()} ${user?.LastName?.toLowerCase()}`, value: user.Id, key: user.Id };
    })).sort((a, b) => (a.text > b.text) ? 1 : -1);
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers]);

  /**
     * Update dropdownItems in routineIds filter
     */
  useEffect(() => {
    const newFilters = [...filters];
    routines && (newFilters[2].dropDownItems = routines.map(routine => {
      return { text: `${routine?.Name?.toLowerCase()}`, value: routine.Id, key: routine.Id };
    })).sort((a, b) => (a.text > b.text) ? 1 : -1);
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers]);

  /**
   * Set table data s
   */
  useEffect(() => {
    if (receipts) {
      setTableData(receipts.map(({ Id, CreatedById, CreatedByName, Routine: { Name: routineName, Id: routineId }, DeviceId, DeviceName, StartedAt, Status, Duration }) => ({
        key: Id,
        sortData: {
          deviceName: DeviceName,
          duration: Duration?.toString() ?? '',
          createdBy: CreatedByName,
          routineIds: routineName,
          confirmed: Status?.toString() ?? ''
        },
        data: {
          timestamp: StartedAt ? formatDate(StartedAt.toString()) : '0',
          deviceName: <TableLink text={DeviceName} to={`/devices/${DeviceId}`} />,
          routineIds: <TableLink text={routineName} to={`/routines/${routineId}`} />,
          duration: durationFromTime(Duration),
          confirmed: Status === 1 ? <div className='true'><StyledCheckIcon /></div> : <div className='false'><StyledXIcon /></div>,
          createdBy: <TableLink text={CreatedByName} to={`/users/${CreatedById}`} />
        }
      }))
      );
    }
  }, [receipts]);

  const handleColumnOrderChange = (_e: React.SyntheticEvent<HTMLElement, MouseEvent>, _sortDirection: TableSortDirectionType, columnOrder?: string) => {
    const query = new URLSearchParams(location.search);
    if (columnOrder) {
      query.set('orderBy', columnOrder);
      query.delete('activePage');
      history.replace({ ...history.location, search: query.toString() });
    }
  };

  const handlePageChange = (_e: React.SyntheticEvent<HTMLElement, MouseEvent>, page: PaginationProps) => {
    if (page && page.activePage && !isNaN(page.activePage as number)) {
      setCurrentTablePage(page.activePage as number);
      const query = new URLSearchParams(location.search);
      query.set('activePage', page.activePage.toString());
      history.replace({ ...history.location, search: query.toString() });
    }
  };

  return (
    <>

      {
        showPage
          ? (
            <>
              <TableFilter
                filters={filters}
                tableData={tableData}
                tableTitle='Receipts'
                exportTableFnc={exportFnc}
                tableColumns={tableDataColumns as TableColumnType[]}
                excelSheetName='Receipts'
                showFilters={showFilters || false}
                permissionToViewContent
                exportable
              />
              <TableWrapper>
                <ServerSideTable
                  tableDataColumns={tableDataColumns}
                  tableDataRows={tableData}
                  loading={loading}
                  sortable
                  activePage={currentTablePage}
                  numPages={(nbrOfReceipts && receiptsPerPage) ? Math.ceil(nbrOfReceipts / receiptsPerPage) : 1}
                  onColumnOrderChange={handleColumnOrderChange}
                  onPageChange={handlePageChange}
                />
              </TableWrapper>
            </>
          )
          : (
            <>
              <FeatureDisabled name='Daily Inspection' />
            </>
          )
      }

    </>
  );
};

export default ReceiptTable;
