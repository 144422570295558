import { useEffect, useState, useRef } from 'react';

export const usePolling = (callback, delay) => {
  const savedCallback = useRef();
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = async () => {
      await savedCallback.current();
      setToggle(!toggle);
    };

    if (delay !== null) {
      const timeout = setTimeout(tick, delay);
      return () => clearTimeout(timeout);
    }
  }, [delay, toggle]);
}
