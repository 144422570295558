import { operationStatusDatakeys } from 'Common/Constant/OperationStatusDataKeys';
import { ConnectionStatus } from 'Common/Enums/DeviceConnectionStatus';
import { DeviceLog, OperationStatusData } from 'Interfaces';
import { getAliveFromTimestamp, getAliveFromValue } from 'Util/deviceIsAlive';
/**
 * Returns correct connection status for a device
 * @param {string} deviceId - Id of device
 * @param {DeviceLog[]} deviceLogs - Current devicelogs
 * @returns {ConnectionStatus} status - current status
 */
function useConnectionStatus(deviceId: string, deviceLogs: DeviceLog[]) {
  let status = ConnectionStatus.UNKNOWN;
  function isAlive(key:string, data:OperationStatusData[]) {
    const status = data.find(log => log.Key === key);
    if (!status) {
      return false;
    }
    // Check if value and timestamp are assumed to be "alive"
    return getAliveFromValue(status.Value) && getAliveFromTimestamp(status.Value);
  }
  const {
    LCU_PING_KEY,
    RX_VALID_SERIAL_DATA_KEY,
    TX_ACTIVE_KEY
  } = operationStatusDatakeys;
  const deviceLogsData = deviceLogs.find(device => device.Id === deviceId);
  if (deviceLogsData && deviceLogsData.Data) {
    if (isAlive(TX_ACTIVE_KEY, deviceLogsData.Data)) {
      status = ConnectionStatus.ACTIVE;
    } else if (isAlive(RX_VALID_SERIAL_DATA_KEY, deviceLogsData.Data)) {
      status = ConnectionStatus.TRANSMITTER_INACTIVE;
    } else if (isAlive(LCU_PING_KEY, deviceLogsData.Data)) {
      status = ConnectionStatus.RECEIVER_INACTIVE;
    }
  }
  return status;
}
export default useConnectionStatus;
