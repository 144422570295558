import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { setCurrentPage } from 'Store/Actions/appActions';

import { Layout } from 'access_ctrl-ui';
import { Page } from 'Interfaces';
import styled from 'styled-components';
import DocumentContainer from './DocumentContainer';

const StyledSection = styled(Layout.Section)`
  padding-left: ${({ theme }) => theme.sizes.md};
  padding-right: ${({ theme }) => theme.sizes.md};
  padding-bottom: ${({ theme }) => theme.sizes.lg};

  .contact-wrapper {
    padding-bottom: ${({ theme }) => theme.sizes.lg};

    .contact-details {
      margin-bottom: 0;
    }
  }

  p {
    font-size: 1.3rem;
  }
`;
interface ContactProps {
  title: string;
  email: string;
  phone: string;
  description: string;
}

const contactDetails: ContactProps[] = [
  {
    description: 'Questions, support for technical issues',
    title: 'Technical Support',
    email: 'support@akerstroms.se',
    phone: '+46 (0)241-250 50'
  },
  {
    description: 'Sales, orders, questions concerning deliveries',
    title: 'Sales and Front Office',
    email: 'frontoffice@akerstroms.se',
    phone: ' +46 (0)241-250 70'
  },
  {
    description: 'Reservation, handling service and repairs',
    title: 'Service & Repairs',
    email: 'repair@akerstroms.se',
    phone: '+46 (0)241-250 80'
  }
];

interface Props {
  setCurrentPage: (pageInfo: Page) => void;
}
const FAQ:React.FC<Props> = ({ setCurrentPage }) => {
  useEffect(() => {
    setCurrentPage({ name: 'FAQ' });
  }, [setCurrentPage]);

  return (
    <Layout.Wrapper>
      <DocumentContainer />
      <StyledSection>
        {
          contactDetails.map(({ title, description, phone, email }) => {
            return (
              <div className='contact-wrapper' key={description + title}>
                <h2 className='contact-title'>{title}</h2>
                <p className='contact-description'>{description}</p>
                <p className='contact-phone contact-details'>{phone}</p>
                <p className='contact-mail contact-details'>
                  <a href={`mailto:${email}`}>{email}</a>
                </p>
              </div>
            );
          })
        }
      </StyledSection>
    </Layout.Wrapper>
  );
};

export default connect(() => ({

}), dispatch => (
  {
    setCurrentPage: setCurrentPage(dispatch)
  }
))(FAQ);
