import { Button, CheckboxProps, Layout, Modal, Table, TableColumnType, TableKeyType, TableLink, TableRowType } from 'access_ctrl-ui';
import { FilterTypes } from 'Common/Enums/FilterTypes';
import { columnBName, columnCName } from 'Common/messages';
import TableFilter from 'Components/Global/TableFilter';
import { RoutinesOptionKey } from 'Features/Routines/Interfaces';
import { setRoutineForDevices } from 'Features/Routines/routineSlice';
import { FilterProps } from 'Interfaces/FilterProps';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from 'Store/hooks';
import styled from 'styled-components';

const filters: FilterProps[] = [
  { filterKey: 'name', filterType: FilterTypes.DROPDOWN_LIST, label: 'name', placeholder: 'All devices' },
  { filterKey: 'columnB', filterType: FilterTypes.DROPDOWN_LIST, label: columnBName, placeholder: 'All' },
  { filterKey: 'columnC', filterType: FilterTypes.DROPDOWN_LIST, label: columnCName, placeholder: 'All' }
];
const tableColumns: TableColumnType[] = [{ name: 'Name', key: 'name' }, { name: columnBName, key: 'columnB' }, { name: columnCName, key: 'columnC' }];
interface Props {
  open: boolean;
  close: () => void;
}
const Content = styled(Layout.Modal.Content)`
  padding: ${({ theme }) => theme.sizes.md};
  height: 40vh;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledHeader = styled(Layout.Modal.Header)`
  padding-left: ${({ theme }) => theme.sizes.lg};
`;
const AddDeviceToRoutine: React.FC<Props> = ({ open, close }) => {
  const { devices } = useAppSelector(state => state.device) ?? {};
  const { currentRoutine } = useAppSelector(state => state.routine) ?? {};
  const [rows, setRows] = useState<TableRowType[]>([] as TableRowType[]);
  const [filteredRows, setFilteredRows] = useState<TableRowType[]>([] as TableRowType[]);
  const [checkedList, setCheckedList] = useState<TableKeyType[]>([] as TableKeyType[]);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setRows(devices.map(device => ({
      sortData: {
        name: device.Name.toString()
      },
      key: device.Id,
      data: {
        name: <TableLink to={`/devices/${device.Id}`} text={device.Name} />,
        columnB: device.B,
        columnC: device.C
      }
    })));
  }, [devices]);

  const onClose = () => {
    clearAllFilters();
    setList();
    close();
  };

  const clearAllFilters = () => {
    const query = new URLSearchParams(location.search);
    filters.reduce((prevValue, currValue) => {
      // Delete queries
      query.delete(currValue.filterKey);

      // Delete filter values
      if (currValue.filterType === FilterTypes.INPUT_FIELD) {
        return ({ ...prevValue, [currValue.filterKey]: '' });
      } else if (currValue.filterType === FilterTypes.DROPDOWN_LIST) {
        return ({ ...prevValue, [currValue.filterKey]: [] });
      } else if (currValue.filterType === FilterTypes.DATE_RANGE) {
        query.delete(`${currValue.filterKey}_to`);
        query.delete(`${currValue.filterKey}_from`);
        return ({
          ...prevValue,
          [`${currValue.filterKey}_to`]: '',
          [`${currValue.filterKey}_from`]: ''
        });
      } else {
        return { ...prevValue, [currValue.filterKey]: '' };
      }
    }, {});

    history.replace({ ...history.location, search: query.toString() });
  };

  const setList = useCallback(() => {
    if (currentRoutine && devices) {
      const checkedList = devices.filter(device => device.Options?.[RoutinesOptionKey]?.Value
        ? JSON.parse(device.Options[RoutinesOptionKey].Value)?.[currentRoutine.Id] : false)
        .map(device => device.Id);
      setCheckedList(checkedList);
    }
  }, [currentRoutine, devices]);

  useEffect(() => {
    setList();
  }, [currentRoutine, setList]);

  const onSelectAll = (el: CheckboxProps) => {
    if (el.checked) {
      const all = filteredRows.map(row => row.key);
      setCheckedList((checkedList) => [...checkedList, ...all]);
    } else {
      setCheckedList([]);
    }
  };

  const onSelectSingle = (el: CheckboxProps, _: number, row: TableRowType) => {
    if (el.checked) {
      setCheckedList((checkedList) => [...checkedList, row.key]);
    } else {
      setCheckedList(checkedList.filter((item) => item !== row.key));
    }
  };
  const onSave = async () => {
    if (currentRoutine) {
      await dispatch(setRoutineForDevices({
        id: currentRoutine.Id,
        idList: checkedList.map(x => x.toString()) as string[] ?? []
      }));
      onClose();
    }
  };

  const pullData = (data: TableRowType[]) => {
    setFilteredRows(data);
  };

  return (
    <Modal
      showConfirmModal={false}
      open={open}
      confirmClose={onClose}
      closeIcon
      size='large'
      minHeight='15vh'
    >
      <Layout.Modal.Wrapper>
        <StyledHeader>Add device to routine</StyledHeader>
        <Content>
          <TableFilter
            filters={filters}
            tableData={rows}
            tableColumns={tableColumns}
            tableTitle=''
            pullFilteredTableData={pullData}
            permissionToViewContent
          />
          <Table
            checkbox
            checkedRows={checkedList}
            onSelectSingle={onSelectSingle}
            onSelectAll={onSelectAll}
            setQueryString={false}
            defaultSort='name'
            defaultSortDir='asc'
            tableDataColumns={tableColumns}
            tableDataRows={filteredRows}
          />
        </Content>
        <Layout.Modal.ButtonContainer>
          <Button onClick={onSave}>Save</Button>
        </Layout.Modal.ButtonContainer>

      </Layout.Modal.Wrapper>
    </Modal>
  );
};
export default AddDeviceToRoutine;
