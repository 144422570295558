import { IconTypes, ServerSideTableRowType, Table, TableColumnType, TableLink, TableRowType } from 'access_ctrl-ui';
import { convertDate, formatDate } from '../../Util/formatDate';
import React, { useEffect, useState } from 'react';
import TableFilter from '../../Components/Global/TableFilter';
import { User } from 'Interfaces';
import { loadingMessages } from 'Common/messages';
import { dateSort, nameSort, nameSortAlphaNum } from 'Util/sortFn';
import { FilterTypes } from 'Common/Enums/FilterTypes';
import { FilterProps } from 'Interfaces/FilterProps';

const tableDataColumns:TableColumnType[] = [
  {
    name: 'First name',
    key: 'FirstName',
    sortFn: (dir, col) => {
      return nameSort(dir, col);
    }
  },
  {
    name: 'Last name',
    key: 'LastName',
    sortFn: (dir, col) => {
      return nameSort(dir, col);
    }
  },
  {
    name: 'Employee number',
    key: 'employeeId',
    sortFn: (dir, col) => {
      return nameSortAlphaNum(dir, col);
    }
  },
  {
    name: 'Access to',
    key: 'accessTo',
    sortFn: (dir, col) => {
      return dateSort(dir, col);
    }
  },
  { name: 'Admin dashboard user', key: 'isAdmin' }
];

const filters: FilterProps[] = [
  { filterKey: 'FirstName', label: 'First Name', filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'All' },
  { filterKey: 'LastName', label: 'Last Name', filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'All' },
  { filterKey: 'employeeId', label: 'Employee id', filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'All' },
  { filterKey: 'accessTo', label: 'Access', filterType: FilterTypes.DATE_RANGE },
  { filterKey: 'isAdmin', label: 'Administrator', filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'All' }
];

/**
 * This component renders a filterable table with all receipts
 * @returns A filterable table with all receipts
 */
const UsersTable:React.FC<{users?: User[], loading: boolean, permissionToViewContent: boolean, showFilters?: boolean, CustomButton?: JSX.Element}> = ({ users, loading, permissionToViewContent, showFilters, CustomButton }) => {
  const [tableData, setTableData] = useState<TableRowType[]>([] as TableRowType[]);
  const [filteredTableData, setFilteredTableData] = useState<TableRowType[]>([] as TableRowType[]);
  /**
   * Set table data s
   */
  useEffect(() => {
    if (users) {
      const _tData = users.filter(user => user.FirstName && user.LastName).map((user) => {
        let accessToSortData;
        let accessTo = '';
        if (user.Options && user.Options['access.to']) {
          accessTo = user.Options['access.to'].Value ? formatDate(user.Options['access.to'].Value) : '';
          accessToSortData = convertDate(user.Options['access.to'].Value);
        }
        return {
          key: user.Id,
          sortData: {
            FirstName: user.FirstName,
            LastName: user.LastName,
            accessTo: (accessToSortData || '').toString()
          },
          data: {
            FirstName: <TableLink to={`/users/${user.Id}`} iconType={IconTypes.User} text={user.FirstName} />,
            LastName: <TableLink to={`/users/${user.Id}`} text={user.LastName} />,
            employeeId: user.EmployeeId,
            accessTo: accessTo,
            isAdmin: user.IsAdmin ? 'Yes' : 'No'
          }
        };
      });

      setTableData(_tData);
    }
  }, [users]);

  /**
   * Pull data and set filterTableData state
   * @param data filteredTableData
   */
  const pullFilteredData = (data: TableRowType[]) => {
    setFilteredTableData(data);
  };

  return (
    <>
      <TableFilter
        filters={filters}
        pullFilteredTableData={pullFilteredData}
        tableData={tableData}
        tableTitle='Users'
        tableColumns={tableDataColumns}
        excelSheetName='Users'
        CustomButton={CustomButton}
        permissionToViewContent={permissionToViewContent}
        showFilters={showFilters || false}
        exportable
      />
      <Table
        setQueryString={false}
        rowsPerPage={25}
        defaultSortDir='asc'
        defaultSort='FirstName'
        tableDataColumns={tableDataColumns}
        tableDataRows={permissionToViewContent ? filteredTableData : [] as ServerSideTableRowType[]}
        loading={loading}
        loadingMessage={loadingMessages.LOADING_USERS}
        emptyText={permissionToViewContent ? 'No users' : 'You don\'t have permission to view users'}
        sortable
      />
    </>
  );
};

export default UsersTable;
