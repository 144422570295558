import styled, { useTheme } from 'styled-components';
import { Theme } from 'access_ctrl-ui';
import useStatusColor from 'Hooks/useStatusColor';
import ConnectionStatusIcon from './ConnectionStatusIcon';
import useConnectionStatus from 'Hooks/useConnectionStatus';
import { DeviceLog } from 'Interfaces';

interface StatusContainerProps {
  $color: string;
}
const StatusContainer = styled.div<StatusContainerProps>`
  display: flex;
  width: 100%;
  color: ${({ $color }) => $color};
  align-items: center;

  p {
    margin-left: ${({ theme }) => theme.sizes.md};
  }
`;

interface StatusLineProps {
  deviceId: string;
  deviceLogs: DeviceLog[];
  stroke?: boolean;
}
const DeviceStatusItem: React.FC<StatusLineProps> = ({ deviceId, deviceLogs, stroke }) => {
  const theme:Theme = useTheme();
  const status = useConnectionStatus(deviceId, deviceLogs);
  const color = useStatusColor(status, theme);
  const icon = <ConnectionStatusIcon status={status} theme={theme} stroke={stroke} />;
  return <StatusContainer $color={color}>{icon}<p>{status}</p></StatusContainer>;
};

export default DeviceStatusItem;
