import { Button, HeaderRow, Table, TableColumnType } from 'access_ctrl-ui';
import { ModalTypes } from 'Common/Enums';
import useGetRoutineRows from 'Features/Routines/Hooks/useGetRoutineRows';
import { useRoutineModal } from 'Features/Routines/Hooks/useRoutineModal';
import { DailyRoutine } from 'Features/Routines/Interfaces';
import { useState } from 'react';
import styled from 'styled-components';

const StyledHeaderRow = styled(HeaderRow)`
  display: flex;
  justify-content: space-between;
`;
const columns: TableColumnType[] = [
  {
    name: 'Name',
    key: 'routine'
  },
  {
    name: 'Routine',
    key: 'type'
  },
  {
    name: 'Frequency',
    key: 'frequency'
  },
  {
    name: 'Triggers',
    key: 'trigger'
  }
];
const RoutinesPane:React.FC<{routines?: DailyRoutine[], loading: boolean }> = ({ routines, loading }) => {
  const rows = useGetRoutineRows(routines);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const close = () => setModalOpen(false);
  const onClick = () => setModalOpen(true);
  const addRoutineModal = useRoutineModal({ open: modalOpen, type: ModalTypes.EDIT_ROUTINE_MODAL, close, title: 'Add Routine' });
  return (
    <>
      <StyledHeaderRow>
        <h2>Routines</h2>
        <Button onClick={onClick}>Add routine</Button>
      </StyledHeaderRow>
      <Table
        loading={loading}
        setQueryString={false}
        key='routines'
        tableDataColumns={columns}
        tableDataRows={rows}
      />
      {addRoutineModal}
    </>
  );
};
export default RoutinesPane;
