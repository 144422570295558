import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Plus } from 'react-feather';

import { getAllDevices } from 'Store/Actions/deviceActions';
import { setCurrentPage, toggleModal } from 'Store/Actions/appActions';
import { permissions } from '../../../Common/Constant';

import { Layout, Button } from 'access_ctrl-ui';
import { RootState } from 'Store/Reducers';
import { Device, Page, User } from 'Interfaces';
import { ModalTypes } from 'Common/Enums';
import DevicesTable from 'Features/Devices/DevicesTable';

interface Props {
  devices: Device[];
  setCurrentPage: (pageInfo: Page) => void;
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
  fetchingDevices: boolean;
  getAllDevices: (withUsers?: boolean) => Promise<void>;
  fetchingLoggedInUser: boolean;
  loggedInUser: User;
}
const Devices:React.FC<Props> = ({ devices, setCurrentPage, toggleModal, fetchingDevices, getAllDevices, fetchingLoggedInUser, loggedInUser }) => {
  const [viewDevicesAction, setViewDevicesAction] = useState(false);
  const [deviceAdminAction, setDeviceAdminAction] = useState(false);

  useEffect(() => {
    setCurrentPage({ name: 'Devices' });
  }, [setCurrentPage]);

  useEffect(() => {
    if (viewDevicesAction) {
      getAllDevices(false);
    }
  }, [getAllDevices, viewDevicesAction]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.Options && loggedInUser.Options['permissions.actions']) {
      const actions = JSON.parse(loggedInUser.Options['permissions.actions'].Value);

      setViewDevicesAction((actions.includes(permissions.viewDevices) || actions.includes(permissions.deviceAdmin)) || actions.includes(permissions.all));

      setDeviceAdminAction(actions.includes(permissions.deviceAdmin) || actions.includes(permissions.all));
    } else if (loggedInUser) {
      setViewDevicesAction(true);
      setDeviceAdminAction(true);
    }
  }, [loggedInUser]);

  const onAddDevice = () => {
    toggleModal(ModalTypes.ADD_DEVICE_MODAL);
  };

  return (
    <Layout.Wrapper>
      <Layout.Section minHeight='15rem'>
        <DevicesTable
          loading={fetchingDevices || fetchingLoggedInUser}
          devices={(devices && viewDevicesAction) ? devices : undefined}
          permissionToViewContent={viewDevicesAction}
          CustomButton={deviceAdminAction ? <Button onClick={onAddDevice}><Plus />Add device</Button> : undefined}
          showFilters
        />
      </Layout.Section>
    </Layout.Wrapper>
  );
};

export default connect(({ app, device } : RootState) => ({
  devices: device.devices,
  loggedInUser: app.loggedInUser,
  fetchingLoggedInUser: app.fetchingLoggedInUser,
  fetchingDevices: device.fetchingDevices
}),
(dispatch) => ({
  setCurrentPage: setCurrentPage(dispatch),
  toggleModal: toggleModal(dispatch),
  getAllDevices: getAllDevices(dispatch)
}))(Devices);
