import { AuditLogItem } from 'Interfaces';
import { AuditLogActionType } from 'Store/ActionEnums';
import { AuditLogActions } from 'Store/ActionTypes';
import { AuditLogState } from 'Store/Interfaces/States/AuditLogState';

const defaultState: AuditLogState = {
  logs: [] as AuditLogItem[],
  fetchingLogs: false,
  fetchingExcelAuditLog: false
};

const auditLog = (state = defaultState, action: AuditLogActions):AuditLogState => {
  switch (action.type) {
    case AuditLogActionType.FILTER_AUDIT_LOG_START:
      return {
        ...state,
        fetchingLogs: true
      };

    case AuditLogActionType.GOT_FILTERED_AUDIT_LOG:
      return {
        ...state,
        logs: action.payload,
        fetchingLogs: false
      };

    case AuditLogActionType.FAILED_GETTING_FILTERED_AUDIT_LOG:
      return {
        ...state,
        fetchingLogs: false
      };

    case AuditLogActionType.EXCEL_AUDIT_LOG_FILTER_START:
      return {
        ...state,
        fetchingExcelAuditLog: true
      };

    case AuditLogActionType.GOT_EXCEL_AUDIT_LOG:
      return {
        ...state,
        fetchingExcelAuditLog: false
      };

    case AuditLogActionType.ERROR_FETCHING_EXCEL_AUDIT_LOG:
      return {
        ...state,
        fetchingExcelAuditLog: false
      };

    default:
      return state;
  }
};

export default auditLog;
