import { Marker } from '@react-google-maps/api';
import { Theme } from 'access_ctrl-ui';
import ConnectionStatusIcon from 'Components/Global/ConnectionStatusIcon';
import useConnectionStatus from 'Hooks/useConnectionStatus';
import { DeviceLog, LcuPosition } from 'Interfaces';
import { GpsPosition } from 'Interfaces/GpsPosition';
import ReactDOMServer from 'react-dom/server';
import { useTheme } from 'styled-components';
interface Props {
  deviceLogs:DeviceLog[];
  onClickMarker: (_e:React.MouseEvent|React.TouchEvent, LcuPosition:LcuPosition) => void;
  place:LcuPosition;
  onLoadMarker: (marker: google.maps.MVCObject, place:LcuPosition) => void;
}
function StyledMarker({ place, deviceLogs, onClickMarker, onLoadMarker }:Props) {
  const status = useConnectionStatus(place.id, deviceLogs);
  const theme:Theme = useTheme();
  function getEncodedElementSvg() { return 'data:image/svg+xml,' + escape(ReactDOMServer.renderToStaticMarkup(<ConnectionStatusIcon larger status={status} theme={theme} stroke />)); }

  return (
    <Marker
      key={place.id}
      position={place.pos as GpsPosition}
      icon={getEncodedElementSvg()}
      onClick={e => onClickMarker(e, place)}
      onLoad={marker => onLoadMarker(marker, place)}
    />
  );
}
export default StyledMarker;
