import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import defaultUserOptions from '../../Common/defaultUserOptions';
import { loadingMessages } from '../../Common/messages';
import { ModalTypes } from '../../Common/Enums';
import { getUserOptions, postUserOption } from 'Store/Actions/optionsActions';
import { toggleModal } from 'Store/Actions/appActions';

import EditOptionsModal from 'Components/Modals/EditOptionsModal';
import { RootState } from 'Store/Reducers';
import { ChangedDates, ChangedInputs, ModalOptions, User } from 'Interfaces';

interface Props {
  optionsForUser: ModalOptions | null | undefined;
  currentUser: User | null;
  getUserOptions: (userId: string) => Promise<void>;
  postUserOption: (userId: string, key: string, value: string | boolean | number | unknown) => Promise<void>;
  toggleModal: (modalName: ModalTypes, forcedOpenState: boolean | null) => void;
  open: boolean;
}

export const EditUserOptionsModal:React.FC<Props> = ({ toggleModal, optionsForUser, postUserOption, currentUser, getUserOptions, open }) => {
  const [loadedOptions, setOptionsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setOptionsLoaded(false);
      if (currentUser?.Id) {
        getUserOptions(currentUser.Id).then(() => {
          setOptionsLoaded(true);
        });
      }
    }
  }, [currentUser, getUserOptions, open]);

  useEffect(() => {
    setOptionsLoaded(true);
  }, [optionsForUser]);

  const onSubmit = async (changedInputs: ChangedInputs, changedDates: ChangedDates) => {
    setLoading(true);
    const promises = [];

    for (const [key, value] of Object.entries(changedInputs)) {
      if (currentUser?.Id) {
        const { value: val } = value;
        promises.push(postUserOption(currentUser.Id, key, val));
      }
    }

    for (const [key, value] of Object.entries(changedDates)) {
      if (currentUser?.Id) {
        promises.push(postUserOption(currentUser.Id, key, value));
      }
    }

    await Promise.all(promises);
    setLoading(false);
  };

  const onClose = () => {
    toggleModal(ModalTypes.EDIT_USER_OPTIONS_MODAL, false);
  };

  return (
    <EditOptionsModal
      options={optionsForUser}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      saving={loading}
      loadedOptions={loadedOptions}
      header='Edit user options'
      defaultOptions={defaultUserOptions}
      loadingMessage={loadingMessages.LOADING_USER_OPTIONS}
    />
  );
};

export default connect(({ modalsOpen, options, user }: RootState) => ({
  open: modalsOpen[ModalTypes.EDIT_USER_OPTIONS_MODAL],
  currentUser: user.currentUser,
  optionsForUser: options.optionsForUser
}), dispatch => ({
  toggleModal: toggleModal(dispatch),
  getUserOptions: getUserOptions(dispatch),
  postUserOption: postUserOption(dispatch)
}))(EditUserOptionsModal);
