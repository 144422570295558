import { AxiosError } from 'axios';
import { StatusMessageTypes } from '../../Common/Enums';
import { Dispatch } from 'redux';
import { StatusType } from 'types';

export const actionTypes = {
  ADD_FEEDBACK_MESSAGE: 'ADD_FEEDBACK_MESSAGE',
  REMOVE_FEEDBACK_MESSAGE: 'REMOVE_FEEDBACK_MESSAGE'
};

/**
  Construct error message to display to user
  @param message The message to show
  @param error The error object to append to message
*/
export const makeErrorPayload = (message: string, error: AxiosError) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return `${message} ${error.response.status} ${error.response.statusText}: ${error.response.data}`;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return `${message} ${error.request}`;
  } else {
    // Something happened in setting up the request that triggered an Error
    return `${message} ${error.message}`;
  }
};

/**
  Add an error message to display to the user
  @param content The content of the message
  @param error The error object to append to the content if any
  @param autoClose If the message should close automatically or not
*/
export const addErrorMessage =
  (
    dispatch: Dispatch
  ) =>
    (content: string, error: unknown | null = null, autoClose = true) => {
      let message = content;

      if (error) {
        message = makeErrorPayload(content, error as AxiosError);
      }

      addFeedbackMessage(dispatch)(message, StatusMessageTypes.ERROR, autoClose);
    };

/**
  Add an success message to display to the user
  @param content The content of the message
  @param autoClose If the message should close automatically or not
*/
export const addSuccessMessage =
  (dispatch: Dispatch) =>
    (content: string, autoClose = true) => {
      addFeedbackMessage(dispatch)(content, StatusMessageTypes.SUCCESS, autoClose);
    };

/**
  Add an warning message to display to the user
  @param content The content of the message
  @param autoClose If the message should close automatically or not
*/
export const addWarningMessage =
  (dispatch: Dispatch) =>
    (content: string, autoClose = true) => {
      addFeedbackMessage(dispatch)(content, StatusMessageTypes.WARNING, autoClose);
    };

/**
  Add a feedback message to display to the user
  @param content The content of the message
  @param autoClose If the message should close automatically or not
*/
export const addFeedbackMessage =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (dispatch: Dispatch) =>
    (content: string, type: StatusType, autoClose = true) => {
      // Todo: Figure out the correct typ
      dispatch({
        type: actionTypes.ADD_FEEDBACK_MESSAGE,
        payload: {
          content,
          type,
          autoClose,
          id: Math.random().toString(36).substring(7)
        }
      });
    };

/**
  Remove a feedback message by id
  @param id The id of the message to remove
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeFeedbackMessage = (dispatch: Dispatch) => (id: string) => {
  dispatch({
    type: actionTypes.REMOVE_FEEDBACK_MESSAGE,
    payload: {
      id
    }
  });
};
