import { CheckboxProps } from 'access_ctrl-ui';
import { DayType, TriggerTime } from 'Features/Routines/Interfaces';
import { FormEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import DaySelector from './DaySelector';
import TimeSelector from './TimeSelector';

interface Props {
  onSave: (times:TriggerTime[], days: DayType[]) => void;
  days?: DayType[];
  times?: TriggerTime[];
}
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  width: 100%;
`;

const AddTrigger:React.FC<Props> = ({ onSave, times: savedTimes, days: savedDays }) => {
  const [state, setState] = useState<{ days: DayType[], times: TriggerTime[] }>({ days: savedDays ?? [], times: savedTimes ?? [] });
  const { times, days } = state;
  const onTimeSelect = (time:TriggerTime) => {
    setState(state => ({
      ...state,
      times: [...times.filter(t => t !== time), time]
    }));
  };
  const onTimeRemove = (time: TriggerTime) => {
    setState(state => ({
      ...state,
      times: times.filter(t => t !== time)
    }));
  };

  const onCheck = (_:FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    setState(state => ({
      ...state,
      days: data.checked ? [...state.days, data.value as DayType] : [...state.days.filter(x => x !== data.value as DayType)]
    }));
  };
  useEffect(() => {
    const { times, days } = state || {};
    onSave(times, days);
  }, [state, onSave]);
  return (
    <StyledContainer>
      <DaySelector onCheck={onCheck} days={days} />
      <TimeSelector
        onSelectDate={onTimeSelect} onRemove={onTimeRemove} times={times}
      />
    </StyledContainer>
  );
};
export default AddTrigger;
