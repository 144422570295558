import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAppSelector } from 'Store/hooks';
import { useDispatch, connect } from 'react-redux';
import { setCurrentPage, getLicense } from 'Store/Actions/appActions';
import { Layout } from 'access_ctrl-ui';
import { Page } from 'Interfaces';
import FeatureDisabled from '../Feature';
import { features } from 'Common/Constant/Features';

const StyledSection = styled(Layout.Section)`
  &&& {
    & .report-style-class {
      height: 100%;
    }

    & .backgroundContainer {
      background: ${({ theme }) => theme.appBg} !important;
    }
  }
`;
interface Props {
  setCurrentPage: ((pageInfo: Page) => void);
}
const NoPermission: React.FC<Props> = ({ setCurrentPage }) => {
  // const [url, setUrl] = useState<string>();
  // const [showReport, setShowReport] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const { license } = useAppSelector(state => state.app);

  useEffect(() => {
    setCurrentPage({ name: 'Production Report' });
  }, [setCurrentPage]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (license) {
      for (const [key, value] of Object.entries(license.Features)) {
        if (value.Enabled === true && key.toLowerCase() === features.productionData) {
          setShowPage(true);
        }
      }
    }
  }, [license]);
  useEffect(() => {
    if (!license) {
      getLicense(dispatch)();
    }
  }, [license]);
  // const updateUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.currentTarget;
  //   setUrl(value);
  // };

  return (
    <Layout.Wrapper>
      <StyledSection>
        {
          showPage
            ? (
              // <>
              //   {
              //     showReport &&
              <iframe title='Production Data' width='100%' height='100%' src='https://app.powerbi.com/view?r=eyJrIjoiOTY0YThiMjctNzVmNi00MzhjLTliYWItZjZhNjliNjA5YTRlIiwidCI6Ijk0YzdkNzIxLWM4NTktNGI2Yi1iMDA5LTlmMGNkYzNkOTYyNiIsImMiOjh9' />
          //     <PowerBIEmbed
          //       embedConfig={{
          //         type: 'report', // Supported types: report, dashboard, tile, visual and qna
          //         id: 'korv',
          //         embedUrl: url,
          //         accessToken: '',
          //         tokenType: models.TokenType.Embed,
          //         // TODO: Find out what should be here instead
          //         // permissions: models.Permissions.View,
          //         settings: {
          //           filterPaneEnabled: false,
          //           navContentPaneEnabled: false,
          //           bars: {
          //             actionBar: {
          //               visible: false
          //             }
          //           },
          //           panes: {
          //             bookmarks: {
          //               visible: false
          //             },
          //             fields: {
          //               expanded: false
          //             },
          //             filters: {
          //               expanded: false,
          //               visible: false
          //             },
          //             pageNavigation: {
          //               visible: false
          //             },
          //             selection: {
          //               visible: false
          //             },
          //             syncSlicers: {
          //               visible: false
          //             },
          //             visualizations: {
          //               expanded: false
          //             }
          //           },
          //           background: models.BackgroundType.Transparent
          //         }
          //       }}
          //       eventHandlers={
          //         new Map([
          //           ['loaded', function () { console.log('Report loaded'); }],
          //           ['rendered', function () { console.log('Report rendered'); }],
          //           ['error', function (event) { console.log(event?.detail); }]
          //         ])
          //       }
          //       cssClassName='report-style-class'
          //     />
          // }{
          //   !showReport && <Input onChange={updateUrl} placeholder='url' style={{ marginBottom: '11px' }} />
          // }
              //   <Button onClick={() => setShowReport(!showReport)}>{`${!showReport ? 'Show' : 'Hide'} report`}</Button>
              // </>
            )
            : (
              <>
                <FeatureDisabled name='Production data' />
              </>
            )
        }
      </StyledSection>
    </Layout.Wrapper>
  );
};

export default connect(() => ({

}), dispatch => (
  {
    setCurrentPage: setCurrentPage(dispatch)
  }
))(NoPermission);
