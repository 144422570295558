import React, { useState, useEffect, FC } from 'react';
import {
  Home as HomeIcon,
  Box,
  Users,
  Layers,
  Zap,
  Calendar,
  HelpCircle
} from 'react-feather';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { permissions } from '../../Common/Constant';
import MainMenuItem from './MainMenuItem';
import { User } from 'Interfaces';
import { RootState } from '../../Store/Reducers';
import useCheckInput from 'Hooks/useCheckInput';

const Menu = styled.ul`
  background: none;
  list-style: none;
  margin: 0;
  padding: 0;
`;

// TODO: Update when Store is typed
interface Props {
  loggedInUser?: User;
}

export const MainMenu: FC<Props> = ({ loggedInUser }) => {
  const [dashboardAction, setDashboardAction] = useState(false);
  const [devicesAction, setDevicesAction] = useState(false);
  const [usersAction, setUsersAction] = useState(false);
  const [auditLogAction, setAuditLogAction] = useState(false);
  const [routinesAction, setRoutinesAction] = useState(false);
  const showPowerUser = useCheckInput('show', 500);

  useEffect(() => {
    if (loggedInUser?.Options?.['permissions.actions']) {
      const actions = JSON.parse(
        loggedInUser.Options['permissions.actions'].Value
      );
      setDashboardAction(
        actions.includes(permissions.viewDashboard) ||
          actions.includes(permissions.all)
      );

      setDevicesAction(
        actions.includes(permissions.viewDevices) ||
          actions.includes(permissions.deviceAdmin) ||
          actions.includes(permissions.all)
      );

      setUsersAction(
        actions.includes(permissions.viewUsers) ||
          actions.includes(permissions.userAdmin) ||
          actions.includes(permissions.all)
      );

      setAuditLogAction(
        actions.includes(permissions.viewAuditLog) ||
          actions.includes(permissions.all)
      );
      setRoutinesAction(
        actions.includes(permissions.userAdmin) ||
          actions.includes(permissions.dailyInspection) ||
          actions.includes(permissions.all)
      );
    } else {
      setDashboardAction(true);
      setDevicesAction(true);
      setUsersAction(true);
      setAuditLogAction(true);
      setRoutinesAction(true);
    }
  }, [loggedInUser]);

  return (
    <Menu>
      {dashboardAction && (
        <MainMenuItem name='Dashboard' exact to='/' MenuIcon={HomeIcon} />
      )}
      {devicesAction && (
        <MainMenuItem name='Devices' to='/devices' MenuIcon={Box} />
      )}
      {usersAction && (
        <MainMenuItem name='Users' to='/users' MenuIcon={Users} />
      )}
      {auditLogAction && (
        <MainMenuItem name='Audit Log' to='/auditlog' MenuIcon={Layers} />
      )}
      {routinesAction &&
        <MainMenuItem name='Routines' to='/routines' MenuIcon={Calendar} />}
      {/*       {productionDataAction && (
        <MainMenuItem
          name='Production Data'
          to='/productiondata'
          MenuIcon={Activity}
        />
      )} */}
      {/* <MainMenuItem name='FAQ' to='/FAQ' MenuIcon={HelpCircle} /> */}
      {showPowerUser &&
        <MainMenuItem name='Power user' to='/poweruser' MenuIcon={Zap} />}
      <MainMenuItem name='FAQ' to='/FAQ' MenuIcon={HelpCircle} />
    </Menu>
  );
};

export default connect(
  ({ app: { loggedInUser } }: RootState) => ({
    loggedInUser: loggedInUser
  }),
  null
)(MainMenu);
