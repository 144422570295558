import { ActionActionType } from 'Store/ActionEnums';
import { ActionActions } from 'Store/ActionTypes';
import { ActionState } from 'Store/Interfaces/States/ActionState';
const defaultState: ActionState = {
  messages: []
};

const action = (state = defaultState, action: ActionActions): ActionState => {
  switch (action.type) {
    case ActionActionType.ADD_FEEDBACK_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };

    case ActionActionType.REMOVE_FEEDBACK_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter(message => message.id !== action.payload.id)
      };

    default:
      return state;
  }
};

export default action;
