import { DebugActionTypes } from '../ActionEnums';
import { DebugMessage } from 'Interfaces/DebugMessage';
import { DebugState } from 'Store/Interfaces/States/DebugState';
import { DebugActions } from 'Store/ActionTypes';

const defaultState: DebugState = {
  fetchingDebugMessages: false,
  fetchingAllDebugMessages: false,
  fetchingSystemMessages: false,
  debugMessages: [] as DebugMessage[]
};

const debug = (state = defaultState, action: DebugActions):DebugState => {
  switch (action.type) {
    case DebugActionTypes.GET_DEVICE_DEBUG_MESSAGES_START:
      return {
        ...state,
        fetchingDebugMessages: true
      };
    case DebugActionTypes.GOT_DEVICE_DEBUG_MESSAGES:
      return {
        ...state,
        fetchingDebugMessages: false,
        debugMessages: action.payload
      };
    case DebugActionTypes.FAILED_GETTING_DEVICE_DEBUG_MESSAGES:
      return {
        ...state,
        fetchingDebugMessages: false
      };
    case DebugActionTypes.GET_ALL_DEBUG_MESSAGES_START:
      return {
        ...state,
        fetchingAllDebugMessages: true
      };
    case DebugActionTypes.GOT_ALL_DEBUG_MESSAGES:
      return {
        ...state,
        fetchingDebugMessages: false,
        allDebugMessages: action.payload
      };
    case DebugActionTypes.FAILED_GETTING_ALL_DEBUG_MESSAGES:
      return {
        ...state,
        fetchingAllDebugMessages: false
      };
    case DebugActionTypes.GET_SYSTEM_MESSAGES_START:
      return {
        ...state,
        fetchingSystemMessages: true
      };
    case DebugActionTypes.GOT_SYSTEM_MESSAGES:
      return {
        ...state,
        fetchingDebugMessages: false,
        systemMessages: action.payload
      };
    case DebugActionTypes.FAILED_GETTING_SYSTEM_MESSAGES:
      return {
        ...state,
        fetchingSystemMessages: false
      };
    default:
      return state;
  }
};

export default debug;
