import { createBrowserHistory, History } from 'history';
import createMiddlewares from 'Middlewares/index';
import { configureStore } from '@reduxjs/toolkit';
import { createRootReducer } from './Reducers';

const createStoreInternal = (history: History) => {
  const middlewares = createMiddlewares(history);
  const rootReducer = createRootReducer(history);
  return configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: getDefaultMiddleware => getDefaultMiddleware({ immutableCheck: false }).concat(middlewares)
  });
};
export const browserHistory = createBrowserHistory({ basename: '/' });
const store = createStoreInternal(browserHistory);
export default store;
