import { Dispatch } from 'redux';
import { accessControlApi, webApi } from '../../Common/api';
import { addErrorMessage } from './feedbackMessage';
import { apiCallErrorMessages } from '../../Common/messages';
import { DebugActionTypes } from 'Store/ActionEnums';

/**
  Get debug messages
  @param deviceId The ID of the device to get logs for
  @return The request promise
*/
export const getDebugMessages = (dispatch: Dispatch) => async (deviceId: string) => {
  dispatch({ type: DebugActionTypes.GET_DEVICE_DEBUG_MESSAGES_START });

  try {
    const result = await webApi.get(`debug/messages/${deviceId}`);
    if (result && result.data) {
      dispatch({
        type: DebugActionTypes.GOT_DEVICE_DEBUG_MESSAGES,
        payload: result.data
      });
      return result.data;
    }
    dispatch({
      type: DebugActionTypes.FAILED_GETTING_DEVICE_DEBUG_MESSAGES
    });
  } catch (error) {
    dispatch({
      type: DebugActionTypes.FAILED_GETTING_DEVICE_DEBUG_MESSAGES
    });
    addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_GETTING_DEBUG_MESSAGES_ERROR_MESSAGE, error);
  }
};
export const getAllDebugMessages = (dispatch: Dispatch) => async () => {
  dispatch({ type: DebugActionTypes.GET_ALL_DEBUG_MESSAGES_START });

  try {
    const result = await webApi.get('debug/messages/');
    if (result && result.data) {
      dispatch({
        type: DebugActionTypes.GOT_ALL_DEBUG_MESSAGES,
        payload: result.data
      });
      return result.data;
    }
    dispatch({
      type: DebugActionTypes.FAILED_GETTING_ALL_DEBUG_MESSAGES
    });
  } catch (error) {
    dispatch({
      type: DebugActionTypes.FAILED_GETTING_ALL_DEBUG_MESSAGES
    });
    addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_GETTING_DEBUG_MESSAGES_ERROR_MESSAGE, error);
  }
};

export const getSystemMessages = (dispatch: Dispatch) => async () => {
  dispatch({ type: DebugActionTypes.GET_SYSTEM_MESSAGES_START });

  try {
    const result = await accessControlApi.get('debug/messages/');
    if (result && result.data) {
      dispatch({
        type: DebugActionTypes.GOT_SYSTEM_MESSAGES,
        payload: result.data
      });
      return result.data;
    }
    dispatch({
      type: DebugActionTypes.FAILED_GETTING_SYSTEM_MESSAGES
    });
  } catch (error) {
    dispatch({
      type: DebugActionTypes.FAILED_GETTING_SYSTEM_MESSAGES
    });
    addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_GETTING_DEBUG_MESSAGES_ERROR_MESSAGE, error);
  }
};
