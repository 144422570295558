import { useEffect, useState } from 'react';
import { activeRoutines, RoutineType } from '../Interfaces';

/**
 * Get the routine types
 */
export default function useRoutineTypes() {
  const [values, setValues] = useState<{ amount: number, routines:(RoutineType | string)[]}>();
  useEffect(() => {
    const routines = Object.values(RoutineType)
      .filter(x => isNaN(Number(x)));
    const amount = routines?.filter(routine => activeRoutines?.includes(routine.toString()))?.length;
    setValues({ amount, routines });
  }, []);
  return values;
}
