import { Layout, TableRowType } from 'access_ctrl-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'Store/hooks';
import { getReceiptsWithFilter } from '../receiptSlice';
import ReceiptTable from '../ReceiptTable/ReceiptTable';
import { setCurrentPage } from 'Store/Actions/appActions';
import { Receipt, ReceiptFilter } from '../Interfaces';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ReceiptsOrderByColumn } from 'Common/Enums/ReceiptsOrderByColumn';
import { RoutineStatus, Translate } from 'Features/Routines/Interfaces';
import { webApi } from 'Common/api';
import { formatDate } from 'Util/formatDate';
import _ from 'lodash';

const rowsPerPage = 40;
const initReceiptFilter: ReceiptFilter = {
  startIndex: 0,
  stopIndex: rowsPerPage - 1
};
const ReceiptsPage: React.FC = () => {
  const { receipts, loading: recieptsLoading, nbrOfReceipts } = useAppSelector(state => state.receipts);
  const [fetchFilters, setFetchFilters] = useState<ReceiptFilter>(initReceiptFilter);
  const dispatch = useDispatch();
  const location = useLocation();

  /**
   * Set filters for fetching data
   */
  const setFilterFromParams = useCallback(() => {
    const params = queryString.parse(location.search);

    const activePage = +(params.activePage || 1);
    const startIndex = (+activePage - 1) * rowsPerPage;
    const stopIndex = activePage * rowsPerPage - 1;

    setFetchFilters({
      userIds: params.createdBy?.toString().split(',') ?? undefined,
      deviceIds: params.deviceId?.toString().split(',') ?? undefined,
      from: params.timestamp_from ? new Date(params.timestamp_from as string) : null,
      to: params.timestamp_to ? new Date(params.timestamp_to as string) : null,
      startIndex: startIndex,
      stopIndex: stopIndex,
      status: params.confirmed?.toString().split(',') as unknown as RoutineStatus[] ?? undefined,
      routineIds: params.routineIds?.toString().split(',') ?? undefined,
      duration: params.duration ? parseInt(params.duration.toString()) : undefined,
      orderBy: params.orderBy?.toString() as unknown as ReceiptsOrderByColumn ?? undefined
    });
  }, [location]);

  /**
   * SetFilterFromParams
   */
  useEffect(() => {
    setFilterFromParams();
  }, [setFilterFromParams]);

  useEffect(() => {
    dispatch(getReceiptsWithFilter(fetchFilters));
  }, [fetchFilters, dispatch]);

  useEffect(() => {
    setCurrentPage(dispatch)({ name: 'Receipts' });
  }, [dispatch]);

  /**
   * Fetching all data when downloading excel data sheet
   * @returns TableRows as a promise
   */
  const getExcelData = async () => {
    const _filterReq = _.clone(fetchFilters);
    _filterReq.stopIndex = nbrOfReceipts || rowsPerPage;
    _filterReq.startIndex = 1;
    const { data: { Item1: _receipts } } = await webApi.post<{ Item1: Receipt[], Item2: number}>('/receipt', _filterReq);

    const _tdata: TableRowType[] = _receipts.map(({ CreatedByName, DeviceName, Duration, StartedAt, Routine, Id, Status }) => {
      return {
        key: Id,
        data: {
          timestamp: StartedAt ? formatDate(StartedAt.toString()) : '0',
          deviceName: DeviceName,
          routineName: Routine.Name,
          duration: Duration ? Duration.toString() + 's' : '',
          confirmed: Translate.RoutineStatus[Status],
          createdBy: CreatedByName
        }
      };
    });
    return _tdata;
  };

  return (
    <Layout.Wrapper>
      <Layout.Section minHeight='15rem'>
        <ReceiptTable
          loading={recieptsLoading}
          receipts={receipts}
          receiptsPerPage={rowsPerPage}
          showFilters
          exportFnc={getExcelData}
        />
      </Layout.Section>
    </Layout.Wrapper>
  );
};

export default ReceiptsPage;
