import { Dispatch } from 'redux';
import { OptionsActionType } from '../ActionEnums';
import { addErrorMessage } from './feedbackMessage';
import { apiCallErrorMessages } from '../../Common/messages';
import { gpsOperationLogKeys } from '../../Common/Constant';
import { UserOptionsKeys, GpsOptionsKeys, JTokenType } from '../../Common/Enums';
import { webApi } from '../../Common/api';
import { DeviceLog, LcuPosition } from 'Interfaces';
import { AppDispatch } from 'Store/hooks';

/**
  Get the keys access.from and access.to for the specified user
  @param userId The user to get access from
  @return The request promise
*/
export const getUserAccess = (dispatch: Dispatch) => (userId: string) => {
  return getUserOptions(dispatch)(userId, UserOptionsKeys.access);
};

/**
  Get the options for the speicifed user and key
  @param userId The user to get options for
  @param key The key to get
  @return The request promise
*/
export const getUserOptions = (dispatch: Dispatch) => (userId: string, key?:UserOptionsKeys) => {
  dispatch({ type: OptionsActionType.FETCHING_USER_OPTIONS });

  return webApi.get(`useroptions/${userId}${key ? `/${key}` : ''}/latest`).then((response) => {
    dispatch({
      type: OptionsActionType.GOT_USER_OPTIONS,
      payload: response.data || {}
    });

    return response.data;
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_USER_OPTIONS_ERROR_MESSAGE, error);
    dispatch({
      type: OptionsActionType.FAILED_FETCHING_USER_OPTIONS
    });
  });
};

/**
  Post a new user option
  @param userId The user to get the new option
  @param key The key to post to
  @param value The value to post
  @return The request promise
*/
export const postUserOption = (dispatch: Dispatch) => (userId: string, key: string, value: string | boolean | number | unknown) => {
  dispatch({
    type: OptionsActionType.EDITING_USER_OPTION
  });

  const keyValue = [{
    value
  }];

  return webApi.post(`useroptions/${userId}/${key}`, keyValue).then(() => {
    dispatch({
      type: OptionsActionType.DONE_EDITING_USER_OPTION
    });
  }).catch(error => {
    dispatch({
      type: OptionsActionType.FAILED_EDITING_USER_OPTION
    });
    addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_EDITING_USER_OPTION_ERROR_MESSAGE, error);
  });
};

/**
  Change the user theme
  @param userId The user to change theme for
  @param value The new theme value
  @return The request promise
*/
export const changeUserTheme = (dispatch: Dispatch | AppDispatch) => (userId: string, value: string) => {
  return postUserOption(dispatch)(userId, UserOptionsKeys.theme, value);
};

/**
  Get theme of the user
  @param userId The ID of the user to get theme from
*/
export const getUserTheme = (dispatch: Dispatch) => (userId: string) => {
  return getUserOptions(dispatch)(userId, UserOptionsKeys.theme);
};

/**
  Get the options for the speicifed device
  @param deviceId The device to get options for
  @return The request promise
*/
export const getDeviceOptions = (dispatch: Dispatch) => (deviceId: string) => {
  dispatch({ type: OptionsActionType.FETCHING_DEVICE_OPTIONS });
  return webApi.get(`inventoryoptions/${deviceId}/latest`).then((response) => {
    dispatch({
      type: OptionsActionType.GOT_DEVICE_OPTIONS,
      payload: response.data || {}
    });
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_DEVICE_OPTIONS_ERROR_MESSAGE, error);
    dispatch({
      type: OptionsActionType.FAILED_FETCHING_DEVICE_OPTIONS
    });
  });
};

/**
  Post a new device options to the specified device and key
  @param deviceId The device to post options to
  @param key The key to post
  @param value The value to post
  @return The request promise
*/
export const postDeviceOption = (dispatch: Dispatch) => (deviceId: string, key: string, value: string|null, tokenType?: JTokenType) => {
  dispatch({
    type: OptionsActionType.EDITING_DEVICE_OPTION
  });

  const keyValue = tokenType ? [{
    value,
    tokenType
  }] : [{ value }];

  return webApi.post(`inventoryoptions/${deviceId}/${key}`, keyValue).then(() => {
    dispatch({
      type: OptionsActionType.DONE_EDITING_DEVICE_OPTION
    });
  }).catch(error => {
    dispatch({
      type: OptionsActionType.FAILED_EDITING_DEVICE_OPTION
    });
    addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_EDITING_DEVICE_OPTION_ERROR_MESSAGE, error);
  });
};

/**
 *
 * @param deviceId - Id of the device
 * @param key - Key of the setting
 * @returns {Promise<void>} - Promise
 */
export const deleteDeviceOption = (dispatch: Dispatch) => (deviceId: string, key:string):Promise<void> => {
  dispatch({
    type: OptionsActionType.DELETING_DEVICE_OPTION
  });

  return webApi.delete(`inventoryoptions/${deviceId}/${key}`).then(() => {
    dispatch({
      type: OptionsActionType.DELETING_DEVICE_OPTION_SUCCESS
    });
  }).catch(error => {
    dispatch({
      type: OptionsActionType.FAILED_DELETING_DEVICE_OPTION
    });
    addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_DELETING_SETTING, error);
  });
};

/**
  Get GPS positions for the LCU to display on the map
  @return The request promise
*/
export const getPositionsForLCU = (dispatch: Dispatch) => () => {
  dispatch({
    type: OptionsActionType.FETCHING_LCU_POSITIONS
  });

  const optionsPosition = webApi.get('inventoryoptions/latest', { params: { key: 'gps' } });
  const operationPosition = webApi.get('log/latest', { params: { key: 'GPS' } });

  return Promise.all([optionsPosition, operationPosition]).then((values) => {
    // Fill data with values from options
    const lcuPositions:LcuPosition[] = values[0].data.map((dataitem:DeviceLog) => {
      const lonItem = dataitem.Data.find((item) => item.Key === GpsOptionsKeys.longitude) || null;
      const latItem = dataitem.Data.find((item) => item.Key === GpsOptionsKeys.latitude) || null;

      return {
        id: dataitem.Id,
        pos: {
          lat: (latItem?.Value?.Value && latItem.Value.Value !== '0' && parseFloat(latItem.Value.Value)) || null,
          lng: (lonItem?.Value?.Value && lonItem.Value.Value !== '0' && parseFloat(lonItem.Value.Value)) || null
        },
        manualPosition: true
      } as LcuPosition;
    });

    values[1].data.forEach((dataitem: DeviceLog) => {
      const item = lcuPositions.find((i) => i.id === dataitem.Id);

      const lonItem = dataitem.Data.find((item) => item.Key === gpsOperationLogKeys.longitude) || null;
      const latItem = dataitem.Data.find((item) => item.Key === gpsOperationLogKeys.latitude) || null;

      const long = (lonItem?.Value?.Value && lonItem.Value.Value !== '0' && parseFloat(lonItem.Value.Value)) || null;
      const lat = (latItem?.Value?.Value && latItem.Value.Value !== '0' && parseFloat(latItem.Value.Value)) || null;

      // If item exists and lat and long is null, use value from device log
      if (item && item.pos.lat === null && item.pos.lng === null && long && lat) {
        item.pos.lat = lat;
        item.pos.lng = long;
        item.manualPosition = false;
        return;
      } else if (item && item.pos.lat && item.pos.lng) {
        return;
      }
      lcuPositions.push({
        id: dataitem.Id,
        pos: {
          lat: lat,
          lng: long
        },
        manualPosition: false
      } as LcuPosition);
    });
    return lcuPositions;
  }).then(lcuPositions => {
    dispatch({
      type: OptionsActionType.GOT_LCU_POSITIONS,
      // Filter out items that have null position
      payload: lcuPositions.filter(position => (position.pos.lat && position.pos.lng))
    });
  })
    .catch(() => {
      // addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_DEVICE_OPTIONS_ERROR_MESSAGE, error);
      dispatch({
        type: OptionsActionType.FAILED_GETTING_LCU_POSITIONS
      });
    });
};
