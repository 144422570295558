interface signalInterface {
  [key: string]: {
    value: number,
    text: string
  }
}

export const signalStrenghtLimits: signalInterface = {
  LEVEL1: {
    value: -80,
    text: 'Excellent'
  },
  LEVEL2: {
    value: -95,
    text: 'Good'
  },
  LEVEL3: {
    value: -105,
    text: 'Poor'
  }
};

// Excellent > -94
// Good        -95 - (-104)
// Poor      < -105
