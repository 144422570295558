import { Theme } from 'access_ctrl-ui';
import { ConnectionStatus } from 'Common/Enums/DeviceConnectionStatus';
/**
 * Get the current themed status color;
 * @param {ConnectionStatus} status - current status
 * @param {theme} theme - theme in use
 * @returns {string} color
 */
function useStatusColor(status: ConnectionStatus, theme: Theme): string {
  switch (status) {
    case ConnectionStatus.ACTIVE:
      return theme.colorPrimary;
    case ConnectionStatus.TRANSMITTER_INACTIVE:
      return theme.textColorSecondary;
    case ConnectionStatus.RECEIVER_INACTIVE:
      return theme.textColorSecondary;
    default:
      return theme.textColorError;
  }
}
export default useStatusColor;
