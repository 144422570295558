import { DateTime } from 'luxon';

export const deviceIsAlive = (lastSeen) => {
  const operationTimestamp = DateTime.fromISO(lastSeen, { zone: 'utc' });
  const seconds = -operationTimestamp.diffNow('seconds').seconds;

  return seconds < 30;
};

export const getAliveFromTimestamp = (status) => {
  if (!status) {
    return false;
  }

  // Check that the timestamp is recent
  return deviceIsAlive(status.Timestamp);
};

export const getAliveFromValue = (status) => {
  if (!status) {
    return false;
  }

  // If the value is not 0 or False assume it's good
  return status.Value !== '0' && status.Value !== 'False';
};
