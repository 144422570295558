import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Menu as MenuIcon,
  User as UserIcon,
  LogOut as LogOutIcon,
  Home as HomeIcon,
  Box as DeviceIcon,
  Users as UsersIcon,
  Layers as AuditLogIcon,
  Sun as SunIcon,
  Moon as MoonIcon,
  Settings,
  HelpCircle,
  Calendar
} from 'react-feather';
import { Icon, Dropdown } from 'semantic-ui-react';

import { logOut, changeTheme } from 'Store/Actions/appActions';
import { permissions } from '../../Common/Constant';
import { RootState } from 'Store/Reducers';
import { Page, User } from 'Interfaces';
import { ThemeTypes } from 'access_ctrl-ui';

const StyledDropdownMenu = styled(Dropdown.Menu)`
  &&&.menu {
    background: ${({ theme }) => theme.appBg};
    border-top: 2px solid ${({ theme }) => theme.inputBorder} !important;
    border: 2px solid ${({ theme }) => theme.inputBorder};
    margin: 0 -2px;
    width: calc(100% + 4px);
    color: inherit;

    .item {
      border: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .text {
        color: ${({ theme }) => theme.textColor};
      }

      &.selected {
        .text {
          color: ${({ theme }) => theme.textColorSelected};
        }
      }

      &:hover {
        background: ${({ theme }) => theme.colorSecondary};

        .text {
          color: ${({ theme }) => theme.colorPrimary};
        }

        & > svg {
          stroke: ${({ theme }) => theme.colorPrimary};
        }
      }
    }
  }
`;

const StyledDropwdownHeader = styled(Dropdown.Header)`
  &&&.header {
    background: ${({ theme }) => theme.appBg};
    border-top: 2px solid ${({ theme }) => theme.inputBorder} !important;
    border: 2px solid ${({ theme }) => theme.inputBorder};
    margin: 0 -2px;
    width: calc(100% + 4px);
    font-size: 1rem;
    color: ${({ theme }) => theme.textColor};
  }
`;

const MainHeader = styled.h1`
  color: ${({ theme }) => theme.textColor};
  margin: 0;
`;

const StyledTopBarMobile = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.appSecondaryBg};
  display: flex;
  height: ${({ theme }) => theme.headerHeight};
  justify-content: space-between;
  padding: ${({ theme }) => theme.sizes.lg};
`;

const IconStyle = css`
  stroke: ${({ theme }) => theme.textColor};
  width: 1.563rem;
  vertical-align: middle;
`;

const StyledMenuIcon = styled(MenuIcon)`
  ${IconStyle} /* stylelint-disable-line value-keyword-case */
  stroke: ${({ theme }) => theme.colorPrimary};
`;

const StyledRoutinesIcon = styled(Calendar)`
  ${IconStyle}/* stylelint-disable-line value-keyword-case */
`;
const StyledSunIcon = styled(SunIcon)`
  ${IconStyle}/* stylelint-disable-line value-keyword-case */
`;

const StyledMoonIcon = styled(MoonIcon)`
  ${IconStyle}/* stylelint-disable-line value-keyword-case */
`;

const StyledUserIcon = styled(UserIcon)`
  ${IconStyle}/* stylelint-disable-line value-keyword-case */
`;

const StyledHelpCircle = styled(HelpCircle)`
  ${IconStyle}/* stylelint-disable-line value-keyword-case */
`;

const StyledLogOutIcon = styled(LogOutIcon)`
  ${IconStyle}/* stylelint-disable-line value-keyword-case */
`;

const StyledHomeIcon = styled(HomeIcon)`
  ${IconStyle}/* stylelint-disable-line value-keyword-case */
`;

const StyledDeviceIcon = styled(DeviceIcon)`
  ${IconStyle}/* stylelint-disable-line value-keyword-case */
`;

const StyledUsersIcon = styled(UsersIcon)`
  ${IconStyle}/* stylelint-disable-line value-keyword-case */
`;

const StyledAuditLogIcon = styled(AuditLogIcon)`
  ${IconStyle}/* stylelint-disable-line value-keyword-case */
`;

const StyledSettingsIcon = styled(Settings)`
  ${IconStyle} /* stylelint-disable-line value-keyword-case */
`;

const UserMenu = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 1.25rem;
    font-size: 1rem;
  }
`;
interface Props {
  currentPage: Page;
  loggedIn: boolean;
  logOut: (userId: string) => Promise<void>;
  loggedInUser: User;
  theme: ThemeTypes,
  changeTheme: (userId: string, theme: ThemeTypes) => Promise<void> | undefined
}
export const TopBarMobile: React.FC<Props> = ({
  currentPage,
  loggedIn,
  logOut,
  loggedInUser,
  theme,
  changeTheme
}) => {
  const [dashboardAction, setDashboardAction] = useState(false);
  const [devicesAction, setDevicesAction] = useState(false);
  const [usersAction, setUsersAction] = useState(false);
  const [auditLogAction, setAuditLogAction] = useState(false);
  const [routinesAction, setRoutinesAction] = useState(false);

  useEffect(() => {
    if (loggedInUser?.Options?.['permissions.actions']) {
      const actions = JSON.parse(loggedInUser.Options['permissions.actions'].Value);
      setDashboardAction(
        actions.includes(permissions.viewDashboard) ||
        actions.includes(permissions.all));
      setDevicesAction(
        actions.includes(permissions.viewDevices) ||
        actions.includes(permissions.deviceAdmin) ||
        actions.includes(permissions.all)
      );
      setUsersAction(
        actions.includes(permissions.viewUsers) ||
        actions.includes(permissions.userAdmin) ||
        actions.includes(permissions.all)
      );
      setAuditLogAction(
        actions.includes(permissions.viewAuditLog) ||
        actions.includes(permissions.all));
      setRoutinesAction(
        actions.includes(permissions.userAdmin) ||
        actions.includes(permissions.dailyInspection) ||
        actions.includes(permissions.all)
      );
    } else {
      setDashboardAction(true);
      setDevicesAction(true);
      setUsersAction(true);
      /* setProductionDataAction(true); */
      setAuditLogAction(true);
      setRoutinesAction(true);
    }
  }, [loggedInUser]);
  const userNameHeaderContent = loggedInUser ? (
    `${loggedInUser?.FirstName} ${loggedInUser?.LastName}`
  ) : (
    ''
  );
  const logoutUser = () => {
    logOut(loggedInUser && loggedInUser.Id);
  };

  const isDarkTheme = theme === ThemeTypes.DARK;
  const changeUserTheme = () => {
    if (isDarkTheme) {
      changeTheme(loggedInUser.Id, ThemeTypes.LIGHT);
    } else {
      changeTheme(loggedInUser.Id, ThemeTypes.DARK);
    }
  };
  return (
    <StyledTopBarMobile>
      {currentPage && <MainHeader>{currentPage.name}</MainHeader>}
      {loggedIn && (
        <UserMenu>
          <Dropdown direction='left' icon={<StyledMenuIcon />}>
            <StyledDropdownMenu>
              <StyledDropwdownHeader content={userNameHeaderContent} />
              {dashboardAction && (
                <Dropdown.Item icon={<Icon as={StyledHomeIcon} />} text='Dashboard' as={Link} to='/' />
              )}
              {devicesAction && (
                <Dropdown.Item icon={<Icon as={StyledDeviceIcon} />} text='Devices' as={Link} to='/devices' />
              )}
              {usersAction && <Dropdown.Item icon={<Icon as={StyledUsersIcon} />} text='Users' as={Link} to='/users' />}
              {auditLogAction && (
                <Dropdown.Item icon={<Icon as={StyledAuditLogIcon} />} text='Audit Log' as={Link} to='/auditlog' />
              )}
              {routinesAction &&
                <Dropdown.Item
                  icon={<Icon as={StyledRoutinesIcon} />}
                  text='Routines'
                  as={Link}
                  to='/routines'
                />}
              {loggedInUser && <Dropdown.Item icon={<Icon as={StyledUserIcon} />} text='Profile' as={Link} to={`/users/${loggedInUser.Id}`} />}
              <Dropdown.Item icon={<Icon as={StyledSettingsIcon} />} text='Settings' as={Link} to='/settings' />
              <Dropdown.Item icon={isDarkTheme ? <Icon as={StyledSunIcon} /> : <Icon as={StyledMoonIcon} />} onClick={changeUserTheme} text='Theme' />
              <Dropdown.Item icon={<Icon as={StyledHelpCircle} />} text='FAQ' as={Link} to='/FAQ' />
              <Dropdown.Item icon={<Icon as={StyledLogOutIcon} />} text='Log Out' onClick={logoutUser} />
            </StyledDropdownMenu>
          </Dropdown>
        </UserMenu>
      )}
    </StyledTopBarMobile>
  );
};

export default connect(
  ({ app }: RootState) => ({
    currentPage: app.currentPage,
    theme: app.theme,
    loggedIn: app.loggedIn,
    loggedInUser: app.loggedInUser
  }),
  (dispatch) => ({
    logOut: logOut(dispatch),
    changeTheme: changeTheme(dispatch)
  })
)(TopBarMobile);
