import { Settings } from 'react-feather';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { changeTheme } from 'Store/Actions/appActions';
import { RootState } from 'Store/Reducers';
import styled from 'styled-components';

const SettingsButton:React.FC = () => {
  return (
    <div><Link to='/settings'><StyledSettingsIcon /></Link></div>
  );
};
const StyledSettingsIcon = styled(Settings)`
  stroke: ${({ theme }) => theme.colorPrimary};
  width: 1.563rem;
  cursor: pointer;
  margin-bottom: 3px;
`;
export default connect(
  ({ app }: RootState) => ({
    theme: app.theme,
    loggedInUser: app.loggedInUser
  }),
  (dispatch) => ({
    changeTheme: changeTheme(dispatch)
  })
)(SettingsButton);
