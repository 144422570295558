import jwtDecode, { JwtPayload } from 'jwt-decode';
import { get, set } from 'local-storage';
import { addErrorMessage, addSuccessMessage } from './feedbackMessage';
import { apiCallErrorMessages, statusMessages } from '../../Common/messages';
import store, { browserHistory as history } from '../../Store/store';
import { changeUserTheme, getUserTheme } from './optionsActions';
import { session } from '../../Common/session';
import { themes } from '../../Common/theme';
import { webApi, accessControlApi, setApiAuthTokens } from '../../Common/api';
import { Dispatch } from 'redux';
import { CreateTokenResponse, LoginResult, Page, User } from 'Interfaces';
import { ThemeTypes } from 'access_ctrl-ui';
import { AppActionType, UserActionType } from 'Store/ActionEnums';
import { ModalTypes, RFIDReaderTagTypes } from 'Common/Enums';
import { AxiosResponse } from 'axios';
import { AppDispatch } from 'Store/hooks';
import { License } from 'Store/Interfaces/States/License';

/**
  Initialize the web app.
*/
export const appInit = (dispatch: Dispatch) => () => {
  dispatch({ type: AppActionType.APP_INIT });
  setTheme(dispatch)(get('theme'));
  if (session.token) {
    setApiAuthTokens(session.token);
    dispatch({
      type: AppActionType.ADMIN_IS_LOGGED_IN,
      payload: { accessToken: session.token }
    });
  }
};

/**
  Login a user
  @param username The username of the user
  @param password The password of the user
  @return The promise object for the request
*/
export const login = (dispatch: Dispatch) => (username: string, password: string, identityToken?: string): Promise<void> => {
  dispatch({ type: AppActionType.START_LOGIN, payload: { username, password } });
  return webApi.post<CreateTokenResponse, AxiosResponse<CreateTokenResponse>>('token', { username, password, identityToken }).then(response => {
    const { data: { JwtToken, LoginResult } } = response;
    const { IsAdmin, Authorized, ErrorSummary, ErrorDescription } = LoginResult;
    if (!JwtToken || JwtToken === '' || !IsAdmin || !Authorized) {
      dispatch({
        type: AppActionType.ADMIN_LOGIN_FAIL
      });

      let errorMessage = apiCallErrorMessages.LOGIN_ERROR_MESSAGE;

      if (ErrorSummary && ErrorDescription) {
        errorMessage = `${apiCallErrorMessages.LOGIN_ERROR_MESSAGE}  ${ErrorSummary} - ${ErrorDescription}`;
      }

      addErrorMessage(dispatch)(errorMessage);
      return;
    }

    session.token = response.data.JwtToken;
    setApiAuthTokens(session.token);

    dispatch({
      type: AppActionType.ADMIN_SUCCESSFUL_LOGIN,
      payload: { accessToken: session.token }
    });
    history.push('/');
    if (process.env.REACT_APP_USE_MFA) {
      dispatch({
        type: AppActionType.RESET_MFA_LOGIN
      });
    }
  }).catch((error) => {
    dispatch({
      type: AppActionType.ADMIN_LOGIN_FAIL
    });
    // Reset MFA token if we get an error
    dispatch({
      type: AppActionType.RESET_MFA_LOGIN
    });
    addErrorMessage(dispatch)(apiCallErrorMessages.LOGIN_ERROR_MESSAGE, error);
    // Retrow error to make sure we can handle it in login
    throw error;
  });
};
/**
  Logut a user
*/
export const logOut = (dispatch: Dispatch) => (userId: string) => {
  return accessControlApi.post('/authorize/adminlogout', { identityToken: userId }).then(response => {
    session.logout();
    history.push('/');
    dispatch({ type: AppActionType.ADMIN_LOGOUT });
  }).catch((error) => {
    /* Destroy token if we get an error. If we are unauthorized  or get any other errors trying to log out we are probably
    not logged in and the token should be deleted */
    dispatch({
      type: AppActionType.ADMIN_LOGOUT
    });
    session.logout();
    history.push('/');
    addErrorMessage(dispatch)(apiCallErrorMessages.LOGOUT_ERROR_MESSAGE, error);
  }
  );
};

/**
  Toggle a modal to be displayed in the app
  @param modalName The name of the modal to be displayed
  @param forcedOpenState Set to true if the modal should be forced open. If null the modal will toggle open/close status
*/
export const toggleModal = (dispatch: Dispatch) => (modalName: ModalTypes, forcedOpenState: boolean | null = null) => {
  dispatch({
    type: AppActionType.TOGGLE_MODAL,
    payload: { modalName, forcedOpenState: forcedOpenState }
  });
};

export const setExistingUser = (dispatch: Dispatch) => (user: User) => {
  dispatch({
    type: AppActionType.SET_EXISTING_USER,
    payload: user
  });
};

export const resetExistingUser = (dispatch: Dispatch) => (user: User) => {
  dispatch({
    type: AppActionType.RESET_EXISTING_USER
  });
};

/**
  Search for an RFID tag and dispatch if it's a new tag or existing user
  @param {RFIDReaderTagTypes} tagType The type of tag that was read
  @param {string} tag The tag that was read
  @return The promise for the request
*/
export const RFIDReaderReadTag = (dispatch: Dispatch) => (tagType: RFIDReaderTagTypes, tag: string): Promise<void> => {
  dispatch({ type: AppActionType.RFID_READER_FETCHING_TAG });

  return accessControlApi.get<User, AxiosResponse<User>>(`/Identity/${tag}/user`, { params: { include: 'identities' } }).then(response => {
    const { data: user } = response;
    if (user) {
      dispatch({
        type: AppActionType.RFID_READER_READ_EXISTING_TAG,
        payload: { tagType, tag, user }
      });
    }
    store.dispatch({ type: UserActionType.SELECTED_USER, payload: user });
  }).catch((error) => {
    // Server replies with 406 if the identitytoken did not exist
    // Dispatch it as a new RFID tag
    if (error.response && error.response.status === 406) {
      dispatch({
        type: AppActionType.RFID_READER_READ_TAG,
        payload: { tagType, tag }
      });
      dispatch({
        type: AppActionType.RFID_AUTO_SELECT_TAG,
        payload: tag
      });
    } else {
      // If the server did not reply with 406 display the error message
      dispatch({
        type: AppActionType.RFID_READER_FAILED_FETCHING_TAG
      });
      addErrorMessage(dispatch)(apiCallErrorMessages.GET_USER_FOR_TAG_ERROR_MESSAGE, error);
    }
  });
};

/**
  Clear the selected RFID tag in the store state
  TODO: Why is this in the store and not local state?
*/
export const clearRFIDReadTag = (dispatch: Dispatch) => () => {
  dispatch({ type: AppActionType.RFID_TAG_CLEAR });
};
export const setLicense = (dispatch: Dispatch) => (key :string) => {
  webApi.post<License, AxiosResponse<License>>('license/decrypt', { key })
    .then(response => {
      addSuccessMessage(dispatch)(statusMessages.LICENSE_ADDED);
      dispatch({ type: AppActionType.GET_LICENSE, payload: response.data });
    })
    .catch(error => {
      addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_ADDING_LICENSE, error);
    });
  dispatch({ type: AppActionType });
};
export const getLicense = (dispatch: AppDispatch) => (): Promise<void> => {
  return webApi.get('license').then(response => {
    const { data } = response ?? {};
    if (data) {
      dispatch({ type: AppActionType.GET_LICENSE, payload: data });
    } else {
      dispatch({
        type: AppActionType
      });
    }
  });
};
/**
  Select a new RFID tag in the store state
*/
export const selectRFIDTag = (dispatch: Dispatch) => (tag: string) => {
  dispatch({ type: AppActionType.RFID_SELECTED_TAG, payload: tag });
};

// /**
//   Change the user theme and store new theme in cloud
//   @param theme The name of the new theme
// */
// TODO: This does not compute. changeTheme takes a dispatch of type Dispatch and changeUserTheme: OptionsDispatcher
export const changeTheme = (dispatch: Dispatch) => (userId: string, theme: ThemeTypes) => {
  if (Object.keys(themes).includes(theme)) {
    return changeUserTheme(dispatch)(userId, theme).then((result) => {
      setTheme(dispatch)(theme);
    });
  }
};

/**
  Change the theme of the app
  @param theme The new of the new theme
*/
export const setTheme = (dispatch: Dispatch) => (theme: ThemeTypes) => {
  if (Object.keys(themes).includes(theme)) {
    dispatch({
      type: AppActionType.THEME_CHANGED,
      payload: theme
    });
    set('theme', theme);
  }
};

/**
  Login a user using their RFID tag
  @param tag The RFID tag
  @return The promise for the request
  TODO: Should this be removed? I thought it was not desired
*/
export const RFIDLogin = (dispatch: Dispatch) => (tag: string) => {
  dispatch({
    type: AppActionType.RFID_LOGIN_START
  });
  return webApi.post<CreateTokenResponse, AxiosResponse<CreateTokenResponse>>('/token', { identityToken: tag }).then((response) => {
    const { data: { JwtToken, LoginResult = {} as LoginResult } } = response;
    const { IsAdmin, Authorized, ErrorSummary, ErrorDescription } = LoginResult;
    if (!JwtToken || JwtToken === '' || !IsAdmin || !Authorized) {
      dispatch({
        type: AppActionType.ADMIN_LOGIN_FAIL
      });

      let errorMessage = apiCallErrorMessages.LOGIN_ERROR_MESSAGE;

      if (ErrorSummary && ErrorDescription) {
        errorMessage = `${apiCallErrorMessages.LOGIN_ERROR_MESSAGE}  ${ErrorSummary} - ${ErrorDescription}`;
      }

      addErrorMessage(dispatch)(errorMessage);
      return;
    }

    session.token = JwtToken;
    setApiAuthTokens(session.token);

    dispatch({
      type: AppActionType.ADMIN_SUCCESSFUL_LOGIN,
      payload: { accessToken: session.token }
    });
    history.push('/');
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.LOGIN_ERROR_MESSAGE, error);
  });
};

export const MFALogin = (dispatch:Dispatch) => (tag: string) => dispatch({ type: AppActionType.MFA_LOGIN, payload: tag });

/**
  Get data for the current logged in user
  TODO: Move to userActions???
*/
// TODO: This does not compute. getLoggedInUserData takes a dispatch of type Dispatch and getUserTheme: OptionsDispatcher
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLoggedInUserData = (dispatch: any) => () => {
  dispatch({
    type: AppActionType.FETCH_LOGGED_IN_USER_INFO
  });
  if (session.token) {
    const jwt: JwtPayload = jwtDecode(session.token);
    const loggedInUserId = jwt.sub;
    accessControlApi.get(`/directory/users/${loggedInUserId}`).then((response) => {
      dispatch({
        type: AppActionType.GOT_LOGGED_IN_USER_INFO,
        payload: response.data
      });
    }).catch((error) => {
      dispatch({
        type: AppActionType.FAILED_GETTING_LOGGED_IN_USER_INFO
      });

      addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_GETTING_LOGGED_IN_USER_INFO_ERROR_MESSAGE, error);
    });
    if (loggedInUserId) {
      getUserTheme(dispatch)(loggedInUserId).then((response) => {
        if (response) {
          const { Value } = response;
          if (Value && Object.keys(themes).includes(Value)) {
            setTheme(dispatch)(Value || ThemeTypes.DARK);
          } else {
            setTheme(dispatch)(ThemeTypes.DARK);
          }
        }
      });
    }
  }
};
/**
 * Fetches tag to loggedInUser
 * @params {string} tag - Identity tag to fetch user from
 * @returns {Promise<void>} - The request promise
 */
export const getLoggedInUserByIdentityToken = (dispatch: AppDispatch) => (tag: string): Promise<void> => {
  return accessControlApi.get<User, AxiosResponse<User>>(`/Identity/${tag}/user`, { params: { include: 'identities' } }).then(response => {
    const { data: user } = response;
    if (user) {
      dispatch({
        type: AppActionType.GOT_LOGGED_IN_USER_INFO,
        payload: user
      });
    } else {
      dispatch({
        type: AppActionType.FAILED_GETTING_LOGGED_IN_USER_INFO
      });
    }
  });
};

/**
  Set the name of the current page
  @param pageInfo The info of the current page
*/
export const setCurrentPage = (dispatch: Dispatch) => (pageInfo: Page) => {
  const localBusinessName = process.env.REACT_APP_ACCESS_CONTROL_SITENAME || '';

  pageInfo.name = localBusinessName ? pageInfo.name + ' - ' + localBusinessName : pageInfo.name;

  dispatch({
    type: AppActionType.SET_CURRENT_PAGE,
    payload: pageInfo
  });
};

/**
 *
 */
export const getDocuments = (dispatch: Dispatch) => () => {
  webApi.get('Inventory/documents').then().then(response => {
    dispatch(
      {
        type: AppActionType.GET_DOCUMENTS,
        payload: response.data
      }
    );
  });
};
export const getReservedUsers = (dispatch: Dispatch) => () => {
  webApi.get('License/users/reserved').then(response => {
    const { data } = response ?? {};
    if (data) {
      dispatch({
        type: AppActionType.GET_RESERVED_USERS,
        payload: data
      });
    }
  });
};
/**
  Reset app state
*/
export const reset = (dispatch: Dispatch) => () => {
  dispatch({
    type: AppActionType.RESET
  });
};
