import { LogEventType } from 'Common/Enums/LogEventTypes';

export const logOrderByColumn = {
  Undefined: 0,
  InventoryIdAsc: 1,
  InventoryIdDesc: 2,
  UserIdAsc: 4,
  UserIdDesc: 8,
  TimestampAsc: 16,
  TimestampDesc: 32,
  TypeAsc: 64,
  TypeDesc: 128,
  SuccessfulAsc: 256,
  SuccessfulDesc: 512,
  InventoryNameAsc: 1024,
  InventoryNameDesc: 2048,
  UserNameAsc: 4096,
  UserNameDesc: 8192,
  ColumnBAsc: 16384,
  ColumnBDesc: 32768,
  ColumnCAsc: 65536,
  ColumnCDesc: 131072
};

export const logAuthorizationTypes = {
  SUCCESSFUL: 1,
  NOT_SUCCESSFUL: 2
};

export const logEventTypes = {
  ALL: 1,
  OPERATOR_LOGIN: 2,
  OPERATOR_LOGOUT: 8,
  ADMIN_LOGIN: 16,
  ADMIN_LOGOUT: 32
};
interface Label {
  label: string
}

export type LogEventTypeLabel = {
  [key: string]: Label;
};
export const logEventTypeLabels: LogEventTypeLabel = {
  [LogEventType.ALL]: { label: 'All Types' },
  [LogEventType.OPERATOR_LOGIN]: { label: 'Operator Login' },
  [LogEventType.OPERATOR_LOGOUT]: { label: 'Operator Logout' },
  [LogEventType.ADMIN_LOGIN]: { label: 'Admin Login' },
  [LogEventType.ADMIN_LOGOUT]: { label: 'Admin  Logout' }
};
