import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { MFALogin, RFIDLogin, RFIDReaderReadTag } from 'Store/Actions/appActions';
import { useAppSelector } from 'Store/hooks';
import { useMfa } from './useMfa';
/**
 * Scan rfid card from card reader
 */
const useScanRfid = () => {
  const mfaEnabled = useMfa();
  const RFIDReaderStart = useRef<DateTime | null>(null);
  const RFIDReaderTag = useRef<string>('');
  const dispatch = useDispatch();
  const { lastReadRFIDTags, loggedIn } = useAppSelector(state => state.app);

  useEffect(() => {
    const resetRef = () => {
      RFIDReaderTag.current = '';
      RFIDReaderStart.current = null;
    };
    const reset = (event: KeyboardEvent) => {
      resetRef();
      event.preventDefault();
    };
    if (lastReadRFIDTags.length > 0) {
      return;
    }
    const _handleKeyDown = (event: KeyboardEvent) => {
      if (event == null || event.target == null) {
        return;
      }
      const target = event.target as HTMLElement;
      // Blur if key indicates that a rfid read is starting, or a rfid read in progress
      if (target.nodeName === 'INPUT' && (event.key === '#' || RFIDReaderStart.current !== null)) {
        target.blur();
      }

      if (event.key === '#') {
        RFIDReaderStart.current = DateTime.fromMillis(event.timeStamp);
        event.preventDefault();
        return;
      }

      if (event.key === '%' && RFIDReaderStart.current !== null) {
      // The RFID reader sends TAGTYPE,UID. Split on ,
        const readTagInfo = RFIDReaderTag.current.split(',');
        const tagType = parseInt(readTagInfo[0]);
        const tag = readTagInfo[1];

        if (loggedIn && tag) {
          RFIDReaderReadTag(dispatch)(tagType, tag);
          reset(event);
          return;
        }
        if (!mfaEnabled && tag) {
          RFIDLogin(dispatch)(tag);
          reset(event);
          return;
        }
        if (tag) {
          MFALogin(dispatch)(tag);
          reset(event);
          return;
        }
      }
      if (RFIDReaderStart.current) {
        if (event.key === 'Shift') {
          return;
        }

        const diff = DateTime.fromMillis(event.timeStamp).diff(
          RFIDReaderStart.current
        ).milliseconds;

        if (diff >= 600) {
          resetRef();
          return;
        }

        RFIDReaderTag.current = RFIDReaderTag.current + event.key;
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', _handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', _handleKeyDown, false);
    };
  }, [lastReadRFIDTags, dispatch, loggedIn, mfaEnabled]);
};
export default useScanRfid;
