import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Download, Lock, RotateCcw, Settings } from 'react-feather';
import styled from 'styled-components';

import { getDevice, deleteDevice, restartLCU, updateLCU, restartSoftware } from 'Store/Actions/deviceActions';
import { getDeviceOptions } from 'Store/Actions/optionsActions';
import { setCurrentPage, toggleModal } from 'Store/Actions/appActions';
import { addWarningMessage, makeErrorPayload } from 'Store/Actions/feedbackMessage';
import { loadingMessages, apiCallErrorMessages } from '../../../Common/messages';
import { permissions } from '../../../Common/Constant';

import DeviceInfoPane from './DeviceInfoPane';
import UserAccessPane from './UserAccessPane';
import AuditLogPane from './AuditLogPane';
import OperationStatus from './OperationStatusPane';

import NoPermission from 'Components/Pages/NoPermission';

import { ConfirmModal, DangerZone, DangerZoneItem, HeaderRow, InfoPopup, Layout } from 'access_ctrl-ui';
import { breakpoints, StyledMedia as Media } from 'Components/Fresnel';

import { RootState } from 'Store/Reducers';
import { Device, ModalOptions, Page, User } from 'Interfaces';
import { ModalTypes } from 'Common/Enums';
import useCheckInput from 'Hooks/useCheckInput';
import { getStatusForDevices, getOperationLogForDevice } from 'Store/Actions/operationLogActions';
import { useAppSelector } from 'Store/hooks';
import { usePolling } from 'Util/usePolling';
import VisualOperationStatus from './VisualOperationStatus';

const FlexRow = styled(Layout.Row)`
  flex-grow: 4;
`;

const StyledLayoutSection = styled(Layout.Section)`
  max-height: 30em;
`;

const FlexContainer = styled.div`
  display: flex;
  height: 100%;
  flex-flow: column;
  flex: 1;
`;

const StyledSection = styled(Layout.Section)`
  padding: 1rem;
  max-height: 30em;
`;

interface Props extends RouteComponentProps<MatchParams> {
  currentDevice: Device | null;
  optionsForDevice: ModalOptions;
  setCurrentPage: (pageInfo: Page) => void;
  fetchingDeviceItem: boolean;
  getOperationLogForDevice: (deviceId: string) => Promise<void>;
  getStatusForDevices: (deviceId?: string) => Promise<void>;
  getDevice: (deviceId: string, withUsers?: boolean, withOptions?: boolean) => Promise<void>;
  getDeviceOptions: (deviceId: string) => Promise<void>;
  deleteDevice: (deviceId: string) => Promise<void>;
  restartLCU: (deviceId: string) => void;
  restartSoftware: (deviceId: string) => void;
  addWarningMessage: (deviceId: string) => void;
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
  fetchingLoggedInUser: boolean;
  loggedInUser: User;
  updateLCU: (deviceId: string) => void;
}
interface MatchParams {
  deviceId: string;
}
const DeviceDetails: React.FC<Props> = ({ match, currentDevice, getOperationLogForDevice, getStatusForDevices, optionsForDevice, setCurrentPage, fetchingDeviceItem, getDevice, getDeviceOptions, deleteDevice, restartLCU, restartSoftware, addWarningMessage, toggleModal, fetchingLoggedInUser, loggedInUser, updateLCU }) => {
  const { deviceId } = match.params;
  const [showDeleteDeviceModal, setShowDeleteDeviceModal] = useState(false);
  const [showConfirmRestartDeviceModal, setShowConfirmRestartDeviceModal] = useState(false);
  const [showConfirmRestartSoftwareDeviceModal, setShowConfirmRestartSoftwareDeviceModal] = useState(false);
  const [showUpdateDeviceModal, setShowUpdateDeviceModal] = useState(false);
  const [viewDevicesAction, setViewDevicesAction] = useState(false);
  const [deviceAdminAction, setDeviceAdminAction] = useState(false);
  const [viewAuditLogAction, setViewAuditLogAction] = useState(false);
  const [adminAction, setAdminAction] = useState(false);

  const { operationlog } = useAppSelector(state => state.operationlog);
  const dispatch = useDispatch();
  const showDiagnostics = useCheckInput('show');

  usePolling(async () => {
    getStatusForDevices();
    getDeviceOptions(deviceId);
    getOperationLogForDevice(deviceId);
  }, 5000);

  useEffect(() => {
    getOperationLogForDevice(deviceId);
    getStatusForDevices();
  }, [deviceId, dispatch, currentDevice, getOperationLogForDevice, getStatusForDevices]);

  const history = useHistory();

  const handleRestartConfirm = () => {
    if (currentDevice) {
      restartLCU(currentDevice.Id);
      setShowConfirmRestartDeviceModal(false);
    }
  };

  const handleRestartSoftwareConfirm = () => {
    if (currentDevice) {
      restartSoftware(currentDevice.Id);
      setShowConfirmRestartSoftwareDeviceModal(false);
    }
  };

  const handleUpdateConfirm = () => {
    if (currentDevice) {
      updateLCU(currentDevice.Id);
      setShowUpdateDeviceModal(false);
    }
  };

  const onConfirmDeleteDevice = () => {
    if (currentDevice) {
      deleteDevice(currentDevice.Id).then(() => {
        setShowDeleteDeviceModal(false);
      }).then(() => {
        history.goBack();
      });
    }
  };

  useEffect(() => {
    if (deviceId) {
      getDevice(deviceId, true, true).catch(err => {
        addWarningMessage(makeErrorPayload(apiCallErrorMessages.FETCHING_SINGLE_DEVICE_NOT_FOUND, err));
        history.push('/devices');
      });
    }
    setCurrentPage({ name: 'Device Details' });
  }, [deviceId, setCurrentPage, addWarningMessage, getDevice, history]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.Options && loggedInUser.Options['permissions.actions']) {
      const actions = JSON.parse(loggedInUser.Options['permissions.actions'].Value);

      setViewDevicesAction((actions.includes(permissions.viewDevices) || actions.includes(permissions.deviceAdmin)) || actions.includes(permissions.all));

      setDeviceAdminAction(actions.includes(permissions.deviceAdmin) || actions.includes(permissions.all));

      setViewAuditLogAction(actions.includes(permissions.viewAuditLog) || actions.includes(permissions.all));

      setAdminAction(actions.includes(permissions.deviceAdmin) || actions.includes(permissions.all));
    } else if (loggedInUser) {
      setViewDevicesAction(true);
      setViewAuditLogAction(true);
      setDeviceAdminAction(true);
      setAdminAction(true);
    }
  }, [loggedInUser]);

  if ((fetchingDeviceItem || !currentDevice) || (fetchingLoggedInUser || !loggedInUser)) {
    return (
      <Loader active>{loadingMessages.LOADING_DEVICE_ITEM}</Loader>
    );
  }

  if (!viewDevicesAction) {
    return (
      <NoPermission />
    );
  }
  const dangerZoneUnlockText = `${currentDevice.Name.toLocaleLowerCase()}`.replace(/\s/g, '-');
  const SW_VERSION = (currentDevice.Options && currentDevice.Options['SW.VERSION']?.Value) ?? 'Software version not found';

  return (
    currentDevice ? (
      <Layout.Wrapper>
        <Media greaterThanOrEqual='computer'>
          <FlexContainer>
            <FlexRow>
              <Layout.Section minHeight='15rem'>
                <DeviceInfoPane deviceAdmin={deviceAdminAction} currentDevice={currentDevice} SWVersion={SW_VERSION} />
              </Layout.Section>
              <StyledLayoutSection minHeight='15rem'>
                <UserAccessPane editUsers={deviceAdminAction} users={currentDevice.Users} />
              </StyledLayoutSection>
            </FlexRow>
            {
              !showDiagnostics &&
                <FlexRow>
                  <Layout.Section minHeight='16rem'>
                    <HeaderRow>
                      <h2>Status</h2>
                      <InfoPopup descriptionText='Status for this device' />
                    </HeaderRow>
                    <VisualOperationStatus operationlog={operationlog} currentDevice={currentDevice} />
                  </Layout.Section>
                  <></>
                </FlexRow>
            }
            {
              showDiagnostics &&
                <FlexRow>
                  <StyledSection minHeight='25rem'>
                    <OperationStatus currentDevice={currentDevice} />
                  </StyledSection>
                  <StyledSection minHeight='30rem'>
                    <AuditLogPane viewAuditLogs={viewAuditLogAction} currentDevice={currentDevice} />
                  </StyledSection>
                </FlexRow>
            }
          </FlexContainer>
        </Media>
        <Media at='mobile'>
          <FlexRow>
            <Layout.Section minWidth={`calc((${breakpoints.computer}px / 3) + 5px)`} minHeight='35rem'>
              <DeviceInfoPane deviceAdmin={deviceAdminAction} currentDevice={currentDevice} SWVersion={SW_VERSION} />
            </Layout.Section>
            <Layout.Section minWidth={`calc((${breakpoints.computer}px / 3) + 5px)`} minHeight='30rem'>
              <UserAccessPane editUsers={deviceAdminAction} users={currentDevice.Users} />
            </Layout.Section>
          </FlexRow>
          <FlexRow>
            <Layout.Section minHeight='15rem'>
              <HeaderRow>
                <h2>Status</h2>
                <InfoPopup descriptionText='Status for this device' />
              </HeaderRow>
              <VisualOperationStatus operationlog={operationlog} currentDevice={currentDevice} />
            </Layout.Section>
          </FlexRow>
          {
            showDiagnostics &&
              <FlexRow>
                <Layout.Section minWidth={`calc((${breakpoints.computer}px / 3) + 5px)`} minHeight='35rem'>
                  <AuditLogPane viewAuditLogs={viewAuditLogAction} currentDevice={currentDevice} />
                </Layout.Section>
                <Layout.Section minWidth={`calc((${breakpoints.computer}px / 3) + 5px)`} minHeight='35rem'>
                  <OperationStatus currentDevice={currentDevice} />
                </Layout.Section>
              </FlexRow>
          }
        </Media>
        {
          adminAction &&
            <Layout.Row>
              <Layout.Section>
                {/* <VisualOperationStatus operationlog={operationlog} currentDevice={currentDevice} /> */}
                <DangerZone unlockText={dangerZoneUnlockText} placeholder={`Type ${dangerZoneUnlockText} to unlock danger zone`} infoPopupText='Dangerous actions'>
                  <DangerZoneItem
                    onClick={() => { toggleModal(ModalTypes.EDIT_DEVICE_OPTIONS_MODAL); }}
                    icon={<Settings />}
                    buttonText='Configure'
                    headerText='Configure device'
                    detailsText='Change device settings'
                  />
                  <DangerZoneItem
                    onClick={() => { setShowUpdateDeviceModal(true); }}
                    icon={<Download />}
                    buttonText='Update'
                    headerText='Update device'
                    detailsText='Update device to latest software'
                  />
                  <DangerZoneItem
                    onClick={() => { setShowConfirmRestartDeviceModal(true); }}
                    icon={<RotateCcw />}
                    buttonText='Reboot'
                    headerText='Reboot device'
                    detailsText='Request to reboot the device'
                  />
                  <DangerZoneItem
                    onClick={() => { setShowConfirmRestartSoftwareDeviceModal(true); }}
                    icon={<RotateCcw />}
                    buttonText='Restart'
                    headerText='Restart software'
                    detailsText='Request to restart the software'
                  />
                  <DangerZoneItem
                    onClick={() => { setShowDeleteDeviceModal(true); }}
                    icon={<Lock />}
                    buttonText='Delete'
                    headerText='Delete device'
                    detailsText='This action can not be reversed'
                  />
                </DangerZone>
                <ConfirmModal
                  open={showDeleteDeviceModal}
                  onConfirm={onConfirmDeleteDevice}
                  onCancel={() => { setShowDeleteDeviceModal(false); }}
                  header={`Delete Device ${currentDevice.Name}`}
                  content='Do you really want to delete the device?'
                />
                <ConfirmModal
                  open={showConfirmRestartDeviceModal}
                  onConfirm={handleRestartConfirm}
                  onCancel={() => { setShowConfirmRestartDeviceModal(false); }}
                  header={`Reboot device ${currentDevice.Name}`}
                  content='Do you really want to reboot the device?'
                />
                <ConfirmModal
                  open={showConfirmRestartSoftwareDeviceModal}
                  onConfirm={handleRestartSoftwareConfirm}
                  onCancel={() => { setShowConfirmRestartSoftwareDeviceModal(false); }}
                  header={`Restart software for device ${currentDevice.Name}`}
                  content='Do you really want to restart the software?'
                />
                <ConfirmModal
                  open={showUpdateDeviceModal}
                  onConfirm={handleUpdateConfirm}
                  onCancel={() => { setShowUpdateDeviceModal(false); }}
                  header={`Update device ${currentDevice.Name}`}
                  content='Do you really want to update the device?'
                />
              </Layout.Section>
            </Layout.Row>
        }
      </Layout.Wrapper>
    ) : <></>);
};

export default connect(({ app, device, options }: RootState) => (
  {
    currentDevice: device.currentDevice,
    optionsForDevice: options.optionsForDevice,
    loggedInUser: app.loggedInUser,
    fetchingLoggedInUser: app.fetchingLoggedInUser,
    fetchingDeviceItem: device.fetchingDeviceItem
  }
), dispatch => ({
  getDevice: getDevice(dispatch),
  getDeviceOptions: getDeviceOptions(dispatch),
  getStatusForDevices: getStatusForDevices(dispatch),
  getOperationLogForDevice: getOperationLogForDevice(dispatch),
  deleteDevice: deleteDevice(dispatch),
  restartLCU: restartLCU(dispatch),
  restartSoftware: restartSoftware(dispatch),
  updateLCU: updateLCU(dispatch),
  addWarningMessage: addWarningMessage(dispatch),
  setCurrentPage: setCurrentPage(dispatch),
  toggleModal: toggleModal(dispatch)
}))(DeviceDetails);
