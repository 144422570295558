export enum JTokenType {
  None = 0, // No token type has been set.
  Object = 1, // A JSON object.
  Array = 2, // A JSON array.
  Constructor = 3, // A JSON constructor.
  Property = 4, // A JSON object property.
  Comment = 5, // A comment.
  Integer = 6, // An integer value.
  Float = 7, // A float value.
  String = 8, // A string value.
  Boolean = 9, // A boolean value.
  Null = 10, // A null value.
  Undefined = 11, // An undefined value.
  Date = 12, // A date value.
  Raw = 13, // A raw JSON value.
  Bytes = 14, // A collection of bytes value.
  Guid = 15, // A Guid value.
  Uri = 16, // A Uri value.
  TimeSpan = 17 // A TimeSpan value.
}
