import { AppSession } from '../Interfaces/Session';
export const TOKEN_NAME = 'accessToken';

// TODO: Use redux persist?
class Session implements AppSession {
  get token() {
    return localStorage.getItem(TOKEN_NAME) as string;
  }

  set token(token) {
    if (!token || token === 'undefined' || token === 'null') {
      this.clear();
      return;
    }
    localStorage.setItem(TOKEN_NAME, token);
  }

  clear() {
    localStorage.removeItem(TOKEN_NAME);
  }

  logout() {
    this.clear();
  }
}

export const session = new Session();
