import { UserActionType } from 'Store/ActionEnums';
import { UserActions } from 'Store/ActionTypes';
import { UserState } from 'Store/Interfaces/States/UserState';

const defaultState: UserState = {} as UserState;

const user = (state = defaultState, action: UserActions): UserState => {
  switch (action.type) {
    case UserActionType.FETCHING_USER_INFO:
      return {
        ...state,
        currentUser: null,
        userIdentities: null,
        currentUserDevices: null,
        auditLog: null
      };

    case UserActionType.FETCHING_USER_IDENTITIES:
      return {
        ...state,
        userIdentities: null,
        fetchingUserIdentities: true
      };

    case UserActionType.GOT_USER_IDENTITIES:
      return {
        ...state,
        userIdentities: action.payload,
        fetchingUserIdentities: false
      };

    case UserActionType.FAILED_FETCHING_USER_IDENTITIES:
      return {
        ...state,
        fetchingUserIdentities: false
      };

    case UserActionType.FETCHING_ALL_USERS:
      return {
        ...state,
        allUsers: [],
        fetchingUsers: true
      };

    case UserActionType.GOT_ALL_USERS:
      return {
        ...state,
        fetchingUsers: false,
        allUsers: action.payload
      };

    case UserActionType.FAILED_FETCHING_ALL_USERS:
      return {
        ...state,
        fetchingUsers: false
      };

    case UserActionType.IDENTITY_TOKEN_ADDED:
      if (state.userIdentities && state.userIdentities.find((i:string) => i === action.payload)) {
        return { ...state };
      }
      return {
        ...state,
        userIdentities: state.userIdentities ? [...state.userIdentities, action.payload] : [action.payload]
      };

    case UserActionType.DELETING_IDENTITY_TOKENS:
      return {
        ...state,
        deletingIdentityTokens: true
      };

    case UserActionType.IDENTITY_TOKENS_DELETED:
      return {
        ...state,
        deletingIdentityTokens: false
      };

    case UserActionType.FAILED_DELETING_IDENTITY_TOKENS:
      return {
        ...state,
        deletingIdentityTokens: false
      };

    case UserActionType.SELECTED_USER:
      return {
        ...state,
        currentUser: action.payload,
        currentUserDevices: null
      };

    case UserActionType.ADDING_USER:
      return {
        ...state,
        addingNewUser: true
      };

    case UserActionType.USER_ADDED:
      return {
        ...state,
        allUsers: [...state.allUsers, action.payload],
        addingNewUser: false
      };

    case UserActionType.FAILED_ADDING_USER:
      return {
        ...state,
        addingNewUser: false
      };

    case UserActionType.UPDATE_USER_START:
      return {
        ...state,
        updatingUser: true
      };

    case UserActionType.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        currentUser: action.payload,
        updatingUser: false
      };
    }

    case UserActionType.FAILED_UPDATING_USER:
      return {
        ...state,
        updatingUser: false
      };

    case UserActionType.FETCHING_DEVICES_FOR_USER:
      return {
        ...state,
        currentUserDevices: null,
        fetchingUserDevices: true
      };

    case UserActionType.GOT_DEVICES_FOR_USER:
      return {
        ...state,
        currentUserDevices: action.payload,
        fetchingUserDevices: false
      };

    case UserActionType.FAILED_FETCHING_USER_DEVICES:
      return {
        ...state,
        fetchingUserDevices: false
      };

    case UserActionType.EDIT_USER_DEVICES_START:
      return {
        ...state,
        updatingUserAccess: true
      };

    case UserActionType.EDIT_USER_DEVICES_SUCCESS:
      return {
        ...state,
        updatingUserAccess: false
      };

    case UserActionType.EDIT_USER_DEVICES_FAILED:
      return {
        ...state,
        updatingUserAccess: false
      };
    case UserActionType.RESET_ALL_USERS:
      return {
        ...state,
        allUsers: []
      };
    case UserActionType.GOT_USER_COUNT:
      return {
        ...state,
        count: action.payload
      };
    default:
      return state;
  }
};

export default user;
