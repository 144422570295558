import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { InputOnChangeData, List } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, FormInput, Layout, Modal, PasswordInput, Select, ToggleSegment, Dropdown, DropdownProps } from 'access_ctrl-ui';
import { addUser, getUserCount } from 'Store/Actions/userActions';
import { columnBName, columnCName } from '../../Common/messages';
import { getDistinctColBValues, getDistinctColCValues } from 'Store/Actions/deviceActions';
import { permissionOptions } from '../../Common/Constant';
import { ModalTypes } from '../../Common/Enums';
import { sizes } from '../../Common/theme';
import { toggleModal, clearRFIDReadTag, selectRFIDTag, getLicense } from 'Store/Actions/appActions';
import { validatePasswordWithConfirm } from 'Util/passwordValidator';
import { Tag, User } from 'Interfaces';
import { tagTypeToString } from 'Common/Constant/RFID';
import { useAppSelector } from 'Store/hooks';
import { addErrorMessage } from 'Store/Actions/feedbackMessage';
import { useMfa } from 'Hooks/useMfa';
import useAdminPermissions from 'Hooks/useAdminUser';
import useDropdownOptions from 'Hooks/useDropdownOptions';
import useAdminAction from 'Hooks/useAdminAction';
import AnimatedImage from 'Components/Global/AnimatedImage';

const Arrow = styled.div`
  border-width: 1px 0 0 1px;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 0;
  left: 6rem;
  z-index: 3;
  width: 9px;
  height: 9px;
  background-color: ${({ theme }) => theme.appBg};
  border-style: solid;
  border-color: ${({ theme }) => theme.textColorError};
  position: absolute;
`;

const ValidationList = styled(List)`
  &&& {
    padding: ${({ theme }) => theme.sizes.sm};
    margin: 1rem;
    background: ${({ theme }) => theme.appBg};
    border: 1px solid ${({ theme }) => theme.textColorError};
    border-radius: 5px;
    position: relative;

    &:last-child {
      padding-bottom: ${({ theme }) => theme.sizes.md};
    }
  }

  .item {
    color: ${({ theme }) => theme.textColorError};
    font-size: 0.875rem;
    padding: 0.22em 0 !important;

    &:last-child {
      padding: 0.22em 0 !important;
    }
  }
`;

const Container = styled.div<{ $flexGrow?: boolean }>`
  ${({ $flexGrow }) => $flexGrow ? '' : 'flex-grow: 0'};
  padding: ${({ theme }) => theme.sizes.sm} ${({ theme }) => theme.sizes.md};
`;
const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const CredentialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.sizes.sm} ${({ theme }) => theme.sizes.md};
`;

const H3 = styled.h3`
  margin: 0;
  padding: ${({ theme }) => theme.sizes.md};
  text-align: center;
`;

const StyledContent = styled(Layout.Modal.Wrapper)`
  padding: ${({ theme }) => theme.sizes.md};
  font-family: ${({ theme }) => theme.fontBase};
  font-size: 16px;
`;

const SelectContainer = styled.div`
  padding: ${({ theme }) => theme.sizes.md};
`;

const _defaultUserState: User = {
  Id: '',
  Name: '',
  FirstName: '',
  LastName: '',
  Email: '',
  EmployeeId: '',
  IsAdmin: false,
  SetPassword: '',
  Options: {
    'permissions.actions': {
      Value: '[]'
    },
    'permissions.columnsb': {
      Value: '["All"]'
    },
    'permissions.columnsc': {
      Value: '["All"]'
    }
  }
};

const _defaultPasswordValidation: PasswordValidation = {
  messages: [],
  passwordConfirm: '',
  isValid: true
};

const invalidName = (_?: string, value?: string) => {
  if (value && value.includes(' ')) {
    return 'Error: Name should not contain space';
  } else if (!value || value === '') {
    return 'Error: This field is required';
  }
  return false;
};

const invalidUsername = (_?: string, value?: string) => {
  const regexForSwedishCharacters = new RegExp('å|ä|ö');
  const regexForEmailPrefix = new RegExp('^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))$');

  if (value && value !== value.toLowerCase()) {
    return 'Error: Username must be lowercase';
  } else if (value && regexForSwedishCharacters.test(value)) {
    return 'Error: Username should not contain \'å\', \'ä\' or \'ö\'';
  } else if (value && value.includes(' ')) {
    return 'Error: Username should not contain space';
  } else if (!value || value === '') {
    return 'Error: This field is required';
  } else if (value && !regexForEmailPrefix.test(value)) {
    return 'Error: Username must be a valid email prefix';
  }
  return false;
};

const invalidUserId = (_?: string, value?: string) => {
  if (value && value.length > 16) {
    return 'Error: EmployeeId must not be longer than 16 characters';
  }
  return false;
};

interface PasswordValidation {
  messages: string[];
  passwordConfirm: string;
  isValid: boolean;
}

export const AddUserModal: React.FC = () => {
  const [newUser, setNewUser] = useState(_defaultUserState);

  const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>(_defaultPasswordValidation);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { FirstName, LastName, Email, EmployeeId, IsAdmin, SetPassword } = newUser;
  const { passwordConfirm, messages: passwordMessages } = passwordValidation;

  const { license, reservedUsers, lastReadRFIDTags, loggedInUser, selectedRFIDTag } = useAppSelector(state => state.app);
  const { columnBValues, columnCValues, fetchingColBValues, fetchingColCValues } = useAppSelector(state => state.device);
  const allUsers = useAppSelector(state => state.user).count;
  const { addingNewUser } = useAppSelector(state => state.user);
  const open = useAppSelector(state => state.modalsOpen)[ModalTypes.ADD_USER_MODAL];

  const dispatch = useDispatch();
  const mfa = useMfa();
  const history = useHistory();
  const options = useAdminPermissions(IsAdmin);

  const colBOptions = useDropdownOptions(columnBValues);
  const colCOptions = useDropdownOptions(columnCValues);

  const adminAction = useAdminAction(loggedInUser);
  useEffect(() => {
    if (options !== newUser.Options) {
      setNewUser(prevState => ({
        ...prevState,
        Options: options
      }));
    }
  }, [options, newUser.Options]);

  useEffect(() => {
    if (!allUsers || allUsers === 0) {
      getUserCount(dispatch)();
    }
  }, [dispatch, allUsers]);

  const canAddUser = useMemo(() => license && (license.Users >= (allUsers - reservedUsers)), [reservedUsers, license, allUsers]);

  const requestClose = () => setShowConfirmModal(true);

  const onCloseConfirm = () => {
    setShowConfirmModal(false);
    toggleModal(dispatch)(ModalTypes.ADD_USER_MODAL, false);
    clearRFIDReadTag(dispatch)();
    setNewUser(_defaultUserState);
    setPasswordValidation(_defaultPasswordValidation);
  };

  useEffect(() => {
    if (!license) {
      getLicense(dispatch)();
    }
  }, [license, dispatch]);

  useEffect(() => {
    if (open) {
      getDistinctColCValues(dispatch)();
      getDistinctColBValues(dispatch)();
    }
  }, [dispatch, open]);

  const onInputUpdate = (name: string, value: string | boolean) => {
    if (name === 'IsAdmin' && value === false) {
      setPasswordValidation(_defaultPasswordValidation);

      setNewUser({
        ...newUser,
        [name]: value,
        SetPassword: ''
      });

      return;
    }

    if (name === 'SetPassword') {
      const validationResult = validatePasswordWithConfirm(value as string, passwordValidation.passwordConfirm, newUser.Email);
      setPasswordValidation(validationResult);
    } else if (name === 'PasswordConfirm') {
      const validationResult = validatePasswordWithConfirm(newUser.SetPassword ? newUser.SetPassword : '', value as string, newUser.Email);
      setPasswordValidation(validationResult);
      return;
    } else if (name === 'Email' && newUser.SetPassword) {
      const validationResult = validatePasswordWithConfirm(newUser.SetPassword, passwordValidation.passwordConfirm, value as string);
      setPasswordValidation(validationResult);
    }

    setNewUser({ ...newUser, [name]: value });
  };

  const handlePermissionChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, option: string) => {
    setNewUser({
      ...newUser,
      Options: {
        ...newUser.Options,
        [option]: {
          Value: JSON.stringify(data.value)
        }
      }
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (invalidForm) {
      return;
    }

    if (!canAddUser) {
      addErrorMessage(dispatch)('Can not add user. license limit reached');
      return;
    }

    addUser(dispatch)(newUser, selectedRFIDTag || null).then(x => {
      setNewUser(_defaultUserState);
      setPasswordValidation(_defaultPasswordValidation);
      toggleModal(dispatch)(ModalTypes.ADD_USER_MODAL, false);
      history.push('/users/' + x.data.Id);
    });
  };

  // Validate form on change of form inputs
  const invalidForm = useMemo(() => {
    const { isValid: PasswordIsValid } = passwordValidation;
    const { FirstName, LastName, Email, SetPassword, IsAdmin, EmployeeId } = newUser;
    if (!FirstName || !LastName || !Email || (IsAdmin && !SetPassword)) {
      return true;
    }
    return (!!invalidName(undefined, FirstName) ||
      !!invalidName(undefined, LastName) ||
      !!invalidUsername(undefined, Email) ||
      !!invalidUserId(undefined, EmployeeId) ||
      !PasswordIsValid ||
      (IsAdmin && mfa && !selectedRFIDTag));
  }, [mfa, selectedRFIDTag, newUser, passwordValidation]);

  const tagOptions = lastReadRFIDTags.map((tag:Tag) => {
    return {
      key: tag.tag,
      value: tag.tag,
      text: `ID: ${tag.tag} / Type: ${tagTypeToString(tag.tagType)}`
    };
  });
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { name, value } = event.currentTarget;
    onInputUpdate(name, value);
  };
  const { isValid } = passwordValidation;
  return (
    <Modal
      open={open}
      onClose={requestClose}
      confirmClose={onCloseConfirm}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      closeIcon
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>Add User</Layout.Modal.Header>
        {
          !canAddUser
            ? (
              <>
                <StyledContent>Can not add new user, <Link to='/settings' onClick={onCloseConfirm}>license</Link> limit reached. Contact Åkerströms if you want to upgrade the license</StyledContent>
              </>
            )
            : (
              <>
                <Layout.Modal.Content>
                  <Form onSubmit={handleSubmit}>
                    <Layout.Row spacing={sizes.md} padding={sizes.md}>
                      <FormInput
                        autoComplete='new-password'
                        label='First name'
                        name='FirstName'
                        onChange={onInputChange}
                        required
                        validationFn={invalidName}
                        value={FirstName}
                        fluid
                      />
                      <FormInput
                        autoComplete='new-password'
                        label='Last name'
                        name='LastName'
                        onChange={onInputChange}
                        required
                        validationFn={invalidName}
                        value={LastName}
                        fluid
                      />
                    </Layout.Row>
                    <Layout.Row spacing={sizes.md} padding={sizes.md}>
                      <FormInput
                        autoComplete='new-password'
                        label='Username'
                        name='Email'
                        onChange={onInputChange}
                        validationFn={invalidUsername}
                        value={Email}
                        required
                        fluid
                      />
                      <FormInput
                        autoComplete='new-password'
                        label='Employee number'
                        name='EmployeeId'
                        onChange={onInputChange}
                        validationFn={invalidUserId}
                        value={EmployeeId}
                        fluid
                      />
                    </Layout.Row>
                    {
                      adminAction &&
                        <ToggleSegment
                          header='Create Admin dashboard login'
                          onToggleChange={(e, x) => onInputUpdate('IsAdmin', x.checked as boolean)}
                          toggled={IsAdmin}
                          hideChildren
                        >
                          <CredentialsContainer>
                            <Column>
                              <PasswordInput
                                width='100%'
                                autoComplete='new-password'
                                label='Password'
                                name='SetPassword'
                                onChange={(e) => onInputUpdate('SetPassword', e.target.value)}
                                type='password'
                                value={SetPassword}
                              />
                              <PasswordInput
                                width='100%'
                                autoComplete='new-password'
                                label='Repeat new password'
                                name='PasswordConfirm'
                                onChange={(e) => onInputUpdate('PasswordConfirm', e.target.value)}
                                type='password'
                                value={passwordConfirm}
                              />
                              {
                                passwordMessages.length >= 1 &&
                                  <ValidationList>
                                    <Arrow />
                                    {passwordMessages.map((message: string, index: number) =>
                                      <List.Item key={index}>
                                        {message}
                                      </List.Item>)}
                                  </ValidationList>
                              }
                            </Column>
                            <Column>
                              {
                                mfa && IsAdmin &&
                                  <>
                                    <AnimatedImage active={isValid && passwordConfirm !== '' && !lastReadRFIDTags[0]} />
                                    <H3>Scan rfid card to add identity</H3>
                                  </>
                              }
                              {
                                lastReadRFIDTags[0]
                                  ? (
                                    <>
                                      <SelectContainer>
                                        <Select
                                          onChange={(_e, { value }) => selectRFIDTag(dispatch)(value as string)}
                                          options={tagOptions}
                                          placeholder='Select tag'
                                          value={selectedRFIDTag}
                                          clearable
                                        />
                                      </SelectContainer>
                                    </>) : <></>
                              }
                            </Column>
                          </CredentialsContainer>
                          <Container>
                            <Dropdown value={JSON.parse(newUser.Options['permissions.actions'].Value)} label='Permissions' allOption onChange={(e, data) => handlePermissionChange(e, data, 'permissions.actions')} fluid multiple options={permissionOptions()} />
                            <Dropdown value={JSON.parse(newUser.Options['permissions.columnsb'].Value)} label={columnBName} allOption allValue='All' onChange={(e, data) => handlePermissionChange(e, data, 'permissions.columnsb')} fluid multiple options={colBOptions} loading={fetchingColBValues} />
                            <Dropdown value={JSON.parse(newUser.Options['permissions.columnsc'].Value)} label={columnCName} allOption allValue='All' onChange={(e, data) => handlePermissionChange(e, data, 'permissions.columnsc')} fluid multiple options={colCOptions} loading={fetchingColCValues} />
                          </Container>
                        </ToggleSegment>
                    }

                    <Layout.Modal.ButtonContainer>
                      <Button
                        disabled={invalidForm}
                        loading={addingNewUser}
                      >
                        Save
                      </Button>
                    </Layout.Modal.ButtonContainer>
                  </Form>
                </Layout.Modal.Content>
              </>
            )
        }
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default AddUserModal;
