import { ModalTypes, RFIDReaderTagTypes } from '../Common/Enums';
import { setExistingUser, toggleModal } from 'Store/Actions/appActions';
import { Dispatch } from 'redux';
import { History } from 'history';
import { AppActionType } from 'Store/ActionEnums';
import { Tag, User } from 'Interfaces';
import { RootState } from 'Store/Reducers';
export const createAppMiddleware = (history:History) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (storeAPI: { dispatch: Dispatch; getState: () => RootState; }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (next: (arg0: any) => any) => (action: { type: AppActionType; payload: {user:User, tag:Tag, tagTypes:RFIDReaderTagTypes} }) => {
      if (action.type === AppActionType.RFID_READER_READ_EXISTING_TAG) {
        const { user } = action.payload;

        if (!user) {
          return next(action);
        }
        const { modalsOpen } = storeAPI.getState();

        if (modalsOpen[ModalTypes.ADD_USER_MODAL]) {
          setExistingUser(storeAPI.dispatch)(user);
          toggleModal(storeAPI.dispatch)(ModalTypes.IDENTITY_IN_USE_MODAL, true);
          return next(action);
        }

        toggleModal(storeAPI.dispatch)(ModalTypes.EDIT_IDENTITIES_MODAL, false);
        toggleModal(storeAPI.dispatch)(ModalTypes.ADD_IDENTITY_TOKEN_MODAL, false);

        history.push('/users/' + user.Id);
      }

      if (action.type === AppActionType.RFID_READER_READ_TAG) {
        const { modalsOpen, user: { currentUser } } = storeAPI.getState();

        if (!modalsOpen[ModalTypes.ADD_IDENTITY_TOKEN_MODAL] && !currentUser) {
          toggleModal(storeAPI.dispatch)(ModalTypes.ADD_USER_MODAL, true);
          toggleModal(storeAPI.dispatch)(ModalTypes.ADD_IDENTITY_TOKEN_MODAL, false);
          return next(action);
        }
      }

      return next(action);
    };
  };
};
