import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { columnBName, columnCName } from '../../Common/messages';
import { ModalTypes } from '../../Common/Enums';
import { sizes } from '../../Common/theme';
import { getLicense, toggleModal } from 'Store/Actions/appActions';
import { updateDevice, getDistinctColBValues, getDistinctColCValues, refreshCurrentDevice, updateRoutinesForDevice } from 'Store/Actions/deviceActions';

import { Form, Button, FormInput, Search, Layout, Modal, SearchValues, Label } from 'access_ctrl-ui';
import { RootState } from 'Store/Reducers';
import { Device } from 'Interfaces';
import DropdownList from 'Components/Global/DropdownList';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { useAppSelector } from 'Store/hooks';
import { deviceHasRoutine } from 'Util/deviceHasRoutine';
import { setRoutinesForDevice } from 'Features/Routines/routineSlice';
import { features } from 'Common/Constant/Features';

const InspectionWrapper = styled.div`
  margin: ${({ theme }) => theme.sizes.md};
`;

const _defaultEditingDevice = {
  Name: ''
} as Device;
const validateDeviceName = (name: string | undefined, value: string | undefined) => {
  if (value && value.length < 2) {
    return 'Error: Device name needs to be at least two characters';
  } else if (!value || value === '') {
    return 'Error: This field is required';
  }
  return false;
};

interface Props {
  columnBValues: (string | SearchValues)[];
  columnCValues: (string | SearchValues)[];
  currentDevice: Device | null;
  fetchingColBValues: boolean;
  fetchingColCValues: boolean;
  getDistinctColBValues: () => Promise<void>;
  getDistinctColCValues: () => Promise<void>;
  open: boolean;
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
  updateDevice: (device: Device) => Promise<void>;
  updatingDeviceItem: boolean;
  updateRoutinesForDevice: (deviceIds: string[], oldRoutines: string[], newRoutines: string[]) => Promise<void>;
  refreshCurrentDevice: () => void;
}

export const EditDeviceInfoModal:React.FC<Props> = ({
  columnBValues,
  columnCValues,
  currentDevice,
  fetchingColBValues,
  fetchingColCValues,
  getDistinctColBValues,
  getDistinctColCValues,
  open,
  toggleModal,
  updateDevice,
  updatingDeviceItem,
  updateRoutinesForDevice,
  refreshCurrentDevice
}) => {
  const [editingDevice, setEditingDevice] = useState<Device>(_defaultEditingDevice);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [options, setOptions] = useState<DropdownItemProps[]>();
  const { routines } = useAppSelector((state: RootState) => state.routine);
  const { license } = useAppSelector(state => state.app);
  const [hasDailyInspectionFeature, setHasDailyInspectionFeature] = useState(false);
  const [newRoutines, setNewroutines] = useState<string[]>();
  const dispatch = useDispatch();
  const requestClose = () => {
    setShowConfirmModal(true);
  };

  const onCloseConfirm = () => {
    setShowConfirmModal(false);
    toggleModal(ModalTypes.EDIT_DEVICE_MODAL, false);
    setEditingDevice(_defaultEditingDevice);
    if (routines && currentDevice) {
      setNewroutines(routines.filter(x => deviceHasRoutine(currentDevice, x)).map(routine => routine.Id));
    }
  };

  useEffect(() => {
    if (open) {
      getDistinctColCValues();
      getDistinctColBValues();
    }
  }, [getDistinctColBValues, getDistinctColCValues, open]);

  useEffect(() => {
    if (currentDevice) {
      setEditingDevice({
        Id: currentDevice.Id,
        Name: currentDevice.Name,
        B: currentDevice.B || '',
        C: currentDevice.C || ''
      } as Device);
    }
  }, [currentDevice, open]);
  useEffect(() => {
    if (routines) {
      setOptions(routines.map(routine => ({
        key: routine.Id,
        text: routine.Name,
        value: routine.Id
      })));
    }
  }, [routines]);

  useEffect(() => {
    if (routines && currentDevice) {
      setNewroutines(routines.filter(x => deviceHasRoutine(currentDevice, x)).map(routine => routine.Id));
    }
  }, [currentDevice, routines]);

  useEffect(() => {
    if (license) {
      for (const [key, value] of Object.entries(license.Features)) {
        if (value.Enabled === true && key.toLowerCase() === features.dailyInspection) {
          setHasDailyInspectionFeature(true);
        }
      }
    }
  }, [license]);

  useEffect(() => {
    if (!license) {
      getLicense(dispatch)();
    }
  }, [license]);

  const onSubmit = async () => {
    await updateDevice(editingDevice);
    // Update routine name if changed;
    if (newRoutines) {
      await dispatch(setRoutinesForDevice({ id: editingDevice.Id, idList: newRoutines }));
    }
    onCloseConfirm();
    refreshCurrentDevice();
  };

  const onInputUpdate = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.currentTarget;
    setEditingDevice({
      ...editingDevice,
      [name]: value
    });
  };

  const onSearchUpdate = (value: string, event?:React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const { name } = event.currentTarget;
      setEditingDevice({
        ...editingDevice,
        [name]: value
      });
    }
  };
  if (!currentDevice) return null;
  const onResultSelect = (items:SearchValues, name?: string) => {
    if (name) {
      setEditingDevice({
        ...editingDevice,
        [name]: items.name
      });
    }
  };

  const onChangeDropDown = (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (data.value) {
      setNewroutines(data.value as string[]);
    }
  };
  return (
    <Modal
      open={open}
      onClose={requestClose}
      confirmClose={onCloseConfirm}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      closeIcon
      size='large'
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>Edit device</Layout.Modal.Header>
        <Form onSubmit={onSubmit}>
          <Layout.Row spacing={sizes.md} padding={sizes.md}>
            <FormInput
              onChange={onInputUpdate}
              value={editingDevice.Name}
              name='Name'
              label='Device name'
              validationFn={validateDeviceName}
              required
              fluid
            />
            <Search
              onChange={onSearchUpdate}
              label={columnBName}
              name='B'
              searchValues={columnBValues}
              fetchingSource={fetchingColBValues}
              value={editingDevice.B}
              onResultSelect={onResultSelect}
              noResultsMessage={`No ${columnBName?.toLowerCase()} found.`}
              noResultsDescription={`Will add a new ${columnBName?.toLowerCase()} with this name`}
            />
            <Search
              onChange={onSearchUpdate}
              label={columnCName}
              name='C'
              searchValues={columnCValues}
              fetchingSource={fetchingColCValues}
              value={editingDevice.C}
              onResultSelect={onResultSelect}
              noResultsMessage={`No ${columnCName?.toLowerCase()} found.`}
              noResultsDescription={`Will add a new ${columnCName?.toLowerCase()} with this name`}
            />
          </Layout.Row>
          {
            hasDailyInspectionFeature &&
              <InspectionWrapper>
                <Label label='Inspections' />
                <DropdownList multiple closeOnChange closeOnEscape search placeholder='Select routines...' options={options} onChange={onChangeDropDown} value={newRoutines} />
              </InspectionWrapper>
          }
          <Layout.Modal.ButtonContainer>
            <Button disabled={editingDevice.Name != null && editingDevice.Name.length < 2} loading={updatingDeviceItem}>Save</Button>
          </Layout.Modal.ButtonContainer>
        </Form>
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default connect(({ modalsOpen, device }: RootState) => ({
  open: modalsOpen[ModalTypes.EDIT_DEVICE_MODAL],
  columnBValues: device.columnBValues,
  fetchingColBValues: device.fetchingColBValues,
  columnCValues: device.columnCValues,
  fetchingColCValues: device.fetchingColCValues,
  currentDevice: device.currentDevice,
  updatingDeviceItem: device.updatingDeviceItem
}), dispatch => ({
  refreshCurrentDevice: refreshCurrentDevice(dispatch),
  updateRoutinesForDevice: updateRoutinesForDevice(dispatch),
  toggleModal: toggleModal(dispatch),
  updateDevice: updateDevice(dispatch),
  getDistinctColBValues: getDistinctColBValues(dispatch),
  getDistinctColCValues: getDistinctColCValues(dispatch)
}))(EditDeviceInfoModal);
