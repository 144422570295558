import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Box, Book, Rss, Cloud } from 'react-feather';
import { ifaceKvp } from 'Common/Constant/ifacekvp';

import { getAliveFromTimestamp, getAliveFromValue } from 'Util/deviceIsAlive';
import { convertDate } from 'Util/formatDate';
import StatusLine from 'Components/Global/StatusLine';
import { OperationLog } from 'Interfaces/OperationLog';
import { DateTime } from 'luxon';
import { operationStatusDatakeys } from 'Common/Constant/OperationStatusDataKeys';
import { Popup, Theme } from 'access_ctrl-ui';
import ConnectionStatusIcon from 'Components/Global/ConnectionStatusIcon';
import { ConnectionStatus } from 'Common/Enums/DeviceConnectionStatus';
import { Device } from 'Interfaces';
import { signalStrenghtLimits } from 'Common/Constant/signalQuality';
import { deviceOptionsDataKeys } from 'Common/Constant/DeviceOptionsDataKeys';

const VisualOperationStatusContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.sizes.md};
`;

const PopupWrapper = styled.div`
  color: ${({ theme }) => theme.textColor};

  .popup-content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
  }

  .field-name {
    padding-right: 60px;
    min-width: 10rem;
    font-weight: 800;
  }

  .field {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.sizes.sm};
  }
`;

const StatusLineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 5px;
`;
interface StatusContainerProps {
  $active?: boolean;
}
const StatusContainer = styled.div<StatusContainerProps>`
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: center;
  color: ${({ $active, theme }) => $active ? theme.textColorPrimary : theme.textColorSecondary};

  & .icon-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  & p {
    display: flex;
    flex-direction: row;
    padding-top: ${({ theme }) => theme.sizes.md};
  }

  svg {
    color: ${({ $active, theme }) => $active ? theme.colorPrimary : theme.textColorSecondary};
    animation: ${({ $active }) => $active ? 'svg-shadow 2.0s ease-in-out infinite alternate' : 'none'};
  }
`;

const {
  TX_ACTIVE_KEY,
  RX_HK_ON_KEY,
  RX_VALID_SERIAL_DATA_KEY,
  LCU_PING_KEY,
  LCU_INTERFACE_DESC_KEY,
  TX_SIGNAL_QUALITY_KEY
} = operationStatusDatakeys;

const visualOperationKeys = [
  TX_ACTIVE_KEY,
  RX_HK_ON_KEY,
  RX_VALID_SERIAL_DATA_KEY,
  LCU_PING_KEY
];

const {
  LCU_SERVICE_TAG,
  LCU_TYPE
} = deviceOptionsDataKeys;
interface VisualData {
  Key: string;
  Timestamp: DateTime;
  Value: string;
}
interface Props {
  operationlog: OperationLog;
  currentDevice: Device;
}
const VisualOperationStatus: React.FC<Props> = ({ operationlog, currentDevice }) => {
  const [visualStatus, setVisualStatus] = useState<VisualData[]>([] as VisualData[]);
  const theme: Theme = useTheme();

  useEffect(() => {
    if (operationlog) {
      let _visualDataObjects = Object.keys(operationlog).filter((key) => visualOperationKeys.includes(key));
      _visualDataObjects = _visualDataObjects.sort((a, b) => visualOperationKeys.indexOf(a) - visualOperationKeys.indexOf(b));

      const _visualData = _visualDataObjects.map((key) => {
        return {
          Key: key,
          Timestamp: convertDate(operationlog[key].Timestamp),
          Value: operationlog[key].Value
        };
      });

      setVisualStatus(_visualData);
    }
  }, [operationlog]);

  const isAlive = (key: string) => {
    const status = visualStatus.find((i) => i.Key === key);

    // Check if value and timestamp are assumed to be "alive"
    return getAliveFromValue(status) && getAliveFromTimestamp(status);
  };

  const getSignalQuality = () => {
    if (!operationlog) {
      return '';
    }

    const operationLogKeys = Object.keys(operationlog);
    const signalQualityExists = operationLogKeys.includes(TX_SIGNAL_QUALITY_KEY);

    // Do we have TX.SIGNAL_QUALITY?
    if (!signalQualityExists) {
      return '';
    }

    // Is TX active?
    if (!getAliveFromValue(visualStatus[0])) {
      return '';
    }

    // Check if signal is good or poor
    const signalValue = Number(operationlog[TX_SIGNAL_QUALITY_KEY].Value);
    const signalStrength = getSignalStrength(signalValue);

    return `${operationlog[TX_SIGNAL_QUALITY_KEY].Value} dBm (${signalStrength})`;
  };

  const getSignalStrength = (signalValue: number): string => {
    if (signalValue >= signalStrenghtLimits.LEVEL1.value) {
      return signalStrenghtLimits.LEVEL1.text;
    } else if (signalValue >= signalStrenghtLimits.LEVEL2.value) {
      return signalStrenghtLimits.LEVEL2.text;
    } else {
      return signalStrenghtLimits.LEVEL3.text;
    }
  };

  const getActiveInterface = () => {
    if (!operationlog) {
      return '';
    }

    const operationLogKeys = Object.keys(operationlog);
    const interfaceExists = operationLogKeys.includes(LCU_INTERFACE_DESC_KEY);

    // Do we have TX.LCU_INTERFACE_DESC_KEY?
    if (!interfaceExists) {
      return '';
    }

    const opLogValue = operationlog[LCU_INTERFACE_DESC_KEY]?.Value?.toString() as string;

    return `${ifaceKvp[opLogValue]}`;
  };

  const getOperationlogValue = (signal: typeof operationStatusDatakeys[keyof typeof operationStatusDatakeys]) => {
    if (!operationlog) {
      return '';
    }

    const operationLogKeys = Object.keys(operationlog);
    const keyExist = operationLogKeys.includes(signal);

    if (!keyExist) {
      return '';
    }

    const value = operationlog[signal]?.Value?.toString().toLowerCase().trim() as string;

    return value;
  };

  const getDeviceOptionValue = (signal: typeof deviceOptionsDataKeys[keyof typeof deviceOptionsDataKeys]) => {
    return currentDevice?.Options?.[signal]?.Value?.toString() ?? '-';
  };

  const connectionStatusSymbol = (trueOrFalse: boolean) => {
    let status: ConnectionStatus = ConnectionStatus.ACTIVE;
    if (!trueOrFalse) {
      status = ConnectionStatus.UNKNOWN;
    }

    return <ConnectionStatusIcon status={status} theme={theme} />;
  };

  const popupTransmitter: React.FC = () => {
    return (
      <PopupWrapper>
        <h3>Transmitter</h3>
        <div className='popup-content-wrapper'>
          <p className='field-name field'>Status</p>
          <p className='field-content field'>{connectionStatusSymbol(isAlive(TX_ACTIVE_KEY))}</p>
        </div>
        <div className='popup-content-wrapper'>
          <p className='field-name field'>MC on</p>
          <p className='field-content field'>{connectionStatusSymbol(getOperationlogValue(operationStatusDatakeys.RX_HK_ON_KEY) === 'true')}</p>
        </div>
        <div className='popup-content-wrapper'>
          <p className='field-name field'>Signal quality</p>
          <p className='field-content field'>{getSignalQuality() ? getSignalQuality() : '-'}</p>
        </div>
      </PopupWrapper>
    );
  };

  const popupReceiver: React.FC = () => {
    return (
      <PopupWrapper>
        <h3>Receiver</h3>
        <div className='popup-content-wrapper'>
          <p className='field-name field'>Status</p>
          <p className='field-content field'>{connectionStatusSymbol(isAlive(RX_VALID_SERIAL_DATA_KEY))}</p>
        </div>
        <div className='popup-content-wrapper'>
          <p className='field-name field'>Serial COM</p>
          <p className='field-content field'>{currentDevice?.Options?.['LcuSettings.rxComPort']?.Value ? 'Port' + currentDevice.Options['LcuSettings.rxComPort'].Value?.toString()?.slice(-1) : '-'}</p>
        </div>
        <div className='popup-content-wrapper'>
          <p className='field-name field'>Serial protocol</p>
          <p className='field-content field'>{currentDevice?.Options?.[`LcuSettings.rxSerial${currentDevice.Options?.['LcuSettings.rxComPort']?.Value?.toString()?.slice(-1)}Configuration`]?.Value?.toString() ?? '-'}</p>
        </div>
      </PopupWrapper>
    );
  };

  const popupGateway: React.FC = () => {
    return (
      <PopupWrapper>
        <h3>Gateway</h3>
        <div className='popup-content-wrapper'>
          <p className='field-name field'>Status</p>
          <p className='field-content field'>{connectionStatusSymbol(isAlive(LCU_PING_KEY))}</p>
        </div>
        <div className='popup-content-wrapper'>
          <p className='field-name field'>Connection</p>
          <p className='field-content field'>{getActiveInterface() ? getActiveInterface() : '-'}</p>
        </div>
        {
          getActiveInterface()?.toLowerCase() === 'wi-fi' &&
            <div className='popup-content-wrapper'>
              <p className='field-name field'>SSID</p>
              <p className='field-content field'>{getOperationlogValue(operationStatusDatakeys.LCU_WIFI_NETWORK)}</p>
            </div>
        }
        <div className='popup-content-wrapper'>
          <p className='field-name field'>GPS connected</p>
          <p className='field-content field'>{connectionStatusSymbol(getOperationlogValue(operationStatusDatakeys.GPS_CONNECTED) === 'true')}</p>
        </div>
        <div className='popup-content-wrapper'>
          <p className='field-name field'>LCU name</p>
          <p className='field-content field'>{getDeviceOptionValue(LCU_TYPE)}</p>
        </div>
        <div className='popup-content-wrapper'>
          <p className='field-name field'>Service tag</p>
          <p className='field-content field'>{getDeviceOptionValue(LCU_SERVICE_TAG)}</p>
        </div>
      </PopupWrapper>
    );
  };

  const popupServer: React.FC = () => {
    return (
      <PopupWrapper>
        <h3>Server</h3>
        <div className='popup-content-wrapper'>
          <p className='field-name field'>Status</p>
          <p className='field-content field'>{connectionStatusSymbol(isAlive(LCU_PING_KEY))}</p>
        </div>
      </PopupWrapper>
    );
  };

  return (
    <>
      <VisualOperationStatusContainer>
        {/* <DeviceStatus deviceId={deviceId} /> */}
        <StatusContainer $active={isAlive(TX_ACTIVE_KEY)}>
          <Popup
            hoverable
            position='bottom left'
            trigger={
              <div className='icon-container'>
                <Rss />
                <p>Transmitter</p>
              </div>
            } content={popupTransmitter}
          />

        </StatusContainer>
        <StatusLineContainer>
          <StatusLine active={isAlive(RX_HK_ON_KEY)} />
          {/* <p>{getSignalQuality()}</p> */}
        </StatusLineContainer>
        <StatusContainer $active={isAlive(RX_VALID_SERIAL_DATA_KEY)}>
          <Popup
            hoverable
            position='bottom center'
            trigger={
              <div className='icon-container'>
                <Book />
                <p>Receiver</p>
              </div>
            } content={popupReceiver}
          />
        </StatusContainer>
        <StatusLineContainer>
          <StatusLine active={isAlive(RX_VALID_SERIAL_DATA_KEY)} />
        </StatusLineContainer>
        <StatusContainer $active={isAlive(LCU_PING_KEY)}>
          <Popup
            hoverable
            position='bottom center'
            trigger={
              <div className='icon-container'>
                <Box />
                <p>Gateway</p>
              </div>
            } content={popupGateway}
          />

        </StatusContainer>
        <StatusLineContainer>
          <StatusLine active={isAlive(LCU_PING_KEY)} />
        </StatusLineContainer>

        <StatusContainer $active={isAlive(LCU_PING_KEY)}>
          <Popup
            hoverable
            position='bottom right'
            trigger={
              <div className='icon-container'>
                <Cloud />
                <p>Cloud</p>
              </div>
            } content={popupServer}
          />
        </StatusContainer>
      </VisualOperationStatusContainer>
    </>
  );
};
export default VisualOperationStatus;
