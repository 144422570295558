import { ModalActionType } from 'Store/ActionEnums';
import { ModalOpenActions } from 'Store/ActionTypes';
import { ModalOpenState } from 'Store/Interfaces/States/ModalOpenState';

const modalsOpen = (state: ModalOpenState = {}, action: ModalOpenActions):ModalOpenState => {
  switch (action.type) {
    case ModalActionType.TOGGLE_MODAL: {
      let open = state[action.payload.modalName] === undefined ? true : !state[action.payload.modalName];
      if (action.payload.forcedOpenState !== null) {
        open = action.payload.forcedOpenState;
      }
      return {
        ...state,
        [action.payload.modalName]: open
      };
    }
    default:
      return state;
  }
};

export default modalsOpen;
