import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { login } from 'Store/Actions/appActions';

import { ThemedCssProps } from 'Interfaces/ThemedCssProps';
import { useAppSelector } from 'Store/hooks';
import { useDispatch } from 'react-redux';
import Login from './Login';
import MultiFactorLogin from './MultiFactorLogin';
import { useMfa } from 'Hooks/useMfa';
import { Dimmer, Loader } from 'semantic-ui-react';

const LoginFormWrapper = styled.div<ThemedCssProps>`
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div<ThemedCssProps>`
  width: 30rem;

  svg {
    margin-bottom: ${({ theme }) => theme.sizes.lg};
  }
`;

export interface LoginState {
  username?: string;
  password?: string;
  passwordEntered?: boolean;
}
export const LoginForm: React.FC = () => {
  const [state, setState] = useState<LoginState>({});
  const dispatch = useDispatch();
  const { mfaLogin, loggingIn } = useAppSelector(state => state.app);
  const mfaEnabled = useMfa();
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const { username, password } = state;
    if (!mfaEnabled && username && password) {
      login(dispatch)(username, password).catch(() => setState({}));
    } else {
      setState(prevState => ({
        ...prevState,
        passwordEntered: true
      }));
    }
  };

  useEffect(() => {
    const { passwordEntered, username, password } = state;
    if (mfaEnabled && passwordEntered && username && password && mfaLogin) {
      login(dispatch)(username, password, mfaLogin).catch(() => {
        setState({});
      });
    }
  }, [state, mfaLogin, dispatch, mfaEnabled]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setState({
      ...state,
      [name]: value
    });
  };
  return (
    <LoginFormWrapper>
      {loggingIn
        ? (
          <Dimmer active={loggingIn}>
            <Loader active={loggingIn}>
              Logging in...
            </Loader>
          </Dimmer>
        )
        : (
          <FormContainer>
            {
              mfaEnabled
                ? (
                  <MultiFactorLogin
                    onChange={onChange}
                    onSubmit={onSubmit}
                    state={state}
                  />)
                : (
                  <Login
                    onChange={onChange}
                    onSubmit={onSubmit}
                    state={state}
                  />)
            }
          </FormContainer>
        )}
    </LoginFormWrapper>
  );
};

export default LoginForm;
