import LogPane from 'Components/Panes/LogPane';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSystemMessages } from 'Store/Actions/debugActions';
import { useAppSelector } from 'Store/hooks';
import { usePolling } from 'Util/usePolling';

const SystemLogsPane:React.FC = () => {
  const debugDispatch = useDispatch();
  const [messages, setMessages] = useState<string[]>([] as string[]);
  const { systemMessages } = useAppSelector(state => state.debug);
  useEffect(() => {
    getSystemMessages(debugDispatch)();
  }, [debugDispatch]);
  useEffect(() => {
    if (systemMessages) {
      setMessages(systemMessages?.map(m => `${m.timestamp}: ${m.message}\n`));
    }
  }, [systemMessages]);
  usePolling(async () => {
    getSystemMessages(debugDispatch)();
  }, 10000);
  return (
    <LogPane logData={messages} />
  );
};
export default SystemLogsPane;
