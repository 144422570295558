import { accessControlApi } from '../../Common/api';
import { addErrorMessage } from './feedbackMessage';
import { apiCallErrorMessages } from '../../Common/messages';
import { AuditLogActionType } from '../ActionEnums';
import { Filter } from 'Interfaces';
import { Dispatch } from 'redux';

/**
  Get audit log data to export to Excel
  @param filter The current filter object to be fetched
  @return The request promise
*/
export const getExcelAuditLog = (dispatch: Dispatch) => (filter: Filter) => {
  dispatch({ type: AuditLogActionType.EXCEL_AUDIT_LOG_FILTER_START });

  return accessControlApi.post('audit/logs', filter).then(auditLogResponse => {
    dispatch({ type: AuditLogActionType.GOT_EXCEL_AUDIT_LOG });

    return auditLogResponse.data;
  }).catch((error) => {
    dispatch({
      type: AuditLogActionType.ERROR_FETCHING_EXCEL_AUDIT_LOG
    });

    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_EXCEL_AUDIT_LOG_ERROR_MESSAGE, error);
  });
};

/**
  Get audit log data to be displayed in the audit log table
  @param filter The currnet filter object to be fetched
  @return The request promise
*/
export const getAuditLog = (dispatch: Dispatch) => (filter: Filter) => {
  dispatch({ type: AuditLogActionType.FILTER_AUDIT_LOG_START });

  return accessControlApi.post('audit/logs', filter).then(auditLogResponse => {
    dispatch({
      type: AuditLogActionType.GOT_FILTERED_AUDIT_LOG,
      payload: auditLogResponse.data
    });

    return auditLogResponse.data;
  }).catch((error) => {
    dispatch({
      type: AuditLogActionType.FAILED_GETTING_FILTERED_AUDIT_LOG
    });

    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_FILTERED_AUDIT_LOG_ERROR_MESSAGE, error);
  });
};
