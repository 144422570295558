import { Theme } from 'access_ctrl-ui';
import { ConnectionStatus } from 'Common/Enums/DeviceConnectionStatus';
import useStatusColor from 'Hooks/useStatusColor';
import { FC } from 'react';
import { Circle, X } from 'react-feather';
import styled, { css } from 'styled-components';
interface Props {
  status: ConnectionStatus;
  theme: Theme;
  larger?: boolean;
  stroke?: boolean;
}
interface StyleProps{
  $status: ConnectionStatus;
}
const iconStyles = css<StyleProps>`
  animation: ${({ $status }) => $status === ConnectionStatus.ACTIVE ? 'svg-shadow 2.0s ease-in-out infinite alternate' : 'none'};
`;
const StyledX = styled(X)`
  ${iconStyles}
`;
const StyledCircle = styled(Circle)`
  ${iconStyles}
`;

const ConnectionStatusIcon:FC<Props> = ({ status, theme, larger, stroke }) => {
  const color = useStatusColor(status, theme);
  switch (status) {
    case ConnectionStatus.ACTIVE:
      return <StyledCircle $status={status} strokeWidth={stroke ? 3 : 0} size={larger ? 20 : 16} fill={color} color={color} />;
    case ConnectionStatus.TRANSMITTER_INACTIVE:
      return <StyledCircle $status={status} strokeWidth={stroke ? 3 : 0} size={larger ? 20 : 16} fill={color} color={color} />;
    case ConnectionStatus.RECEIVER_INACTIVE:
      return <StyledCircle $status={status} strokeWidth={stroke ? 3 : 0} size={larger ? 20 : 16} fill={color} color={color} />;
    default:
      return <StyledX $status={status} strokeWidth={4} size={larger ? 22 : 17} fill={color} color={color} />;
  }
};
export default ConnectionStatusIcon;
