import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';

import { usePolling } from 'Util/usePolling';
import { setCurrentPage } from 'Store/Actions/appActions';
import { getStatusForDevices } from 'Store/Actions/operationLogActions';
import { deviceIsAlive } from 'Util/deviceIsAlive';
import { formatDate } from 'Util/formatDate';
import { sizes } from '../../../Common/theme';
import { permissions } from '../../../Common/Constant';

import Map from './Map';

import { IconTypes, TableLink, Table, InfoPopup, HeaderRow, Layout, TableRowType } from 'access_ctrl-ui';

import { breakpoints } from 'Components/Fresnel';
import { RootState } from 'Store/Reducers';
import { DeviceLog, Page, User } from 'Interfaces';
import DeviceStatusItem from 'Components/Global/DeviceStatusItem';

const MapWrapper = styled.div`
  flex: 1;
  margin: -${sizes.md} -${sizes.md} -${sizes.md} ${sizes.md};
`;

const tableDataColumns = [
  { name: 'Device', key: 'name' },
  { name: 'Last Contact', key: 'date' },
  { name: 'Status', key: 'status' }
];

interface Props {
  deviceLogs: DeviceLog[];
  setCurrentPage: (pageInfo: Page) => void;
  getStatusForDevices: (key?: string) => Promise<void>;
  fetchingStatusForDevices: boolean;
  fetchingLoggedInUser: boolean;
  loggedInUser: User;
}
const Dashboard:React.FC<Props> = ({ deviceLogs, setCurrentPage, getStatusForDevices, fetchingStatusForDevices, fetchingLoggedInUser, loggedInUser }) => {
  const [tableDataRows, setTableDataRows] = useState<TableRowType[]>([] as TableRowType[]);
  const [statusLoaded, setStatusLoaded] = useState(false);
  const [viewDashboardAction, setViewDashboardAction] = useState(false);

  useEffect(() => {
    return () => {
      setStatusLoaded(false);
    };
  }, []);

  useEffect(() => {
    setCurrentPage({ name: 'Dashboard' });
  }, [setCurrentPage]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.Options && loggedInUser.Options['permissions.actions']) {
      const actions = JSON.parse(loggedInUser.Options['permissions.actions'].Value);

      setViewDashboardAction(actions.includes(permissions.viewDashboard) || actions.includes(permissions.all));
    } else if (loggedInUser) {
      setViewDashboardAction(true);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (deviceLogs) {
      const deviceLogsData = deviceLogs.map((logItem) => {
        return {
          successful: deviceIsAlive(logItem.LastSeen),
          key: logItem.Id,
          name: logItem.Name,
          data: {
            name: <TableLink successful={deviceIsAlive(logItem.LastSeen)} text={logItem.Name} iconType={IconTypes.Device} to={`/devices/${logItem.Id}`} />,
            date: formatDate(logItem.LastSeen),
            status: <DeviceStatusItem deviceId={logItem.Id} deviceLogs={deviceLogs} />
          }
        };
      });
      const sortedLogs = _.orderBy(deviceLogsData, ['successful', 'name'], ['asc', 'asc']);
      setTableDataRows(sortedLogs);
    }
  }, [deviceLogs]);

  useEffect(() => {
    if (viewDashboardAction) {
      getStatusForDevices().then(() => {
        setStatusLoaded(true);
      });
    }
  }, [getStatusForDevices, viewDashboardAction]);

  usePolling(async () => {
    getStatusForDevices();
  }, 10000);

  return (
    <Layout.Wrapper>
      <Layout.Row childFlex={[1, 2]}>
        <Layout.Section minWidth={`calc(${breakpoints.computer}px / 3)`}>
          <HeaderRow>
            <h2>Status</h2>
            <InfoPopup descriptionText='Last contact for devices' />
            {fetchingStatusForDevices && <Loader size='small' inline active />}
          </HeaderRow>
          <Table
            rowsPerPage={13}
            tableDataColumns={tableDataColumns}
            tableDataRows={viewDashboardAction ? tableDataRows : [] as TableRowType[]}
            emptyText={viewDashboardAction ? 'No devices with last contact' : 'You don\'t have permission to view the dashboard'}
            loading={(viewDashboardAction && !statusLoaded) || fetchingLoggedInUser}
          />
        </Layout.Section>
        <Layout.Section>
          <MapWrapper>
            {
              viewDashboardAction &&
                <Map />
            }
          </MapWrapper>
        </Layout.Section>
      </Layout.Row>
    </Layout.Wrapper>
  );
};

export default connect(({ app, operationlog } : RootState) => ({
  deviceLogs: operationlog.deviceLogs,
  loggedInUser: app.loggedInUser,
  fetchingLoggedInUser: app.fetchingLoggedInUser,
  fetchingStatusForDevices: operationlog.fetchingStatusForDevices
}), (dispatch) => ({
  setCurrentPage: setCurrentPage(dispatch),
  getStatusForDevices: getStatusForDevices(dispatch)
}))(Dashboard);
