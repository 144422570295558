export enum ACLChangedEvent {
  /**
  * The user added to inventory
  */
  UserAddedToInventory = 0,
  /**
  * The user removed from inventory
  */
  UserRemovedFromInventory = 1,
  /**
  * The inventory object removed
  */
  InventoryObjectRemoved = 2,
  /**
  * The inventory object created
  */
  InventoryObjectCreated = 3,
  /**
  * The lcu database synchronized
  */
  LcuDbSynchronized = 4,
  /**
  * The lcu termination requested
  */
  LcuTerminationRequested = 5,
  /**
  * The lcu termination acknowledged
  */
  LcuTerminationAcknowledged = 6,
  /**
  * The inventory access changed
  */
  InventoryAccessChanged = 7,
  /**
  * LCU update request event
  */
  LcuUpdateRequested = 8,
  /**
  * LCU update acknowledged
  */
  LcuUpdateAcknowledged = 9,
  /**
  * Acknowledgement for inventory removed
  */
  InventoryObjectRemovalAcknowledged = 10,
  /**
  * LCU software restart event
  */
  LcuSoftwareRestartRequested = 11,
  /**
  * LCU software restart acknowledged
  */
  LcuSoftwareRestartAcknowledged = 12
}
