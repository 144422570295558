import { webApi } from '../../Common/api';
import { addErrorMessage } from './feedbackMessage';
import { apiCallErrorMessages } from '../../Common/messages';
import { Dispatch } from 'redux';
import { OperationLogItem } from 'Interfaces';
import { OperationLogActionType } from 'Store/ActionEnums';

/**
  Get operation log history for a key
  @param deviceID The ID of the device to get logs for
  @param key The key to get logs for
  @return The operation logs
*/
export const getOperationLogHistory = (dispatch: Dispatch) => async (deviceId: string, key: string):Promise<OperationLogItem[]|null> => {
  dispatch({ type: OperationLogActionType.FETCHING_OPERATION_LOG_HISTORY });

  try {
    const logResult = await webApi.get(`log/${deviceId}/${key}`);
    dispatch({
      type: OperationLogActionType.GOT_OPERATION_LOG_HISTORY,
      payload: logResult.data
    });

    return logResult.data;
  } catch (error) {
    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_OPERATION_LOG_ERROR_MESSAGE, error);
    dispatch({
      type: OperationLogActionType.FAILED_GETTING_OPERATION_LOG_HISTORY
    });

    return null;
  }
};

/**
  Get operation logs for a single device
  @param deviceID The ID of the device to get logs for
  @return The request promise
*/
export const getOperationLogForDevice = (dispatch: Dispatch) => (deviceId: string):Promise<void> => {
  dispatch({ type: OperationLogActionType.FETCHING_OPERATION_LOG });
  return webApi.get(`log/${deviceId}/latest`).then((response) => {
    dispatch({
      type: OperationLogActionType.GOT_OPERATION_LOG,
      payload: response.data
    });
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_OPERATION_LOG_ERROR_MESSAGE, error);
    dispatch({
      type: OperationLogActionType.FAILED_GETTING_OPERATION_LOG
    });
  });
};

/**
  Get status for all devices
  @param key The key to get status for if any
  @return The request promise
*/
export const getStatusForDevices = (dispatch: Dispatch) => (key = '') => {
  dispatch({ type: OperationLogActionType.FETCHING_DEVICE_LOGS });

  let options = {};

  if (key !== '') {
    options = {
      params: {
        key: key
      }
    };
  }

  return webApi.get('log/latest', options).then((response) => {
    dispatch({
      type: OperationLogActionType.GOT_DEVICE_LOGS,
      payload: response.data
    });
  }).catch(error => {
    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_DEVICE_STATUS_ERROR, error);
    dispatch({
      type: OperationLogActionType.FAILED_FETCHING_DEVICE_LOGS
    });
  });
};

/**
  Post a new item to the operation log
  @param deviceId The device ID to post operation log to
  @param key The key to post
  @param logObject The log object to post to the operation log
*/
export const postDeviceLogItem = (dispatch: Dispatch) => (deviceId: string, key: string, logObject: Partial<OperationLogItem>) => {
  dispatch({
    type: OperationLogActionType.POST_LOGITEM_START
  });

  return webApi.post(`log/${deviceId}/${key}`, [logObject]).then(() => {
    dispatch({
      type: OperationLogActionType.POST_LOGITEM_SUCCESS
    });
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.POST_LOGITEM_ERROR_MESSAGE, error);
    dispatch({
      type: OperationLogActionType.FAILED_POST_LOGITEM
    });
  });
};

/**
* Obliterate all operation logs
* @returns Promise<void>
*/
export const obliterateLogs = (dispatch: Dispatch) => ():Promise<void> => {
  dispatch({
    type: OperationLogActionType.OBLITERATE_LOGS_START
  });

  return webApi.delete('log/').then(() => {
    dispatch({
      type: OperationLogActionType.OBLITERATE_LOGS_SUCCESS
    });
  }).catch(err => {
    addErrorMessage(dispatch)(err);
  });
};
/**
* Obliterate all operation logs for device
* @param deviceId {deviceId}
* @returns Promise<void>
*/
export const obliterateLogsForDevice = (dispatch: Dispatch) => (deviceId:string):Promise<void> => {
  dispatch({
    type: OperationLogActionType.OBLITERATE_LOGS_START
  });

  return webApi.delete(`log/${deviceId}`).then(() => {
    dispatch({
      type: OperationLogActionType.OBLITERATE_LOGS_SUCCESS
    });
  }).catch(err => {
    addErrorMessage(dispatch)(err);
  });
};
