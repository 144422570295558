export const operationStatusDatakeys = {
  LCU_WIFI_NETWORK: 'LCU.WIFI_NETWORK',
  LCU_WIFI_CONNECTION_STATUS: 'LCU.WIFI_CONNECTION_STATUS',
  TX_ACTIVE_KEY: 'RX.TX_ACTIVE',
  RX_VALID_SERIAL_DATA_KEY: 'RX.VALID_SERIAL_DATA_RECEIVED',
  LCU_PING_KEY: 'LCU.PING',
  LCU_LAST_READ_IDENTITY_TOKEN_KEY: 'LCU.LAST_READ_IDENTITY_TOKEN',
  LCU_USED_SPACE_KEY: 'LCU.USED_SPACE',
  LCU_INTERFACE_DESC_KEY: 'LCU.INTERFACE_DESC',
  LCU_SERVICE_TAG_KEY: 'LCU.SERVICE_TAG',
  LCU_LAST_AUTHORIZED_IDENTITY_TOKEN_KEY: 'LCU.LAST_AUTHORIZED_IDENTITY_TOKEN',
  TX_SIGNAL_QUALITY_KEY: 'TX.SIGNAL_QUALITY',
  ACL_KEY: '.ACL',
  LCU_LOCALTIME_KEY: 'LCU.LOCALTIME',
  LCU_UPTIME_KEY: 'LCU.UPTIME',
  LCU_PING_ROUNDTRIP_KEY: 'LCU.PING_ROUNDTRIP',
  LCU_PING_STATUS_KEY: 'LCU.PING_STATUS',
  RX_VALID_SERIAL_DATA_RECEIVED_KEY: 'RX.VALID_SERIAL_DATA_RECEIVED',
  LCU_FREE_MEMORY_KEY: 'LCU.FREE_MEMORY',
  LCU_TYPE_KEY: 'LCU.TYPE',
  LCU_TX_ERRORS_KEY: 'LCU.TX_ERRORS',
  RX_STATE_KEY: 'RX.STATE',
  LCU_RX_ERRORS_KEY: 'LCU.RX_ERRORS',
  LCU_SW_STARTED_KEY: 'LCU.SW_STARTED',
  LCU_BOOT_KEY: 'LCU.BOOT',
  RX_HK_ON_KEY: 'RX.HK_ON',
  GPS_CONNECTED: 'GPS.GPS_CONNECTED'
};
