import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { TextArea, Form } from 'access_ctrl-ui';

const StyledForm = styled(Form)`
  height: 100%;
  width: 100%;
`;

const StyledTextArea = styled(TextArea)`
  &&& {
    border: none;
    height: 100%;
    resize: none;
    width: 100%;

    &:focus {
      background: none;
      color: ${({ theme }) => theme.inputText};
      border: none;
      outline: none;
    }
  }
`;
interface Props {
  logData: string[];
}
const LogPane:React.FC<Props> = ({ logData }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [logText, setLogText] = useState('');
  // TODO: Should this be moved as a feature in the TextArea component?
  const [autoScroll, setAutoScroll] = useState(true);

  useEffect(() => {
    if (logData) {
      setLogText(logData.join('\n'));
    } else {
      setLogText('No log data available');
    }
  }, [logData]);

  useEffect(() => {
    if (textAreaRef.current && autoScroll) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [logText, autoScroll]);

  const handleScroll = (e:React.SyntheticEvent) => {
    if (textAreaRef.current) {
      setAutoScroll((textAreaRef.current.scrollTop + textAreaRef.current.offsetHeight) >= textAreaRef.current.scrollHeight);
    }
  };

  return (
    <StyledForm>
      <StyledTextArea col='30' _ref={textAreaRef} value={logText} readOnly onScroll={handleScroll} />
    </StyledForm>
  );
};

export default LogPane;
