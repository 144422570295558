import { Layout } from 'access_ctrl-ui';
import { browserHistory as history } from 'Store/store';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';

import styled from 'styled-components';
import { useLocation } from 'react-router';
import { Tab, TabProps } from 'semantic-ui-react';
import PaneMenuItem from 'Components/Global/PaneMenuItem';
import { convertToStringLowerCase } from 'Util/formatString';
import { setCurrentPage } from 'Store/Actions/appActions';
import DeviceSettingsPane from './DeviceSettingsPane';
import DeviceLogsPane from './DeviceLogsPane';
import SystemLogsPane from './SystemLogsPane';
import SystemPane from './SystemPane';
import { useDispatch } from 'react-redux';

const StyledTab = styled(Tab)<TabProps>`
  &&& {
    display: flex;
    flex-flow: column;
    flex: 1;

    & > div.ui.menu {
      & a.item {
        color: ${({ theme }) => theme.textColor};
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        border-top: 2px solid transparent;
        font-family: ${({ theme }) => theme.fontBase};
      }

      & a.active {
        border-color: ${({ theme }) => theme.appBorderColor};
        background: none;
        font-weight: normal;
      }
    }

    & > div.ui.tab.active {
      background: ${({ theme }) => theme.appBg};
      display: flex;
      flex-flow: column;
      flex: 1;
      border: none;
      box-shadow: none;
      margin: 0;
      padding: 0;
    }

    & .ui.loader {
      margin-left: 1rem;
    }
  }
`;

const PowerUser:React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const location = useLocation();
  const appDispatch = useDispatch();
  useEffect(() => {
    setCurrentPage(appDispatch)({ name: 'Power user' });
  });
  useEffect(() => {
    const params = queryString.parse(location.search);
    const { activePowerAdminTab } = params;

    if (activePowerAdminTab === undefined) {
      setActiveIndex(0);
    } else {
      setActiveIndex(parseInt(convertToStringLowerCase(activePowerAdminTab)));
    }
  }, [location]);
  const onTabChange = (_:React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps) => {
    const query = new URLSearchParams(location.search);

    if (activeIndex === undefined) {
      query.delete('activePowerAdminTab');
    } else {
      query.set('activePowerAdminTab', activeIndex.toString());
    }
    history.replace({ ...history.location, search: query.toString() });
  };

  const getPanes = () => {
    return [
      {
        menuItem: <PaneMenuItem key='deviceLogs' text='Device logs' description='Debug logs for devices' loading={false} />,
        render: () => (
          <Tab.Pane>
            <DeviceLogsPane />
          </Tab.Pane>
        )
      },
      {
        menuItem: <PaneMenuItem key='systemLogs' text='System logs' description='Debug logs for system' loading={false} />,
        render: () => (
          <Tab.Pane>
            <SystemLogsPane />
          </Tab.Pane>
        )
      },
      {
        menuItem: <PaneMenuItem key='system' text='System' description='System' loading={false} />,
        render: () => (
          <Tab.Pane>
            <SystemPane />
          </Tab.Pane>
        )
      },
      {
        menuItem: <PaneMenuItem key='devicesettings' text='Settings for devices' description='Update settings for selected devices' loading={false} />,
        render: () => (
          <Tab.Pane>
            <DeviceSettingsPane />
          </Tab.Pane>
        )
      }
    ];
  };
  return (
    <Layout.Wrapper>
      <Layout.Section minHeight='15rem'>
        <StyledTab onTabChange={onTabChange} activeIndex={activeIndex} panes={getPanes()} />
      </Layout.Section>
    </Layout.Wrapper>

  );
};
export default PowerUser;
