import React, { useEffect, useState } from 'react';
import { nameSort, dateSort } from 'Util/sortFn';
import { formatDate } from 'Util/formatDate';
import { toggleModal, getLicense } from 'Store/Actions/appActions';
import { IconTypes, HeaderRow, InfoPopup, Table, TableLink, TableText, TableRowType, TableKeyType, Button } from 'access_ctrl-ui';
import { RootState } from 'Store/Reducers';
import { connect, useDispatch } from 'react-redux';
import { ModalTypes } from 'Common/Enums';
import { User } from 'Interfaces';
import { Edit2 } from 'react-feather';
import userUserHasAccess from 'Util/userHasAccess';
import { useAppSelector } from 'Store/hooks';
import FeatureDisabled from '../Feature';
import { features } from 'Common/Constant/Features';

const tableDataColumns = [
  {
    name: 'User',
    key: 'user',
    sortFn: (dir: TableKeyType, col: TableKeyType) => {
      return nameSort(dir, col);
    }
  },
  {
    name: 'Access from',
    key: 'accessFrom',
    sortFn: (dir: TableKeyType, col: TableKeyType) => {
      return dateSort(dir, col);
    }
  },
  {
    name: 'Access to',
    key: 'accessTo',
    sortFn: (dir: TableKeyType, col: TableKeyType) => {
      return dateSort(dir, col);
    }
  }
];
interface Props {
  users?: User[];
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
  editUsers: boolean;
}
const UserAccessPane: React.FC<Props> = ({ users, toggleModal, editUsers }) => {
  const [tableDataRows, setTableData] = useState<TableRowType[]>([] as TableRowType[]);
  const [editingDevice, setEditingDevice] = useState<boolean>(false);
  const [hasLicense, setHasLicense] = useState(false);
  const { license } = useAppSelector(state => state.app);
  const dispatch = useDispatch();
  useEffect(() => {
    if (users) {
      const _tdata = users.filter(u => {
        if (!u) {
          return false;
        }
        return true;
      }).map((user) => {
        const hasAccess = userUserHasAccess(user, new Date());
        const accessFrom = user && user.Options && user.Options['access.from'] && user.Options['access.from'].Value ? formatDate(user.Options['access.from'].Value) : '';
        const accessTo = user && user.Options && user.Options['access.to'] && user.Options['access.to'].Value ? formatDate(user.Options['access.to'].Value) : '';
        return {
          key: user.Id,
          successful: hasAccess,
          sortData: {
            user: `${user.FirstName} ${user.LastName}`
          },
          data: {
            user: <TableLink successful={hasAccess} to={`/users/${user.Id}`} iconType={IconTypes.User} text={`${user.FirstName} ${user.LastName}`} />,
            accessFrom: <TableText successful={hasAccess} text={`${accessFrom}`} />,
            accessTo: <TableText successful={hasAccess} text={`${accessTo}`} />
          }
        };
      });

      setTableData(_tdata);
    }
  }, [users, editingDevice]);

  const onEditDevice = () => {
    setEditingDevice(false);
    toggleModal(ModalTypes.EDIT_DEVICE_USER_ACCESS_MODAL);
  };
  useEffect(() => {
    if (license) {
      for (const [key, value] of Object.entries(license.Features)) {
        if (value.Enabled === true && key.toLowerCase() === features.accessCtrl) {
          setHasLicense(true);
        }
      }
    }
  }, [license]);

  useEffect(() => {
    if (!license) {
      getLicense(dispatch)();
    }
  }, [license]);

  return (
    <>
      <HeaderRow>
        <h2>User access</h2>
        <InfoPopup descriptionText='Users with access to this device' />
        {(editUsers && hasLicense) && <Button onClick={onEditDevice}><Edit2 /> Edit</Button>}
      </HeaderRow>
      {
        hasLicense
          ? (
            <>
              <Table rowsPerPage={5} defaultSortDir='asc' defaultSort='user' emptyText='No users for this device' tableDataColumns={tableDataColumns} tableDataRows={tableDataRows} setQueryString={false} />
              {editingDevice &&
                <Table defaultSortDir='asc' defaultSort='user' emptyText='No users for this device' tableDataColumns={tableDataColumns} tableDataRows={tableDataRows} setQueryString={false} />}
            </>
          )
          : (
            <>
              <FeatureDisabled name={features.accessCtrl.toString()} />
            </>
          )
      }
    </>
  );
};

export default connect(({ app, device }: RootState) => ({
  loggedInUser: app.loggedInUser
}
), dispatch => ({
  toggleModal: toggleModal(dispatch)
}))(UserAccessPane);
