import { Label, Layout, Modal } from 'access_ctrl-ui';
import React, { useState } from 'react';
import { DeviceRecord } from 'Interfaces/DeviceRecord';
import styled from 'styled-components';
import { formatDate } from 'Util/formatDate';

const StyledContent = styled(Layout.Modal.Content)`
  padding: 0 ${({ theme }) => theme.sizes.md};
  padding-bottom: ${({ theme }) => theme.sizes.md};

  & .label-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

type ModalProps = {
  open: boolean;
  onClose: () => void;
  modalHeader: string;
  record: DeviceRecord;
  recordId: string;
  createdBy: string
};

const DeviceRecordModal: React.FC<ModalProps> = ({ open, onClose, modalHeader, record, recordId, createdBy }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const requestClose = () => {
    setShowConfirmModal(true);
  };

  return (
    <Modal
      open={open}
      size='small'
      closeIcon
      onClose={onClose}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      confirmClose={requestClose}
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>{record.tag}</Layout.Modal.Header>
        <StyledContent>
          <div className='label-wrapper'>
            <Label label={`${createdBy} [${formatDate(record.Date)}]`} />
          </div>
          <p>{record.text}</p>
          <div>{record.status}</div>
        </StyledContent>
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default DeviceRecordModal;
