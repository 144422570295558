import { ModalTypes } from 'Common/Enums';
import AddDeviceToRoutine from '../Components/AddDeviceToRoutine.tsx/AddDeviceToRoutine';
import AddRoutineModal from '../Components/HandleRoutine/HandleRoutineModal';

export interface ModalOptions {
  open: boolean;
  type: ModalTypes;
  title: string;
  close: () => void;
  edit?: boolean;
}
/**
 * Returns a modal element from props
 * @param {ModalOptions} props - options for modal to render
 * @returns {JSX.Element} - The wanted modal
 */
export function useRoutineModal(props?: ModalOptions):JSX.Element {
  const { open, type, close, edit, title } = props ?? {};

  if (open && type && close && title) {
    switch (type) {
      case ModalTypes.EDIT_ROUTINE_MODAL:
        return <AddRoutineModal title={title} open={open} close={close} edit={edit} />;
      case ModalTypes.EDIT_ROUTINE_DEVICES_MODAL:
        return <AddDeviceToRoutine open={open} close={close} />;
      default:
        return <></>;
    }
  }
  return <></>;
}
