import { FC } from 'react';
import styled from 'styled-components';
import { Dropdown, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { User as UserIcon } from 'react-feather';
import { connect } from 'react-redux';

import { logOut } from 'Store/Actions/appActions';
import { RootState } from 'Store/Reducers';
import { Page, User } from 'Interfaces';
import ThemeToggle from './ThemeToggle';
import SettingsButton from './SettingsButton';

const MainHeader = styled.h1`
  color: ${({ theme }) => theme.textColor};
  margin: 0;
`;

const StyledTopBar = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.textColor};
  background-color: ${({ theme }) => theme.appBg};
  display: flex;
  height: ${({ theme }) => theme.headerHeight};
  justify-content: flex-start;
  padding: ${({ theme }) => theme.sizes.lg};
`;

const StyledUserIcon = styled(UserIcon)`
  stroke: ${({ theme }) => theme.colorPrimary};
  width: 1.763rem;
`;

const UserMenu = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  div {
    margin-right: ${({ theme }) => theme.sizes.sm};
  }

  div:last-child {
    margin-right: 0;
  }

  span {
    margin-right: ${({ theme }) => theme.sizes.md};
    font-size: 1rem;
  }
`;

interface Props {
  currentPage: Page;

  logOut: (arg: string) => void;
  loggedIn: boolean;
  loggedInUser: User;
}

export const TopBar: FC<Props> = ({
  currentPage,
  loggedIn,
  logOut,
  loggedInUser
}) => {
  const handleLogout = () => {
    logOut(loggedInUser && loggedInUser.Id);
  };
  return (
    <StyledTopBar>
      {currentPage && <MainHeader>{currentPage.name}</MainHeader>}
      {loggedIn && (
        <UserMenu>
          <span>
            {loggedInUser ? (
              <>
                {loggedInUser?.FirstName} {loggedInUser?.LastName}
              </>) : <Loader active inline />}
          </span>
          <Dropdown direction='left' icon={<StyledUserIcon />}>
            <Dropdown.Menu>
              {loggedInUser && <Dropdown.Item text='Profile' as={Link} to={`/users/${loggedInUser.Id}`} />}
              <Dropdown.Item text='Log Out' onClick={handleLogout} />
            </Dropdown.Menu>
          </Dropdown>
          <SettingsButton />
          <ThemeToggle />
        </UserMenu>
      )}
    </StyledTopBar>
  );
};

export default connect(
  ({ app: { currentPage, loggedIn, loggedInUser } }: RootState) => ({
    currentPage: currentPage,
    loggedIn: loggedIn,
    loggedInUser: loggedInUser
  }),
  (dispatch) => ({
    logOut: logOut(dispatch)
  })
)(TopBar);
