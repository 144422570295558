import React from 'react';

import { AddDeviceModal, AddUserModal, AddIdentityTokenModal, EditUserDetailsModal, DeviceAccessModal, EditIdentitiesModal, EditDeviceInfoModal, EditDeviceOptionsModal, EditUserOptionsModal, DeviceUserAccessModal } from 'Components/Modals';
import IdentityTokenInUseModal from './IdentityTokenInUseModal';

const AllModals:React.FC = () => {
  return (
    <>
      <AddDeviceModal />
      <AddIdentityTokenModal />
      <AddUserModal />
      <EditUserDetailsModal />
      <DeviceAccessModal />
      <EditIdentitiesModal />
      <EditDeviceInfoModal />
      <EditDeviceOptionsModal />
      <EditUserOptionsModal />
      <DeviceUserAccessModal />
      <IdentityTokenInUseModal />
    </>
  );
};

export default AllModals;
