import { Device, KeyStringDictionary } from 'Interfaces';
import { DeviceActionTypes } from 'Store/ActionEnums';
import { DeviceActions } from 'Store/ActionTypes';
import { DeviceState } from 'Store/Interfaces/States/DeviceState';

const softwareVersions:KeyStringDictionary = {
  asus_pv100a: '',
  dell: ''
};
const defaultState: DeviceState = {
  columnBValues: [],
  columnCValues: [],
  currentDevice: null,
  devices: [],
  latestSoftwareVersions: softwareVersions,
  fetchingColBValues: false,
  fetchingColCValues: false,
  fetchingDevices: false,
  updatingDeviceItem: false,
  addingDeviceItem: false,
  fetchingDeviceItem: false,
  refreshingCurrentDevice: false,
  addingMultipleUsers: false,
  fetchingLatestSoftwareVersions: false,
  count: 0
};

const device = (state = defaultState, action: DeviceActions) => {
  switch (action.type) {
    case DeviceActionTypes.ADD_DEVICE_START:
      return {
        ...state,
        addingDeviceItem: true
      };
    case DeviceActionTypes.ADD_DEVICE_SUCCESS:
      return {
        ...state,
        addingDeviceItem: false
      };
    case DeviceActionTypes.FAILED_ADDING_DEVICE:
      return {
        ...state,
        addingDeviceItem: false
      };
    case DeviceActionTypes.FETCHING_ALL_DEVICES:
      return {
        ...state,
        devices: [],
        fetchingDevices: true
      };
    case DeviceActionTypes.GOT_ALL_DEVICES:
      return {
        ...state,
        devices: action.payload,
        fetchingDevices: false
      };
    case DeviceActionTypes.FAILED_FETCHING_ALL_DEVICES:
      return {
        ...state,
        fetchingDevices: false
      };
    case DeviceActionTypes.FETCHING_SINGLE_DEVICE:
      return {
        ...state,
        fetchingDeviceItem: true
      };
    case DeviceActionTypes.GOT_SINGLE_DEVICE:
      return {
        ...state,
        currentDevice: action.payload,
        fetchingDeviceItem: false
      };
    case DeviceActionTypes.FAILED_FETCHING_SINGLE_DEVICE:
      return {
        ...state,
        fetchingDeviceItem: false
      };
    case DeviceActionTypes.UPDATE_DEVICE_START:
      return {
        ...state,
        updatingDeviceItem: true
      };
    case DeviceActionTypes.UPDATE_DEVICE_SUCCESS:
      return {
        ...state,
        currentDevice: {
          ...state.currentDevice,
          Name: action.payload.Name,
          B: action.payload.B,
          C: action.payload.C
        } as Device,
        updatingDeviceItem: false
      };
    case DeviceActionTypes.FAILED_UPDATING_DEVICE:
      return {
        ...state,
        updatingDeviceItem: false
      };
    case DeviceActionTypes.GETTING_DISTINCT_COL_B_VALUES:
      return {
        ...state,
        fetchingColBValues: true
      };
    case DeviceActionTypes.GOT_DISTINCT_COL_B_VALUES:
      return {
        ...state,
        fetchingColBValues: false,
        columnBValues: action.payload
      };
    case DeviceActionTypes.FAILED_GETTING_DISTINCT_COL_B_VALUES:
      return {
        ...state,
        fetchingColBValues: false
      };
    case DeviceActionTypes.GETTING_DISTINCT_COL_C_VALUES:
      return {
        ...state,
        fetchingColCValues: true
      };
    case DeviceActionTypes.GOT_DISTINCT_COL_C_VALUES:
      return {
        ...state,
        columnCValues: action.payload,
        fetchingColCValues: false
      };
    case DeviceActionTypes.FAILED_GETTING_DISTINCT_COL_C_VALUES:
      return {
        ...state,
        fetchingColCValues: false
      };
    case DeviceActionTypes.REFRESHING_CURRENT_DEVICE:
      return {
        ...state,
        refreshingCurrentDevice: true
      };
    case DeviceActionTypes.REFRESHED_CURRENT_DEVICE:
      return {
        ...state,
        currentDevice: action.payload,
        refreshingCurrentDevice: false
      };
    case DeviceActionTypes.FAILED_REFRESHING_CURRENT_DEVICE:
      return {
        ...state,
        refreshingCurrentDevice: false
      };
    case DeviceActionTypes.NO_CURRENT_DEVICE:
      return {
        ...state,
        refreshingCurrentDevice: false
      };
    case DeviceActionTypes.ADDING_MULTIPLE_USERS_START:
      return {
        ...state,
        addingMultipleUsers: true
      };
    case DeviceActionTypes.ADDING_MULTIPLE_USERS_END:
      return {
        ...state,
        addingMultipleUsers: false
      };
    case DeviceActionTypes.FETCHING_LATEST_SOFTWARE_VERSIONS_START:
      return {
        ...state,
        fetchingLatestSoftwareVersions: true
      };
    case DeviceActionTypes.FETCHING_LATEST_SOFTWARE_VERSIONS_SUCCESS:
      return {
        ...state,
        fetchingLatestSoftwareVersions: false,
        latestSoftwareVersions: { ...action.payload }
      };
    case DeviceActionTypes.FAILED_FETCHING_LATEST_SOFTWARE_VERSIONS:
      return {
        ...state,
        fetchingLatestSoftwareVersions: false
      };
    case DeviceActionTypes.GOT_DEVICE_COUNT:
      return {
        ...state,
        count: action.payload
      };
    default:
      return state;
  }
};

export default device;
