import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';
import { GoogleMap, InfoBox, useLoadScript } from '@react-google-maps/api';

import { usePolling } from 'Util/usePolling';
import { getPositionsForLCU } from 'Store/Actions/optionsActions';
import { loadingMessages } from '../../../Common/messages';
import { deviceIsAlive } from 'Util/deviceIsAlive';
import { operationStatusNameFromKeyName } from 'Util/operationStatusHelpers';
import { formatDate } from 'Util/formatDate';

import { IconTypes, TableLink } from 'access_ctrl-ui';
import mapStyles from 'Assets/mapStyles';
import { RootState } from 'Store/Reducers';
import { DeviceLog, LcuPosition } from 'Interfaces';
import StyledMarker from './StyledMarker';

const mapStylesKeys = ['dark', 'light', 'satellite'];

const Wrapper = styled.main`
  width: 97%;
  height: 97%;

  .gm-fullscreen-control {
    background: ${({ theme }) => theme.mapBg} !important;

    & > img {
      filter: ${({ theme }) => theme.mapFilter} !important;
    }
  }

  .gm-style-mtc {
    & > button {
      background: ${({ theme }) => theme.mapBg} !important;
      color: ${({ theme }) => theme.textColor} !important;
    }

    & div {
      background: ${({ theme }) => theme.mapBg} !important;
      color: ${({ theme }) => theme.textColor} !important;
    }
  }

  .mapsInfoBox {
    & > img {
      z-index: 2;
    }
  }

  .gm-bundled-control-on-bottom > div > div {
    background: #363636 !important;

    & > button {
      background: ${({ theme }) => theme.mapBg} !important;

      & > img {
        filter: ${({ theme }) => theme.mapFilter} !important;
      }
    }

    & > button + div {
      background: #363636 !important;
    }
  }

  .gm-svpc {
    background: ${({ theme }) => theme.mapBg} !important;
  }
`;

const infoWindowWidth = 340;

const StyledInfoWindow = styled.div`
  background: ${({ theme }) => theme.appBg};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.appBorderColor};
  color: ${({ theme }) => theme.textColor};
  padding: ${({ theme }) => theme.sizes.md};
  position: relative;
  width: ${infoWindowWidth}px;
  z-index: 0;

  h3:nth-child(1) {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: ${({ theme }) => theme.sizes.lg};
    margin-right: 30px;
  }

  &::after,
  &::before {
    background: transparent;
    bottom: 100%;
    content: " ";
    height: 0;
    left: 50%;
    pointer-events: none;
    position: absolute;
    width: 0;
  }

  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid ${({ theme }) => theme.appBg};
    transform: translate(-50%);
  }

  &::before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.appBorderColor};
    transform: translate(-50%);
  }
`;
interface TableProps {
  $offline: boolean;
}
const Table = styled.table<TableProps>`
  border-collapse: collapse;
  font-size: 0.7rem;
  margin-bottom: ${({ theme }) => theme.sizes.md};
  table-layout: fixed;
  width: 100%;

  tr {
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-bottom: ${({ theme }) => theme.sizes.md};

      &:first-child {
        color: ${({ theme }) => theme.textColorSecondary};
        font-family: ${({ theme }) => theme.fontBase};
        text-transform: uppercase;
        width: 115px;
      }

      &:last-child {
        color: ${({ theme, $offline }) => $offline ? theme.textColorError : theme.textColorPrimary};
        font-family: ${({ theme }) => theme.fontSecondary};
      }
    }
  }
`;
interface DeviceMarker {
  [key:string]: google.maps.MVCObject;
}

// Defaults to Åkerströms HQ
const DEFAULT_MAP_POSITION = {
  lat: 60.466153651271554,
  long: 14.683579758559084

};
interface Props {
  deviceLogs: DeviceLog[];
  lcuPositions: LcuPosition[];
  getPositionsForLCU: () => Promise<void>;
  theme: string;
}
export const MapComponent:React.FC<Props> = ({ deviceLogs, lcuPositions, getPositionsForLCU, theme }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markers, setMarkers] = useState<DeviceMarker>({} as DeviceMarker);
  const [currentOpenDevice, setCurrentOpenDevice] = useState<LcuPosition | null>(null);
  const [loadingPlaces, setLoadingPlaces] = useState<boolean>(true);
  const getCurrentIsAliveData = (place:LcuPosition | null) => {
    const empty = { Key: 'Unknown', Value: { Value: 'Unknown', ServerTimestamp: '0001-01-01' }, LastSeen: '0001-01-01' };
    if (!place) {
      // Open device is empty return empty
      return empty;
    }

    const deviceLogForPlace = deviceLogs.find((l) => place.id === l.Id);

    if (!deviceLogForPlace) {
      // We can't find this device at all in device logs return empty
      return empty;
    }

    // Find the data for the LastSeen date
    const keyDataForDeviceLog = deviceLogForPlace.Data.find((data) => deviceLogForPlace.LastSeen === data.Value.ServerTimestamp);

    if (!keyDataForDeviceLog) {
      // The device has a LastSeen but doesn't have any data.
      // Return LastSeen as timestamp
      return {
        Key: 'Unknown',
        Value: { Value: 'Unknown', ServerTimestamp: deviceLogForPlace.LastSeen },
        LastSeen: deviceLogForPlace.LastSeen
      };
    }

    // Return the complete LastSeen object.
    return keyDataForDeviceLog;
  };

  const getCurrentIsAliveName = (place:LcuPosition) => {
    if (!place) {
      return null;
    }

    const loga = deviceLogs.find((l) => place.id === l.Id);

    if (!loga) {
      return '';
    }

    return loga.Name;
  };

  useEffect(() => {
    if (map) {
      map.setMapTypeId(getMapTheme(theme));
    }
  }, [theme, map]);

  useEffect(() => {
    getPositionsForLCU().then(() => {
      setLoadingPlaces(false);
    });
  }, [getPositionsForLCU]);

  usePolling(async () => {
    getPositionsForLCU();
  }, 10000);

  const onClickMarker = (_e:React.MouseEvent|React.TouchEvent, place:LcuPosition) => {
    if (currentOpenDevice) {
      setCurrentOpenDevice(null);
      return;
    }

    setCurrentOpenDevice(place);
  };

  const close = () => {
    setCurrentOpenDevice(null);
  };

  const onLoadMarker = (marker: google.maps.MVCObject, place:LcuPosition) => {
    return setMarkers(prevState => {
      return { ...prevState, [place.id]: marker };
    });
  };

  const getMapBounds = (locations:LcuPosition[]) => {
    const bounds = new window.google.maps.LatLngBounds();

    if (!locations || locations.length <= 0) {
      bounds.extend(
        new window.google.maps.LatLng(DEFAULT_MAP_POSITION.lat, DEFAULT_MAP_POSITION.long)
      );
      return bounds;
    }

    locations.forEach((location) => {
      if (location.pos.lat && location.pos.lng) {
        bounds.extend(
          new window.google.maps.LatLng(location.pos.lat, location.pos.lng)
        );
      }
    });

    return bounds;
  };

  const isDeviceAlive = (place:LcuPosition) => {
    if (!deviceLogs) {
      return false;
    }

    const deviceLog = deviceLogs.find((log) => place.id === log.Id);

    // TODO: This is so the dashboard doesn't crash when
    // there are coordinates in options for a device but it's offline and
    // operation status is empty. There are still more bugs to fix with this scenario
    if (!deviceLog) {
      return false;
    }

    return deviceIsAlive(deviceLog.LastSeen);
  };

  const getMapTheme = (theme:string) => {
    if (mapStylesKeys.includes(theme)) {
      return theme;
    }

    return 'dark';
  };

  const renderMap = () => {
    const g = window.google;
    const style = g.maps.MapTypeControlStyle.DROPDOWN_MENU;

    const currentIsAliveData = getCurrentIsAliveData(currentOpenDevice);

    const lightStyledMapType = new g.maps.StyledMapType(mapStyles.light, { name: 'Light' });
    const darkStyledMapType = new g.maps.StyledMapType(mapStyles.dark, { name: 'Dark' });

    const opts:google.maps.MapOptions = {
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: style,
        mapTypeIds: mapStylesKeys
      },
      streetViewControl: false
    };

    const infoBoxOpts = {
      boxClass: 'mapsInfoBox',
      boxStyle: {
        padding: '10px 0px 0px 0px',
        background: 'transparent'
      },
      closeBoxMargin: '18px 16px 0px 0px',
      closeBoxURL: 'data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="rgb(239,124,0)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
      disableAutoPan: false,
      enableEventPropagation: false,
      infoBoxClearance: new g.maps.Size(1, 1),
      isHidden: false,
      maxWidth: 0,
      padding: '20px 0px 0px 0px',
      pane: 'floatPane',
      pixelOffset: new g.maps.Size(-infoWindowWidth / 2, 8),
      zIndex: undefined
    };

    return (
      <Wrapper>
        <GoogleMap
          options={opts}
          mapContainerStyle={{
            background: '#defdfa',
            height: '100%',
            maxWidth: '100%',
            width: '100%'
          }}
          onClick={close}
          onLoad={map => {
            setMap(map);
            map.fitBounds(getMapBounds(lcuPositions));
            map.mapTypes.set('dark', darkStyledMapType);
            map.mapTypes.set('light', lightStyledMapType);
            map.setMapTypeId(getMapTheme(theme));
          }}
        >
          {lcuPositions && lcuPositions.map(place => {
            if (place?.id && deviceLogs) {
              return (
                <StyledMarker
                  key={place.id}
                  deviceLogs={deviceLogs}
                  onClickMarker={onClickMarker}
                  place={place}
                  onLoadMarker={onLoadMarker}
                />
              );
            }
            return <></>;
          }
          )}
          {currentOpenDevice &&
            <InfoBox
              anchor={markers[currentOpenDevice.id]}
              options={infoBoxOpts}
              onCloseClick={close}
            >
              <StyledInfoWindow>
                <h3>{getCurrentIsAliveName(currentOpenDevice)}</h3>
                <Table $offline={!isDeviceAlive(currentOpenDevice)}>
                  <tbody>
                    <tr>
                      <td>Key:</td>
                      <td title={operationStatusNameFromKeyName(currentIsAliveData.Key as string)}>{operationStatusNameFromKeyName(currentIsAliveData.Key as string)}</td>
                    </tr>
                    <tr>
                      <td>Value:</td>
                      <td title={currentIsAliveData.Value.Value}>{currentIsAliveData.Value.Value}</td>
                    </tr>
                    <tr>
                      <td>Last contact:</td>
                      <td title={formatDate(currentIsAliveData.Value.ServerTimestamp)}>{formatDate(currentIsAliveData.Value.ServerTimestamp)}</td>
                    </tr>
                    <tr>
                      <td>Manual position:</td>
                      <td title='Manual position'>{currentOpenDevice?.manualPosition?.toString() ?? ''}</td>
                    </tr>
                  </tbody>
                </Table>
                <TableLink
                  text='Device details'
                  iconType={IconTypes.Device}
                  to={`/devices/${currentOpenDevice.id}`}
                  fontSize='0.9rem'
                />
              </StyledInfoWindow>
            </InfoBox>}
        </GoogleMap>
      </Wrapper>
    );
  };

  if (loadError) {
    return <p>Something went wrong, loading map</p>;
  }

  return (isLoaded && !loadingPlaces) ? renderMap() : <Loader active>{loadingMessages.LOADING_MAP}</Loader>;
};

export default connect(({ app, operationlog, options }:RootState) => ({
  deviceLogs: operationlog.deviceLogs,
  lcuPositions: options.lcuPositions,
  theme: app.theme
}), (dispatch) => ({
  getPositionsForLCU: getPositionsForLCU(dispatch)
}))(MapComponent);
