import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { reset, setCurrentPage } from 'Store/Actions/appActions';

import { Layout } from 'access_ctrl-ui';
import { Page } from 'Interfaces';
import { RootState } from 'Store/Reducers';
import { browserHistory as history } from 'Store/store';
const NoPermissionText = styled.h2`
  color: #fff;
`;
const GoToLoginText = styled.span`
  cursor: pointer;
`;
interface Props {
  setCurrentPage: (pageInfo: Page) => void
  reset: () => void;
}
const NoPermission:React.FC<Props> = ({ setCurrentPage, reset }) => {
  useEffect(() => {
    setCurrentPage({ name: 'Access Denied' });
  }, [setCurrentPage]);
  const onClick = () => {
    reset();
    history.replace('/');
  };
  return (
    <Layout.Wrapper>
      <Layout.Section flexGrow={false}>
        <NoPermissionText>You don&#39;t have access to view this page. <GoToLoginText onClick={onClick}><strong>Go to login page</strong></GoToLoginText> </NoPermissionText>
      </Layout.Section>
    </Layout.Wrapper>
  );
};

export default connect(({ app }: RootState) => ({
}), dispatch => (
  {
    setCurrentPage: setCurrentPage(dispatch),
    reset: reset(dispatch)
  }
))(NoPermission);
