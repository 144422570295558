import { useMemo } from 'react';
import { License } from 'Store/Interfaces/States/License';

export default function useEnabledFeatures(license?:License) {
  return useMemo(() => {
    return license
      ? Object.entries(license.Features)
        .filter(kvp => kvp[1].Enabled)
        .map(kvp => kvp[0].toLowerCase().replace(' ', '')) : [] as string[];
  }, [license]);
}
