import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useCurrentEffect } from 'use-current-effect';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Tab, TabProps } from 'semantic-ui-react';
import { Play, Pause } from 'react-feather';

import { addWarningMessage, makeErrorPayload } from 'Store/Actions/feedbackMessage';
import { apiCallErrorMessages } from '../../../Common/messages';
import { getOperationLogHistory, getOperationLogForDevice, getStatusForDevices } from 'Store/Actions/operationLogActions';
import { getDebugMessages } from 'Store/Actions/debugActions';
import { operationStatusNameFromKeyName, convertOperationStatusToChartData, convertOperationStatusToTableData } from 'Util/operationStatusHelpers';
import { usePolling } from 'Util/usePolling';
import { formatDate, convertDate } from 'Util/formatDate';
import { STATE_HISTORY_KEY } from '../../../Common/Constant';

import { Popup, Button, IconTypes, TableText, Table, ThemeTypes, TableRowType, TableColumnType, TableKeyType, HeaderRow } from 'access_ctrl-ui';
import VisualOperationStatus from './VisualOperationStatus';
import LogPane from '../../Panes/LogPane';
import StateHistory from './StateHistory';
import { TableModal, ChartModal } from 'Components/Modals';
import { ChartData, Device, OperationLogItem } from 'Interfaces';
import { RootState } from 'Store/Reducers';
import { convertToStringLowerCase } from 'Util/formatString';
import PaneMenuItem from 'Components/Global/PaneMenuItem';
import { DateTime } from 'luxon';
import { DebugMessage } from 'Interfaces/DebugMessage';
import { OperationLog } from 'Interfaces/OperationLog';
import { dateSort } from 'Util/sortFn';
import DeviceStatusItem from 'Components/Global/DeviceStatusItem';
import RecordPane from 'Components/Panes/RecordPane';
import { useAppSelector } from 'Store/hooks';
import useCheckInput from 'Hooks/useCheckInput';

const PauseButton = styled(Button)`
  border: none;
  padding: 0;
  float: right;

  & svg {
    padding: 0;
    margin: 0;
  }

  &:hover:not(.loading) {
    background: inherit;

    & svg {
      padding: 0;
      margin: 0;
    }
  }
`;

const PauseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-start;
  margin-top: 1rem;
`;

const OperationStatusContainer = styled.div`
  display: flex;
  flex-flow: row;
  height: 100%;
  padding-right: ${({ theme }) => theme.sizes.md};
`;

const StyledTab = styled(Tab)`
  &&& {
    display: flex;
    flex-flow: column;
    flex: 1;

    & > div.ui.menu {
      & a.item {
        color: ${({ theme }) => theme.textColor};
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        border-top: 2px solid transparent;
        font-family: ${({ theme }) => theme.fontBase};
      }

      & a.active {
        border-color: ${({ theme }) => theme.appBorderColor};
        background: none;
        font-weight: normal;
      }
    }

    & > div.ui.tab.active {
      background: ${({ theme }) => theme.appBg};
      display: flex;
      flex-flow: column;
      flex: 1;
      border: none;
      box-shadow: none;
      margin: 0;
      padding: 0;
    }

    & .ui.loader {
      margin-left: 1rem;
    }
  }
`;

const StyledH2 = styled.h2`
  margin-right: ${({ theme }) => theme.sizes.md} !important;
  right: 0;
`;

const StyledHeaderRow = styled(HeaderRow)`
  display: flex;
  justify-content: space-between;
`;

const operationLogTableDataColumns = [
  {
    name: 'Key',
    key: 'name'
  },
  {
    name: 'Value',
    key: 'value'
  },
  {
    name: 'Last sample',
    key: 'lastSample'
  }
];

const graphKeys = [
  'LCU.PING',
  'RX.VALID_SERIAL_DATA_RECEIVED',
  'RX.HK_ON',
  'RX.TX_ACTIVE',
  'TX.SIGNAL_QUALITY',
  'RX.RSSI'
];
interface ChartStep {
  [key: string]: number;
}
const chartStep: ChartStep = {
  'LCU.PING': 6000,
  'RX.VALID_SERIAL_DATA_RECEIVED': 6000
};

const tableKeys = [
  'LCU.LAST_READ_IDENTITY_TOKEN',
  'LCU.LAST_AUTHORIZED_IDENTITY_TOKEN',
  'RX.SERIAL_DATA_RECEIVED',
  'LCU.SERVICE_TAG',
  'RX.STATE',
  'RX.PLC_REG_178',
  'RX.PLC_REG_179',
  '_ACL'
];

const historyTableDataColumns: TableColumnType[] = [
  {
    name: 'Key',
    key: 'keyname'
    // width: 3
  },
  {
    name: 'Value',
    key: 'value'
    // width: 3
  },
  {
    name: 'Date',
    key: 'date',
    // width: 7,
    sortFn: (dir: TableKeyType, col: TableKeyType) => {
      return dateSort(dir, col);
    }
  },
  {
    name: 'Count',
    key: 'count'
    // width: 3
  }
];

interface Props {
  currentDevice: Device;
  getDebugMessages: (deviceId: string) => Promise<DebugMessage[]>;
  getOperationLogHistory: (deviceId: string, key: string) => Promise<OperationLogItem[] | null>;
  getOperationLogForDevice: (deviceId: string) => Promise<void>;
  getStatusForDevices: () => Promise<void>;
  addWarningMessage: (content: string, autoClose?: boolean) => void;
  theme: ThemeTypes;
  fetchingOperationLog: boolean;
  operationlog: OperationLog;
  fetchingDebugMessages: boolean;
}

const OperationStatus: React.FC<Props> = ({ currentDevice, getDebugMessages, getOperationLogHistory, getOperationLogForDevice, addWarningMessage, getStatusForDevices, theme, fetchingOperationLog, operationlog, fetchingDebugMessages }) => {
  const [operationLogTableData, setOperationLogTableData] = useState<TableRowType[]>([] as TableRowType[]);
  const [activeOpStatusTab, setActiveTab] = useState<number>(0);

  const [historyTableData, setHistoryTableData] = useState<TableRowType[]>([] as TableRowType[]);
  const [showTableModal, setShowTableModal] = useState(false);
  const [historyHeader, setHistoryHeader] = useState('');
  const [activeHistoryKey, setActiveHistoryKey] = useState<string | null>(null);

  const [chartData, setChartData] = useState<ChartData[]>([] as ChartData[]);
  const [showChartModal, setShowChartModal] = useState(false);
  const [chartHeader, setChartHeader] = useState('');
  const [datasetLabel, setDatasetLabel] = useState('');
  const [spanGaps, setSpanGaps] = useState<number>(6000);

  const [debugLogData, setDebugLogData] = useState<string[]>([] as string[]);
  const [lastMessageTimestamp, setLastMessageTimestamp] = useState<DateTime>();

  const [stateHistoryData, setStateHistoryData] = useState<OperationLogItem[] | null>(null);
  const [loadingStateHistory, setLoadingStateHistory] = useState(false);
  const pause = useCheckInput('show');
  const [pausePolling, setPausePolling] = useState(pause);

  const deviceLogs = useAppSelector(state => state.operationlog.deviceLogs);

  const location = useLocation();
  const history = useHistory();

  const getOperationLog = useCallback(() => {
    return getOperationLogForDevice(currentDevice.Id).catch(err => {
      addWarningMessage(makeErrorPayload(apiCallErrorMessages.FETCHING_OPERATION_LOG_NOT_FOUND, err), false);
    });
  }, [addWarningMessage, currentDevice, getOperationLogForDevice]);

  const getDebugLogData = useCallback(async () => {
    const data = await getDebugMessages(currentDevice.Id);

    if (data) {
      const newLogData = [] as string[];

      data.forEach((row: DebugMessage) => {
        if (!lastMessageTimestamp || convertDate(row.timestamp) > lastMessageTimestamp) {
          newLogData.push(`${formatDate(row.timestamp)} - ${row.message}`);
        }
      });

      const [lastLogItem] = data.slice(-1);

      setLastMessageTimestamp(convertDate(lastLogItem.timestamp));

      setDebugLogData([
        ...debugLogData,
        ...newLogData
      ]);
    }
  }, [currentDevice, getDebugMessages, lastMessageTimestamp, debugLogData, setDebugLogData]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    const { activeOpStatusTab } = params;
    if (activeOpStatusTab === undefined) {
      setActiveTab(0);
    } else {
      setActiveTab(parseInt(convertToStringLowerCase(activeOpStatusTab)));
    }
  }, [location]);

  useCurrentEffect((isCurrent) => {
    const fetchData = async () => {
      const stateHistory = await getOperationLogHistory(currentDevice.Id, STATE_HISTORY_KEY);

      if (isCurrent()) {
        if (stateHistory) {
          setStateHistoryData(stateHistory);
        }
        setLoadingStateHistory(false);
      }
    };

    if (activeOpStatusTab === 3) {
      setLoadingStateHistory(true);
      fetchData();
    }
  }, [activeOpStatusTab]);

  useEffect(() => {
    if (currentDevice) {
      getOperationLog();
    }
  }, [currentDevice, getOperationLog]);

  useCurrentEffect((isCurrent) => {
    const fetchData = async () => {
      const data = await getDebugMessages(currentDevice.Id);

      if (isCurrent()) {
        if (data) {
          const newLogData = data.map((row: DebugMessage) => {
            return `${formatDate(row.timestamp)} - ${row.message}`;
          });

          const [lastLogItem] = data.slice(-1);

          setLastMessageTimestamp(convertDate(lastLogItem.timestamp));

          setDebugLogData(newLogData);
        }
      }
    };

    fetchData();
  }, [currentDevice]);

  usePolling(async () => {
    await getDebugLogData();
  }, pausePolling ? null : 4000);

  usePolling(async () => {
    const stateData = await fetchOperationLogHistory(STATE_HISTORY_KEY);

    if (stateData) {
      setStateHistoryData(stateData);
    }
  }, pausePolling ? null : 4000);

  usePolling(async () => {
    await getOperationLog();
  }, pausePolling ? null : 10000);

  usePolling(async () => {
    const logHistory = await fetchOperationLogHistory(activeHistoryKey);
    if (logHistory) {
      setHistoryTableData(convertOperationStatusToTableData(logHistory, activeHistoryKey));
    }
  }, (showTableModal && !pausePolling) ? 5000 : null);

  usePolling(async () => {
    const logHistory = await fetchOperationLogHistory(activeHistoryKey);
    if (logHistory) {
      setChartData(convertOperationStatusToChartData(logHistory));
    }
  }, (showChartModal && !pausePolling) ? 5000 : null);

  usePolling(async () => {
    getStatusForDevices();
  }, 4000);

  const fetchOperationLogHistory = useCallback(async (key: string | null) => {
    if (!key) {
      return;
    }
    return await getOperationLogHistory(currentDevice.Id, key);
  }, [getOperationLogHistory, currentDevice]);

  const showGraph = useCallback(async (key: string) => {
    const logHistory = await fetchOperationLogHistory(key);
    if (logHistory) {
      setChartData(convertOperationStatusToChartData(logHistory));
    }
    setChartHeader(`Graph for ${operationStatusNameFromKeyName(key)}`);
    setShowChartModal(true);
    setDatasetLabel(operationStatusNameFromKeyName(key));
    setSpanGaps(chartStep[key]);
    setActiveHistoryKey(key);
  }, [fetchOperationLogHistory]);

  const showTable = useCallback(async (key) => {
    const logHistory = await fetchOperationLogHistory(key);

    setHistoryHeader(`History for ${operationStatusNameFromKeyName(key)}`);
    if (logHistory) {
      setHistoryTableData(convertOperationStatusToTableData(logHistory, key));
    }
    setShowTableModal(true);
    setActiveHistoryKey(key);
  }, [fetchOperationLogHistory]);

  const onCloseHistoryModal = () => {
    setShowTableModal(false);
    setHistoryTableData([]);
    setActiveHistoryKey(null);
  };

  const onCloseChartModal = () => {
    setShowChartModal(false);
    setChartData([]);
    setActiveHistoryKey(null);
  };

  const handleTabChange = (_: React.MouseEvent<HTMLDivElement>, { activeIndex }: TabProps) => {
    const query = new URLSearchParams(location.search);

    if (activeIndex === undefined) {
      query.delete('activeOpStatusTab');
    } else {
      query.set('activeOpStatusTab', activeIndex.toString());
    }

    history.replace({ ...history.location, search: query.toString() });
  };

  const handlePauseButtonClick = () => {
    setPausePolling(!pausePolling);
  };

  useEffect(() => {
    if (operationlog) {
      const _tdata = Object.keys(operationlog).filter((key) => !key.startsWith('.')).map((key) => {
        const name = operationStatusNameFromKeyName(key);

        let nameLink: string | React.ReactNode = name;

        if (graphKeys.includes(key)) {
          nameLink = <TableText onClick={() => showGraph(key)} iconType={IconTypes.Graph} text={name} />;
        } else if (tableKeys.includes(key)) {
          nameLink = <TableText onClick={() => showTable(key)} iconType={IconTypes.List} text={name} />;
        }

        return {
          key,
          successful: true,
          sortData: {
            name
          },
          data: {
            name: nameLink,
            value: operationlog[key].Value,
            lastSample: formatDate(operationlog[key].Timestamp)
          }
        };
      });

      setOperationLogTableData(_tdata);
    }
  }, [operationlog, showGraph, showTable]);

  const getPanes = () => {
    return [
      {
        menuItem: <PaneMenuItem key='opstatstatus' text='Status' description='Visual operation status for this device' loading={fetchingOperationLog} />,
        render: () => (
          <Tab.Pane attached={false}>
            <VisualOperationStatus operationlog={operationlog} currentDevice={currentDevice} />
          </Tab.Pane>
        )
      },
      {
        menuItem: <PaneMenuItem key='opstatdetails' text='Details' description='Operation status keys for this device' loading={fetchingOperationLog} />,
        render: () => (
          <Tab.Pane attached={false}>
            <Table
              defaultSortDir='asc'
              defaultSort='name'
              tableDataColumns={operationLogTableDataColumns}
              tableDataRows={operationLogTableData}
              setQueryString={false}
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: <PaneMenuItem key='opstatlog' text='Log' description='View device log output' loading={fetchingDebugMessages} />,
        render: () => (
          <Tab.Pane attached={false}>
            <LogPane logData={debugLogData} />
          </Tab.Pane>
        )
      },
      {
        menuItem: <PaneMenuItem key='opstathistory' text='History' description='View receiver command history' loading={fetchingOperationLog} />,
        render: () => (
          <Tab.Pane attached={false}>
            <StateHistory loading={loadingStateHistory} state={stateHistoryData} />
          </Tab.Pane>
        )
      },
      {
        menuItem: <PaneMenuItem key='opstatservice' text='Records' description='View device records' loading={fetchingDebugMessages} />,
        render: () => (
          <Tab.Pane attached={false}>
            <RecordPane />
          </Tab.Pane>
        )
      }
    ];
  };

  return (
    <>
      <StyledHeaderRow>
        <StyledH2>Diagnostics</StyledH2>
        <DeviceStatusItem deviceId={currentDevice.Id} deviceLogs={deviceLogs} />
      </StyledHeaderRow>
      <OperationStatusContainer>
        <Popup content={`${pausePolling ? 'Resume' : 'Pause'} polling`} trigger={<PauseButtonContainer><PauseButton onClick={handlePauseButtonClick}>{!pausePolling ? <Pause /> : <Play />}</PauseButton></PauseButtonContainer>} />
        <StyledTab onTabChange={handleTabChange} activeIndex={activeOpStatusTab} panes={getPanes()} />
      </OperationStatusContainer>
      <TableModal
        open={showTableModal}
        modalHeader={historyHeader}
        onClose={onCloseHistoryModal}
        tableDataRows={historyTableData}
        tableDataColumns={historyTableDataColumns}
        descriptionText='Operations status'
        excelExport
      />
      <ChartModal
        open={showChartModal}
        modalHeader={chartHeader}
        onClose={onCloseChartModal}
        theme={theme}
        datasetLabel={datasetLabel}
        chartData={chartData}
        spanGaps={spanGaps}
      />
    </>
  );
};

export default connect(({ operationlog, app, debug }: RootState) => (
  {
    operationlog: operationlog.operationlog,
    fetchingOperationLog: operationlog.fetchingOperationLog,
    fetchingDebugMessages: debug.fetchingDebugMessages,
    theme: app.theme
  }
), dispatch => ({
  getOperationLogForDevice: getOperationLogForDevice(dispatch),
  getOperationLogHistory: getOperationLogHistory(dispatch),
  getDebugMessages: getDebugMessages(dispatch),
  addWarningMessage: addWarningMessage(dispatch),
  getStatusForDevices: getStatusForDevices(dispatch)
}))(OperationStatus);
