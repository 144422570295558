import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { sizes } from '../../../Common/theme';

import { Accordion, Input, Select, Button, Datepicker, Layout, DropdownProps } from 'access_ctrl-ui';
import { convertToString, parseStringFromParam } from 'Util/formatString';
import { UserSearchfilter } from 'Interfaces/UserSearchFilter';

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.sizes.md};
`;

const FilterWrapper = styled.form`
  &&& input,
  &&& div.dropdown {
    margin-top: ${({ theme }) => theme.sizes.sm};
  }

  &&& svg.datepicker-icon {
    margin-top: ${({ theme }) => theme.sizes.sm};
  }

  &&& .react-datepicker-wrapper button {
    margin-top: ${({ theme }) => parseFloat(theme.sizes.sm) / 2}rem;
  }
`;

const initialFilter:UserSearchfilter = {
  FirstName: '',
  LastName: '',
  employeeId: '',
  isAdmin: '0'
};
interface Props {
  activated: boolean;
  hideAccess?:boolean;
}
const UserFilter:React.FC<Props> = ({ activated, hideAccess }) => {
  const [filter, setFilter] = useState(initialFilter);
  const delayedUpdateParams = useRef(_.debounce((key, value, location) => updateParams(key, value, location), 200)).current;
  const delayedUpdateIsAdmin = useRef(_.debounce((value, location) => updateIsAdminParams(value, location), 200)).current;

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = queryString.parse(location.search);

    const newFilter:UserSearchfilter = {
      FirstName: convertToString(params.FirstName),
      LastName: convertToString(params.LastName),
      employeeId: convertToString(params.employeeId),
      isAdmin: params.isAdmin ? parseStringFromParam(params.isAdmin) : undefined,
      accessFrom: params.from ? new Date(convertToString(params.from)) : undefined,
      accessTo: params.to ? new Date(convertToString(params.to)) : undefined
    };

    setFilter(newFilter);
  }, [location.search]);

  const updateIsAdminParams = (value:boolean, location:string) => {
    const query = new URLSearchParams(location);
    if (value) {
      query.set('isAdmin', value.toString());
    } else {
      query.delete('isAdmin');
    }
    query.delete('activePage');
    history.replace({ ...history.location, search: query.toString() });
  };

  const updateParams = (key:string, value:string, location:string) => {
    const query = new URLSearchParams(location);
    if (value === '') {
      query.delete(key);
    } else {
      query.set(key, value);
    }
    query.delete('activePage');
    history.replace({ ...history.location, search: query.toString() });
  };

  const handleFilterChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFilter({
      ...filter,
      [name]: value
    });
    delayedUpdateParams(name, value, location.search);
  };

  const handleIsAdminFilterChange = (_:React.SyntheticEvent<HTMLElement, Event>, data:DropdownProps) => {
    setFilter({
      ...filter,
      isAdmin: data.value?.toString()
    });

    delayedUpdateIsAdmin(data.value || null, location.search);
  };

  const setDatepicker = (_:React.SyntheticEvent<HTMLInputElement, Event> | undefined, name:string, value:Date | null) => {
    const query = new URLSearchParams(location.search);

    if (value === null) {
      query.delete(name);
    } else {
      query.set(name, value.toString());
    }

    query.delete('activePage');
    history.replace({ ...history.location, search: query.toString() });
  };

  const handleClearFilter = () => {
    history.replace({ ...history.location, search: '' });
  };
  return (
    <Accordion title='Filter' fluid activated={activated}>
      <FilterWrapper>
        <ButtonRow>
          <Button type='reset' onClick={handleClearFilter}>Clear filter</Button>
        </ButtonRow>
        <Layout.Row spacing={sizes.md} padding={`0 0 ${sizes.md} 0`}>
          <Input name='FirstName' value={filter.FirstName} placeholder='First name' onChange={handleFilterChange} fluid />
          <Input name='LastName' value={filter.LastName} placeholder='Last name' onChange={handleFilterChange} fluid />
          <Input name='employeeId' value={filter.employeeId} placeholder='Employee number' onChange={handleFilterChange} fluid />
        </Layout.Row>

        {!hideAccess && (
          <Layout.Row spacing={sizes.md} padding={`0 0 ${sizes.md} 0`}>
            <Datepicker
              selected={filter.accessFrom}
              maxDate={filter.accessTo}
              startDate={filter.accessFrom}
              endDate={filter.accessTo}
              selectsStart
              placeholderText='Access from'
              onDateSelect={(e, value) => { setDatepicker(e, 'from', value); }}
              showTimeSelect={false}
            />
            <Datepicker
              selected={filter.accessTo}
              minDate={filter.accessFrom}
              startDate={filter.accessFrom}
              endDate={filter.accessTo}
              selectsEnd
              placeholderText='Access to'
              onDateSelect={(e, value) => { setDatepicker(e, 'to', value); }}
              showTimeSelect={false}
            />
            <Select value={filter.isAdmin} defaultSelectedLabel={0} options={[{ text: 'All' }, { text: 'Yes', value: '1' }, { text: 'No', value: '2' }]} placeholder='Administrator' onChange={handleIsAdminFilterChange} clearable fluid />
          </Layout.Row>)}
      </FilterWrapper>
    </Accordion>
  );
};

export default UserFilter;
