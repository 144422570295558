import { JTokenType } from './Enums';
import { DefaultDeviceOptions } from 'Interfaces';

const defaultDeviceOptions: DefaultDeviceOptions = {
  'appsettings.lastSynchronized': {
    TokenType: JTokenType.Date,
    DefaultFrontendValue: null,
    ReadOnly: true
  },

  'appsettings.AccessControlApiClient.baseUrl': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },
  'appsettings.AccessControlApiClient.clientSecret': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },

  'appsettings.InventoryApiClient.baseUrl': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },
  'appsettings.InventoryApiClient.clientSecret': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },

  'appsettings.InventoryOptionsApiClient.baseUrl': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },
  'appsettings.InventoryOptionsApiClient.clientSecret': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },

  'appsettings.GlobalOptionsApiClient.baseUrl': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },
  'appsettings.GlobalOptionsApiClient.clientSecret': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },

  'appsettings.LogApiClient.clientSecret': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },
  'appsettings.LogApiClient.baseUrl': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },

  'appsettings.DebugApiClient.clientSecret': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },
  'appsettings.DebugApiClient.baseUrl': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },

  'LcuSettings.rxComPort': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: '/dev/serial-port-ioioi1'
  },
  'LcuSettings.rxComPortBaudrate': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: 9600
  },
  'LcuSettings.paddingVersion': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: 0
  },
  'LcuSettings.modbusPort': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: '/dev/serial-port-ioioi2'
  },

  'LcuSettings.useWifiScale': {
    TokenType: JTokenType.Boolean,
    DefaultFrontendValue: false
  },
  'LcuSettings.wifiScaleComPort': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: '/dev/serial-port-ioioi2'
  },
  'LcuSettings.wifiScaleBaudrate': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: '9600'
  },
  'LcuSettings.wifiScaleIP': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: '10.0.60.31'
  },

  'LcuSettings.serviceTag': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null,
    ReadOnly: true
  },
  'LcuSettings.swVersion': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null,
    ReadOnly: true
  },

  'LcuSettings.gpsDataPort': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: '/dev/ttyHS0'
  },
  'LcuSettings.gpsDataBaudrate': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: 9600
  },

  'LcuSettings.logDataConnectionName': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },
  'LcuSettings.logDataConnectionString': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },

  'LcuSettings.useMifareReader': {
    TokenType: JTokenType.Boolean,
    DefaultFrontendValue: false
  },
  'LcuSettings.semaphoreComPort': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: '/dev/serial-port-ioioi2'
  },
  'LcuSettings.mifareListenAddress': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: '10.0.60.1'
  },
  'LcuSettings.useEmReader': {
    TokenType: JTokenType.Boolean,
    DefaultFrontendValue: false
  },
  'LcuSettings.loginTimeoutTime': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: 1800
  },
  'LcuSettings.emReaderIP': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: '172.16.0.5'
  },
  'LcuSettings.semaphoreBaudrate': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: 9600
  },
  'LcuSettings.adminRFIDCard': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null
  },
  'LcuSettings.loginTimeoutWarningTime': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: 900
  },
  'LcuSettings.periodicWarningPeriod': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: 120
  },

  'LcuSettings.uploadInitialSettings': {
    TokenType: JTokenType.Boolean,
    DefaultFrontendValue: false
  },
  'LcuSettings.uploadLinkStatus': {
    TokenType: JTokenType.Boolean,
    DefaultFrontendValue: false
  },
  'LcuSettings.resendTimedoutTasks': {
    TokenType: JTokenType.Boolean,
    DefaultFrontendValue: true
  },
  'LcuSettings.connectionTimeout': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: 1000
  },
  'LcuSettings.maxTaskTimeout': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: 5000
  },
  'LcuSettings.maxOperationalLogTTL': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: 30
  },
  'LcuSettings.cloudUserSyncInterval': {
    TokenType: JTokenType.Integer,
    DefaultFrontendValue: 5
  },
  'LcuSettings.showVerboseInfo': {
    TokenType: JTokenType.Boolean,
    DefaultFrontendValue: false
  },
  'LcuSettings.uploadDebugLogs': {
    TokenType: JTokenType.Boolean,
    DefaultFrontendValue: false
  },
  'LcuSettings.uploadNetworkStatistics': {
    TokenType: JTokenType.Boolean,
    DefaultFrontendValue: false
  },
  'LcuSettings.lastSynchronized': {
    TokenType: JTokenType.Date,
    DefaultFrontendValue: null,
    ReadOnly: true
  },
  'LcuSettings.lastUpdated': {
    TokenType: JTokenType.Date,
    DefaultFrontendValue: null,
    ReadOnly: true
  },
  'LcuSettings.activeWifi': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null,
    ReadOnly: true
  },
  'LcuSettings.lastWifiActivationStatus': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null,
    ReadOnly: true
  },
  'LcuSettings.lastWifiActivationAttempt': {
    TokenType: JTokenType.Date,
    DefaultFrontendValue: null,
    ReadOnly: true
  },
  'LcuSettings.deserializationError': {
    TokenType: JTokenType.Date,
    DefaultFrontendValue: null,
    ReadOnly: true
  },
  'gps.longitude': {
    TokenType: JTokenType.Float,
    DefaultFrontendValue: null
  },
  'gps.latitude': {
    TokenType: JTokenType.Float,
    DefaultFrontendValue: null
  },
  'SW.VERSION': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null,
    ReadOnly: true
  },
  'LCU.TYPE': {
    TokenType: JTokenType.String,
    DefaultFrontendValue: null,
    ReadOnly: true
  }
};

export default defaultDeviceOptions;
