
export const convertToStringLowerCase = (value: string | string[] | null):string => {
  if (Array.isArray(value)) {
    return String(value[0]).toLocaleLowerCase();
  }
  return value ? String(value).toLocaleLowerCase() : '';
};

export const convertToString = (value: string | string[] | null): string => {
  if (Array.isArray(value)) {
    return String(value[0]);
  }
  return value ?? '';
};

export const parseStringFromParam = (value: string | string[] | null):string => {
  const parsedString = convertToString(value);
  return parsedString.toLowerCase();
};

/**
 * Converts a word from PascalCase to contain a space
 * @param {string} word - word to convert
 * @returns {string} converted word, or if not possible original word
 */
export function convertPascalCase(word?:string):string|undefined {
  const wordRe = /($[a-z])|[A-Z][^A-Z]+/g;

  return word?.match(wordRe)?.join(' ') ?? word;
}
