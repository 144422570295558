import { Button, Input, PasswordInput, Popup } from 'access_ctrl-ui';
import { ThemedCssProps } from 'Interfaces';
import styled from 'styled-components';
import { LoginState } from '.';
import { ReactComponent as Logo } from 'Assets/access_ctrl.svg';
import ScanRFID from './ScanRFID';
import { allowedCharacters } from 'Util/passwordValidator';

const Form = styled.form<ThemedCssProps>`
  display: flex;
  flex-flow: column;

  input {
    margin-bottom: ${({ theme }) => theme.sizes.lg};
  }
`;

const ButtonContainer = styled.div<ThemedCssProps>`
  display: flex;
  justify-content: space-between;
`;

const ForgotPassword = styled.a<ThemedCssProps>`
  display: flex;
  color: ${({ theme }) => theme.textColorLink};
  cursor: pointer;
`;

interface Props {
  onChange: (event:React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: React.FormEvent) => void;
  state: LoginState;
}

const MultiFactorLogin:React.FC<Props> = ({ onChange, onSubmit, state: { username, password, passwordEntered } }) => {
  return (
    <>
      <Form onSubmit={onSubmit}>
        {passwordEntered
          ? (<ScanRFID />)
          : (
            <>
              <Logo />
              <Input
                pattern={`[${allowedCharacters}]+`}
                name='username'
                className='username-input'
                onChange={onChange}
                placeholder='Username'
                fluid
              />
              <PasswordInput
                pattern={`[${allowedCharacters}]+`}
                name='password'
                onChange={onChange}
                placeholder='Password'
                fluid
              />
              <ButtonContainer>
                <Button disabled={!(username && password)}>Login</Button>
                <Popup
                  className='popupStyle'
                  content='Contact your administrator and ask them to change your password.'
                  trigger={<ForgotPassword>Forgot password?</ForgotPassword>}
                />
              </ButtonContainer>
            </>)}
      </Form>
    </>
  );
};

export default MultiFactorLogin;
