
export interface DailyRoutine {
  Id: string;
  Name: string;
  Description: string;
  Type: RoutineType;
  Days: DayType[];
  Times: TriggerTime[]
}

export enum RoutineType {
  All = 0,
  DailyInspection = 1,
  MonthlyInspection,
  YearlyInspection,
}

export enum RoutineStatus {
  All = 0,
  Ok = 1,
  Deviation = 2,
  Performed = 4,
  NotPerformed = 8,
  Timeout = 16,
}

export const Translate = {
  Inspections: {
    [RoutineType.DailyInspection]: 'Daily Inspection',
    [RoutineType.MonthlyInspection]: 'Monthly Inspection',
    [RoutineType.YearlyInspection]: 'Yearly Inspection'
  },
  RoutineStatus: {
    [RoutineStatus.All]: 'All',
    [RoutineStatus.Ok]: 'Ok',
    [RoutineStatus.Deviation]: 'Deviation',
    [RoutineStatus.Performed]: 'Performed',
    [RoutineStatus.NotPerformed]: 'Not Performed',
    [RoutineStatus.Timeout]: 'Timeout'
  }
};
const activeRoutines = [
  'DailyInspection'
];

export enum TriggerType {
  TimeTrigger
}
export const Days = {
  mon: 'Monday',
  tue: 'Tuesday',
  wen: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday'
};
export type DayType = 'Monday'| 'Tuesday'| 'Wednesday'| 'Thursday'| 'Friday'| 'Saturday'| 'Sunday';

export type TriggerTime = `${string}:${string}`
export { activeRoutines };
export const RoutinesOptionKey = '.ROUTINES';
