import { UserOptions } from 'Interfaces';
import { useMemo } from 'react';

export default function useAdminPermissions(isAdmin:boolean) {
  const options = useMemo(() => {
    return isAdmin ? ({
      'permissions.actions': {
        Value: '[0]'
      },
      'permissions.columnsb': {
        Value: '["All"]'
      },
      'permissions.columnsc': {
        Value: '["All"]'
      }
    } as UserOptions) : (
      {
        'permissions.actions': {
          Value: '[]'
        },
        'permissions.columnsb': {
          Value: '["All"]'
        },
        'permissions.columnsc': {
          Value: '["All"]'
        }
      } as UserOptions);
  }, [isAdmin]);
  return options;
}
