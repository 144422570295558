import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { MainMenu } from 'Components/Global';
import { ReactComponent as Logo } from 'Assets/logo_orange.svg';
import { ThemedCssProps } from 'Interfaces/ThemedCssProps';

const StyledNavBar = styled.div<ThemedCssProps>`
  width: 15.625rem;
  border-right: 1px solid ${({ theme }) => theme.appSecondaryBg};
  flex: 0 0 auto;
`;

const StyledLink = styled(Link)<ThemedCssProps>`
  display: flex;
  justify-content: center;
  height: ${({ theme }) => theme.headerHeight};
`;

const StyledLogo = styled(Logo)`
  &&& {
    width: 12.375rem;
    height: auto;
  }
`;

const NavBar = () => (
  <StyledNavBar>
    <StyledLink to='/'>
      <StyledLogo />
    </StyledLink>
    <MainMenu />
  </StyledNavBar>
);

export default NavBar;
