import React from 'react';
import styled from 'styled-components';
import { convertPascalCase } from 'Util/formatString';
import { activeRoutines, RoutineType } from '../../Interfaces';
interface ItemProps {
  $selected: boolean;
}
const StyledItem = styled.button<ItemProps>`
  &&& {
    box-sizing: border-box;
    border: 2px solid;
    border-color: ${({ theme }) => theme.buttonBorder};
    background: ${({ theme, $selected }) => $selected ? theme.buttonBorder : 'none'};
    border-radius: 25px;
    color: ${({ theme, $selected }) => $selected ? theme.buttonTextSecondary : theme.buttonText};
    padding: 0.5rem calc(3 * 0.5rem);
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.875rem;
    margin: 1em;

    &&&:disabled {
      cursor: not-allowed;
      color: ${({ theme }) => theme.inputInactiveText};
      border-color: ${({ theme }) => theme.inputInactiveBorder};
      background: ${({ theme }) => theme.inputInactiveBackground};
    }

    &:hover {
      background: ${({ theme }) => theme.buttonBorder};
      color: ${({ theme }) => theme.buttonTextSecondary};

      &&&:disabled {
        cursor: not-allowed;
        border-color: ${({ theme }) => theme.inputInactiveBorder};
        background: ${({ theme }) => theme.inputInactiveBackground};
        color: ${({ theme }) => theme.inputInactiveText};
      }
    }
  }
`;

const StyledList = styled.div`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-block-start: 0;
  padding-inline-start: 0;
`;

interface Props {
  onSelect: (routine: RoutineType) => void;
  selected?: RoutineType;
}
const RoutineTypeList:React.FC<Props> =
({ onSelect, selected }) => {
  const routines = Object.values(RoutineType)
    .filter(x => isNaN(Number(x)));

  const amount = routines.filter(routine => activeRoutines.includes(routine.toString()))?.length;
  const selectedIndex = amount > 1 ? selected : 0;
  const onClick = (event: React.MouseEvent<HTMLElement>, index:number) => {
    if (selectedIndex === index) {
      event.preventDefault();
      return;
    }
    onSelect(index + 1);
  };
  return (
    <StyledList>
      {routines.map((routine, index) => (
        <StyledItem type='button' $selected={index === selectedIndex} disabled={!activeRoutines.includes(routine.toString())} role='button' key={routine.toString()} onClick={(event) => onClick(event, index)}>
          {convertPascalCase(routine.toString())}
        </StyledItem>)
      )}
    </StyledList>
  );
};
export default RoutineTypeList;
