import { Button, HeaderRow, Label } from 'access_ctrl-ui';
import { ModalTypes } from 'Common/Enums';
import InfoList from 'Components/Global/InfoList';
import { useRoutineModal } from 'Features/Routines/Hooks/useRoutineModal';
import { DailyRoutine } from 'Features/Routines/Interfaces';
import { getTypes } from 'Features/Routines/routineSlice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useAppSelector } from 'Store/hooks';
import styled from 'styled-components';
import { getLocalTimeFromUtc } from 'Util/formatDate';
import { convertPascalCase } from 'Util/formatString';

const StyledRow = styled.div`
  display: flex;
  justify-content: stretch;
`;
const StyledContainer = styled.div`
  display: block;
  width: 100%;
  flex: 1;
  padding: ${({ theme }) => theme.sizes.md};
`;

const StyledButton = styled(Button)`
  width: auto;
  align-self: flex-end;
`;

const RoutinePane:React.FC<{routine?: DailyRoutine, loading: boolean, numberOfDevices: number}> = ({ routine, loading, numberOfDevices }) => {
  const { Name, Description, Days, Times, Type } = routine ?? {};

  const close = () => { setModalOpen(undefined); };
  const [modalOpen, setModalOpen] = useState<{ open: boolean, type: ModalTypes, edit: boolean, title: string, close:() => void } | undefined>();
  const editRoutine = () => setModalOpen({ open: true, type: ModalTypes.EDIT_ROUTINE_MODAL, edit: true, title: 'Edit Routine', close });
  const { types } = useAppSelector(state => state.routine);
  const modal = useRoutineModal(modalOpen);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!types) {
      dispatch(getTypes());
    }
  }, [dispatch, types]);
  return (
    loading ? <Loader />
      : (
        <>
          <HeaderRow>
            {Name !== undefined && <h2>{Name}</h2>}
            <StyledButton onClick={editRoutine}>Edit routine</StyledButton>
          </HeaderRow>
          <StyledRow>
            <StyledContainer>
              <Label name='Name' label='Name' />
              <p>{Name}</p>
              <Label name='Description' label='Description' />
              <p>{Description}</p>
              <InfoList label='Days' data={Days ?? []} />
            </StyledContainer>
            <StyledContainer>
              <Label name='Routine type' label='Routine type' />
              <p>{convertPascalCase((Type && types?.[Type])?.toString()) ?? 'Unknown'}</p>
              <Label name='Devices' label='Number of devices' />
              <p>{numberOfDevices}</p>
              <InfoList label='Times' data={Times?.map(x => getLocalTimeFromUtc(x)) ?? []} />
            </StyledContainer>
          </StyledRow>
          {modal}
        </>)
  );
};
export default RoutinePane;
