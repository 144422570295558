import { Layout } from 'access_ctrl-ui';
import React, { useEffect, useState } from 'react';
import PostKey from './PostKey';
import { useAppSelector } from 'Store/hooks';
import { useDispatch } from 'react-redux';
import { getLicense, getReservedUsers, setCurrentPage } from 'Store/Actions/appActions';
import InfoList from 'Components/Global/InfoList';
import { getUserCount } from 'Store/Actions/userActions';
import { getDeviceCount } from 'Store/Actions/deviceActions';
import styled from 'styled-components';

const Text = styled.span`
  font-weight: bold;
`;

const StyledWrapper = styled(Layout.Wrapper)`
  margin-left: ${({ theme }) => theme.sizes.md};
  margin-right: ${({ theme }) => theme.sizes.md};

  h5 {
    font-size: 1.3rem;
  }

  p {
    font-size: 1.1rem;
  }

  .please-contact {
    margin-top: ${({ theme }) => theme.sizes.lg};
  }
`;

const LicensPage: React.FC = () => {
  const [disabledfeatures, setDisabledfeatures] = useState(['']);
  const [activatedfeatures, setActivatedfeatures] = useState(['']);

  const { license, reservedUsers } = useAppSelector(state => state.app);
  const { Users, Devices } = license || {};
  const { count: deviceCount } = useAppSelector(state => state.device);
  const { count: userCount } = useAppSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentPage(dispatch)({ name: 'License' });
  }, [dispatch]);

  useEffect(() => {
    if (!license) {
      getLicense(dispatch)();
    }
  }, [license, dispatch]);

  useEffect(() => {
    if (license) {
      const Disabled: string[] = [];
      const Activated: string[] = [];
      for (const [key, value] of Object.entries(license.Features)) {
        if (value.Enabled === true) {
          Activated.push(key);
        } else {
          Disabled.push(key);
        }
        setDisabledfeatures(Disabled);
        setActivatedfeatures(Activated);
      }
    }
  }, [license]);
  useEffect(() => {
    getDeviceCount(dispatch)();
  }, [dispatch]);

  useEffect(() => {
    getUserCount(dispatch)();
  }, [dispatch]);
  useEffect(() => {
    if (!reservedUsers) {
      getReservedUsers(dispatch)();
    }
  }, [dispatch, reservedUsers]);
  return (
    <StyledWrapper>
      <Layout.Section minHeight='15rem'>
        <PostKey />
        <p className='please-contact' />
        {license ? (
          <>
            <div>
              <p> Number of Users: {(userCount - reservedUsers ?? 'unknown')}  (<Text>{Users}</Text>)</p><p>Number of Devices: {deviceCount ?? 'unknown'} (<Text>{Devices}</Text>)</p>
            </div>
            <h5>Activated features</h5>
            <div>
              <InfoList useAccentColor data={activatedfeatures && Object.values(activatedfeatures).map(x => x.toString())} label='' />
            </div>
            <h5>Disabled features</h5>
            <InfoList data={disabledfeatures && Object.values(disabledfeatures).map(x => x.toString())} label={disabledfeatures.length === 0 ? 'None' : ''} />
          </>
        ) : <p className='please-contact'>Please contact your Åkerströms sales representative if you want to upgrade your license.</p>}

      </Layout.Section>
    </StyledWrapper>);
};
export default LicensPage;
