import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { getAuditLog } from 'Store/Actions/auditLogActions';
import { loadingMessages } from '../../../Common/messages';
import { logAuthorizationTypes, logEventTypeLabels, logEventTypes, logOrderByColumn } from '../../../Common/Constant';
import { getDeviceNameLink } from 'Util/auditLogHelpers';
import { formatDate } from 'Util/formatDate';

import { HeaderRow, InfoPopup, Table, TableRowType } from 'access_ctrl-ui';
import { AuditLogItem, AuditLogResponse, Filter, User } from 'Interfaces';
import { RootState } from 'Store/Reducers';

const _defaultFilter:Filter = {
  authorizationResult: +(logAuthorizationTypes.NOT_SUCCESSFUL | logAuthorizationTypes.SUCCESSFUL),
  events: [logEventTypes.ALL],
  startIndex: 0,
  stopIndex: 6,
  orderBy: logOrderByColumn.TimestampDesc
};

const tableColumns = [
  { name: 'Device', key: 'deviceName' },
  { name: 'Type', key: 'type' },
  { name: 'Timestamp', key: 'timestamp' }
];

interface Props {
  viewAuditLogs: boolean;
  currentUser: User;
  getAuditLog: (filter: Filter) => Promise<AuditLogResponse>;
  fetchingLogs:boolean;
}
const AuditLogPane:React.FC<Props> = ({ viewAuditLogs, currentUser, getAuditLog, fetchingLogs }) => {
  const unmounted = useRef(false);
  const [logData, setLogData] = useState<AuditLogItem[]>();
  const [tableData, setTableData] = useState<TableRowType[]>([] as TableRowType[]);
  const [filter, setFilter] = useState<Filter>(_defaultFilter);

  useEffect(() => {
    if (currentUser) {
      setFilter(currentFilter => ({ ...currentFilter, userId: currentUser.Id }));
    }
    return () => { unmounted.current = true; };
  }, [currentUser]);

  useEffect(() => {
    if (filter.userIds && viewAuditLogs) {
      getAuditLog(filter).then(data => {
        if (data && data.Items && !unmounted.current) {
          setLogData(data.Items);
        }
      });
    }
  }, [filter, getAuditLog, viewAuditLogs]);

  useEffect(() => {
    if (logData) {
      const _tdata = logData.map((item, index) => ({
        key: index,
        successful: item.Successful,
        data: {
          deviceName: getDeviceNameLink(item),
          type: logEventTypeLabels[item.Event].label,
          timestamp: formatDate(item.Timestamp)
        }
      }));
      setTableData(_tdata);
    }
  }, [logData]);

  return (
    <>
      <HeaderRow>
        <h2>Audit Log</h2>
        {
          viewAuditLogs &&
            <>
              <InfoPopup descriptionText='Latest access for this user' />
            </>
        }
      </HeaderRow>
      <Table
        rowsPerPage={5}
        emptyText={viewAuditLogs ? 'No audit logs for this user' : 'You don\'t have permission to view Audit Logs'}
        tableDataColumns={tableColumns}
        tableDataRows={viewAuditLogs ? tableData : []}
        setQueryString={false}
        loadingMessage={loadingMessages.LOADING_AUDIT_LOGS}
        loading={fetchingLogs}
      />
    </>
  );
};
export default connect(({ auditLog }:RootState) => ({
  fetchingLogs: auditLog.fetchingLogs
}), dispatch => (
  {
    getAuditLog: getAuditLog(dispatch)
  }
))(AuditLogPane);
