import { Label } from 'access_ctrl-ui';
import React from 'react';
import { IconProps } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ShortcutsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const ShortcutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  p {
    padding-top: ${({ theme }) => theme.sizes.sm};
  }

  .link-wrapper {
    margin-right: ${({ theme }) => theme.sizes.md};
    margin-bottom: ${({ theme }) => theme.sizes.md};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: initial;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.inputInactiveBackground};
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  height: same-as-width;
  width: 7rem;
  aspect-ratio: 1;
  padding: ${({ theme }) => theme.sizes.md};
  text-align: center;

  :hover {
    background-color: ${({ theme }) => theme.colorPrimary};
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colorPrimary} !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  :hover {
    color: ${({ theme }) => theme.textColor} !important;
  }
`;

export interface ShortcutsInterface {
  feature: string;
  link: string;
  text: string;
  icon: React.FC<IconProps>;
}
interface shortcutProps {
  shortcuts: ShortcutsInterface[];
  label?: string;
  className?: string;
}
const ShortcutList: React.FC<shortcutProps> = ({ shortcuts, label, className }) => {
  return (
    <ShortcutsContainer className={className}>
      {label && <Label name={label} label={label} />}
      <ShortcutWrapper>
        {
          shortcuts.map(shortcut => {
            return (
              <div className='link-wrapper' key={shortcut.link + shortcut.text} data-tooltip={`See all ${shortcut.text}`}>
                <StyledLink to={`${shortcut.link}`}>
                  <IconWrapper>
                    <shortcut.icon />
                    <p>{shortcut.text}</p>
                  </IconWrapper>
                </StyledLink>
              </div>
            );
          })
        }
      </ShortcutWrapper>
    </ShortcutsContainer>
  );
};

export default ShortcutList;
