import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

const useCheckInput = (word: string, milliSeconds?: number) => {
  const [inputText, setInputText] = useState('');
  const [inputStart, setInputStart] = useState<DateTime |null>(null);
  const [inputEvent, setInputEvent] = useState<KeyboardEvent>();
  const [isInputValue, setIsInputValue] = useState<boolean>(false);

  const checkInput = useCallback(() => {
    if (inputEvent) {
      if (!inputStart) {
        setInputStart(DateTime.fromMillis(inputEvent.timeStamp));
      } else {
        const diff = DateTime.fromMillis(inputEvent.timeStamp).diff(inputStart).milliseconds;
        if (diff >= (milliSeconds || 1500)) {
          setInputText('');
          setInputStart(DateTime.fromMillis(inputEvent.timeStamp));
        } else {
          setInputText((inputText) => (inputText + inputEvent.key));
        }
      }
    }
  }, [inputEvent, inputStart, milliSeconds]);

  const keyDownHandler = (e:KeyboardEvent) => {
    setInputEvent(e);
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      // Clean up on unmount
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    checkInput();
  }, [inputEvent, checkInput]);

  useEffect(() => {
    if (inputText && inputText === word) {
      setIsInputValue(prevValue => !prevValue);
      setInputText('');
      setInputStart(null);
    }
  }, [inputText, word]);

  return isInputValue;
};

export default useCheckInput;
