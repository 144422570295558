import { TableRowType } from 'access_ctrl-ui';
import _ from 'lodash';
import { ChartData, OperationLogItem } from 'Interfaces';

import { convertDate, formatDate } from 'Util/formatDate';
import { OperationLogHistoryActions } from 'Interfaces/OperationLogHistoryActions';

const PLC_REG_178_NAME = process.env.REACT_APP_PLC_REG_178_NAME || 'RX.PLC_REG_178';
const PLC_REG_179_NAME = process.env.REACT_APP_PLC_REG_179_NAME || 'RX.PLC_REG_179';
interface operationsStatus {
  [key:string]: string;
}
const operationStatusName:operationsStatus = {
  'LCU.PING': 'Cloud Access',
  'RX.VALID_SERIAL_DATA_RECEIVED': 'RX OK',
  'RX.SERIAL_DATA_RECEIVED': 'RX Data',
  'LCU.LAST_AUTHORIZED_IDENTITY_TOKEN': 'Last Valid Login ID',
  'RX.TX_ACTIVE': 'TX Active',
  'RX.HK_ON': 'MC Active',
  'LCU.LAST_READ_IDENTITY_TOKEN': 'Last Read ID',
  'LCU.SERVICE_TAG': 'LCU Service Tag',
  'TX.SIGNAL_QUALITY': 'Signal Quality (dBm)',
  'RX.PLC_REG_178': PLC_REG_178_NAME,
  'RX.PLC_REG_179': PLC_REG_179_NAME
};

export const keyToString = (key:string):string => {
  const settingsKey = key.substring(key.lastIndexOf('.') + 1).replace(/_/g, ' ').toLowerCase();
  return settingsKey.charAt(0).toUpperCase() + settingsKey.slice(1);
};

export const operationStatusNameFromKeyName = (operationStatusKey:string|null):string => {
  if (operationStatusKey) {
    return operationStatusName[operationStatusKey] || keyToString(operationStatusKey);
  }
  return '';
};

export const convertOperationStatusToChartData = (data: OperationLogItem[]):ChartData[] => {
  const convertValue = (value: string) => {
    if (Number.isInteger(parseInt(value))) {
      return parseInt(value);
    } else if (value.toLowerCase() === 'true') {
      return 1;
    } else if (value.toLowerCase() === 'false') {
      return 0;
    } else if (value === '') {
      return 1;
    }
    // Return 0 by default, since 0 is falsy and undefined which was previously return is falsy
    return 0;
  };

  if (data) {
    return data.map((history) => (
      {
        x: history.Timestamp,
        y: convertValue(history.Value)
      }
    ));
  }

  return [] as ChartData[];
};

export const convertOperationStatusToTableData = (data: OperationLogItem[], key: string | null):TableRowType[] => {
  let prevDataValue: string;
  let prevDataStartDate: string;
  let lastDate: string;
  let count = 0;
  const _tData = [] as TableRowType[];

  if (data) {
    const sortedData = _.orderBy(data, 'Timestamp');
    sortedData.forEach((history, idx, arr) => {
      // Is this a new value? Store variables
      if (!prevDataValue) {
        prevDataStartDate = history.Timestamp;
        prevDataValue = history.Value;
        count++;
      } else if (prevDataValue === history.Value) {
        // If it's the same value add to count
        count++;
        lastDate = history.Timestamp;
      }

      // If the value is different add it to the table
      if (prevDataValue !== history.Value) {
        let date = formatDate(prevDataStartDate);

        if (count > 1) {
          date += ' - ' + formatDate(lastDate);
        }

        _tData.push({
          key: idx,
          sortData: {
            date: convertDate(prevDataStartDate).toString()
          },
          data: {
            keyname: operationStatusNameFromKeyName(key),
            date: date,
            value: prevDataValue,
            count: count
          }
        });

        // Reset for next value
        prevDataValue = history.Value;
        prevDataStartDate = history.Timestamp;
        lastDate = history.Timestamp;
        count = 1;
      }

      // If its the last element add it to the table
      if (Object.is(arr.length - 1, idx)) {
        let date = formatDate(prevDataStartDate);

        if (count > 1) {
          date += ' - ' + formatDate(lastDate);
        }

        _tData.push({
          key: ++idx,
          sortData: {
            date: convertDate(prevDataStartDate).toString()
          },
          data: {
            keyname: operationStatusNameFromKeyName(key),
            date: date,
            value: prevDataValue,
            count: count
          }
        });
      }
    });
  }

  return _tData;
};

export const parseLOG = (LOG0:number, LOG1:number, LOG2:number) => {
  const state:OperationLogHistoryActions = {
    Mov1Dir1: false,
    Mov1Dir2: false,
    Mov2Dir1: false,
    Mov2Dir2: false,
    Mov3Dir1: false,
    Mov3Dir2: false,
    Mov4Dir1: false,
    Mov4Dir2: false,

    Mov5Dir1: false,
    Mov5Dir2: false,
    Mov6Dir1: false,
    Mov6Dir2: false,
    Mov7Dir1: false,
    Mov7Dir2: false,
    Mov8Dir1: false,
    Mov8Dir2: false,

    X34Micro: false,
    X35Signal: false,
    X36: false,
    X37: false,
    MC: false,
    McOffReason: '',
    text: [] as string[]
  };

  const meaning = [] as string[];

  /*
    LOGG0
    Bit 0   ;X6   riktn1 Mov1
    Bit 1         riktn2
    Bit 2   ;X14  riktn1 Mov2
    Bit 3         riktn2
    Bit 4   ;X22  riktn1 Mov3
    Bit 5         riktn2
    Bit 6   ;X30  riktn1 Mov4
    Bit 7         riktn2
  */

  if (LOG0 & 0x01) {
    state.Mov1Dir1 = true;
    meaning.push('Mov1 Direction 1');
  }

  if (LOG0 & 0x02) {
    state.Mov1Dir2 = true;
    meaning.push('Mov1 Direction 2');
  }

  if (LOG0 & 0x04) {
    state.Mov2Dir1 = true;
    meaning.push('Mov2 Direction 1');
  }

  if (LOG0 & 0x08) {
    state.Mov2Dir2 = true;
    meaning.push('Mov2 Direction 2');
  }

  if (LOG0 & 0x10) {
    state.Mov3Dir1 = true;
    meaning.push('Mov3 Direction 1');
  }

  if (LOG0 & 0x20) {
    state.Mov3Dir2 = true;
    meaning.push('Mov3 Direction 2');
  }

  if (LOG0 & 0x40) {
    state.Mov4Dir1 = true;
    meaning.push('Mov4 Direction 1');
  }

  if (LOG0 & 0x80) {
    state.Mov4Dir2 = true;
    meaning.push('Mov4 Direction 2');
  }

  /*
  LOGG1
  Bit 0   ;X62  riktn1 Mov5
  Bit 1         riktn2
  Bit 2   ;X70  riktn1 Mov6
  Bit 3         riktn2
  Bit 4   ;X94  riktn1 Mov7
  Bit 5         riktn2
  Bit 6   ;X102 riktn1 Mov8
  Bit 7         riktn2
  */

  if (LOG1 & 0x01) {
    state.Mov5Dir1 = true;
    meaning.push('Mov5 Direction 1');
  }

  if (LOG1 & 0x02) {
    state.Mov5Dir2 = true;
    meaning.push('Mov5 Direction 2');
  }

  if (LOG1 & 0x04) {
    state.Mov6Dir1 = true;
    meaning.push('Mov6 Direction 1');
  }

  if (LOG1 & 0x08) {
    state.Mov6Dir2 = true;
    meaning.push('Mov6 Direction 2');
  }

  if (LOG1 & 0x10) {
    state.Mov7Dir1 = true;
    meaning.push('Mov7 Direction 1');
  }

  if (LOG1 & 0x20) {
    state.Mov7Dir2 = true;
    meaning.push('Mov7 Direction 2');
  }

  if (LOG1 & 0x40) {
    state.Mov8Dir1 = true;
    meaning.push('Mov8 Direction 1');
  }

  if (LOG1 & 0x80) {
    state.Mov8Dir2 = true;
    meaning.push('Mov8 Direction 2');
  }

  /*
    LOGG2
    Bit 0   ;X34 funktion micro
    Bit 1   ;X35 funktion signal
    Bit 2   ;X36
    Bit 3   ;X37
    Bit 4;  MC on/off
    BIT 5;  =1 -> MCoff pga nödstopp
    Bit 6;  =1 -> MCoff pga timeout
    Bit 7;  =1 -> MCoff pga låg sp i ack
  */

  if (LOG2 & 0x01) {
    state.X34Micro = true;
    meaning.push('Function micro');
  }

  if (LOG2 & 0x02) {
    state.X35Signal = true;
    meaning.push('Function signal');
  }

  if (LOG2 & 0x04) {
    state.X36 = true;
    meaning.push('X36');
  }

  if (LOG2 & 0x08) {
    state.X37 = true;
    meaning.push('X37');
  }

  if (LOG2 & 0x10) {
    state.MC = true;
    meaning.push('MC on');
  }

  if (LOG2 & 0x20) {
    state.McOffReason = 'MC off pga nödstopp';
  }

  if (LOG2 & 0x40) {
    state.McOffReason = 'MC off pga timeout';
  }

  if (LOG2 & 0x80) {
    state.McOffReason = 'MC off pga låg sp i ack';
  }

  if (state.McOffReason) {
    meaning.push(state.McOffReason);
  }

  if (meaning.length > 0) {
    state.text = meaning;
  } else {
    state.text = ['Nothing'];
  }

  return state;
};
