/**
  Actiontypes dispatched from auditLogActions
*/
export enum AuditLogActionType {
  FILTER_AUDIT_LOG_START = 'FILTER_AUDIT_LOG_START',
  GOT_FILTERED_AUDIT_LOG = 'GOT_FILTERED_AUDIT_LOG',
  FAILED_GETTING_FILTERED_AUDIT_LOG = 'FAILED_GETTING_FILTERED_AUDIT_LOG',
  EXCEL_AUDIT_LOG_FILTER_START = 'EXCEL_AUDIT_LOG_FILTER_START',
  GOT_EXCEL_AUDIT_LOG = 'GOT_EXCEL_AUDIT_LOG',
  ERROR_FETCHING_EXCEL_AUDIT_LOG = 'ERROR_FETCHING_EXCEL_AUDIT_LOG'
}
