import _ from 'lodash';
import { ThemeTypes } from 'access_ctrl-ui';
import { Page, User } from 'Interfaces';
import { session } from 'Common/session';
import { AppActions } from 'Store/ActionTypes';
import { AppActionType } from 'Store/ActionEnums';
import { AppState } from 'Store/Interfaces/States/AppState';

const defaultState: AppState = {
  accessToken: '',
  currentPage: {} as Page,
  fetchingLoggedInUser: false,
  fetchingRFIDTag: false,
  lastReadRFIDTags: [],
  loggedIn: false,
  loggedInUser: {} as User,
  loggingIn: false,
  messages: [],
  selectedRFIDTag: '',
  theme: ThemeTypes.DARK,
  license: undefined,
  documents: undefined,
  reservedUsers: 1

};

function app(state = defaultState, action: AppActions): AppState {
  switch (action.type) {
    case AppActionType.START_LOGIN:
      return {
        ...state,
        loggingIn: true
      };

    case AppActionType.ADMIN_SUCCESSFUL_LOGIN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        loggedIn: true,
        loggingIn: false
      };
    case AppActionType.GET_LICENSE:
      return {
        ...state,
        license: action.payload
      };

    case AppActionType.ADMIN_LOGIN_FAIL:
    {
      session.clear();
      return {
        ...state,
        loggingIn: false
      };
    }
    case AppActionType.APP_INIT:
      return {
        ...state
      };

    case AppActionType.ADMIN_IS_LOGGED_IN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        loggedIn: true
      };

    case AppActionType.ADMIN_LOGOUT:
      session.logout();
      return {
        ...state,
        accessToken: '',
        loggedIn: false
      };

    case AppActionType.ADD_FEEDBACK_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };

    case AppActionType.RFID_READER_FETCHING_TAG:
      return {
        ...state,
        fetchingRFIDTag: true
      };

    case AppActionType.RFID_READER_READ_TAG:
      return {
        ...state,
        lastReadRFIDTags: _.uniqBy([...(state.lastReadRFIDTags ? state.lastReadRFIDTags : []), { tag: action.payload.tag, tagType: action.payload.tagType }], 'tag'),
        fetchingRFIDTag: false
      };

    case AppActionType.RFID_READER_READ_EXISTING_TAG: {
      const { tag, tagType, user } = action.payload;
      return {
        ...state,
        lastReadRFIDTags: _.uniqBy([...(state.lastReadRFIDTags ? state.lastReadRFIDTags : []), { tag, tagType }], 'tag'),
        fetchingRFIDTag: false,
        existingUser: user
      };
    }

    case AppActionType.RFID_TAG_CLEAR:
      return {
        ...state,
        lastReadRFIDTags: [],
        fetchingRFIDTag: false,
        selectedRFIDTag: '',
        mfaLogin: undefined
      };

    case AppActionType.RFID_READER_FAILED_FETCHING_TAG:
      return {
        ...state,
        fetchingRFIDTag: false,
        lastReadRFIDTags: [],
        selectedRFIDTag: ''
      };

    case AppActionType.RFID_AUTO_SELECT_TAG:
      if (state.lastReadRFIDTags.length > 0) {
        return {
          ...state,
          selectedRFIDTag: action.payload
        };
      } else {
        return {
          ...state,
          selectedRFIDTag: ''
        };
      }

    case AppActionType.RFID_SELECTED_TAG:
      return {
        ...state,
        selectedRFIDTag: action.payload
      };

    case AppActionType.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      };

    case AppActionType.FETCH_LOGGED_IN_USER_INFO:
      return {
        ...state,
        fetchingLoggedInUser: true
      };

    case AppActionType.GOT_LOGGED_IN_USER_INFO:
      return {
        ...state,
        loggedInUser: action.payload,
        fetchingLoggedInUser: false
      };

    case AppActionType.FAILED_GETTING_LOGGED_IN_USER_INFO: {
      session.clear();
      return {
        ...state,
        fetchingLoggedInUser: false,
        accessToken: ''
      };
    }

    case AppActionType.THEME_CHANGED:
      return {
        ...state,
        theme: action.payload
      };
    case AppActionType.GET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload
      };
    case AppActionType.GET_RESERVED_USERS:
      return {
        ...state,
        reservedUsers: action.payload
      };
    case AppActionType.MFA_LOGIN:
      return {
        ...state,
        mfaLogin: action.payload
      };
    case AppActionType.RESET_MFA_LOGIN:
      return {
        ...state,
        mfaLogin: undefined
      };
    case AppActionType.SET_EXISTING_USER:
      return {
        ...state,
        existingUser: action.payload
      };
    case AppActionType.RESET_EXISTING_USER:
      return {
        ...state,
        existingUser: undefined
      };
    case AppActionType.RESET:
      session.clear();
      return defaultState;
    default:
      return state;
  }
}

export default app;
