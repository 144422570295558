import React, { useCallback, useState, useEffect } from 'react';
import 'chartjs-adapter-luxon';
import { BubbleDataPoint, ChartConfiguration, ChartDataset, LineControllerDatasetOptions, ScatterDataPoint } from 'chart.js';
import Chart from 'chart.js/auto';
import { themes } from '../../Common/theme';
import { DateTime } from 'luxon';
import { Modal, Layout, ThemeTypes } from 'access_ctrl-ui';
import { ChartData } from 'Interfaces';
const UNIX_MIN_DATE = DateTime.fromISO('1970-01-01T00:00:00.000000Z');
const plugin = {
  id: 'afterdraw',
  afterDraw: function(chart:Chart) {
    if (chart.data.datasets[0].data.every((item:number | ScatterDataPoint | BubbleDataPoint | null) => item === 0)) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;

      chart.clear();
      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('No data to display', width / 2, height / 2);
      ctx.restore();
    }
  }
};

const chartConfig:ChartConfiguration = {
  type: 'line',
  data: {
    datasets: [{
      stepped: 'before',
      showLine: true,
      fill: false
    } as ChartDataset]
  },
  options: {
    scales: {
      x: {
        display: true,
        type: 'timeseries',

        time: {
          unit: 'second',
          round: 'second',
          stepSize: 1
        },
        ticks: {
          source: 'data',
          maxRotation: 100
        },
        position: 'bottom'
      }
    }
  },
  plugins: [plugin]
};

interface Props {
  open: boolean;
  onClose: () => void;
  chartData:ChartData[];
  theme: ThemeTypes;
  spanGaps:number|boolean;
  datasetLabel: string;
  modalHeader: string;
}
const ChartModal:React.FC<Props> = ({ open, onClose, chartData, theme, spanGaps, datasetLabel, modalHeader = 'Chart' }) => {
  const [chartInstance, setChartInstance] = useState<Chart>();
  const chartContainer = useCallback(node => {
    if (node) {
      const newChartInstance = new Chart(node, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, []);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const requestClose = () => {
    setShowConfirmModal(true);
  };

  const onCloseConfirm = () => {
    setShowConfirmModal(false);
    onClose();
  };

  useEffect(() => {
    const updateDataset = (datasetIndex:number, newData:ChartData[]) => {
      if (chartInstance && chartData) {
        chartInstance.data.datasets[datasetIndex].data = newData.map(data => {
          const date = DateTime.fromISO(data.x);
          if (date && date > UNIX_MIN_DATE) {
            return { x: date.toMillis(), y: data.y } as ScatterDataPoint;
          }
          return null;
        });
        chartInstance.update();
      }
    };

    updateDataset(0, chartData);
  }, [chartData, chartInstance]);

  useEffect(() => {
    if (chartInstance) {
      (chartInstance.data.datasets[0] as LineControllerDatasetOptions).spanGaps = spanGaps;
      chartInstance.update();
    }
  }, [spanGaps, chartInstance]);

  useEffect(() => {
    if (!chartInstance || !chartInstance.options || !theme) {
      return;
    }
    if (chartInstance && chartInstance.options && chartInstance.options.scales && theme) {
      const xAxis = chartInstance.options.scales.x;
      const yAxis = chartInstance.options.scales.y;
      chartInstance.data.datasets[0].borderColor = themes[theme].colorPrimary;
      if (xAxis && xAxis.grid?.color) {
        xAxis.grid.color = themes[theme].textColorSecondary;
      }
      if (xAxis?.ticks) {
        xAxis.ticks.textStrokeColor = themes[theme].textColorSecondary;
      }
      if (yAxis && yAxis.grid?.color) {
        yAxis.grid.color = themes[theme].textColorSecondary;
      }
      if (yAxis?.ticks) {
        yAxis.ticks.textStrokeColor = themes[theme].textColorSecondary;
      }
      chartInstance.update();
    }
  }, [theme, chartInstance]);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.data.datasets[0].label = datasetLabel;
      chartInstance.update();
    }
  }, [datasetLabel, chartInstance]);

  return (
    <Modal
      open={open}
      onClose={requestClose}
      confirmClose={onCloseConfirm}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      closeIcon
      size='large'
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>{modalHeader}</Layout.Modal.Header>
        <canvas ref={chartContainer} />
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default ChartModal;
