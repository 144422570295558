/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Loader, Divider, CheckboxProps } from 'semantic-ui-react';

import {
  Form,
  Layout,
  Modal,
  Accordion,
  FormInput,
  Button,
  Datepicker,
  Checkbox,
  Label
} from 'access_ctrl-ui';
import { isValidDate } from 'Util/isValidDate';
import { JTokenType } from 'Common/Enums';
import { ChangedDates, ChangedInputs, DefaultDeviceOptions, ModalOptions } from 'Interfaces';
import { ModalTokenType } from 'types';
import useSortedOptions from 'Hooks/useSortedOptions';
import useCheckInput from 'Hooks/useCheckInput';

const FieldContainer = styled.div`
  & label {
    margin-top: 0.313rem;
  }
`;

const DefaultValueLabelContainer = styled.div`
  &&& label {
    text-transform: none;
    font-size: 0.73rem;
    color: ${({ theme }) => theme.inputPlaceholderText};
    margin-top: 0.413rem;
  }
`;

const SubmenuAccordion = styled.div`
  && h2 {
    font-size: 15px !important;
    padding: 0;
  }
`;

// Check if an object contains other objects
const containsObject = (object:any) => {
  let containsObject = false;

  for (const value of Object.values(object)) {
    if (Array.isArray(value) || value === null || value === undefined) {
      continue;
    }

    if (typeof (value) === 'object') {
      containsObject = true;
      break;
    }
  }
  return containsObject;
};

// Puts settingsGroup and settingsKey one layer deep under appsettings, so that they can be
// rendered as submenus
const handleCaseAppsettings = (currentOpts:any, keyNames:any, value:any) => {
  const updatedOpts = { ...currentOpts };

  const settingsGroup = keyNames[1];
  const settingsKey = keyNames[2];

  if (!settingsGroup) {
    return currentOpts;
  }
  if (!updatedOpts.appsettings[settingsGroup]) {
    updatedOpts.appsettings[settingsGroup] = {};
  }

  updatedOpts.appsettings[settingsGroup][settingsKey] = value;

  return updatedOpts;
};

interface Props {
  options?: ModalOptions | null;
  loadedOptions: boolean;
  onSubmit: (changedInputs:ChangedInputs, changedDates:ChangedDates) => void;
  onClose: any;
  open: boolean;
  loadingMessage: string;
  saving: boolean;
  defaultOptions: DefaultDeviceOptions;
  header: string;
}

const EditOptionsModal:React.FC<Props> = ({
  open,
  defaultOptions,
  options,
  onSubmit,
  loadedOptions,
  loadingMessage,
  header,
  onClose,
  saving
}) => {
  const [optionsList, setOptionsList] = useState({});
  const [changedInputs, setChangedInputs] = useState<ChangedInputs>({} as ChangedInputs);
  const [changedDates, setChangedDates] = useState<ChangedDates>({});
  const showAllSettings = useCheckInput('show', 500);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const sortedOptions = useSortedOptions(defaultOptions);
  const requestClose = () => {
    setShowConfirmModal(true);
  };

  const onCloseConfirm = () => {
    setShowConfirmModal(false);
    setChangedDates({});
    setChangedInputs({});
    onClose();
  };

  useEffect(() => {
    setChangedInputs({});
    setChangedDates({});
  }, [open]);

  useEffect(() => {
    let opts: any = {
      appsettings: {}
    };
    if (sortedOptions) {
      for (const [key, value] of sortedOptions) {
        if (value === null) {
          continue;
        }
        if (value.TokenType === JTokenType.Object) {
          continue;
        }

        const keyNames = key.split('.');
        const settingsGroup = keyNames[0];
        const settingsKey = keyNames[1];

        if (keyNames[0] === 'appsettings' && keyNames.length === 3) {
          opts = handleCaseAppsettings(opts, keyNames, value);
          continue;
        }

        if (!settingsGroup) {
          continue;
        }

        if (!opts[settingsGroup]) {
          opts[settingsGroup] = {};
        }

        opts[settingsGroup][settingsKey] = value;
      }

      setOptionsList(opts);
    }
  }, [sortedOptions]);

  const handleCheckboxChange = (settingsname: string, tokentype:ModalTokenType, value: CheckboxProps) => {
    setChangedInputs({ ...changedInputs, [settingsname]: { value: value.checked ? value.checked : false, type: tokentype } });
  };

  const handleInputChange = (settingsname: string, tokentype:ModalTokenType, value: string) => {
    setChangedInputs({ ...changedInputs, [settingsname]: { value: value, type: tokentype } });
  };

  const handleDatepickerChange = (settingsname: string, tokentype: ModalTokenType, value: Date | null) => {
    if (value) {
      setChangedDates({ ...changedDates, [settingsname]: { value: value, type: tokentype } });
    }
  };

  const handleNumberInputChange = (
    settingsname: string,
    tokenType: ModalTokenType,
    value: number
  ) => {
    if (isNaN(value)) {
      return;
    }
    setChangedInputs({ ...changedInputs, [settingsname]: { value: value, type: tokenType } });
  };

  const handleSubmit = async () => {
    await onSubmit(changedInputs, changedDates);
    onCloseConfirm();
  };

  const keyToValue = (key: string) => {
    const newKey = key.replace(/([a-z])([A-Z])/g, '$1 $2');
    return newKey.charAt(0).toUpperCase() + newKey.slice(1);
  };

  const hiddenLcuStrings: string[] = [
    'adminRFIDCard',
    'allowSetWiFi',
    'cloudUserSyncInterval',
    'csmPollingInterval',
    'deleteOfflineUsers',
    'deserializationError',
    'emReaderIP',
    'enableLdThread',
    'enableSerialCommunications',
    'logDataConnectionName',
    'logDataConnectionString',
    'logLines',
    'loginTimeoutTime',
    'loginTimeoutWarningTime',
    'maxOperationalLogTTL',
    'maxTaskTimeout',
    'mifareListenAddress',
    'modbusAddress',
    'modbusPort',
    'modbusRegister',
    'modbusStart',
    'operationalLogPostIntervalMs',
    'outputToLCUConsole',
    'paddingVersion',
    'periodicWarningPeriod',
    'receivedBytesThreshold',
    'resendTimedoutTasks',
    'restoreToVvIfNoBackupExists',
    'runStartupCommand',
    'semaphoreBaudrate',
    'semaphoreComPort',
    'signalQualityReg',
    'startupCommand',
    'usbReaderPort',
    'useCSM',
    'useEmReader',
    'useMifareReader',
    'useUSBReader',
    'useWifiScale',
    'wifiScaleBaudrate',
    'wifiScaleComPort',
    'wifiScaleIP',
    'enableLedThread'
  ];

  const renderField = (groupname: string, key:any, value: any) => {
    const settingsname = `${groupname}.${key}`;

    if (value.ReadOnly) {
      return (
        <FieldContainer key={`${groupname}${key}`}>
          <Label label={`${keyToValue(key)}: ${value.Value}`} />
          <Divider />
        </FieldContainer>
      );
    }

    if (value.TokenType === JTokenType.Date) {
      const tokenType = value.TokenType;
      let selectedDate = null;
      if (changedDates[settingsname] === undefined && value.Value) {
        selectedDate = new Date(value.Value);

        if (!isValidDate(selectedDate)) {
          selectedDate = null;
        }
      } else if (changedDates[settingsname]) {
        selectedDate = changedDates[settingsname].value;
      }

      return (
        <FieldContainer key={`${groupname}${key}`}>
          <Datepicker
            label={keyToValue(key)}
            onDateSelect={(_: React.SyntheticEvent<HTMLInputElement, Event> | undefined, value: Date | null) =>
              handleDatepickerChange(settingsname, tokenType, value)}
            selected={selectedDate}
          />
          {value.DefaultFrontendValue != null && (
            <DefaultValueLabelContainer>
              <Label label={`Default value: ${value.DefaultFrontendValue}`} />
            </DefaultValueLabelContainer>
          )}
          <Divider />
        </FieldContainer>
      );
    }

    if (value.TokenType === JTokenType.Boolean) {
      return (
        <FieldContainer key={`${groupname}${key}`}>
          <Label name={`${groupname}${key}`} label={keyToValue(key)} />
          <Checkbox
            defaultChecked={value.Value === 'True' || value.Value === 'true'}
            onChange={(_: React.FormEvent<HTMLInputElement>, data: CheckboxProps) =>
              handleCheckboxChange(settingsname, value.TokenType, data)}
            label={keyToValue(key)}
          />
          {value.DefaultFrontendValue != null && (
            <DefaultValueLabelContainer>
              <Label label={`Default value: ${value.DefaultFrontendValue}`} />
            </DefaultValueLabelContainer>
          )}
          <Divider />
        </FieldContainer>
      );
    }

    if (
      value.TokenType === JTokenType.Integer ||
      value.TokenType === JTokenType.Float
    ) {
      return (
        <FieldContainer key={`${groupname}${key}`}>
          <FormInput
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleNumberInputChange(
                settingsname,
                value.TokenType,
                Number(e.target.value))}
            key={`${groupname}${key}`}
            label={keyToValue(key)}
            name={`${groupname}${key}`}
            type='number'
            step='any'
            defaultValue={value.Value}
          />
          {value.DefaultFrontendValue != null && (
            <DefaultValueLabelContainer>
              <Label label={`Default value: ${value.DefaultFrontendValue}`} />
            </DefaultValueLabelContainer>
          )}
          <Divider />
        </FieldContainer>
      );
    }

    return (
      <FieldContainer key={`${groupname}${key}`}>
        <FormInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(settingsname, value.TokenType, e.target.value)}
          key={`${groupname}${key}`}
          label={keyToValue(key)}
          name={`${groupname}${key}`}
          defaultValue={value.Value}
        />
        {value.DefaultFrontendValue != null && (
          <DefaultValueLabelContainer>
            <Label label={`Default value: ${value.DefaultFrontendValue}`} />
          </DefaultValueLabelContainer>
        )}
        <Divider />
      </FieldContainer>
    );
  };

  const renderSubmenu = (groupname: any, accordionName:string, optgroup: any) => {
    return (
      <SubmenuAccordion>
        <Accordion title={accordionName} key={accordionName}>
          {
            Object.entries(optgroup).map(([key, value]) =>
              renderField(groupname, key, value)
            )
          }
        </Accordion>
      </SubmenuAccordion>
    );
  };
  const renderGroup = (groupname: any, optgroup: any) => {
    return (
      <Accordion title={groupname} key={groupname}>
        {
          Object.entries(optgroup).map(([key, value]) => {
            if (groupname === 'LcuSettings' && hiddenLcuStrings.includes(key) && !showAllSettings) return <></>;
            if (groupname === 'appsettings' && containsObject(value)) {
              return renderSubmenu(`${groupname}.${key}`, key, value);
            }

            return renderField(groupname, key, value);
          }
          )
        }
      </Accordion>
    );
  };

  const renderOptions = () => {
    if (_.isEmpty(optionsList)) {
      return <h1>No options for this user</h1>;
    }

    return Object.entries(optionsList).map(([key, value]) =>
      renderGroup(key, value)
    );
  };

  return (
    <Modal
      open={open}
      onClose={requestClose}
      confirmClose={onCloseConfirm}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      closeIcon
      size='small'
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>{header}</Layout.Modal.Header>
        <Layout.Modal.Content>
          <Form onSubmit={handleSubmit}>
            {!loadedOptions ? (
              <Loader inline active>
                {loadingMessage}
              </Loader>
            ) : (
              renderOptions()
            )}
            <Layout.Modal.ButtonContainer>
              <Button
                loading={saving}
                disabled={_.isEmpty(changedInputs) && _.isEmpty(changedDates)}
              >
                Save settings
              </Button>
            </Layout.Modal.ButtonContainer>
          </Form>
        </Layout.Modal.Content>
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default EditOptionsModal;
