import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'react-feather';

import { formatDate } from 'Util/formatDate';
import { parseLOG } from 'Util/operationStatusHelpers';

import { Button, HeaderRow, InfoPopup, Layout } from 'access_ctrl-ui';
import { OperationLogHistoryActions, OperationLogItem } from 'Interfaces';

const ButtonRow = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.sizes.md};
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: column;

  & > div {
    display: flex;
    justify-content: center;
    min-width: 9rem;

    & button {
      padding: 0.2rem;
      float: right;
      border: none;

      & > svg {
        padding-right: 0;
      }

      &:hover:not(.loading) {
        background: inherit;

        svg {
          padding-right: 0;
        }
      }
    }
  }

  & > span {
    margin-top: 5px;
  }
`;

const StateContainer = styled.div`
  padding: ${({ theme }) => theme.sizes.md};
`;

/*
C8 = FUNC
00 = LOGG0
00 = LOGG1
40 = LOGG2
0000 = PLC_REG_178
0A15 = PLC_REG_179
7D = CRC

  Vid LOGG:
  FUNC=200 to LCU. "DATA" är 7 bytes (sändes som 14 ACII-hex kodat)
    <LF>
    <FUNC>          ;=200, ASCIIhex 00-FF
    LOGG0         ;ASCIIhex_tecken
    LOGG1
    LOGG2
    PLC_REG1.hi   ;WORD hi byte (=R178)
    PLC_REG1.lo   ;WORD lo byte (=R178)
    PLC_REG2.hi   ;WORD hi byte (=R179)
    PLC_REG2.lo   ;WORD lo byte (=R179)
    CRC8
    <CR>

  Svar från LCU: Inget svar skall sändas.
  Loggen representerar följande:
  LOGG0
  Bit 0   ;X6   riktn1 Mov1
  Bit 1         riktn2
  Bit 2   ;X14  riktn1 Mov2
  Bit 3         riktn2
  Bit 4   ;X22  riktn1 Mov3
  Bit 5         riktn2
  Bit 6   ;X30  riktn1 Mov4
  Bit 7         riktn2

  LOGG1
  Bit 0   ;X62  riktn1 Mov5
  Bit 1         riktn2
  Bit 2   ;X70  riktn1 Mov6
  Bit 3         riktn2
  Bit 4   ;X94  riktn1 Mov7
  Bit 5         riktn2
  Bit 6   ;X102 riktn1 Mov8
  Bit 7         riktn2

  LOGG2
  Bit 0   ;X34 funktion micro
  Bit 1   ;X35 funktion signal
  Bit 2   ;X36
  Bit 3   ;X37
  Bit 4;  MC on/off
  BIT 5;  =1 -> MCoff pga nödstopp
  Bit 6;  =1 -> MCoff pga timeout
  Bit 7;  =1 -> MCoff pga låg sp i ack

  PLC_REG1 och PLC_REG2 är PLC register (WORD) där PLC-programmet bestämmer vad
  det ska innehålla.
 */
interface OperationHistory {
  Timestamp: string;
  Data:OperationLogHistoryActions;
}
interface Props {
  state: OperationLogItem[] | null;
  loading:boolean;
}
const StateHistory:React.FC<Props> = ({ state, loading }) => {
  const [stateHistory, setStateHistory] = useState<OperationHistory[]>([] as OperationHistory[]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    if (state) {
      let prevParsedLOG = {} as OperationLogHistoryActions;
      const _stateData = [] as OperationHistory[];

      state.forEach((s:OperationLogItem) => {
        if (s.Value) {
          const LOG0 = parseInt(s.Value.substring(0, 2), 16);
          const LOG1 = parseInt(s.Value.substring(2, 4), 16);
          const LOG2 = parseInt(s.Value.substring(4, 6), 16);

          const parsedLOG = parseLOG(LOG0, LOG1, LOG2);

          if (!_.isEqual(prevParsedLOG, parsedLOG)) {
            prevParsedLOG = parsedLOG;
            _stateData.push({
              Data: parsedLOG,
              Timestamp: s.Timestamp
            });
          }
        }
      });
      setStateHistory(_stateData);
      setActive(_stateData.length - 1);
    }
  }, [state]);

  const handleKeyDown = (e:KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      handleNextClick();
    } else if (e.key === 'ArrowLeft') {
      handlePreviousClick();
    }
  };

  const handlePreviousClick = () => {
    if (active > 0) {
      setActive(active - 1);
    }
  };

  const handleNextClick = () => {
    if (active < (stateHistory.length - 1)) {
      setActive(active + 1);
    }
  };

  return (
    <Layout.Wrapper>
      {(!loading && stateHistory.length > 0) &&
        <>
          <HeaderRow>
            <h2>{formatDate(stateHistory[active].Timestamp)}</h2>
            <InfoPopup descriptionText='The state at this time' />
          </HeaderRow>
          <StateContainer>
            <span>{stateHistory[active].Data.text.join(', ')}</span>
          </StateContainer>
          <ButtonRow>
            <ButtonContainer>
              <div>
                <Button onClick={handlePreviousClick} disabled={active === 0}><ChevronLeft /></Button>
              </div>
              {active > 0 &&
                <span>{formatDate(stateHistory[active - 1].Timestamp)}</span>}
            </ButtonContainer>
            <ButtonContainer>
              <div>
                <Button onClick={handleNextClick} disabled={active === (stateHistory.length - 1)}><ChevronRight /></Button>
              </div>
              {active < (stateHistory.length - 1) &&
                <span>{formatDate(stateHistory[active + 1].Timestamp)}</span>}
            </ButtonContainer>
          </ButtonRow>
        </>}
    </Layout.Wrapper>
  );
};

export default StateHistory;
