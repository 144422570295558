import { OptionsActionType } from 'Store/ActionEnums';
import { OptionsActions } from 'Store/ActionTypes';
import { OptionsState } from 'Store/Interfaces/States/OptionsState';

const defaultState: OptionsState = {
  lcuPositions: [],
  optionsForDevice: {},
  optionsForUser: {},
  fetchingLcuPositions: false,
  fetchingUserOptions: false,
  fetchingDeviceOptions: false,
  deletingDeviceOptions: false
};

const options = (state = defaultState, action: OptionsActions): OptionsState => {
  switch (action.type) {
    case OptionsActionType.FETCHING_DEVICE_OPTIONS:
      return {
        ...state,
        fetchingDeviceOptions: true
      };
    case OptionsActionType.GOT_DEVICE_OPTIONS:
      return {
        ...state,
        fetchingDeviceOptions: false,
        optionsForDevice: action.payload
      };
    case OptionsActionType.FAILED_GETTING_DEVICE_OPTIONS:
      return {
        ...state,
        fetchingDeviceOptions: false
      };
    case OptionsActionType.FETCHING_USER_OPTIONS:
      return {
        ...state,
        fetchingUserOptions: true
      };
    case OptionsActionType.GOT_USER_OPTIONS:
      return {
        ...state,
        fetchingUserOptions: false,
        optionsForUser: action.payload
      };
    case OptionsActionType.FAILED_GETTING_USER_OPTIONS:
      return {
        ...state,
        fetchingUserOptions: false
      };
    case OptionsActionType.FETCHING_LCU_POSITIONS:
      return {
        ...state,
        fetchingLcuPositions: true
      };
    case OptionsActionType.GOT_LCU_POSITIONS:
      return {
        ...state,
        fetchingLcuPositions: false,
        lcuPositions: action.payload
      };
    case OptionsActionType.FAILED_GETTING_LCU_POSITIONS:
      return {
        ...state,
        fetchingLcuPositions: false
      };
    case OptionsActionType.DELETING_DEVICE_OPTION:
      return {
        ...state,
        deletingDeviceOptions: true
      };
    case OptionsActionType.DELETING_DEVICE_OPTION_SUCCESS:
      return {
        ...state,
        deletingDeviceOptions: false
      };
    case OptionsActionType.FAILED_DELETING_DEVICE_OPTION:
      return {
        ...state,
        fetchingDeviceOptions: false
      };
    default:
      return state;
  }
};

export default options;
