import React, { useState } from 'react';
import styled from 'styled-components';

import { ExportToExcel, HeaderRow, InfoPopup, Modal, Table, Layout, TableRowType, TableColumnType } from 'access_ctrl-ui';

const Content = styled(Layout.Modal.Content)`
  padding: ${({ theme }) => theme.sizes.md};
  height: 60vh;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface Props {
  tableDataColumns: TableColumnType[];
  tableDataRows: TableRowType[];
  open: boolean;
  onClose: () => void;
  excelExport: boolean;
  modalHeader: string;
  descriptionText: string;
}

const TableModal:React.FC<Props> = ({
  open,
  onClose,
  modalHeader = 'Table',
  descriptionText,
  tableDataRows,
  tableDataColumns,
  excelExport,
  ...rest
}) => {
  const [sortedRows, setSortedRows] = useState<TableRowType[] | null>(null);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const requestClose = () => {
    setShowConfirmModal(true);
  };

  const onCloseConfirm = () => {
    setShowConfirmModal(false);
    onClose();
  };

  const handleDataSorted = (sortedData: TableRowType[]) => {
    setSortedRows(sortedData);
  };

  return (
    <Modal
      open={open}
      onClose={requestClose}
      confirmClose={onCloseConfirm}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      closeIcon
      size='large'
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>{modalHeader}</Layout.Modal.Header>
        <Content>
          <HeaderRow>
            <InfoPopup descriptionText={descriptionText || `Table showing ${modalHeader.substr(0, 1).toLowerCase()}${modalHeader.substr(1)}`} />
            {
              excelExport &&
                <ExportToExcel
                  tableDataRows={sortedRows || tableDataRows}
                  tableDataColumns={tableDataColumns}
                  sheetName={modalHeader}
                  loading={false}
                />
            }
          </HeaderRow>
          <Table
            setQueryString={false}
            onDataSorted={handleDataSorted}
            tableDataRows={tableDataRows}
            tableDataColumns={tableDataColumns}
            {...rest}
          />
        </Content>
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default TableModal;
