import { Label } from 'access_ctrl-ui';
import { DailyRoutine } from 'Features/Routines/Interfaces';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Chip = styled(Link)`
  border-radius: 100px;
  padding: calc(${({ theme }) => theme.sizes.sm} / 2) ${({ theme }) => theme.sizes.md};
  margin-right: calc(${({ theme }) => theme.sizes.sm} / 2);
  margin-top: calc(${({ theme }) => theme.sizes.sm});
  border-width: 0;
  background-color: #565659;
  border: 2px solid #565659;
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;

  &&& {
    color: #fff;
  }

  &&&:hover {
    background: ${({ theme }) => theme.buttonBorder};
    border: 2px solid ${({ theme }) => theme.buttonBorder};
  }
`;

const NoData = styled.div`
  margin-top: calc(${({ theme }) => theme.sizes.sm});
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const InfoListWrapper = styled.div`
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
`;

type DataItem = Pick<DailyRoutine, 'Id' | 'Name'>;
interface InfoListProps {
  data: DataItem[] | undefined | null;
  label: string;
  messageError?: string;
}

const RoutineList: React.FC<InfoListProps> = ({ data, label, messageError = 'No data' }) => {
  return (
    <InfoListWrapper>
      <Label name={label} label={label} />
      <ChipWrapper>
        {(data && data.length !== 0)
          ? data.map(({ Id, Name }) => <Chip to={`/routines/${Id}`} key={Id}>{Name}</Chip>)
          : <NoData>{messageError}</NoData>}
      </ChipWrapper>
    </InfoListWrapper>
  );
};

export default RoutineList;
