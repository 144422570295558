import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Divider, Dimmer, Loader, Image, DropdownItemProps } from 'semantic-ui-react';
import styled from 'styled-components';

import { addUserIdentityToken } from 'Store/Actions/userActions';
import { loadingMessages } from '../../Common/messages';
import { ModalTypes, RFIDReaderTagTypes } from '../../Common/Enums';
import { toggleModal, clearRFIDReadTag, RFIDReaderReadTag, selectRFIDTag } from 'Store/Actions/appActions';

import { Button, Input, Layout, Modal, Select } from 'access_ctrl-ui';

import rfid from 'Assets/rfid.svg';
import { Tag, User } from 'Interfaces';
import { RootState } from 'Store/Reducers';
import { ThemedCssProps } from 'Interfaces/ThemedCssProps';
import { tagTypeToString } from 'Common/Constant/RFID';

interface CSSProps extends ThemedCssProps {
  $disabled?: boolean;
}

const Segment = styled.div<CSSProps>`
  background: ${({ $disabled }) => ($disabled ? 'rgba(0,0,0,0.5)' : '')};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.sizes.md};
`;

const StyledDivider = styled(Divider)`
  &&& {
    color: ${({ theme }) => theme.textColor};
    font-weight: normal;
    margin: ${({ theme }) => theme.sizes.md};
  }
`;

const H3 = styled.h3`
  margin-bottom: ${({ theme }) => theme.sizes.md};
`;
interface IProps {
  open: boolean;
  identityTokens: Tag[];
  currentUser: User | null;
  fetchingRFIDTag: boolean;
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
  addUserIdentityToken: (userId: string, identityToken: string) => Promise<void>;
  RFIDReaderReadTag: (tagType: RFIDReaderTagTypes, tag: string) => Promise<void>;
  clearRFIDReadTag: () => void;
  selectRFIDTag: (tag: string) => void;
  selectedRFIDTag: string;
}
export const AddIdentityTokenModal: React.FC<IProps> = ({
  open,
  identityTokens,
  currentUser,
  fetchingRFIDTag,
  toggleModal,
  addUserIdentityToken,
  RFIDReaderReadTag,
  clearRFIDReadTag,
  selectRFIDTag,
  selectedRFIDTag
}) => {
  const [enteredToken, setEnteredToken] = useState('');

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const requestClose = () => {
    setShowConfirmModal(true);
    clearRFIDReadTag();
  };

  const onCloseConfirm = () => {
    setShowConfirmModal(false);
    toggleModal(ModalTypes.ADD_IDENTITY_TOKEN_MODAL, false);
    clearRFIDReadTag();
    setEnteredToken('');
  };

  const handleAddIdentityToken = () => {
    const token = enteredToken || selectedRFIDTag;

    if (token.length > 0 && currentUser != null) {
      addUserIdentityToken(currentUser.Id, token).then(() => {
        onCloseConfirm();
      });
    }
  };

  const handleSearchIdentityToken = () => {
    RFIDReaderReadTag(RFIDReaderTagTypes.NOTAG, enteredToken);
  };

  const tagOptions:DropdownItemProps[] = identityTokens.map((tag: Tag) => {
    return {
      key: tag.tag,
      value: tag.tag,
      text: `ID: ${tag.tag} / Type: ${tagTypeToString(tag.tagType)}`
    };
  });

  return (
    <Modal
      open={open}
      onClose={requestClose}
      confirmClose={onCloseConfirm}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      closeIcon
      size='tiny'
    >
      <Layout.Modal.Wrapper>
        {currentUser && (
          <Layout.Modal.Header>
            Add ID {`to ${(currentUser.FirstName || '') + ' ' + (currentUser.LastName || '')}`}
          </Layout.Modal.Header>
        )}
        {!currentUser && <Layout.Modal.Header>Search ID</Layout.Modal.Header>}
        <Layout.Modal.Content>
          <Image centered disabled={enteredToken.length !== 0} rounded size='medium' src={rfid} />

          <Dimmer inverted active={fetchingRFIDTag}>
            <Loader active>{loadingMessages.CHECKING_RFID_TAG}</Loader>
          </Dimmer>

          <Segment $disabled={enteredToken.length !== 0}>
            <H3>Read tag or card with reader</H3>
            {identityTokens[0] && (
              <Select
                onChange={(e, { value }) => selectRFIDTag(value as string)}
                options={tagOptions}
                placeholder='Select tag'
                value={selectedRFIDTag}
              />
            )}
          </Segment>

          <StyledDivider horizontal>Or</StyledDivider>

          <Segment>
            <Input
              fluid
              onChange={(e) => setEnteredToken(e.target.value)}
              placeholder='Enter ID here'
              value={enteredToken}
            />
          </Segment>

          <Layout.Modal.ButtonContainer>
            {currentUser && (
              <Button disabled={!identityTokens[0] && !enteredToken} onClick={handleAddIdentityToken}>
                Save
              </Button>
            )}
            {!currentUser && (
              <Button disabled={fetchingRFIDTag} onClick={handleSearchIdentityToken}>
                Search
              </Button>
            )}
          </Layout.Modal.ButtonContainer>
        </Layout.Modal.Content>
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default connect(
  ({ modalsOpen, app, user }: RootState) => ({
    open: modalsOpen[ModalTypes.ADD_IDENTITY_TOKEN_MODAL],
    identityTokens: app.lastReadRFIDTags,
    currentUser: user.currentUser,
    fetchingRFIDTag: app.fetchingRFIDTag,
    selectedRFIDTag: app.selectedRFIDTag
  }),
  (dispatch) => ({
    toggleModal: toggleModal(dispatch),
    addUserIdentityToken: addUserIdentityToken(dispatch),
    clearRFIDReadTag: clearRFIDReadTag(dispatch),
    RFIDReaderReadTag: RFIDReaderReadTag(dispatch),
    selectRFIDTag: selectRFIDTag(dispatch)
  })
)(AddIdentityTokenModal);
