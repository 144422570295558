import { Button, Datepicker, ExportToExcel, Input, Label, TableColumnType, TableRowType } from 'access_ctrl-ui';
import React, { useEffect, useState } from 'react';
import { Filter, Search } from 'react-feather';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import DropdownList from 'Components/Global/DropdownList';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import useFilter, { DateTypes } from '../../Hooks/useFilter';
import { FilterTypes } from 'Common/Enums/FilterTypes';
import { FilterProps } from 'Interfaces/FilterProps';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

const ClearAllButton = styled(Button)`
  margin-top: ${({ theme }) => theme.sizes.lg};
  align-self: center;
  height: auto;
`;

const DatePickerWrapper = styled.div`
  > div {
    width: 150px;
  }

  div:first-of-type {
    margin-right: ${({ theme }) => theme.sizes.md} !important;
  }
`;

const StyledHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 ${({ theme }) => theme.sizes.md};
  height: 40px;
  align-items: center;

  .filter-section {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon-wrapper {
    margin-left: ${({ theme }) => theme.sizes.md};
    display: flex;
    align-items: center;
  }
`;

const FilterWrapper = styled.div`
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.appSecondaryBg};
  padding: 0 ${({ theme }) => theme.sizes.md} ${({ theme }) => theme.sizes.md} ${({ theme }) => theme.sizes.md};
  flex-direction: row;
  color: white;
  margin: ${({ theme }) => theme.sizes.md} ${({ theme }) => theme.sizes.sm};

  .filter-category {
    margin-right: ${({ theme }) => theme.sizes.lg};
    margin-top: ${({ theme }) => theme.sizes.md};
  }

  .filter-category.text-field {
    input {
      max-width: 150px;
    }
  }

  .filter-category.dropdown {
    min-width: 220px;
    max-width: 300px;
  }

  .filter-category.dropdown .dropdown {
    min-height: 2.6rem !important;
  }
`;

const StyledFilterIcon = styled(Filter)`
  color: ${({ theme }) => theme.colorPrimary};
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.inputBorder};
  }
`;

const StyledSearchIcon = styled(Search)`
  color: ${({ theme }) => theme.colorPrimary};
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.inputBorder};
  }
`;

interface Props {
  filters: FilterProps[];
  tableData: TableRowType[];
  pullFilteredTableData?: (data: TableRowType[]) => void; // Send filtered data to parent
  tableTitle: string;
  tableColumns?: TableColumnType[];
  excelSheetName?: string;
  CustomButton?: JSX.Element;
  permissionToViewContent?: boolean;
  showFilters?: boolean;
  exportTableFnc?: () => Promise<TableRowType[]>;
  exportable?: boolean;
  showSearchAll?: boolean;
}

/**
 * Table filter component used for filtering table data
 * @param filters Array of filters
 * @param tableData Array of table data
 * @param pullFilteredTableData function returning table data that has been filtered
 * @param tableTitle Adds a title to the filter component
 * @param tableColumns Optional, add this to add export to excel functionallity
 * @param excelSheetName Optiona, add this to define excel sheet name
 * @returns React.FC showing a filtering component
 */
const TableFilter: React.FC<Props> = ({ filters, tableData, pullFilteredTableData, tableTitle: label, tableColumns, showSearchAll, excelSheetName, CustomButton, permissionToViewContent, exportTableFnc, showFilters: showFilterInit, exportable }) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [showSearchField, setShowSearchField] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(showFilterInit || false);

  const [filterData, updateFilterValue, setSearchAll, clearAll, filterValues] = useFilter(filters, tableData);

  /**
   * Send filtered table data to parent
   */
  useEffect(() => {
    pullFilteredTableData && pullFilteredTableData(filterData);
  }, [filterData, pullFilteredTableData]);

  /**
   * Filter data based on General searchfield. The filtering is based on all object keys.
   */
  useEffect(() => {
    setSearchAll(searchInput);
  }, [searchInput, setSearchAll]);

  /**
   * Manage search field when clicked
   */
  const HandleSearchOnClick = () => {
    setShowSearchField(true);
  };

  /**
   * Manage search field on blur
   */
  const OnBlurInputField = () => {
    if (isEmpty(searchInput)) {
      setShowSearchField(false);
    }
  };

  /**
   * Manage search field key press events
   * @param e keyboard event
   */
  const HandleSearchOnKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      setSearchInput('');
      setShowSearchField(false);
    }
  };

  /**
   * Managing filter updates based on inputFields
   * @param e changeEvent with inputField value
   * @param key filterKey that will update its value
   */
  const OnChangeInputField = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    switch (key) {
      case 'all':
        setSearchInput(e.target.value);
        break;
      default:
        updateFilterValue(e.target.value, key);
        break;
    }
  };

  /**
   * Manage filters based on datepickers
   * @param e datePicker event
   * @param selected new Date
   * @param dateType dateType (dateFrom or dateTo)
   * @param key filterKey to update
   */
  const onChangeDate = (e: React.SyntheticEvent<HTMLInputElement, Event> | undefined, selected: Date | null, dateType: DateTypes, key: string) => {
    e?.preventDefault();

    updateFilterValue(selected, key, dateType);
  };

  /**
   * Manage filters based on dropdown lists
   * @param event Dropdown event
   * @param data data from DropdownProps
   * @param key filterKey to update
   */
  const onChangeDropDown = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, key: string) => {
    updateFilterValue(data, key);
  };

  /**
   * Search for unique dropdown items in specific key of tableData
   * @param key object key to obtain value
   * @returns DropdownItemProps[] used for Dropdown compononent
   */
  const findDropdownItems = (key: string): DropdownItemProps[] => {
    const dropdownItems: DropdownItemProps[] = [];
    // eslint-disable-next-line array-callback-return
    tableData.map(({ data, sortData }) => {
      if (sortData && sortData[key]) {
        if (sortData[key]?.toString().toLocaleLowerCase() === '') {
          // eslint-disable-next-line array-callback-return
          return;
        }
        if (!dropdownItems.some(item => item.value?.toString().toLocaleLowerCase() === sortData[key]?.toString().toLocaleLowerCase())) {
          dropdownItems.push({ value: sortData[key]?.toString().toLocaleLowerCase(), text: (sortData[key]?.toString().toLocaleLowerCase()), key: sortData[key]?.toString().toLocaleLowerCase() });
        }
      } else if (data[key] !== null && data[key] !== undefined) {
        if (data[key]?.toString().toLocaleLowerCase() === '') {
          // eslint-disable-next-line array-callback-return
          return;
        }
        if (!dropdownItems.some(item => item.value?.toString().toLocaleLowerCase() === data[key]?.toString().toLocaleLowerCase())) {
          dropdownItems.push({ value: data[key]?.toString().toLocaleLowerCase(), text: (data[key]?.toString().toLocaleLowerCase()), key: data[key]?.toString().toLocaleLowerCase() });
        }
      }
    });
    dropdownItems.sort((a, b) => (a.text && b.text) && a?.text > b.text ? 1 : -1);
    return dropdownItems;
  };

  const checkIfValidDuration = (values: NumberFormatValues): number | undefined => {
    const { formattedValue } = values;
    const hour = formattedValue.substring(0, 2);
    const minutes = formattedValue.substring(4, 6);
    const seconds = formattedValue.substring(8, 10);
    if (hour.includes('_') || minutes.includes('_') || seconds.includes('_')) return undefined;
    if (parseInt(hour) >= 24 || parseInt(minutes) >= 60 || parseInt(seconds) >= 60) return undefined;
    return (parseInt(hour) * 60 * 60 + parseInt(minutes) * 60 + parseInt(seconds));
  };

  const durationValueChanged = (values: NumberFormatValues, filterKey: string): void => {
    const seconds = checkIfValidDuration(values);
    isEmpty(values.value) && updateFilterValue('', filterKey);
    seconds && updateFilterValue(seconds.toString(), filterKey);
  };

  return (
    <div>
      <StyledHeaderRow>
        <h2>{label}</h2>
        <div className='filter-section'>
          {
            (showSearchAll) &&
            (
              (showSearchField && permissionToViewContent)
                ? <Input placeholder='Search...' onBlur={OnBlurInputField} onChange={(event) => OnChangeInputField(event, 'all')} onKeyDown={HandleSearchOnKeyPress} autoFocus />
                : <StyledSearchIcon size={20} onClick={HandleSearchOnClick} />
            )
          }
          <div className='icon-wrapper'>
            <StyledFilterIcon size={20} onClick={() => setShowFilters(current => (permissionToViewContent) ? !current : false)} />
          </div>
          {
            (tableColumns && permissionToViewContent && exportable && (tableData.length !== 0 || filterData.length !== 0)) &&
              <div className='icon-wrapper'>
                <ExportToExcel
                  tableDataRows={filterData}
                  getTableDataFunc={exportTableFnc}
                  tableDataColumns={tableColumns}
                  sheetName={excelSheetName !== undefined ? excelSheetName : 'Data'}
                  loading={false}
                />
              </div>
          }
          {
            CustomButton &&
              <div className='icon-wrapper'>
                {CustomButton}
              </div>
          }
        </div>
      </StyledHeaderRow>
      {
        showFilters &&
          <FilterWrapper>
            {/* Render filters */}
            {
              // eslint-disable-next-line array-callback-return
              filters.map(({ filterType, filterKey, dropDownItems, label, placeholder, hidden }) => {
                if (!hidden) {
                  if (filterType === FilterTypes.INPUT_FIELD) {
                    return (
                      <div key={`filter-key-${filterKey}-${filterType}`} className={`filter-category text-field ${filterType}`}>
                        <Label label={label} />
                        <Input
                          placeholder={placeholder && placeholder}
                          onBlur={OnBlurInputField}
                          onChange={(event) => OnChangeInputField(event, filterKey)}
                          value={filterValues[filterKey]}
                        />
                      </div>
                    );
                  } else if (filterType === FilterTypes.DROPDOWN_LIST) {
                    return (
                      <div key={`filter-key-${filterKey}-${filterType}`} className='filter-category dropdown'>
                        <Label label={label} />
                        <DropdownList
                          multiple
                          lazyLoad
                          fluid
                          search
                          placeholder={placeholder && placeholder}
                          onChange={(e, data) => onChangeDropDown(e, data, filterKey)}
                          options={dropDownItems || findDropdownItems(filterKey)}
                          value={filterValues[filterKey]}
                        />
                      </div>
                    );
                  } else if (filterType === FilterTypes.DATE_RANGE) {
                    const dateFromString = filterValues[`${filterKey}_from`]?.toString();
                    const dateToString = filterValues[`${filterKey}_to`]?.toString();
                    const dateFromValue = (!dateFromString || dateFromString === '') ? null : new Date(dateFromString);
                    const dateToValue = (!dateToString || dateToString === '') ? null : new Date(dateToString);
                    return (
                      <div key={`filter-key-${filterKey}-${filterType}`} className='filter-category date-range'>
                        <Label label={label} />
                        <DatePickerWrapper>
                          <Datepicker
                            selected={dateFromValue}
                            maxDate={new Date()}
                            placeholderText='From'
                            onDateSelect={(e, selected) => onChangeDate(e, selected, DateTypes.DATE_FROM, filterKey)}
                            showTimeSelect
                          />
                          <Datepicker
                            selected={dateToValue}
                            maxDate={new Date()}
                            placeholderText='To'
                            onDateSelect={(e, selected) => onChangeDate(e, selected, DateTypes.DATE_TO, filterKey)}
                            showTimeSelect
                          />
                        </DatePickerWrapper>
                      </div>
                    );
                  } else if (filterType === FilterTypes.TIME) {
                    return (
                      <div className='filter-category time'>
                        <Label label={label} />
                        <NumberFormat
                          displayType='input' format='##h ##m ##s' customInput={Input} mask='_' placeholder={placeholder} onValueChange={(values) => durationValueChanged(values, filterKey)}
                        />
                      </div>
                    );
                  }
                }
              })
            }
            <ClearAllButton onClick={clearAll}>Clear all</ClearAllButton>
          </FilterWrapper>
      }
    </div>
  );
};

export default TableFilter;
