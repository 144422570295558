/**
  Actiontypes dispatched from userActions
*/
export enum UserActionType {
  ADDING_IDENTITY_TOKEN = 'ADDING_IDENTITY_TOKEN',
  ADDING_USER = 'ADDING_USER',
  DELETING_IDENTITY_TOKENS = 'DELETING_IDENTITY_TOKENS',
  DELETING_USER = 'DELETING_USER',
  EDIT_USER_DEVICES_FAILED = 'EDIT_USER_DEVICES_FAILED',
  EDIT_USER_DEVICES_START = 'EDIT_USER_DEVICES_START',
  EDIT_USER_DEVICES_SUCCESS = 'EDIT_USER_DEVICES_SUCCESS',
  FAILED_ADDING_USER = 'FAILED_CREATING_USER',
  FAILED_DELETING_IDENTITY_TOKENS = 'FAILED_DELETING_IDENTITY_TOKENS',
  FAILED_DELETING_USER = 'FAILED_DELETING_USER',
  FAILED_FETCHING_ALL_USERS = 'FAILED_FETCHING_ALL_USERS',
  FAILED_FETCHING_USER_DEVICES = 'FAILED_FETCHING_USER_DEVICES',
  FAILED_FETCHING_USER_IDENTITIES = 'FAILED_FETCHING_USER_IDENTITIES',
  FAILED_UPDATING_USER = 'FAILED_UPDATING_USER',
  FETCHING_ALL_USERS = 'FETCHING_ALL_USERS',
  FETCHING_DEVICES_FOR_USER = 'FETCHING_DEVICES_FOR_USER',
  FETCHING_USER_IDENTITIES = 'FETCH_USER_IDENTITIES',
  FETCHING_USER_INFO = 'FETCH_USER_INFO',
  GOT_ALL_USERS = 'GOT_ALL_USERS',
  GOT_DEVICES_FOR_USER = 'GOT_DEVICES_FOR_USER',
  GOT_USER_IDENTITIES = 'GOT_USER_IDENTITIES',
  GOT_USER_INFO = 'GOT_USER_INFO',
  IDENTITY_TOKENS_DELETED = 'IDENTITY_TOKEN_DELETED',
  IDENTITY_TOKEN_ADDED = 'IDENTITY_TOKEN_ADDED',
  RESET_ALL_USERS = 'RESET_ALL_USERS',
  SELECTED_USER = 'SELECTED_USER',
  UPDATE_USER_START = 'UPDATE_USER_START',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  USER_ADDED = 'USER_ADDED',
  USER_DELETED = 'USER_DELETED',
  GOT_USER_COUNT = 'GOT_USER_COUNT'
}
