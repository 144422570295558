export enum LogOrderByColumn {
  Undefined = 0,
  InventoryIdAsc = 1,
  InventoryIdDesc = 2,
  UserIdAsc = 4,
  UserIdDesc = 8,
  TimestampAsc = 16,
  TimestampDesc = 32,
  TypeAsc = 64,
  TypeDesc = 128,
  SuccessfulAsc = 256,
  SuccessfulDesc = 512,
  InventoryNameAsc = 1024,
  InventoryNameDesc = 2048,
  UserNameAsc = 4096,
  UserNameDesc = 8192,
  ColumnBAsc = 16384,
  ColumnBDesc = 32768,
  ColumnCAsc = 65536,
  ColumnCDesc = 131072
}
