export enum ModalTypes {
  ADD_DEVICE_MODAL = 'ADD_DEVICE_MODAL',
  EDIT_DEVICE_MODAL = 'EDIT_DEVICE_MODAL',
  EDIT_DEVICE_USER_ACCESS_MODAL = 'EDIT_DEVICE_USER_ACCESS_MODAL',
  ADD_IDENTITY_TOKEN_MODAL = 'ADD_IDENTITY_TOKEN_MODAL',
  ADD_USER_MODAL = 'ADD_USER_MODAL',
  EDIT_USER_MODAL = 'EDIT_USER_MODAL',
  EDIT_USER_ACCESS_MODAL = 'EDIT_USER_ACCESS_MODAL',
  EDIT_IDENTITIES_MODAL = 'EDIT_IDENTITIES_MODAL',
  EDIT_DEVICE_OPTIONS_MODAL = 'EDIT_DEVICE_OPTIONS_MODAL',
  EDIT_USER_OPTIONS_MODAL = 'EDIT_USER_OPTIONS_MODAL',
  EDIT_ROUTINE_MODAL = 'EDIT_ROUTINE_MODAL',
  EDIT_ROUTINE_DEVICES_MODAL = 'EDIT_ROUTINE_DEVICES_MODAL',
  IDENTITY_IN_USE_MODAL = 'IDENTITY_IN_USE_MODAL'
}
