import { combineReducers } from 'redux';
import { createBrowserHistory, History } from 'history';
import action from './action';
import app from './app';
import auditLog from './auditLog';
import debug from './debug';
import device from './device';
import modalsOpen from './modalsOpen';
import operationlog from './operationlog';
import options from './options';
import user from './user';
import routineSlice from 'Features/Routines/routineSlice';
import { connectRouter } from 'connected-react-router';
import receiptSlice from 'Features/Routines/Receipts/receiptSlice';

export const createRootReducer = (history:History) => combineReducers({
  router: connectRouter(history),
  action,
  app,
  auditLog,
  debug,
  device,
  modalsOpen,
  operationlog,
  options,
  user,
  routine: routineSlice,
  receipts: receiptSlice
});
const history = createBrowserHistory();
const rootReducer = createRootReducer(history);
export type RootState = ReturnType<typeof rootReducer>;
