import { Layout } from 'access_ctrl-ui';
import { useEffect } from 'react';
import { FileText } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getDocuments } from 'Store/Actions/appActions';
import { useAppSelector } from 'Store/hooks';
import styled from 'styled-components';

const StyledHeader = styled.h2`
  padding-bottom: ${({ theme }) => theme.sizes.md};
`;

const DocumentWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.sizes.md};
  font-size: 1.5em;

  svg {
    margin-right: ${({ theme }) => theme.sizes.md};
  }
`;

const StyledSection = styled(Layout.Section)`
  padding-left: ${({ theme }) => theme.sizes.md};
  padding-right: ${({ theme }) => theme.sizes.md};
  padding-bottom: ${({ theme }) => theme.sizes.lg};
`;

export const DocumentContainer = () => {
  const dispatch = useDispatch();
  const { documents } = useAppSelector(state => state.app);
  useEffect(() => {
    if (!documents) {
      getDocuments(dispatch)();
    }
  }, [documents, dispatch]);
  return documents ? (
    <StyledSection flexGrow={false}>
      <StyledHeader>Manuals</StyledHeader>
      {
        documents?.map((document) => {
          return (
            <DocumentWrapper key={document.Item2}>
              <a href={document.Item2} download target='_blank' rel='noreferrer'><FileText href={document.Item2} /> {document.Item1}</a>
            </DocumentWrapper>
          );
        })
      }
    </StyledSection>) : (<></>);
};
export default DocumentContainer;
