import React, { FC } from 'react';
import styled from 'styled-components';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Icon } from 'react-feather';
import { ThemedCssProps } from 'Interfaces/ThemedCssProps';

const StyledNavLink = styled(NavLink)<ThemedCssProps>`
  &&& {
    align-items: center;
    color: ${({ theme }) => theme.textColorSelected};
    display: flex;
    font-size: 1.25rem;
    padding: ${({ theme }) => theme.sizes.sm};

    svg {
      margin: ${({ theme }) => theme.sizes.md};
    }

    &:hover {
      background: ${({ theme }) => theme.appSecondaryBg};
      border-right: 0.313rem solid ${({ theme }) => theme.colorPrimary};

      svg {
        color: ${({ theme }) => theme.colorPrimary};
      }
    }

    &.active {
      background: ${({ theme }) => theme.appSecondaryBg};
      border-right: 0.313rem solid ${({ theme }) => theme.colorPrimary};
      color: ${({ theme }) => theme.colorPrimary};
    }
  }
`;
interface Props extends NavLinkProps {
  MenuIcon: Icon;
  name: string;
}

const MainMenuItem: FC<Props> = ({ MenuIcon, name, to, ...rest }) => (
  <li>
    <StyledNavLink to={to} {...rest}>
      {MenuIcon && <MenuIcon size='1.75rem' />}
      <span>{name}</span>
    </StyledNavLink>
  </li>
);

export default MainMenuItem;
