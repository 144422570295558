import { Button, Layout, Table, TableLink, TableRowType } from 'access_ctrl-ui';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Plus } from 'react-feather';
import { useAppSelector } from 'Store/hooks';
import AddDeviceRecordModal from 'Components/Modals/AddDeviceRecordModal';
import { DeviceRecord } from 'Interfaces/DeviceRecord';
import { formatDate } from 'Util/formatDate';
import { accessControlApi } from 'Common/api';
import { User } from 'Interfaces';
import { AxiosResponse } from 'axios';
import { addErrorMessage } from 'Store/Actions/feedbackMessage';
import { apiCallErrorMessages } from 'Common/messages';
import DeviceRecordModal from 'Components/Modals/DeviceRecordModal';
import { useDispatch } from 'react-redux';

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledSection = styled(Layout.Section)`
  margin-top: ${({ theme }) => theme.sizes.md};
  flex: content;

  & button {
    width: fit-content;
    justify-content: center;
  }

  & .record-col-data {
    cursor: pointer;
  }
`;

const tableColumns = [
  {
    name: 'Date',
    key: 'date'
  },
  {
    name: 'Record',
    key: 'record'
  },
  {
    name: 'Created by',
    key: 'createdBy'
  }
];

interface UserType {
  [key: string]: string;
}

const RecordPane = () => {
  const { currentDevice } = useAppSelector(state => state.device);
  const { loggedInUser: { IsAdmin, Id: userId, FirstName, LastName } } = useAppSelector(state => state.app);
  const optionsDispatcher = useDispatch();

  const [openAddDeviceModal, setOpenAddDeviceModal] = useState<boolean>(false);
  const [openRecordModal, setOpenRecordModal] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TableRowType[]>([] as TableRowType[]);
  const [selectedRecord, setSelectedRecord] = useState<{ record: DeviceRecord, recordId: string, createdBy: string }>();
  const [userDictionary, setUserDictionary] = useState<UserType>({});

  useEffect(() => {
    setTableData([]);
    const updateRowData = async () => {
      if (currentDevice?.Options !== null && currentDevice?.Options['.RECORDS'] !== undefined && currentDevice?.Options['.RECORDS'] !== null) {
        const records: DeviceRecord[] = JSON.parse(currentDevice.Options['.RECORDS'].Value);
        for (const recordId in records) {
          let createdByName: string;
          if (userDictionary[recordId]) {
            createdByName = userDictionary[recordId];
          } else {
            createdByName = await fetchUserInfo(records[recordId].CreatedBy);
            setUserDictionary((prevState) => ({ ...prevState, [records[recordId].CreatedBy]: createdByName }));
          }

          setTableData(oldTableData => [...oldTableData, {
            key: recordId,
            data: {
              createdBy: <TableLink text={createdByName} to={`/users/${records[recordId].CreatedBy}`} />,
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              record: <a className='record-col-data' onClick={(e) => onClickDeviceRecord(records[recordId], recordId, createdByName)}>{records[recordId].tag}</a>,
              date: formatDate(records[recordId].Date).split(' ')[0]
            }
          }]);
        }
      }
    };

    updateRowData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDevice?.Options]);

  const onClickDeviceRecord = (record: DeviceRecord, recordId: string, createdBy: string): void => {
    setSelectedRecord({ record, recordId, createdBy });
    toggleRecordModal();
  };

  const fetchUserInfo = (userId: string): Promise<string> => {
    return accessControlApi.get<User, AxiosResponse<User>>(`Directory/users/${userId}`).then(value => {
      return `${value.data.FirstName} ${value.data.LastName}`;
    }).catch(() => {
      addErrorMessage(optionsDispatcher)(apiCallErrorMessages.USER_INFO_ERROR_MESSAGE);
      return '';
    });
  };

  const toggleAddRecordModal = () => {
    setOpenAddDeviceModal(!openAddDeviceModal);
  };

  const toggleRecordModal = () => {
    setOpenRecordModal(!openRecordModal);
  };

  return (
    <>
      <AddDeviceRecordModal open={openAddDeviceModal} modalHeader='Add Record' userId={userId} firstName={FirstName} lastName={LastName} currentDevice={currentDevice} onClose={toggleAddRecordModal} />
      {
        selectedRecord !== undefined &&
          <DeviceRecordModal open={openRecordModal} modalHeader='Record' onClose={toggleRecordModal} record={selectedRecord.record} createdBy={selectedRecord.createdBy} recordId={selectedRecord.recordId} key={selectedRecord.recordId + selectedRecord.record.text} />
      }

      <StyledSection flexGrow={false}>
        <ButtonSection>
          {
            IsAdmin &&
              <Button type='button' onClick={toggleAddRecordModal} key='recordButton'>
                <Plus /> Add record
              </Button>
          }
        </ButtonSection>

        {
          tableData &&
            <Table tableDataColumns={tableColumns} tableDataRows={tableData} rowsPerPage={6} setQueryString sortable />
        }

      </StyledSection>
    </>
  );
};

export default RecordPane;
