import { Label } from 'access_ctrl-ui';
import React from 'react';
import styled from 'styled-components';

const Chip = styled.div<{ useAlternateColor?: boolean }>`
  border-radius: 100px;
  padding: calc(${({ theme }) => theme.sizes.sm} / 2) ${({ theme }) => theme.sizes.md};
  margin-right: calc(${({ theme }) => theme.sizes.sm} / 2);
  margin-top: calc(${({ theme }) => theme.sizes.sm});
  border-width: 0;
  background-color: ${({ useAlternateColor, theme }) => useAlternateColor ? theme.colorPrimary : '#565659'};
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const InfoListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoData = styled(Chip)`
  background: none !important;
  padding-left: 0;
  margin-left: 0;
  color: ${({ theme }) => theme.inputInactiveText};
`;

interface InfoListProps {
  data: string[] | undefined | null;
  label?: string;
  messageError?: string;
  className?: string;
  useAccentColor?: boolean;
}

const InfoList: React.FC<InfoListProps> = ({ data, label, messageError = 'No data', className, useAccentColor }) => {
  return (
    <InfoListWrapper className={className}>
      {label && <Label name={label} label={label} />}
      <ChipWrapper>
        {data
          ? data.map(item => <Chip useAlternateColor={useAccentColor} key={item}>{item}</Chip>)
          : <NoData>{messageError}</NoData>}
      </ChipWrapper>
    </InfoListWrapper>
  );
};

export default InfoList;
