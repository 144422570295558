import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { batchDeleteIdentityTokens } from 'Store/Actions/userActions';
import { ModalTypes } from '../../Common/Enums';
import { toggleModal } from 'Store/Actions/appActions';

import { Button, CheckboxProps, Layout, Modal, Table, TableKeyType, TableRowType } from 'access_ctrl-ui';
import { User } from 'Interfaces';
import { RootState } from 'Store/Reducers';

const Content = styled(Layout.Modal.Content)`
  padding: ${({ theme }) => theme.sizes.md};
  height: 40vh;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const tableDataColumns = [
  {
    name: 'Identity',
    key: 'identity'
  }
];

interface Props {
  batchDeleteIdentityTokens: (userId: string, identities: string[]) => Promise<void>;
  currentUser: User | null;
  deletingIdentityTokens: boolean;
  open: boolean;
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
  userIdentities: string[];
}

export const EditIdentitiesModal = ({
  batchDeleteIdentityTokens,
  currentUser,
  deletingIdentityTokens,
  open,
  toggleModal,
  userIdentities
}: Props) => {
  const [selectedIdentities, setSelectedIdentities] = useState<TableKeyType[]>([] as TableKeyType[]);
  const [tableDataRows, setTableDataRows] = useState<TableRowType[]>([] as TableRowType[]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const requestClose = () => {
    setShowConfirmModal(true);
  };

  const onCloseConfirm = () => {
    setShowConfirmModal(false);
    setSelectedIdentities([]);
    toggleModal(ModalTypes.EDIT_IDENTITIES_MODAL, false);
  };

  const addIdentity = () => {
    toggleModal(ModalTypes.ADD_IDENTITY_TOKEN_MODAL, true);
  };

  const onSelectSingle = (el: CheckboxProps, _: number, row: TableRowType) => {
    if (el.checked) {
      setSelectedIdentities([...selectedIdentities, row.key]);
    } else {
      setSelectedIdentities(selectedIdentities.filter((item) => item !== row.key));
    }
  };

  const onSelectAll = (el: CheckboxProps) => {
    if (el.checked) {
      setSelectedIdentities(userIdentities);
    } else {
      setSelectedIdentities([]);
    }
  };

  useEffect(() => {
    if (userIdentities) {
      setTableDataRows(
        userIdentities.map((identity) => (
          {
            key: identity,
            data: {
              id: identity,
              identity: identity
            }
          }
        )));
    }
  }, [userIdentities]);

  useEffect(() => {
    if (open) {
      setSelectedIdentities([]);
    }
  }, [open]);

  const deleteIdentities = () => {
    if (currentUser?.Id) {
      batchDeleteIdentityTokens(currentUser.Id, selectedIdentities.map(identity => identity.toString())).then(() => {
        onCloseConfirm();
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={requestClose}
      confirmClose={onCloseConfirm}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      closeIcon
      size='large'
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>Edit user identities</Layout.Modal.Header>
        <Content>
          <Table
            setQueryString={false}
            rowsPerPage={0}
            tableDataRows={tableDataRows}
            tableDataColumns={tableDataColumns}
            checkbox
            checkedRows={selectedIdentities}
            onSelectSingle={onSelectSingle}
            onSelectAll={onSelectAll}
          />
        </Content>
        <Layout.Modal.ButtonContainer>
          <Button onClick={addIdentity}>Add identity</Button>
          <DeleteButtonContainer>
            <Button loading={deletingIdentityTokens} onClick={deleteIdentities} disabled={selectedIdentities.length < 1}>Delete selected</Button>
          </DeleteButtonContainer>
        </Layout.Modal.ButtonContainer>
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default connect(({ modalsOpen, user }: RootState) => ({
  open: modalsOpen[ModalTypes.EDIT_IDENTITIES_MODAL],
  currentUser: user.currentUser,
  userIdentities: user.userIdentities,
  deletingIdentityTokens: user.deletingIdentityTokens
}), dispatch => ({
  toggleModal: toggleModal(dispatch),
  batchDeleteIdentityTokens: batchDeleteIdentityTokens(dispatch)
}))(EditIdentitiesModal);
