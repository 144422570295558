import { Button, Layout, Modal } from 'access_ctrl-ui';
import { ModalTypes } from 'Common/Enums';
import { AlertTriangle } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearRFIDReadTag, resetExistingUser, toggleModal } from 'Store/Actions/appActions';
import { batchDeleteIdentityTokens } from 'Store/Actions/userActions';
import { useAppSelector } from 'Store/hooks';
import styled from 'styled-components';

const Content = styled(Layout.Modal.Content)`
  padding: ${({ theme }) => theme.sizes.lg};
  height: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledTriangle = styled(AlertTriangle)`
  color: ${({ theme }) => theme.textColorError};
`;

const StyledHeader = styled(Layout.Modal.Header)`
  text-align: center;
`;

const IdentityRow = styled(Layout.Row)`
  padding: ${({ theme }) => theme.sizes.md};
  margin: ${({ theme }) => theme.sizes.md} 0;
  background: ${({ theme }) => theme.colorSecondary};
  height: auto;
  display: flex;
  flex-direction: row;
  flex: 1;
  font-size: 1.2em;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 4em;
`;

const StyledLink = styled(Link)`
  margin-right: 1em;
  margin-left: auto;
`;

const StyledSpan = styled.span`
  margin-left: 1em;
  margin-right: auto;
`;

const StyledButtonContainer = styled(Layout.Modal.ButtonContainer)`
  width: 100%;
  padding: ${({ theme }) => theme.sizes.md};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IdentityTokenInUseModal:React.FC = () => {
  const { lastReadRFIDTags, existingUser } = useAppSelector(state => state.app);
  const open = useAppSelector(state => state.modalsOpen[ModalTypes.IDENTITY_IN_USE_MODAL]) ?? false;
  const dispatch = useDispatch();
  const close = (resetTag = true) => {
    toggleModal(dispatch)(ModalTypes.IDENTITY_IN_USE_MODAL, false);
    resetExistingUser(dispatch);
    if (resetTag) {
      clearRFIDReadTag(dispatch)();
    }
  };
  const replace = () => {
    if (!existingUser?.Id || !lastReadRFIDTags[0].tag) {
      return;
    }
    batchDeleteIdentityTokens(dispatch)(existingUser?.Id, [lastReadRFIDTags[0].tag]).then(() => close());
    close(false);
  };
  return (
    <>
      <Modal open={open} onClose={() => close()} closeIcon showConfirmModal={false} size='tiny'>
        <Layout.Modal.Wrapper>
          <StyledHeader><StyledTriangle /> Identity in use</StyledHeader>
          <Content>
            <IdentityRow>
              <StyledLink to={`/users/${existingUser?.Id}`}>{existingUser?.FirstName} {existingUser?.LastName}</StyledLink><StyledSpan>{lastReadRFIDTags[0]?.tag ?? 'unknown'}</StyledSpan>
            </IdentityRow>
            <StyledButtonContainer>
              <Button onClick={replace}>Replace</Button><Button onClick={close}>Scan new tag</Button>
            </StyledButtonContainer>
          </Content>
        </Layout.Modal.Wrapper>
      </Modal>
    </>
  );
};
export default IdentityTokenInUseModal;
