import rfid from 'Assets/rfid.svg';
import { Image } from 'semantic-ui-react';

const ScanRFID:React.FC = () => {
  return (
    <>
      <Image centered rounded size='medium' src={rfid} />
      <h1>Please scan your card to login</h1>
    </>
  );
};

export default ScanRFID;
