import React, { ReactNode } from 'react';
import { IconTypes, TableLink, TableText } from 'access_ctrl-ui';
import { AuditLogItem } from 'Interfaces';

export const getDeviceDisplayName = (deviceId:string, deviceDisplayName:string):string => {
  return deviceDisplayName || deviceId || '';
};

export const getUserDisplayName = (userId:string, userDisplayName:string):string => {
  return userDisplayName || userId || '';
};

export const getDeviceNameLink = (item:AuditLogItem):ReactNode|string => {
  const deviceDisplayName = getDeviceDisplayName(item.InventoryId, item.InventoryDisplayName);
  if (deviceDisplayName === 'Admin Dashboard') {
    return (
      <TableText successful={item.Successful} iconType={IconTypes.Dashboard} text={deviceDisplayName} />
    );
  } else if (deviceDisplayName !== item.InventoryId) {
    return <TableLink successful={item.Successful} to={`/devices/${item.InventoryId}`} iconType={IconTypes.Device} text={deviceDisplayName} />;
  } else {
    return deviceDisplayName;
  }
};

export const getUserNameLink = (item:AuditLogItem):React.ReactNode => {
  const userDisplayName = getUserDisplayName(item.UserId, item.UserDisplayName);

  if (userDisplayName !== item.UserId) {
    return <TableLink successful={item.Successful} to={`/users/${item.UserId}`} iconType={IconTypes.User} text={userDisplayName} />;
  } else {
    return <TableText successful={item.Successful} iconType={IconTypes.User} text={userDisplayName} />;
  }
};
