import { ConfirmModal, DangerZone, DangerZoneItem, Layout, TableRowType } from 'access_ctrl-ui';
import { Receipt, ReceiptFilter } from 'Features/Routines/Receipts/Interfaces';
import { getReceiptsWithFilter } from 'Features/Routines/Receipts/receiptSlice';
import ReceiptTable from 'Features/Routines/Receipts/ReceiptTable/ReceiptTable';
import { deleteRoutine, getRoutineById, getRoutines } from 'Features/Routines/routineSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Lock } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getAllDevices } from 'Store/Actions/deviceActions';
import { useAppSelector } from 'Store/hooks';
import styled from 'styled-components';
import { deviceHasRoutine } from 'Util/deviceHasRoutine';
import DeviceRoutineList from '../DeviceRoutineList';
import RoutinePane from './RoutinePane';
import queryString from 'query-string';
import { RoutineStatus, Translate } from 'Features/Routines/Interfaces';
import { ReceiptsOrderByColumn } from 'Common/Enums/ReceiptsOrderByColumn';
import { webApi } from 'Common/api';
import { formatDate } from 'Util/formatDate';
import _ from 'lodash';
import { setCurrentPage } from 'Store/Actions/appActions';

const StyledSection = styled(Layout.Section)`
  padding: 1rem;
  &&&button: {
    width: auto;
    align-self: flex-end;
  }
`;
const FlexRow = styled(Layout.Row)`
  flex-grow: 4;
`;
const rowsPerPage = 6;
const initReceiptFilter: ReceiptFilter = {
  startIndex: 0,
  stopIndex: rowsPerPage - 1
};

const RoutinePage = () => {
  const { routines, currentRoutine, loading: routineLoading } = useAppSelector(state => state.routine);
  const { receipts, loading: receiptsLoading, nbrOfReceipts } = useAppSelector(state => state.receipts);
  const { devices, fetchingDevices } = useAppSelector(state => state.device);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [showDeleteRoutineModal, setShowDeleteRoutineModal] = useState(false);
  const [fetchFilters, setFetchFilters] = useState<ReceiptFilter>(initReceiptFilter);

  const params = useParams<{routineId: string}>();
  const { routineId } = params;
  const memorizedDevices = useMemo(() => devices.filter(device => deviceHasRoutine(device, currentRoutine)), [devices, currentRoutine]);

  useEffect(() => {
    setCurrentPage(dispatch)({ name: 'Routine Details' });
  }, [dispatch]);
  /**
   * Set filters for fetching data
   */
  const setFilterFromParams = useCallback(() => {
    const params = queryString.parse(location.search);

    const activePage = +(params.activePage || 1);
    const startIndex = (+activePage - 1) * rowsPerPage;
    const stopIndex = activePage * rowsPerPage - 1;

    setFetchFilters({
      userIds: params.createdBy?.toString().split(',') ?? undefined,
      deviceIds: params.deviceId?.toString().split(',') ?? undefined,
      from: params.timestamp_from ? new Date(params.timestamp_from as string) : null,
      to: params.timestamp_to ? new Date(params.timestamp_to as string) : null,
      startIndex: startIndex,
      stopIndex: stopIndex,
      status: params.confirmed?.toString().split('') as unknown as RoutineStatus[] ?? undefined,
      routineIds: currentRoutine?.Id?.toString().split(',') ?? undefined,
      duration: params.duration ? parseInt(params.duration.toString()) : undefined,
      orderBy: params.orderBy?.toString() as unknown as ReceiptsOrderByColumn ?? undefined
    });
  }, [currentRoutine?.Id, location.search]);

  useEffect(() => {
    if (routineId !== currentRoutine?.Id) {
      dispatch(getRoutineById(routineId));
    }
  }, [dispatch, routineId, params, currentRoutine]);
  /**
   * SetFilterFromParams
   */
  useEffect(() => {
    if (currentRoutine) {
      setFilterFromParams();
    }
  }, [setFilterFromParams, currentRoutine]);

  useEffect(() => {
    dispatch(getReceiptsWithFilter(fetchFilters));
  }, [fetchFilters, dispatch]);
  /**
   * fetch all routines, receipts and devices
   */
  useEffect(() => {
    if (!routines || routines.length === 0) {
      dispatch(getRoutines());
    }
    if (!devices || devices.length === 0) {
      getAllDevices(dispatch)(false, true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params]);

  const onConfirmDeleteRoutine = async () => {
    if (!currentRoutine) {
      return;
    }
    if (dispatch(deleteRoutine(routineId))) {
      history.push('/routines');
      setShowDeleteRoutineModal(false);
    }
  };

  /**
   * Fetching all data when downloading excel data sheet
   * @returns TableRows as a promise
   */
  const getExcelData = async () => {
    const _filterReq = _.clone(fetchFilters);
    _filterReq.stopIndex = nbrOfReceipts || rowsPerPage;
    _filterReq.startIndex = 1;
    const { data: { Item1: _receipts } } = await webApi.post<{ Item1: Receipt[], Item2: number}>('/receipt', _filterReq);

    const _tdata: TableRowType[] = _receipts.map(({ CreatedByName, DeviceName, Duration, StartedAt, Routine, Id, Status }) => {
      return {
        key: Id,
        data: {
          timestamp: StartedAt ? formatDate(StartedAt.toString()) : '0',
          deviceName: DeviceName,
          routineName: Routine.Name,
          duration: Duration ? Duration.toString() + 's' : '',
          confirmed: Translate.RoutineStatus[Status],
          createdBy: CreatedByName
        }
      };
    });
    return _tdata;
  };

  const dangerZoneUnlockText = `${currentRoutine?.Name.toLocaleLowerCase()}`.replace(/\s/g, '-');
  return (
    <>
      <Layout.Wrapper>
        <FlexRow>
          <StyledSection minHeight='15rem'>
            <RoutinePane routine={currentRoutine} loading={routineLoading} numberOfDevices={memorizedDevices.length} />
          </StyledSection>
          <StyledSection minHeight='15rem'>
            <DeviceRoutineList devices={memorizedDevices} loading={fetchingDevices} heading='Active devices' />
          </StyledSection>
        </FlexRow>
        <FlexRow>
          <StyledSection minHeight='15rem'>
            <ReceiptTable hideRoutineFilter exportFnc={getExcelData} receiptsPerPage={rowsPerPage} receipts={receipts?.filter(receipt => (receipt.Routine && currentRoutine) && receipt.Routine.Id === currentRoutine.Id)} loading={receiptsLoading && routineLoading} key='receipts' />
          </StyledSection>
        </FlexRow>
        <Layout.Row>
          <Layout.Section>
            <DangerZone unlockText={dangerZoneUnlockText} placeholder={`Type ${dangerZoneUnlockText} to unlock danger zone`} infoPopupText='Dangerous actions'>
              <DangerZoneItem
                onClick={() => { setShowDeleteRoutineModal(true); }}
                icon={<Lock />}
                buttonText='Delete routine'
                headerText={`Delete routine ${currentRoutine?.Name}`}
                detailsText='This action can not be reversed'
              />
            </DangerZone>
            <ConfirmModal
              open={showDeleteRoutineModal}
              onConfirm={onConfirmDeleteRoutine}
              onCancel={() => { setShowDeleteRoutineModal(false); }}
              header={`Delete routine ${currentRoutine?.Name}`}
              content='Do you really want to delete the routine? This action can not be reversed.'
            />
          </Layout.Section>
        </Layout.Row>
      </Layout.Wrapper>
    </>
  );
};
export default RoutinePage;
