import { useMemo } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';

export default function useDropdownOptions(values:string[]):DropdownItemProps[] {
  const items = useMemo(() => {
    const dropdownValues: DropdownItemProps[] = values.map((v: string) => (
      {
        key: v,
        text: v,
        value: v
      }
    ));

    dropdownValues.unshift({
      key: 0,
      text: 'All',
      value: 'All'
    });
    return dropdownValues;
  }, [values]);

  return items;
}
