import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { addDevice, getDistinctColBValues, getDistinctColCValues } from 'Store/Actions/deviceActions';
import { columnBName, columnCName } from '../../Common/messages';
import { ModalTypes } from '../../Common/Enums';
import { sizes } from '../../Common/theme';
import { toggleModal, getLicense } from 'Store/Actions/appActions';
import { Form, Button, FormInput, Search, Layout, Modal } from 'access_ctrl-ui';
import { AxiosResponse } from 'axios';
import { Device } from 'Interfaces';
import { SearchValues } from 'access_ctrl-ui/dist/Components/ThemedSearch';
import { RootState } from 'Store/Reducers';
import styled from 'styled-components';
import { ThemedCssProps } from 'Interfaces/ThemedCssProps';
import { useAppSelector } from 'Store/hooks';

const StyledContent = styled(Layout.Modal.Wrapper)`
  padding: ${({ theme }) => theme.sizes.md};
  font-family: ${({ theme }) => theme.fontBase};
  font-size: 16px;
`;

const Error = styled.p<ThemedCssProps>`
  color: ${({ theme }) => theme.textColorError};
`;

const defaultDevice = {
  Id: '',
  Name: '',
  B: '',
  C: ''
};

const validateDeviceName = (value?: string) => {
  if (value && value.length < 2) {
    return 'Error: Device name needs to be at least two characters';
  } else if (!value || value === '') {
    return 'Error: This field is required';
  }
  return false;
};

const devicesHasDuplicate = (deviceName: string, devices: Device[]) => {
  return devices.some((device) => device.Name.toLowerCase() === deviceName.toLowerCase());
};
interface Props {
  open: boolean;
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
  devices: Device[];
  addDevice: (device: Device) => Promise<void | AxiosResponse>;
  addingDeviceItem: boolean;
  columnBValues: string[];
  fetchingColBValues: boolean;
  columnCValues: string[];
  fetchingColCValues: boolean;
  getDistinctColBValues: () => Promise<void>;
  getDistinctColCValues: () => Promise<void>;
  allDevices:number;
}
export const AddDeviceModal: React.FC<Props> = ({
  open,
  toggleModal,
  devices,
  addDevice,
  addingDeviceItem,
  columnBValues,
  fetchingColBValues,
  columnCValues,
  fetchingColCValues,
  getDistinctColBValues,
  getDistinctColCValues,
  allDevices
}) => {
  const [newDevice, setNewDevice] = useState(defaultDevice);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const history = useHistory();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const requestClose = () => {
    setShowConfirmModal(true);
  };

  const onCloseConfirm = () => {
    setShowConfirmModal(false);
    toggleModal(ModalTypes.ADD_DEVICE_MODAL, false);
    setNewDevice(defaultDevice);
  };
  const [canAddDevice, setCanAddDevice] = useState(true);
  const dispatch = useDispatch();
  const { license } = useAppSelector(state => state.app);

  useEffect(() => {
    if (license) {
      if (license.Devices <= allDevices) {
        setCanAddDevice(false);
      } else if (canAddDevice !== true) {
        setCanAddDevice(true);
      }
    }
  }, [license, devices]);

  useEffect(() => {
    if (!license) {
      getLicense(dispatch)();
    }
  }, []);

  useEffect(() => {
    if (open) {
      getDistinctColBValues();
    }
  }, [getDistinctColBValues, open]);

  useEffect(() => {
    if (open) {
      getDistinctColCValues();
    }
  }, [getDistinctColCValues, open]);

  useEffect(() => {
    if (newDevice.Name) {
      setIsDuplicate(devicesHasDuplicate(newDevice.Name, devices ?? []));
    }
  }, [newDevice, devices]);

  const onSubmit = () => {
    if (newDevice.Name.length > 0) {
      addDevice(newDevice).then((response) => {
        if (response && response.data) {
          history.push(`/devices/${response.data.Id}`);
          onCloseConfirm();
        }
      });
    }
  };
  // Todo: Improve this
  const onInputUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setNewDevice({
      ...newDevice,
      [name]: value
    });
  };
  // Todo: And this
  const onSearchUpdate = (value: string, event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const { name } = event.currentTarget;
      if (name) {
        setNewDevice({
          ...newDevice,
          [name]: value
        });
      }
    }
  };
  const onResultSelect = (items: SearchValues, name?: string) => {
    if (name) {
      setNewDevice({
        ...newDevice,
        [name]: items.name
      });
    }
  };
  return (
    <Modal
      open={open}
      onClose={requestClose}
      confirmClose={onCloseConfirm}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      closeIcon
      size='small'
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>Add device</Layout.Modal.Header>
        {!canAddDevice
          ? (
            <>
              <StyledContent>Can not add new device, <Link to='/settings' onClick={onCloseConfirm}>license</Link> limit reached. Contact Åkerströms if you want to upgrade the license</StyledContent>
            </>
          )
          : (
            <>
              <Layout.Modal.Content>
                <Form onSubmit={onSubmit}>
                  <Layout.Row padding={sizes.md} spacing={sizes.md}>
                    <FormInput
                      name='Name'
                      value={newDevice.Name}
                      onChange={onInputUpdate}
                      label='Device Name'
                      fluid
                      required
                      validationFn={validateDeviceName}
                    />
                    <Search
                      onChange={onSearchUpdate}
                      label={columnBName}
                      name='B'
                      value={newDevice.B}
                      searchValues={columnBValues}
                      fetchingSource={fetchingColBValues}
                      onResultSelect={onResultSelect}
                      noResultsMessage={`No ${columnBName.toLowerCase()} found.`}
                      noResultsDescription={`Will add a new ${columnBName.toLowerCase()} with this name`}
                      fluid
                    />
                    <Search
                      onChange={onSearchUpdate}
                      label={columnCName}
                      name='C'
                      value={newDevice.C}
                      searchValues={columnCValues}
                      fetchingSource={fetchingColCValues}
                      onResultSelect={onResultSelect}
                      noResultsMessage={`No ${columnCName.toLowerCase()} found.`}
                      noResultsDescription={`Will add a new ${columnCName.toLowerCase()} with this name`}
                      fluid
                    />
                  </Layout.Row>
                  {isDuplicate && (
                    <Layout.Row padding={sizes.md}>
                      <Error>Device already exists</Error>
                    </Layout.Row>
                  )}
                  <Layout.Modal.ButtonContainer>
                    <Button disabled={newDevice.Name.length < 2} loading={addingDeviceItem}>
                      Create
                    </Button>
                  </Layout.Modal.ButtonContainer>
                </Form>
              </Layout.Modal.Content>
            </>
          )}
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default connect(
  ({ modalsOpen, device }: RootState) => ({
    devices: device.devices,
    open: modalsOpen[ModalTypes.ADD_DEVICE_MODAL],
    columnBValues: device.columnBValues,
    fetchingColBValues: device.fetchingColBValues,
    columnCValues: device.columnCValues,
    fetchingColCValues: device.fetchingColCValues,
    addingDeviceItem: device.addingDeviceItem,
    allDevices: device.count
  }),
  (dispatch) => ({
    toggleModal: toggleModal(dispatch),
    addDevice: addDevice(dispatch),
    getDistinctColBValues: getDistinctColBValues(dispatch),
    getDistinctColCValues: getDistinctColCValues(dispatch)
  })
)(AddDeviceModal);
