import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { sizes } from '../Common/theme';
import { columnBName, columnCName } from '../Common/messages';

import { Accordion, Input, Button, Layout } from 'access_ctrl-ui';

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.sizes.md};
`;

const FilterWrapper = styled.form`
  & input,
  & div.dropdown {
    margin-top: ${({ theme }) => theme.sizes.sm};
  }
`;

interface Filter {
  deviceName?: string[] | string;
  columnB?: string[] | string;
  columnC?: string[] | string;
}
const initialFilter: Filter = {
  deviceName: '',
  columnB: '',
  columnC: ''
};
interface Props {
  activated: boolean;
}

const DevicesFilter: React.FC<Props> = ({ activated }) => {
  const [filter, setFilter] = useState<Filter>(initialFilter);
  const delayedUpdateParams = useRef(
    _.debounce((key, value, location) => updateParams(key, value, location), 200)
  ).current;

  const location = useLocation();
  const history = useHistory();

  const updateParams = (key: string, value: string, location: string) => {
    const query = new URLSearchParams(location);

    if (value === '') {
      query.delete(key);
    } else {
      query.set(key, value);
    }
    query.delete('activePage');
    history.replace({ ...history.location, search: query.toString() });
  };

  useEffect(() => {
    const { deviceName, columnB, columnC } = queryString.parse(location.search);
    const newFilter: Filter = {
      deviceName: deviceName || ([] as string[]),
      columnB: columnB || ([] as string[]),
      columnC: columnC || ([] as string[])
    };

    setFilter(newFilter);
  }, [location.search]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const key = e.currentTarget.name;
    setFilter(filter => ({
      ...filter,
      [key]: value
    }));
    delayedUpdateParams(key, value, location.search);
  };

  // Filter out non filter params and keep them
  const handleParams = (allParams: string[]):string => {
    const urlParams = new URLSearchParams(history.location.search);
    let filteredParams = '';
    urlParams.forEach((value, name) => {
      if (!allParams.includes(name)) {
        filteredParams += `${name}=${value}`;
      }
    });
    return filteredParams;
  };
  // Clear filter, while keeping location params, such as powerAdminTab
  const handleClearFilter = () => {
    if (filter) {
      const paramsToKeep = handleParams(Object.keys(filter));
      setFilter(initialFilter);
      history.replace({ ...history.location, search: paramsToKeep });
    }
  };

  return (
    <Accordion title='Filter' fluid activated={activated}>
      <FilterWrapper>
        <ButtonRow>
          <Button type='button' onClick={handleClearFilter}>Clear filter</Button>
        </ButtonRow>
        <Layout.Row spacing={sizes.md}>
          <Input
            placeholder='Name'
            value={filter?.deviceName}
            name='deviceName'
            onChange={handleFilterChange}
            fluid
          />
          <Input
            placeholder={columnBName}
            value={filter?.columnB}
            name='columnB'
            onChange={handleFilterChange}
            fluid
          />
          <Input
            placeholder={columnCName}
            value={filter?.columnC}
            name='columnC'
            onChange={handleFilterChange}
            fluid
          />
        </Layout.Row>
      </FilterWrapper>
    </Accordion>
  );
};

export default DevicesFilter;
