import { Layout, TableRowType } from 'access_ctrl-ui';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import RoutinesPane from './RoutinesPane';
import ReceiptTable from 'Features/Routines/Receipts/ReceiptTable/ReceiptTable';
import { getRoutines } from 'Features/Routines/routineSlice';
import { getReceiptsWithFilter } from 'Features/Routines/Receipts/receiptSlice';
import { useAppSelector } from 'Store/hooks';
import { getAllDevices } from 'Store/Actions/deviceActions';
import DeviceRoutineList from '../DeviceRoutineList';
import useMissingRoutines from 'Features/Routines/Hooks/useMissingRoutines';
import { Receipt, ReceiptFilter } from 'Features/Routines/Receipts/Interfaces';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { ReceiptsOrderByColumn } from 'Common/Enums/ReceiptsOrderByColumn';
import { RoutineStatus, Translate } from 'Features/Routines/Interfaces';
import { getLicense, setCurrentPage } from 'Store/Actions/appActions';
import FeatureDisabled from 'Components/Pages/Feature';
import _ from 'lodash';
import { formatDate } from 'Util/formatDate';
import { webApi } from 'Common/api';
import { features } from 'Common/Constant/Features';
const StyledSection = styled(Layout.Section)`
  padding: 1rem;
`;

const rowsPerPage = 6;
const initReceiptFilter: ReceiptFilter = {
  startIndex: 0,
  stopIndex: rowsPerPage - 1
};
const RoutinesPage: React.FC = () => {
  const { receipts, loading: recieptsLoading, nbrOfReceipts } = useAppSelector(state => state.receipts);
  const { routines, loading: routinesLoading } = useAppSelector(state => state.routine);
  const { fetchingDevices } = useAppSelector(state => state.device);
  const [fetchFilters, setFetchFilters] = useState<ReceiptFilter>(initReceiptFilter);
  const missingRoutines = useMissingRoutines();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPage, setShowPage] = useState(false);
  const { license } = useAppSelector(state => state.app);
  /**
   * Set filters for fetching data
   */
  const setFilterFromParams = useCallback(() => {
    const params = queryString.parse(location.search);

    const activePage = +(params.activePage || 1);
    const startIndex = (+activePage - 1) * rowsPerPage;
    const stopIndex = activePage * rowsPerPage - 1;

    setFetchFilters({
      userIds: params.createdBy?.toString().split(',') ?? undefined,
      deviceIds: params.deviceId?.toString().split(',') ?? undefined,
      from: params.timestamp_from ? new Date(params.timestamp_from as string) : null,
      to: params.timestamp_to ? new Date(params.timestamp_to as string) : null,
      startIndex: startIndex,
      stopIndex: stopIndex,
      status: params.confirmed?.toString().split(',') as unknown as RoutineStatus[] ?? undefined,
      routineIds: params.routineIds?.toString().split(',') ?? undefined,
      duration: params.duration ? parseInt(params.duration.toString()) : undefined,
      orderBy: params.orderBy?.toString() as unknown as ReceiptsOrderByColumn ?? undefined
    });
  }, [location]);

  useEffect(() => {
    setCurrentPage(dispatch)({ name: 'Routines' });
  }, [setCurrentPage, dispatch]);

  /**
   * SetFilterFromParams
   */
  useEffect(() => {
    setFilterFromParams();
  }, [setFilterFromParams]);

  useEffect(() => {
    if (license) {
      for (const [key, value] of Object.entries(license.Features)) {
        if (value.Enabled === true && key.toLowerCase() === features.dailyInspection) {
          setShowPage(true);
        }
      }
    }
  }, [license]);
  useEffect(() => {
    if (!license) {
      getLicense(dispatch)();
    }
  }, [license, dispatch]);
  /**
   * fetch routines, receipts and devices
  */
  useEffect(() => {
    getAllDevices(dispatch)(false, true);
  }, [dispatch]);

  useEffect(() => {
    dispatch(getReceiptsWithFilter(fetchFilters));
  }, [fetchFilters, dispatch]);

  useEffect(() => {
    if (!routines) {
      dispatch(getRoutines());
    }
  }, [dispatch, routines]);

  /**
   * Fetching all data when downloading excel data sheet
   * @returns TableRows as a promise
   */
  const getExcelData = async () => {
    const _filterReq = _.clone(fetchFilters);
    _filterReq.stopIndex = nbrOfReceipts || rowsPerPage;
    _filterReq.startIndex = 1;
    const { data: { Item1: _receipts } } = await webApi.post<{ Item1: Receipt[], Item2: number}>('/receipt', _filterReq);

    const _tdata: TableRowType[] = _receipts.map(({ CreatedByName, DeviceName, Duration, StartedAt, Routine, Id, Status }) => {
      return {
        key: Id,
        data: {
          timestamp: StartedAt ? formatDate(StartedAt.toString()) : '0',
          deviceName: DeviceName,
          routineName: Routine.Name,
          duration: Duration ? Duration.toString() + 's' : '',
          confirmed: Translate.RoutineStatus[Status],
          createdBy: CreatedByName
        }
      };
    });
    return _tdata;
  };

  return (
    <Layout.Wrapper>
      {
        showPage
          ? (
            <>
              <Layout.Row>
                <StyledSection minHeight='15rem'>
                  <RoutinesPane routines={routines} loading={routinesLoading} />
                </StyledSection>
                <StyledSection minHeight='15rem'>
                  <DeviceRoutineList heading='Devices with no routines' devices={missingRoutines} loading={fetchingDevices} hideEditButton />
                </StyledSection>
              </Layout.Row>
              <Layout.Row>
                <StyledSection minHeight='15rem'>
                  <ReceiptTable receipts={receipts} loading={recieptsLoading} key='receipts' receiptsPerPage={rowsPerPage} exportFnc={getExcelData} />
                </StyledSection>
              </Layout.Row>
            </>
          )
          : (
            <>
              <FeatureDisabled name='Daily Inspection' />
            </>
          )
      }
    </Layout.Wrapper>
  );
};
export default RoutinesPage;
