export const statusMessages = {
  POST_LOGITEM_SUCCESS: 'Log item saved',

  DELETE_DEVICE_SUCCESS: 'Device deleted successfully',
  DEVICE_RESTARTING: 'Device Rebooting...',
  DEVICE_UPDATING: 'Device Updating...',
  SOFTWARE_RESTARTING: 'Software Restarting...',
  ADD_DEVICE_SUCCESS: 'Device added successfully',
  ADD_DEVICE_RECORD_SUCCESS: 'Device record added successfully',
  UPDATE_DEVICE_SUCCESS: 'Device updated successfully',

  REMOVED_IDENTITY_TOKEN: 'Removed identity token from user',
  REMOVED_IDENTITY_TOKENS: 'Removed identity tokens from user',
  ADDED_IDENTITY_TOKEN: 'Added identity token to user',

  USER_ADDED: 'User added successfully',
  USER_DELETED: 'User deleted successfully',
  USER_UPDATED: 'User updated successfully',
  USER_ACCESS_CHANGED: 'User access changed successfully',

  LICENSE_ADDED: 'License added successfully',

  ROUTINE_ADDED: 'Routine added successfully',
  ROUTINE_DELETED: 'Routine deleted successfully',
  ROUTINE_UPDATED: 'Routine updated successfully',

  OPTIONS_UPDATED: 'Options updated successfully'
};

export const loadingMessages = {
  LOADING_DEVICES_TABLE: 'Loading devices',
  LOADING_DEVICE_ITEM: 'Loading device',
  CHECKING_RFID_TAG: 'Checking tag',
  LOADING_AUDIT_LOGS: 'Loading audit logs',
  LOADING_USER: 'Loading user',
  LOADING_USERS: 'Loading users',
  LOADING_USER_IDENTITIES: 'Loading user identities',
  LOADING_USER_DEVICES: 'Loading user devices',
  LOADING_USER_PROFILE: 'Loading user profile',
  LOADING_MAP: 'Loading map',
  LOADING_DEVICE_OPTIONS: 'Loading options for device',
  LOADING_USER_OPTIONS: 'Loading options for user'
};

export const apiCallErrorMessages = {
  ADD_DEVICE_RECORD_ERROR_MESSAGE: 'Error adding device record: ',
  ADD_DEVICE_ERROR_MESSAGE: 'Error adding device: ',
  ADD_IDENTITY_TOKEN_ERROR_MESSAGE: 'Error adding identity: ',
  ADD_USER_ERROR_MESSAGE: 'Error creating user: ',
  DELETE_DEVICE_ERROR_MESSAGE: 'Error deleting device: ',
  DELETE_IDENTITY_TOKEN_ERROR_MESSAGE: 'Error deleting identity: ',
  DELETING_USER_ERROR_MESSAGE: 'Error deleting user: ',
  FAILED_CHANGING_USER_ACCESS: 'Error updating user access: ',
  FAILED_EDITING_DEVICE_OPTION_ERROR_MESSAGE: 'Failed setting device option: ',
  FAILED_EDITING_USER_OPTION_ERROR_MESSAGE: 'Failed setting user option: ',
  FAILED_GETTING_DEBUG_MESSAGES_ERROR_MESSAGE: 'Error gettig debug messages: ',
  FAILED_GETTING_DISTINCT_COL_B_VALUES_ERROR_MESSAGE:
    'Failed getting distinct values for autocomplete: ',
  FAILED_GETTING_DISTINCT_COL_C_VALUES_ERROR_MESSAGE:
    'Failed getting distinct values for autocomplete: ',
  FAILED_GETTING_LOGGED_IN_USER_INFO_ERROR_MESSAGE:
    'Error getting information about logged in user: ',
  FETCHING_ALL_DEVICES_ERROR_MESSAGE: 'Error fetching all devices: ',
  FETCHING_ALL_USERS_ERROR_MESSAGE: 'Error fetching all users: ',
  FETCHING_DEVICES_FOR_USER_ERROR_MESSAGE: 'Error fetching devices for user: ',
  FETCHING_DEVICE_OPTIONS_ERROR_MESSAGE: 'Failed getting device options: ',
  FETCHING_DEVICE_STATUS_ERROR: 'Could not get device status:',
  FETCHING_EXCEL_AUDIT_LOG_ERROR_MESSAGE:
    'Error getting audit log data for Excel: ',
  FETCHING_FILTERED_AUDIT_LOG_ERROR_MESSAGE: 'Error filtering audit log: ',
  FETCHING_OPERATION_LOG_ERROR_MESSAGE: 'Error fetching operation log: ',
  FETCHING_OPERATION_LOG_NOT_FOUND: 'Operation log not found',
  FETCHING_SINGLE_DEVICE_ERROR_MESSAGE: 'Error fetching single device: ',
  FETCHING_SINGLE_DEVICE_NOT_FOUND: 'Device not found',
  FETCHING_USER_OPTIONS_ERROR_MESSAGE: 'Failed getting user options: ',
  GET_USER_FOR_TAG_ERROR_MESSAGE: 'Error fetching user for tag: ',
  LOGIN_ERROR_MESSAGE: 'Error logging in: ',
  LOGOUT_ERROR_MESSAGE: 'Error logging out: ',
  POST_LOGITEM_ERROR_MESSAGE: 'Could not save data: ',
  UPDATE_DEVICE_ERROR_MESSAGE: 'Error uppdating device: ',
  UPDATE_USER_ERROR_MESSAGE: 'Error uppdating user: ',
  USER_IDENTITIES_ERROR_MESSAGE: 'Error getting user identites: ',
  USER_INFO_ERROR_MESSAGE: 'Error getting user info: ',
  FAILED_ADDING_MULTIPLE_USERS_TO_DEVICE: 'Error updating device with multiple users',
  FAILED_DELETING_SETTING: 'Error deleting setting',
  FAILED_ADDING_LICENSE: 'Error adding License'

};

export const passwordValidationMessages = {
  PASSWORD_TOO_SHORT:
    'Password is too short, it must be minimum {0} characters',
  PASSWORD_TOO_LONG:
    'Password is too long, it cannot be longer than {0} characters',
  PASSWORD_BAD_CHARACTERS: 'Password contains bad characters',
  PASSWORD_NOT_COMPLEX_ENOUGH:
    'The password must contain at least three of the following groups: Upper case letters, Lower case letters, numbers and symbols',
  PASSWORD_INCLUDES_USERNAME: 'The password must not contain the username',
  REPEATED_PASSWORD_DIFFERENT: 'The passwords do not match'
};

export const clipBoardMessages = {
  TEXT_ADDED_TO_CLIPBOARD: 'Text copied to clipboard'
};

export const columnBName = process.env.REACT_APP_DEVICE_COLUMN_B_NAME
  ? process.env.REACT_APP_DEVICE_COLUMN_B_NAME
  : 'ColumnB';

export const columnCName = process.env.REACT_APP_DEVICE_COLUMN_C_NAME
  ? process.env.REACT_APP_DEVICE_COLUMN_C_NAME
  : 'ColumnC';
