import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Edit2 } from 'react-feather';
import { getDeviceForUser } from 'Store/Actions/userActions';
import { toggleModal, getLicense } from 'Store/Actions/appActions';
import { loadingMessages } from '../../../Common/messages';
import { ModalTypes } from '../../../Common/Enums';
import { IconTypes, HeaderRow, InfoPopup, Table, Button, TableLink, TableRowType } from 'access_ctrl-ui';
import { Device, User } from 'Interfaces';
import { RootState } from 'Store/Reducers';
import { useAppSelector } from 'Store/hooks';
import FeatureDisabled from '../Feature';
import { features } from 'Common/Constant/Features';

const tableDataColumns = [
  { name: 'Device', key: 'name' }
];
interface Props {
  userAdmin: boolean;
  currentUser: User;
  getDeviceForUser: (userId: string) => Promise<void>;
  fetchingUserDevices: boolean;
  currentUserDevices: Device[];
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
}
const DeviceAccessPane: React.FC<Props> = ({ userAdmin, currentUser, getDeviceForUser, currentUserDevices, fetchingUserDevices, toggleModal }) => {
  const [tableDataRows, setTableDataRows] = useState<TableRowType[]>([] as TableRowType[]);
  const [userHasAccess, setUserHasAccess] = useState(true);
  const [hasLicense, setHasLicense] = useState(false);
  const { license } = useAppSelector(state => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser && userAdmin) {
      getDeviceForUser(currentUser.Id);
      if (currentUser.Options) {
        let hasAccess = true;

        if (currentUser.Options['access.from'] && currentUser.Options['access.from'].Value) {
          hasAccess = new Date(currentUser.Options['access.from'].Value) < new Date();
        }

        if (hasAccess) {
          if (currentUser.Options['access.to'] && currentUser.Options['access.to'].Value) {
            hasAccess = new Date(currentUser.Options['access.to'].Value) > new Date();
          }
        }
        setUserHasAccess(hasAccess);
      }
    }
  }, [currentUser, getDeviceForUser, userAdmin]);

  useEffect(() => {
    if (currentUserDevices) {
      const sorted = currentUserDevices.sort((a, b) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase()));
      const _tData = sorted.filter(device => device).map((device) => {
        return {
          key: device.Id,
          successful: userHasAccess,
          data: {
            name: <TableLink successful={userHasAccess} to={`/devices/${device.Id}`} text={device.Name} iconType={IconTypes.Device} />
          }
        };
      });

      setTableDataRows(_tData);
    }
  }, [currentUserDevices, userHasAccess]);

  const onEditDevices = () => {
    toggleModal(ModalTypes.EDIT_USER_ACCESS_MODAL, true);
  };
  useEffect(() => {
    if (license) {
      for (const [key, value] of Object.entries(license.Features)) {
        if (value.Enabled === true && key.toLowerCase() === features.accessCtrl) {
          setHasLicense(true);
        }
      }
    }
  }, [license]);

  useEffect(() => {
    if (!license) {
      getLicense(dispatch)();
    }
  }, [license]);

  return (
    <>
      <HeaderRow>
        <h2>Device access</h2>
        <InfoPopup descriptionText='Devices this user has access to' />
        {
          (userAdmin && hasLicense) &&
            <Button onClick={onEditDevices}><Edit2 />Edit</Button>
        }
      </HeaderRow>
      {
        hasLicense
          ? (
            <>
              <Table
                rowsPerPage={5}
                tableDataColumns={tableDataColumns}
                tableDataRows={tableDataRows}
                emptyText='User has no access'
                setQueryString={false}
                loading={fetchingUserDevices}
                loadingMessage={loadingMessages.LOADING_USER_DEVICES}
              />
            </>
          )
          : (
            <>
              <FeatureDisabled name={features.accessCtrl} />
            </>
          )
      }
    </>
  );
};

export default connect(({ user }: RootState) => ({
  currentUserDevices: user.currentUserDevices,
  fetchingUserDevices: user.fetchingUserDevices
}), dispatch => ({
  toggleModal: toggleModal(dispatch),
  getDeviceForUser: getDeviceForUser(dispatch)
}))(DeviceAccessPane);
