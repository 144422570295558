
/**
  Actiontypes dispatched from operationLogActions
*/
export enum OperationLogActionType {
  FAILED_FETCHING_DEVICE_LOGS = 'FAILED_FETCHING_DEVICE_LOGS',
  FAILED_GETTING_OPERATION_LOG = 'FAILED_GETTING_OPERATION_LOG',
  FAILED_GETTING_OPERATION_LOG_HISTORY = 'FAILED_GETTING_OPERATION_LOG_HISTORY',
  FAILED_POST_LOGITEM = 'FAILED_POST_LOGITEM',
  FETCHING_DEVICE_LOGS = 'FETCHING_DEVICE_LOGS',
  FETCHING_OPERATION_LOG = 'FETCHING_OPERATION_LOG',
  FETCHING_OPERATION_LOG_HISTORY = 'FETCHING_OPERATION_LOG_HISTORY',
  GOT_DEVICE_LOGS = 'GOT_DEVICE_LOGS',
  GOT_OPERATION_LOG = 'GOT_OPERATION_LOG',
  GOT_OPERATION_LOG_HISTORY = 'GOT_OPERATION_LOG_HISTORY',
  POST_LOGITEM_START = 'POST_LOGITEM_START',
  POST_LOGITEM_SUCCESS = 'POST_LOGITEM_SUCCESS',
  OBLITERATE_LOGS_START = 'OBLITERATE_LOGS_START',
  OBLITERATE_LOGS_SUCCESS = 'OBLITERATE_LOGS_SUCCESS',
  OBLITERATE_DEVICE_LOGS_START = 'OBLITERATE_DEVICE_LOGS_START',
  OBLITERATE_DEVICE_LOGS_SUCCESS = 'OBLITERATE_DEVICE_LOGS_SUCCESS',
}
