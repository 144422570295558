import { TableLink, TableRowType } from 'access_ctrl-ui';
import { useEffect, useState } from 'react';
import { getLocalTimeFromUtc } from 'Util/formatDate';
import { DailyRoutine, RoutineType, DayType } from '../Interfaces';

/**
 * Get table rows from routines
 * @param {Routine[]} routines - to get rows from
 * @returns {TableRowType[]} - a array of rows mapped from rows
 */
const useGetRoutineRows = (routines: DailyRoutine[]|undefined):TableRowType[] => {
  const [rows, setRows] = useState<TableRowType[]>([]);
  const getFrequency = (days: DayType[]) => {
    if ((['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as DayType[]).every(day => days?.includes(day))) {
      return 'Everyday';
    }
    if ((['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'] as DayType[]).every(day => days?.includes(day))) {
      return 'Workdays';
    }
    if ((['Saturday', 'Sunday'] as DayType[]).every(day => days?.includes(day))) {
      return 'Weekends';
    }
    return 'Custom';
  };
  useEffect(() => {
    const makeRows = async () => {
      if (!routines) {
        return;
      }

      const newRows = routines.map(routine => {
        const { Id, Name, Days, Times, Type } = routine;
        return {
          key: Id || '',
          data: {
            routine: <TableLink to={`routines/${Id}`} text={Name} />,
            frequency: getFrequency(Days),
            trigger: (Times)?.map(x => getLocalTimeFromUtc(x))?.join(', ') ?? [],
            type: Type === RoutineType.DailyInspection ? 'Daily Inspection' : ''
          }
        };
      });
      setRows(newRows.filter(x => x.key !== ''));
    };
    makeRows();
  }, [routines]);
  return rows;
};
export default useGetRoutineRows;
