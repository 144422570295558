export enum ReceiptsOrderByColumn {
  Undefined = 0,
  InventoryNameAsc = 1,
  InventoryNameDesc = 2,
  CreatedByNameAsc = 4,
  CreatedByNameDesc = 8,
  TimestampAsc = 16,
  TimestampDesc = 32,
  RoutinesNameAsc = 64,
  RoutinesNameDesc = 128,
  DurationAsc = 256,
  DurationDesc = 512,
  StatusAsc = 1024,
  StatusDesc = 2048,
  RoutineTypeAsc = 4096,
  RoutineTypeDesc = 8192,
}
