import { Device } from 'Interfaces';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'Store/hooks';
import { RoutinesOptionKey } from '../Interfaces';
export default function useMissingRoutines() {
  const { devices } = useAppSelector(state => state.device);
  const { routines } = useAppSelector(state => state.routine);
  const [missing, setMissing] = useState<Device[]>([]);
  useEffect(() => {
    if (devices) {
      const missingRoutines = devices?.filter(device => !device?.Options || !device?.Options?.[RoutinesOptionKey]) ?? [];
      setMissing(missingRoutines);
    }
  }, [devices, routines]);
  return missing;
}
