import { Checkbox, CheckboxProps } from 'access_ctrl-ui';
import { Days, DayType } from 'Features/Routines/Interfaces';
import { FormEvent } from 'react';
import styled from 'styled-components';

interface Props {
  onCheck: (_:FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
  days: DayType[]
}
const StyledCheckBox = styled(Checkbox)`
  margin: 1em;
`;
const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0;

  div.ui.checkbox {
    margin-left: 0;
  }
`;
const DaySelector:React.FC<Props> = ({ onCheck, days }) => {
  return (
    <StyledRow>
      {Object.values(Days).map(day =>
        <StyledCheckBox label={day} key={day} onChange={onCheck} checked={!!days?.find(x => x === day)} value={day} />)}
    </StyledRow>
  );
};
export default DaySelector;
