import { JTokenType } from './Enums';
import { Option } from 'Interfaces';

interface IDefaultUserOptions {
  [key: string]: Option
}

const defaultUserOptions: IDefaultUserOptions = {
  'access.from': { TokenType: JTokenType.Date, DefaultFrontendValue: null },
  'access.to': { TokenType: JTokenType.Date, DefaultFrontendValue: null }
};

export default defaultUserOptions;
