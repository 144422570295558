import { Input, Button } from 'access_ctrl-ui';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLicense } from 'Store/Actions/appActions';

const PostKey = () => {
  const [key, setKey] = useState<string>('');
  const dispatch = useDispatch();
  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (key) {
      setLicense(dispatch)(key);
      setKey('');
    }
  };
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKey(event.target.value);
  };
  return (
    <>
      <form onSubmit={submitHandler}>
        <div>
          <Input
            name='Key'
            value={key}
            placeholder='Key'
            onChange={changeHandler}
          />
        </div>
        <br />
        <Button type='submit'>Update License</Button>
      </form>
    </>
  );
};
export default PostKey;
