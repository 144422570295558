import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { Lock } from 'react-feather';
import styled from 'styled-components';

import { loadingMessages } from '../../../Common/messages';
import { deleteUser, fetchUserInfoToCurrentUser, resetAllUsers, selectUser } from '../../../Store/Actions/userActions';
import { setCurrentPage, clearRFIDReadTag } from '../../../Store/Actions/appActions';
import { permissions } from '../../../Common/Constant';

import { ConfirmModal, DangerZone, DangerZoneItem, Layout } from 'access_ctrl-ui';
import AuditLogPane from './AuditLogPane';
import DeviceAccessPane from './DeviceAccessPane';
import IdentitiesPane from './IdentitiesPane';
import UserInfoPane from './UserInfoPane';
import { RootState } from '../../../Store/Reducers';
import NoPermission from '../NoPermission';
import { breakpoints, StyledMedia as Media } from '../../Fresnel';
import { Page, User } from 'Interfaces';

const FlexRow = styled(Layout.Row)`
  flex-grow: 4;
`;

const FlexContainer = styled.div`
  display: flex;
  height: 100%;
  flex-flow: column;
  flex: 1;
`;
const StyledSection = styled(Layout.Section)`
  padding: 1rem;
`;
// TODO: Refactor to only use one user prop, also fix the use of any for promise
interface Props {
  currentUser: User | null;
  loggedInUser: User;
  fetchingLoggedInUser: boolean;
  selectUser: (user: User) => void;
  fetchUserInfoToCurrentUser: (userId: string) => Promise<void>;
  deleteUser: (userId: string) => Promise<void>;
  setCurrentPage: (pageInfo: Page) => void;
  clearRFIDReadTag: () => void;
  resetAllUsers: () => void;
}
type UserDetailsParams = {
  userId: string;
}
const UserDetails:React.FC<Props> = ({ currentUser, loggedInUser, fetchingLoggedInUser, selectUser, fetchUserInfoToCurrentUser, deleteUser, setCurrentPage, clearRFIDReadTag, resetAllUsers }) => {
  const history = useHistory();
  const { userId } = useParams<UserDetailsParams>();
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [viewUsersAction, setViewUsersAction] = useState(false);
  const [userAdminAction, setUserAdminAction] = useState(false);
  const [viewAuditLogAction, setViewAuditLogAction] = useState(false);
  const [adminAction, setAdminAction] = useState(false);

  const onConfirmDeleteUser = async () => {
    if (!currentUser) {
      return;
    }
    await deleteUser(currentUser.Id);
    setShowDeleteUserModal(false);
    resetAllUsers();
    history.push('/users');
  };

  useEffect(() => {
    return () => {
      setComponentLoaded(false);
    };
  }, []);
  useEffect(() => {
    setCurrentPage({ name: 'User details' });
  }, [setCurrentPage]);

  useEffect(() => {
    if (userId) {
      fetchUserInfoToCurrentUser(userId).then(() => {
        clearRFIDReadTag();
        setComponentLoaded(true);
      });
    }
  }, [userId, fetchUserInfoToCurrentUser, clearRFIDReadTag]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.Options && loggedInUser.Options['permissions.actions']) {
      const actions = JSON.parse(loggedInUser.Options['permissions.actions'].Value);

      setViewUsersAction((actions.includes(permissions.viewUsers) || actions.includes(permissions.userAdmin)) || actions.includes(permissions.all));

      setUserAdminAction(actions.includes(permissions.userAdmin) || actions.includes(permissions.all));

      setViewAuditLogAction(actions.includes(permissions.viewAuditLog) || actions.includes(permissions.all));

      setAdminAction(actions.includes(permissions.userAdmin) || actions.includes(permissions.all));
    } else if (loggedInUser) {
      setViewUsersAction(true);
      setViewAuditLogAction(true);
      setUserAdminAction(true);
      setAdminAction(true);
    }
  }, [loggedInUser, selectUser]);

  if ((!currentUser || !componentLoaded) || (fetchingLoggedInUser || !loggedInUser)) {
    return (
      <Loader active>{loadingMessages.LOADING_USER}</Loader>
    );
  }

  if (!viewUsersAction) {
    return (
      <NoPermission />
    );
  }

  const dangerZoneUnlockText = `${currentUser.Email.split('@')[0].toLocaleLowerCase()}`.replace(/\s/g, '-');

  return (
    <Layout.Wrapper>
      <Media greaterThanOrEqual='computer'>
        <FlexContainer>
          <FlexRow>
            <StyledSection minHeight='25rem'>
              <UserInfoPane userAdmin={userAdminAction} currentUser={currentUser} />
            </StyledSection>
            <StyledSection minHeight='25rem'>
              <DeviceAccessPane userAdmin={userAdminAction} currentUser={currentUser} />
            </StyledSection>
          </FlexRow>
          <FlexRow>
            <StyledSection minHeight='25rem'>
              <></>
            </StyledSection>
            <StyledSection minHeight='25rem'>
              <IdentitiesPane userAdmin={userAdminAction} currentUser={currentUser} />
            </StyledSection>
          </FlexRow>
        </FlexContainer>
      </Media>
      <Media at='mobile'>
        <FlexRow>
          <StyledSection flexGrow minWidth={`calc((${breakpoints.computer}px / 3) + 5px)`} minHeight='35rem'>
            <UserInfoPane userAdmin={userAdminAction} currentUser={currentUser} />
          </StyledSection>
          <StyledSection flexGrow minWidth={`calc((${breakpoints.computer}px / 3) + 5px)`} minHeight='35rem'>
            <DeviceAccessPane userAdmin={userAdminAction} currentUser={currentUser} />
          </StyledSection>
        </FlexRow>
        <FlexRow>
          <StyledSection flexGrow minWidth={`calc((${breakpoints.computer}px / 3) + 5px)`} minHeight='35rem'>
            <AuditLogPane viewAuditLogs={viewAuditLogAction} currentUser={currentUser} />
          </StyledSection>
          <StyledSection flexGrow minWidth={`calc((${breakpoints.computer}px / 3) + 5px)`} minHeight='35rem'>
            <IdentitiesPane userAdmin={userAdminAction} currentUser={currentUser} />
          </StyledSection>
        </FlexRow>
      </Media>
      {
        adminAction &&
          <Layout.Row>
            <Layout.Section>
              <DangerZone unlockText={dangerZoneUnlockText} placeholder={`Type ${dangerZoneUnlockText} to unlock danger zone`} infoPopupText='Dangerous actions'>
                <DangerZoneItem
                  onClick={() => { setShowDeleteUserModal(true); }}
                  icon={<Lock />}
                  buttonText='Delete user'
                  headerText={`Delete user ${currentUser.FirstName} ${currentUser.LastName}`}
                  detailsText='This action can not be reversed'
                />
              </DangerZone>
              <ConfirmModal
                open={showDeleteUserModal}
                onConfirm={onConfirmDeleteUser}
                onCancel={() => { setShowDeleteUserModal(false); }}
                header={`Delete user ${currentUser.FirstName} ${currentUser.LastName}?`}
                content='Do you really want to delete the user? This action can not be reversed.'
              />
            </Layout.Section>
          </Layout.Row>
      }
    </Layout.Wrapper>
  );
};

export default connect(({ app, user }: RootState) => (
  {
    currentUser: user.currentUser,
    loggedInUser: app.loggedInUser,
    fetchingLoggedInUser: app.fetchingLoggedInUser
  }
), dispatch => ({
  selectUser: selectUser(dispatch),
  fetchUserInfoToCurrentUser: fetchUserInfoToCurrentUser(dispatch),
  deleteUser: deleteUser(dispatch),
  resetAllUsers: resetAllUsers(dispatch),
  setCurrentPage: setCurrentPage(dispatch),
  clearRFIDReadTag: clearRFIDReadTag(dispatch)
}))(UserDetails);
