import React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledDropdown = styled(Dropdown)`
  background: ${({ theme }) => theme.appBg} !important;
  border: 2px solid ${({ theme }) => theme.inputBorder} !important;
  border-radius: ${({ theme }) => theme.inputBorderRadius};
  color: ${({ theme }) => theme.textColor} !important;
  min-height: auto !important;
  position: relative;
  min-width: 250px;
  width: 100%;

  input: {
    display:none;
  }

  span.sizer {
    display: none;
    white-space: pre;
  }

  &&& i.dropdown.icon {
    color: ${({ theme }) => theme.colorPrimary} !important;
    float: right;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 1em;
    margin-right: 1em;
  }

  input {
    color: ${({ theme }) => theme.textColor} !important;
  }

  a.label {
    background: #565659 !important;
    border-radius: 100px !important;
    font-weight: 400 !important;
  }

  a {
    color: ${({ theme }) => theme.textColor} !important;
  }

  div.menu {
    background: ${({ theme }) => theme.appSecondaryBg} !important;
    color: ${({ theme }) => theme.textColor} !important;
    border: 0 !important;
    width: 100% !important;
    max-height: 15rem !important;

    div.message {
      color: ${({ theme }) => theme.textColor} !important;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colorPrimary};
    }

    &::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  div.item {
    color: ${({ theme }) => theme.textColor} !important;
    border-top: 0 !important;
    appearance: none;

    :hover {
      background: ${({ theme }) => theme.colorSecondary} !important;
    }
  }

  div.item.selected {
    background: ${({ theme }) => theme.colorSecondary} !important;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customLabel: any = (label: DropdownItemProps) => ({
  content: `${label.text}`
});

interface ListProps extends DropdownProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}
const DropdownList: React.FC<ListProps> = (props) => {
  return (
    <StyledDropdown
      renderLabel={customLabel}
      {...props}
    />
  );
};

export default DropdownList;
