import { ThemeTypes } from 'access_ctrl-ui';
import {
  Sun as SunIcon,
  Moon as MoonIcon
} from 'react-feather';
import { connect } from 'react-redux';
import { changeTheme } from 'Store/Actions/appActions';
import { RootState } from 'Store/Reducers';
import styled from 'styled-components';
import { User } from 'Interfaces';
import React from 'react';

const StyledSunIcon = styled(SunIcon)`
  stroke: ${({ theme }) => theme.colorPrimary};
  width: 1.763rem;
  cursor: pointer;
  margin-bottom: 5px;
`;
const StyledMoonIcon = styled(MoonIcon)`
  stroke: ${({ theme }) => theme.colorPrimary};
  width: 1.763rem;
  cursor: pointer;
  margin-bottom: 5px;
`;

const StyledThemedToggle = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 5px;
`;

interface Props {
  theme: ThemeTypes,
  loggedInUser: User,
  changeTheme: (userId: string, theme: ThemeTypes) => Promise<void> | undefined
}

const ThemeToggle: React.FC<Props> = ({ theme, loggedInUser, changeTheme }) => {
  const isDarkTheme = theme === ThemeTypes.DARK;
  const onClick = () => {
    if (isDarkTheme) {
      changeTheme(loggedInUser?.Id, ThemeTypes.LIGHT);
    } else {
      changeTheme(loggedInUser?.Id, ThemeTypes.DARK);
    }
  };

  return (
    <StyledThemedToggle onClick={onClick}>{isDarkTheme ? <StyledSunIcon /> : <StyledMoonIcon />}</StyledThemedToggle>
  );
};

export default connect(
  ({ app }: RootState) => ({
    theme: app.theme,
    loggedInUser: app.loggedInUser
  }),
  (dispatch) => ({
    changeTheme: changeTheme(dispatch)
  })
)(ThemeToggle);
