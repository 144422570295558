import { Datepicker } from 'access_ctrl-ui';
import { TriggerTime } from 'Features/Routines/Interfaces';
import { useMemo, useState } from 'react';
import { X } from 'react-feather';
import styled from 'styled-components';
import { getLocalTimeFromUtc } from 'Util/formatDate';

const StyledTime = styled.li`
  &&& {
    color: ${({ theme }) => theme.textColor};
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    vertical-align: top;
    white-space: normal;
    font-size: 1em;
    padding: 0.35714286em 0.78571429em;
    margin: 0.14285714rem 0.28571429rem 0.14285714rem 0;
    box-shadow: 0 0 0 1px rgb(34 36 38 / 15%) inset;
    text-align: left;
    background: #565659;
    border-radius: 100px;
    font-weight: 400;
    cursor: pointer;

    svg {
      opacity: 0.5;
      stroke-width: 6;
      cursor: pointer;
      margin-right: 0;
      margin-left: 0.3em;
      transition: background 0.1s ease;
      width: auto;
      display: inline-block;
      height: 1em;
      font-style: normal;
      font-weight: 400;
      text-decoration: inherit;
      text-align: center;
      speak: none;
      backface-visibility: hidden;
      user-select: none;
      white-space: normal;
      line-height: 1;
      text-transform: none;
    }

    span {
      opacity: 0.5;
    }

    :hover {
      &&& span,
      svg {
        opacity: 1;
      }
    }
  }
`;
const StyledList = styled.ul`
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-block-start: 0;
  padding-inline-start: 0;

  & li:first-of-type {
    margin-left: 0;
  }
`;
const StyledContainer = styled.div`
  width: 150px;
  max-width: 100%;
`;
interface Props {
  onSelectDate: (time:TriggerTime) => void;
  onRemove: (time:TriggerTime) => void;
  times: TriggerTime[];
}
const TimeSelector:React.FC<Props> = ({ onSelectDate, onRemove, times }) => {
  const [date, setDate] = useState<Date>();
  const onDateChange = (_: React.SyntheticEvent<HTMLInputElement, Event> | undefined, selected: Date | null) => {
    if (selected) {
      setDate(selected);
      onSelectDate(`${selected.getUTCHours()}:${selected.getUTCMinutes() === 0 ? '00' : selected.getUTCMinutes()}`);
    }
  };

  const memoizedTimes = useMemo(() => {
    const collator = new Intl.Collator([], { numeric: true });
    if (times) {
      return [...times].sort((a, b) => collator.compare(a, b)).reverse();
    }
  }, [times]);
  return (
    <>
      <StyledList>
        {memoizedTimes?.map(time =>
          <StyledTime key={time}>
            <span>{`${getLocalTimeFromUtc(time)}`}</span> <X onClick={() => onRemove(time)} />
          </StyledTime>)}
      </StyledList>
      <StyledContainer>
        <Datepicker
          placeholderText='Select time...'
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption='Time'
          dateFormat='h:mm aa'
          isClearable
          selected={date}
          onDateSelect={onDateChange}
        />
      </StyledContainer>
    </>
  );
};
export default TimeSelector;
