import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { getAllDevices } from 'Store/Actions/deviceActions';
import { editUserDevices } from 'Store/Actions/userActions';
import { loadingMessages, columnBName, columnCName } from '../../Common/messages';
import { ModalTypes } from '../../Common/Enums';
import { toggleModal } from 'Store/Actions/appActions';

import { Button, CheckboxProps, Layout, Modal, Table, TableColumnType, TableKeyType, TableRowType } from 'access_ctrl-ui';
import DevicesFilter from 'Components/DevicesFilter';
import { RootState } from 'Store/Reducers';
import { Device, User } from 'Interfaces';
import { convertToStringLowerCase } from 'Util/formatString';

const Content = styled(Layout.Modal.Content)`
  padding: ${({ theme }) => theme.sizes.md};
  height: 80vh;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const tableDataColumns: TableColumnType[] = [
  {
    name: 'Device',
    key: 'device'
  },
  {
    name: columnBName,
    key: 'columnB'
  },
  {
    name: columnCName,
    key: 'columnC'
  }
];

interface Props {
  currentUser: User | null;
  currentUserDevices: Device[];
  devices: Device[];
  editUserDevices: (deviceIds: string[], userId: string) => Promise<void>;
  getAllDevices: (withUsers?: boolean) => Promise<void>;
  open: boolean;
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
  updatingUserAccess: boolean;
}

export const DeviceAccessModal:React.FC<Props> = ({
  currentUser,
  currentUserDevices,
  devices,
  editUserDevices,
  getAllDevices,
  open,
  toggleModal,
  updatingUserAccess
}) => {
  const [checkedList, setCheckedList] = useState<TableKeyType[]>([] as TableKeyType[]);
  const [filteredDevices, setFilteredDevices] = useState<TableRowType[]>([] as TableRowType[]);

  const location = useLocation();
  const history = useHistory();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const requestClose = () => {
    setShowConfirmModal(true);
  };

  const onCloseConfirm = () => {
    setShowConfirmModal(false);
    toggleModal(ModalTypes.EDIT_USER_ACCESS_MODAL, false);
    setList();
    history.replace({ ...history.location, search: '' });
  };

  const setList = useCallback(() => {
    if (currentUserDevices && devices) {
      const cl = currentUserDevices.map((device) => device.Id).filter(str => typeof str === 'string');
      setCheckedList(cl);
    }
  }, [currentUserDevices, devices]);

  useEffect(() => {
    if (open) {
      getAllDevices(false);
    }
  }, [getAllDevices, open]);

  useEffect(() => {
    setList();
  }, [currentUserDevices, devices, setList]);

  const onSelectAll = (el: CheckboxProps) => {
    if (el.checked) {
      const all = filteredDevices.map((device) => device.key);
      setCheckedList((checkedList) => [...checkedList, ...all]);
    } else {
      const filterIdArr = filteredDevices.map((device) => device.key);
      const checkList = checkedList.filter((cl) => !filterIdArr.includes(cl.toString()));
      setCheckedList(checkList);
    }
  };

  const onSelectSingle = (el: CheckboxProps, _: number, row: TableRowType) => {
    if (el.checked) {
      setCheckedList((checkedList) => [...checkedList, row.key]);
    } else {
      setCheckedList(checkedList.filter((item) => item !== row.key));
    }
  };

  const update = () => {
    if (currentUser?.Id) {
      editUserDevices(checkedList.map(device => device.toString()), currentUser.Id).then(() => {
        onCloseConfirm();
      });
    }
  };

  const applyFilter = useCallback(
    (item) => {
      // TODO: type params and check convertToStringLowerCase function
      const params = queryString.parse(location.search);
      const { deviceName, columnB, columnC } = params;

      return (
        (!deviceName ||
          (item.Name && item.Name.toLocaleLowerCase().indexOf(convertToStringLowerCase(deviceName)) !== -1)) &&
        (!columnB || (item.B && item.B.toLocaleLowerCase().indexOf(convertToStringLowerCase(columnB)) !== -1)) &&
        (!columnC || (item.C && item.C.toLocaleLowerCase().indexOf(convertToStringLowerCase(columnC)) !== -1))
      );
    },
    [location]
  );

  useEffect(() => {
    if (devices) {
      const sortedDevices = devices.sort((a, b) => {
        return a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase());
      });
      setFilteredDevices(
        sortedDevices.filter(applyFilter).map(({ Id, Name, B, C }) => ({
          key: Id,
          name: Name,
          data: {
            id: Id,
            device: Name,
            columnB: B,
            columnC: C
          }
        }))
      );
    }
  }, [devices, applyFilter]);

  return (
    <Modal
      open={open}
      onClose={requestClose}
      confirmClose={onCloseConfirm}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      closeIcon
      size='large'
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>Edit device access</Layout.Modal.Header>
        <Content>
          <DevicesFilter activated />
          <Table
            setQueryString={false}
            checkedRows={checkedList}
            rowsPerPage={0}
            tableDataRows={filteredDevices}
            tableDataColumns={tableDataColumns}
            checkbox
            onSelectSingle={onSelectSingle}
            onSelectAll={onSelectAll}
            loading={!(currentUserDevices && devices)}
            loadingMessage={loadingMessages.LOADING_DEVICES_TABLE}
          />
        </Content>
        <Layout.Modal.ButtonContainer>
          <Button loading={updatingUserAccess} onClick={update}>
            Update
          </Button>
        </Layout.Modal.ButtonContainer>
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default connect(
  ({ modalsOpen, user, device }: RootState) => ({
    open: modalsOpen[ModalTypes.EDIT_USER_ACCESS_MODAL],
    currentUser: user.currentUser,
    currentUserDevices: user.currentUserDevices,
    devices: device.devices,
    updatingUserAccess: user.updatingUserAccess
  }),
  (dispatch) => ({
    toggleModal: toggleModal(dispatch),
    editUserDevices: editUserDevices(dispatch),
    getAllDevices: getAllDevices(dispatch)
  })
)(DeviceAccessModal);
