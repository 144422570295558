import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Edit2 } from 'react-feather';

import { fetchUserIdentities } from 'Store/Actions/userActions';
import { toggleModal } from 'Store/Actions/appActions';
import { loadingMessages } from '../../../Common/messages';
import { ModalTypes } from '../../../Common/Enums';

import { HeaderRow, InfoPopup, Table, Button, TableRowType } from 'access_ctrl-ui';
import { User } from 'Interfaces';
import { RootState } from 'Store/Reducers';

const tableDataColumns = [
  { name: 'Identity', key: 'identity' }
];
interface Props {
  userAdmin: boolean;
  currentUser: User;
  userIdentities: string[];
  fetchUserIdentities: (userId: string) => void;
  fetchingUserIdentities: boolean;
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
}
const IdentitiesPane:React.FC<Props> = ({ userAdmin, currentUser, userIdentities, fetchUserIdentities, fetchingUserIdentities, toggleModal }) => {
  const [tableData, setTableData] = useState<TableRowType[]>([] as TableRowType[]);

  useEffect(() => {
    if (currentUser && userAdmin) {
      fetchUserIdentities(currentUser.Id);
    }
  }, [currentUser, fetchUserIdentities, userAdmin]);

  useEffect(() => {
    if (userIdentities) {
      setTableData(userIdentities.map((identity) => ({
        key: identity,
        data: {
          identity
        }
      })));
    }
  }, [userIdentities]);

  const onEditIdentities = () => {
    toggleModal(ModalTypes.EDIT_IDENTITIES_MODAL, true);
  };

  return (
    <>
      <HeaderRow>
        <h2>Identities</h2>
        <InfoPopup descriptionText='All identities connected to this user' />
        {
          (userAdmin) &&
            <Button onClick={onEditIdentities}><Edit2 />Edit</Button>
        }
      </HeaderRow>
      {(userIdentities) &&
        <Table
          tableDataColumns={tableDataColumns}
          tableDataRows={tableData}
          setQueryString={false}
          loading={fetchingUserIdentities}
          loadingMessage={loadingMessages.LOADING_USER_IDENTITIES}
          emptyText='User has no identities'
        />}
    </>
  );
};

export default connect(({ user }:RootState) => ({
  userIdentities: user.userIdentities,
  fetchingUserIdentities: user.fetchingUserIdentities
}), dispatch => (
  {
    toggleModal: toggleModal(dispatch),
    fetchUserIdentities: fetchUserIdentities(dispatch)
  }
))(IdentitiesPane);
