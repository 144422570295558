/**
  Actiontypes dispatched from debugActions
*/
export enum DebugActionTypes {
  GET_DEVICE_DEBUG_MESSAGES_START = 'GET_DEVICE_DEBUG_MESSAGES_START',
  GOT_DEVICE_DEBUG_MESSAGES = 'GOT_DEVICE_DEBUG_MESSAGES',
  FAILED_GETTING_DEVICE_DEBUG_MESSAGES = 'FAILED_GETTING_DEBUG_MESSAGES',
  GET_ALL_DEBUG_MESSAGES_START = 'GET_ALL_DEBUG_MESSAGES_START',
  GOT_ALL_DEBUG_MESSAGES = 'GOT_ALL_DEBUG_MESSAGES',
  FAILED_GETTING_ALL_DEBUG_MESSAGES = 'FAILED_GETTING_ALL_DEBUG_MESSAGES',
  GET_SYSTEM_MESSAGES_START = 'GET_SYSTEM_MESSAGES_START',
  GOT_SYSTEM_MESSAGES = 'GOT_SYSTEM_MESSAGES',
  FAILED_GETTING_SYSTEM_MESSAGES = 'FAILED_GETTING_SYSTEM_MESSAGES'
}
