import React, { FormEvent, SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DropdownItemProps, Loader, List, CheckboxProps } from 'semantic-ui-react';
import { connect } from 'react-redux';

import {
  Form,
  Datepicker,
  Button,
  FormInput,
  Layout,
  Modal,
  PasswordInput,
  ToggleSegment,
  Dropdown,
  DropdownProps
} from 'access_ctrl-ui';
import { PasswordValidation, User, UserOptions } from 'Interfaces';
import { ModalSize, ModalTypes } from '../../Common/Enums';
import { RootState } from 'Store/Reducers';
import { getDistinctColBValues, getDistinctColCValues } from 'Store/Actions/deviceActions';
import { loadingMessages, columnBName, columnCName } from '../../Common/messages';
import { permissionOptions, permissions } from '../../Common/Constant';
import { sizes } from '../../Common/theme';
import { toggleModal, getLoggedInUserData } from 'Store/Actions/appActions';
import { updateUser } from 'Store/Actions/userActions';
import { validatePasswordWithConfirm } from 'Util/passwordValidator';
import { convertDateToString } from 'Util/formatDate';

const Arrow = styled.div`
  border-width: 1px 0 0 1px;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 0;
  left: 6rem;
  z-index: 3;
  width: 9px;
  height: 9px;
  background-color: ${({ theme }) => theme.appBg};
  border-style: solid;
  border-color: ${({ theme }) => theme.textColorError};
  position: absolute;
`;

const ValidationList = styled(List)`
  &&& {
    padding: ${({ theme }) => theme.sizes.sm};
    margin: 1rem;
    background: ${({ theme }) => theme.appBg};
    border: 1px solid ${({ theme }) => theme.textColorError};
    border-radius: 4px;
    position: relative;
  }

  .item {
    color: ${({ theme }) => theme.textColorError};
    font-size: 0.875rem;
    padding: 0.22em 0 !important;
  }
`;

const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  padding: ${({ theme }) => theme.sizes.md};
`;

const _defaultPasswordValidation: PasswordValidation = {
  messages: [],
  isValid: true,
  passwordConfirm: ''
};

const _defaultUserOptions: UserOptions = {
  'access.from': { Value: '' },
  'access.to': { Value: '' },
  'permissions.actions': {
    Value: '[0]'
  },
  'permissions.columnsb': {
    Value: '["All"]'
  },
  'permissions.columnsc': {
    Value: '["All"]'
  }
};

const _defaultEditUser = {
  FirstName: '',
  LastName: '',
  Email: '',
  EmployeeId: '',
  IsAdmin: false,
  SetPassword: '',
  Options: _defaultUserOptions
} as User;

const getEmailPrefixFromEmail = (email:string): string => {
  return email.split('@')[0];
};

const invalidName = (_?:string, value?:string) => {
  if (value && value.includes(' ')) {
    return 'Error: Name should not contain space';
  } else if (!value || value === '') {
    return 'Error: This field is required';
  }
  return false;
};

const invalidUserId = (_?:string, value?:string) => {
  if (value && value.length > 16) {
    return 'Error: EmployeeId must not be longer than 16 characters';
  }
  return false;
};

interface Props {
  columnBValues: string[];
  columnCValues: string[];
  currentUser: User | null;
  fetchingColBValues: boolean;
  fetchingColCValues: boolean;
  getDistinctColBValues: () => void;
  getDistinctColCValues: () => void;
  getLoggedInUserData: () => void;
  loggedInUser: User;
  open: boolean;
  size?: ModalSize;
  toggleModal: (modalName: ModalTypes, forcedOpenState: boolean) => void;
  updateUser: (arg: User) => Promise<void>;
  updatingUser: boolean;
}

export const EditUserDetailsModal:React.FC<Props> = ({
  columnBValues,
  columnCValues,
  currentUser,
  fetchingColBValues,
  fetchingColCValues,
  getDistinctColBValues,
  getDistinctColCValues,
  getLoggedInUserData,
  loggedInUser,
  open,
  size = ModalSize.LARGE,
  toggleModal,
  updateUser,
  updatingUser
}) => {
  const [editUser, setEditUser] = useState(_defaultEditUser);
  const [invalidForm, setInvalidForm] = useState<boolean>(true);
  const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>(_defaultPasswordValidation);
  const [adminAction, setAdminAction] = useState(false);
  const [adminCheckboxIsChecked, setAdminCheckboxIsChecked] = useState(false);

  const [columnBDropdownValues, setColumnBDropdownValues] = useState<DropdownItemProps[]>([] as DropdownItemProps[]);
  const [columnCDropdownValues, setColumnCDropdownValues] = useState<DropdownItemProps[]>([] as DropdownItemProps[]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const requestClose = () => {
    setShowConfirmModal(true);
  };

  const onCloseConfirm = () => {
    setShowConfirmModal(false);
    toggleModal(ModalTypes.EDIT_USER_MODAL, false);
    setPasswordValidation(_defaultPasswordValidation);
    setEditUser(_defaultEditUser);
  };

  // TODO key should probably be number, instead of number or string. Low prio though
  const makeDropdownOptions = (values: string[]): DropdownItemProps[] => {
    let dropdownValues : DropdownItemProps[] = values.map((value: string) => ({
      key: value,
      text: value,
      value: value
    }));

    dropdownValues = [...dropdownValues.sort((a, b) => { return a.key.localeCompare(b.key); }).reverse(), { key: 0, text: 'All', value: 'All' }].reverse();

    return dropdownValues;
  };

  useEffect(() => {
    if (loggedInUser && loggedInUser.Options && loggedInUser.Options['permissions.actions']) {
      const actions = JSON.parse(loggedInUser.Options['permissions.actions'].Value);

      setAdminAction(actions.includes(permissions.userAdmin) || actions.includes(permissions.all));
    } else if (loggedInUser) {
      setAdminAction(true);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (open) {
      getDistinctColCValues();
      getDistinctColBValues();
    }
  }, [getDistinctColBValues, getDistinctColCValues, open]);

  useEffect(() => {
    if (columnBValues) {
      setColumnBDropdownValues(makeDropdownOptions(columnBValues));
    }
  }, [columnBValues]);

  useEffect(() => {
    if (columnCValues) {
      setColumnCDropdownValues(makeDropdownOptions(columnCValues));
    }
  }, [columnCValues]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (invalidForm) {
      return;
    }

    updateUser(editUser).then(() => {
      onCloseConfirm();
      if (currentUser?.Id === loggedInUser.Id) {
        getLoggedInUserData();
      }
    });
  };

  useEffect(() => {
    if (currentUser) {
      let userOptions = { ..._defaultUserOptions };

      if (currentUser.Options) {
        userOptions = { ...userOptions, ...currentUser.Options };
      }

      setEditUser({
        ...currentUser,
        EmployeeId: currentUser.EmployeeId || '',
        Options: userOptions
      });
    }
  }, [currentUser, open]);

  useEffect(() => {
    if (currentUser && currentUser.IsAdmin) {
      setAdminCheckboxIsChecked(true);
    }
  }, [currentUser]);

  const handleDatepickerChange = (key: string, value: Date | null) => {
    const newValue = value === null ? null : convertDateToString(value);

    setEditUser({
      ...editUser,
      Options: {
        ...editUser.Options,
        [key]: {
          Value: newValue
        }
      }
    });
  };

  const onDropDownChange = (_e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const { name } = data;
    handlePermissionChange(data, `permissions.${name}`);
  };

  const handlePermissionChange = (data: DropdownProps, option: string) => {
    setEditUser({
      ...editUser,
      Options: {
        ...editUser.Options,
        [option]: {
          Value: JSON.stringify(data.value)
        }
      }
    });
  };

  // Validate form on change of form inputs
  useEffect(() => {
    const { isValid: PasswordIsValid } = passwordValidation;
    const { FirstName, LastName, SetPassword, EmployeeId } = editUser;
    const currentUserIsAdmin = currentUser && currentUser.IsAdmin;

    const newAdminPasswordValid = !currentUserIsAdmin && adminCheckboxIsChecked && SetPassword && PasswordIsValid;

    // This is kinda confusing, because right now an admins password is shown as empty
    // This makes it look like the admin does not have a password when in fact they do
    // Therefore an empty password on an current adminuser is valid, (it simply means it is unchanged)
    const oldAdminPasswordValid = currentUserIsAdmin && adminCheckboxIsChecked && (!SetPassword || PasswordIsValid);

    // If any of these statements are true, the password is valid
    const PasswordIsValidBasedOnRole = newAdminPasswordValid || oldAdminPasswordValid || !adminCheckboxIsChecked;

    if (!FirstName || !LastName || !PasswordIsValidBasedOnRole) {
      setInvalidForm(true);
      return;
    }

    const invalidForm =
      !!invalidName(undefined, FirstName) ||
      !!invalidName(undefined, LastName) ||
      !!invalidUserId(undefined, EmployeeId);

    setInvalidForm(invalidForm);
  }, [setInvalidForm, editUser, currentUser, adminCheckboxIsChecked, passwordValidation]);

  const onInputCheckboxUpdate = (name: string, value: boolean | undefined) => {
    if (name === 'IsAdmin' && value === false) {
      setAdminCheckboxIsChecked(false);
      setPasswordValidation(_defaultPasswordValidation);

      setEditUser({
        ...editUser,
        [name]: value,
        SetPassword: ''
      });
    } else if (name === 'IsAdmin' && value === true) {
      setAdminCheckboxIsChecked(true);
      setEditUser({
        ...editUser,
        [name]: value
      });
    }
  };

  const onInputTextboxUpdate = (name: string, value: string) => {
    if (name === 'SetPassword') {
      const emailPrefix = getEmailPrefixFromEmail(editUser.Email);
      const validationResult = validatePasswordWithConfirm(value, passwordValidation.passwordConfirm || '', emailPrefix);
      setPasswordValidation(validationResult);
    } else if (name === 'PasswordConfirm') {
      const emailPrefix = getEmailPrefixFromEmail(editUser.Email);
      const validationResult = validatePasswordWithConfirm(editUser.SetPassword || '', value, emailPrefix);
      setPasswordValidation(validationResult);

      return;
    }

    setEditUser({
      ...editUser,
      [name]: value
    });
  };

  if (!currentUser && open) {
    return <Loader active>{loadingMessages.LOADING_USER}</Loader>;
  }

  const accessFrom = editUser?.Options['access.from']?.Value ? new Date(editUser.Options['access.from'].Value) : null;
  const accessTo = editUser?.Options['access.to']?.Value ? new Date(editUser.Options['access.to'].Value) : null;

  const { FirstName, LastName, EmployeeId, IsAdmin, SetPassword, Options: UserOptions } = editUser;
  const { passwordConfirm, messages: passwordMessages } = passwordValidation;

  return (
    <Modal
      open={open}
      onClose={requestClose}
      confirmClose={onCloseConfirm}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      closeIcon
      size={size}
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>Edit user</Layout.Modal.Header>
        <Layout.Modal.Content>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Layout.Row spacing={sizes.md} padding={sizes.md}>
                <FormInput
                  value={FirstName}
                  label='First name'
                  name='FirstName'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputTextboxUpdate('FirstName', e.target.value)}
                  required
                  validateOnBlur={false}
                  validationFn={invalidName}
                  fluid
                />
                <FormInput
                  value={LastName}
                  label='Last name'
                  name='LastName'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputTextboxUpdate('LastName', e.target.value)}
                  required
                  validateOnBlur={false}
                  validationFn={invalidName}
                  fluid
                />
                <FormInput
                  value={EmployeeId}
                  label='Employee number'
                  name='EmployeeId'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputTextboxUpdate('EmployeeId', e.target.value)}
                  validateOnBlur={false}
                  validationFn={invalidUserId}
                  fluid
                />
              </Layout.Row>
            </FormGroup>
            <FormGroup>
              <Layout.Row spacing={sizes.md} padding={sizes.md}>
                <Datepicker
                  label='Access from'
                  onDateSelect={(_: React.SyntheticEvent<HTMLInputElement, Event> | undefined, value: Date | null) => handleDatepickerChange('access.from', value)}
                  selected={accessFrom}
                  maxDate={accessTo}
                  startDate={accessFrom}
                  endDate={accessTo}
                  selectsStart
                />
                <Datepicker
                  label='Access to'
                  onDateSelect={(_: React.SyntheticEvent<HTMLInputElement, Event> | undefined, value: Date | null) => handleDatepickerChange('access.to', value)}
                  selected={accessTo}
                  minDate={accessFrom}
                  startDate={accessFrom}
                  endDate={accessTo}
                  selectsEnd
                />
              </Layout.Row>
            </FormGroup>
            {adminAction && (
              <ToggleSegment
                header='Admin dashboard user'
                onToggleChange={(_e: React.SyntheticEvent<HTMLElement, Event>, value: CheckboxProps) => onInputCheckboxUpdate('IsAdmin', value.checked)}
                toggled={IsAdmin}
              >
                <Container>
                  <PasswordInput
                    autoComplete='new-password'
                    label='Password'
                    name='password'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputTextboxUpdate('SetPassword', e?.target.value)}
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                      e?.target.select();
                    }}
                    type='password'
                    value={SetPassword || ''}
                  />
                </Container>
                <Container>
                  <PasswordInput
                    autoComplete='new-password'
                    name='confirmpassword'
                    label='Confirm Password'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputTextboxUpdate('PasswordConfirm', e.target.value)}
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                      e.target.select();
                    }}
                    type='password'
                    value={passwordConfirm}
                  />
                </Container>
                {passwordMessages.length >= 1 && (
                  <ValidationList>
                    <Arrow />
                    {passwordMessages.map((message: string, index: number) => (
                      <List.Item key={index}>{message}</List.Item>
                    ))}
                  </ValidationList>
                )}
                <Container>
                  <Dropdown
                    value={JSON.parse(UserOptions['permissions.actions'].Value)}
                    label='Permissions'
                    name='actions'
                    allOption
                    onChange={onDropDownChange}
                    fluid
                    multiple
                    options={permissionOptions()}
                  />
                  <Dropdown
                    value={JSON.parse(UserOptions['permissions.columnsb'].Value)}
                    label={columnBName}
                    name='columnsb'
                    allOption
                    allValue='All'
                    onChange={onDropDownChange}
                    fluid
                    multiple
                    options={columnBDropdownValues ?? undefined}
                    loading={fetchingColBValues}
                  />
                  <Dropdown
                    value={JSON.parse(UserOptions['permissions.columnsc'].Value)}
                    label={columnCName}
                    name='columnsc'
                    allOption
                    allValue='All'
                    onChange={onDropDownChange}
                    fluid
                    multiple
                    options={columnCDropdownValues ?? undefined}
                    loading={fetchingColCValues}
                  />
                </Container>
              </ToggleSegment>
            )}
            <Layout.Modal.ButtonContainer>
              <Button disabled={invalidForm} loading={updatingUser}>
                Save
              </Button>
            </Layout.Modal.ButtonContainer>
          </Form>
        </Layout.Modal.Content>
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default connect(
  ({ modalsOpen, user, app, device }: RootState) => ({
    open: modalsOpen[ModalTypes.EDIT_USER_MODAL],
    currentUser: user.currentUser,
    loggedInUser: app.loggedInUser,
    updatingUser: user.updatingUser,
    columnBValues: device.columnBValues,
    fetchingColBValues: device.fetchingColBValues,
    columnCValues: device.columnCValues,
    fetchingColCValues: device.fetchingColCValues
  }),
  (dispatch) => ({
    toggleModal: toggleModal(dispatch),
    updateUser: updateUser(dispatch),
    getLoggedInUserData: getLoggedInUserData(dispatch),
    getDistinctColBValues: getDistinctColBValues(dispatch),
    getDistinctColCValues: getDistinctColCValues(dispatch)
  })
)(EditUserDetailsModal);
