import { InfoPopup } from 'access_ctrl-ui';
import React from 'react';
import { Loader, Menu } from 'semantic-ui-react';
interface Props {
  text: string;
  description: string;
  loading: boolean;
}
const PaneMenuItem:React.FC<Props> = ({ text, description, loading, ...props }) => {
  return (
    <Menu.Item {...props}>
      {text}
      {!loading &&
        <InfoPopup descriptionText={description} />}
      {loading &&
        <Loader active={loading} inline size='small' />}
    </Menu.Item>
  );
};
export default PaneMenuItem;
