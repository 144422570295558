import { Button, ButtonProps, ConfirmModal, Datepicker, Dropdown, HeaderRow, IconTypes, Input, Layout, Modal, Select, Table, TableKeyType, TableLink, TableRowType, TextArea } from 'access_ctrl-ui';
import { JTokenType } from 'Common/Enums';
import { columnBName, columnCName } from 'Common/messages';
import DevicesFilter from 'Components/DevicesFilter';
import queryString from 'query-string';

import { Device, KeyStringDictionary } from 'Interfaces';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { CheckboxProps, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { getAllDevices, restartLCU } from 'Store/Actions/deviceActions';
import { addErrorMessage, addSuccessMessage } from 'Store/Actions/feedbackMessage';
import { deleteDeviceOption, postDeviceOption } from 'Store/Actions/optionsActions';
import { useAppSelector } from 'Store/hooks';
import styled from 'styled-components';
import { nameSort } from 'Util/sortFn';
import { convertToString } from 'Util/formatString';
import { convertDateToString } from 'Util/formatDate';
import { obliterateLogs, obliterateLogsForDevice } from 'Store/Actions/operationLogActions';
import { getUpdateIcon } from 'Util/handleVersions';
import { useDispatch } from 'react-redux';

const StyledSelect = styled(Select)`
  max-width: 17rem;
  margin: 0.5rem 1rem;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  button {
    margin: 0 1rem;
  }
`;
const StyledTextArea = styled(TextArea)`
  resize: none;
`;
const StyledInputContainer = styled.div`
  max-width: 25rem;
  margin: 0.5rem 1rem;
`;
interface ButtonCssProps extends ButtonProps {
  $alignRight?: boolean;
}
const StyledButton = styled(Button)<ButtonCssProps>`
  max-height: 3rem;
  margin-left: ${({ $alignRight }) => ($alignRight ? ' auto !important' : '1rem')};
`;

const StyledRequest = styled.div`
  margin: 3.5rem;
  border: 2px solid rgb(239, 124, 0);
  background-color: #1e1d22;
  width: 50rem;
  font-size: 1.5rem;
  min-width: 10rem;
  padding: 1rem;
`;
const StyledRequestContent = styled.span`
  margin: 1rem;
  min-height: 2rem;

  ::after {
    content: '\00a0';
  }
`;
const StyledErrorModal = styled(Modal)`
  background: ${({ theme }) => theme.background};
  min-height: 10rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  >div {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.color};
  font-size: 1.5rem;
  min-height: 3rem;
  padding: 1rem;
  text-align: center;
`;
const tableColumns = [
  {
    name: 'Name',
    key: 'name',
    sortFn: (dir:TableKeyType, col:TableKeyType) => {
      return nameSort(dir, col);
    }
  },
  {
    name: columnBName,
    key: 'columnB',
    sortFn: (dir:TableKeyType, col:TableKeyType) => {
      return nameSort(dir, col);
    }
  },
  {
    name: columnCName,
    key: 'columnC',
    sortFn: (dir:TableKeyType, col:TableKeyType) => {
      return nameSort(dir, col);
    }
  },
  {
    name: 'Has update',
    key: 'needsUpdate'
  }
];
const getTableRows = (devices:Device[], software: KeyStringDictionary):TableRowType[] => {
  return devices.map(device => ({
    key: device.Id,
    data: {
      name: <TableLink to={`/devices/${device.Id}`} iconType={IconTypes.Device} text={device.Name} />,
      columnB: device.B,
      columnC: device.C,
      needsUpdate: getUpdateIcon(device, software)
    }
  }));
};
const getOptions = ():DropdownItemProps[] => {
  const options = Object.entries(JTokenType).filter(entry =>
    isNaN(parseInt(entry[0]))).map(entry => {
    return {
      value: entry[1],
      text: entry[0].toString()
    };
  });
  return options || [] as DropdownItemProps[];
};
interface Setting {
  tokenType: JTokenType;
  name: string;
  value: string;
}
const initialSetting:Setting = {
  tokenType: JTokenType.String,
  name: 'KEY',
  value: 'VALUE'
};
const initialFilter = {
  deviceName: '',
  columnBName: '',
  columnCName: ''
};
const isValidDate = (date:string) => {
  try {
    return Date.parse(date) ? new Date(date) : undefined;
  } catch {
    return undefined;
  }
};
const DeviceSettingsPane:React.FC = () => {
  const [setting, setSetting] = useState<Setting>(initialSetting);
  const [tableData, setTableData] = useState<TableRowType[]>([] as TableRowType[]);
  const [loading, setLoading] = useState<boolean>(true);
  const [checkedRows, setCheckedRows] = useState<TableKeyType[]>([] as TableKeyType[]);
  const [filter, setFilter] = useState(initialFilter);

  // confirm modals
  const [showSettingsConfirm, setShowSettingsConfirm] = useState<boolean>(false);
  const [showLogsConfirm, setShowLogsConfirm] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [showRestartconfirm, setShowRestartConfirm] = useState<boolean>(false);

  // errors
  const [errorPopupOpen, setErrorPopupOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const location = useLocation();
  const { devices, latestSoftwareVersions } = useAppSelector(state => state.device);
  // Dispatches
  const dispatch = useDispatch();

  const { tokenType, value, name } = setting;

  useEffect(() => {
    getAllDevices(dispatch)(true, true);
  }, [dispatch]);
  useEffect(() => {
    const applyFilter = (item:Device) => {
      return (!filter.deviceName || (item.Name && item.Name.toLocaleLowerCase().indexOf(filter.deviceName.toLocaleLowerCase()) !== -1)) &&
      (!filter.columnBName || (item.B && item.B.toLocaleLowerCase().indexOf(filter.columnBName.toLocaleLowerCase()) !== -1)) &&
      (!filter.columnCName || (item.C && item.C.toLocaleLowerCase().indexOf(filter.columnCName.toLocaleLowerCase()) !== -1));
    };
    const rows = getTableRows(devices.filter(applyFilter), latestSoftwareVersions);
    setTableData(rows);
    setLoading(false);
  }, [devices, filter, latestSoftwareVersions]);

  useEffect(() => {
    const { deviceName, columnB, columnC } = queryString.parse(location.search);
    setFilter({
      deviceName: convertToString(deviceName),
      columnBName: convertToString(columnB),
      columnCName: convertToString(columnC)
    });
  }, [setFilter, location.search]);

  const renderSwitch = (tokenType:JTokenType) => {
    switch (tokenType) {
      case JTokenType.String:
        return <Input value={value} type='text' name='value' onChange={onInputChange} />;
      case JTokenType.Float:
        return <Input value={value} type='number' name='value' onChange={onInputChange} />;
      case JTokenType.Integer:
        return <Input value={value} type='number' name='value' onChange={onInputChange} />;
      case JTokenType.Boolean:
        return <Dropdown onChange={onDropdownChange} options={[{ value: 'true', text: 'True' }, { value: 'false', text: 'False' }]} />;
      case JTokenType.Date:
        return <Datepicker onDateSelect={onDateSelect} selected={isValidDate(value)} />;
      default:
        return <StyledTextArea name='value' cols={40} resize='none' value={value} rows={4} onChange={onInputChange} />;
    }
  };

  const onSelectChange = (_: React.SyntheticEvent<HTMLElement, Event>, data:DropdownProps) => {
    const { value } = data;
    if (!isNaN(value as number)) {
      setSetting({
        ...setting,
        tokenType: value as number
      });
    }
  };
  const onInputChange = (event:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const { value, name } = event.currentTarget;
    setSetting({
      ...setting,
      [name]: value.toString()
    });
  };

  const onDateSelect = (_: React.SyntheticEvent<HTMLInputElement, Event> | undefined, selected: Date | null) => {
    if (selected) {
      setSetting({
        ...setting,
        value: convertDateToString(selected) as string
      });
    }
  };

  const onDropdownChange = (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (data.value) {
      setSetting({
        ...setting,
        value: data.value.toString()
      });
    }
  };
  const onSelectSingle = (el: CheckboxProps, i: number, row: TableRowType) => {
    if (el.checked) {
      setCheckedRows(
        [...checkedRows, row.key]);
    } else {
      (
        setCheckedRows(checkedRows.filter(savedRow => savedRow !== row.key))
      );
    }
  };
  const onSelectAll = (el:CheckboxProps) => {
    const checked = tableData.map(data => data.key);

    el.checked ? setCheckedRows(checked) : setCheckedRows([]);
  };

  const onConfirm = () => {
    const updates = [] as Promise<void>[];
    checkedRows.forEach(key => {
      updates.push(postDeviceOption(dispatch)(key.toString(), name, value, tokenType));
    });
    Promise.all(updates).then(() => {
      addSuccessMessage(dispatch)('All devices updated.');
      setShowSettingsConfirm(false);
    }).catch(err => {
      addErrorMessage(dispatch)(err);
      setShowSettingsConfirm(false);
    });
  };

  const onRestartConfirm = () => {
    const devices = checkedRows.map(key => key.toString());
    const restarts:Promise<void>[] = [];
    devices.forEach(device => {
      restarts.push(restartLCU(dispatch)(device, false));
    });
    Promise.all(restarts).then(() => {
      setShowRestartConfirm(false);
      addSuccessMessage(dispatch)('All devices restarted');
    }).catch(err => {
      setShowRestartConfirm(false);
      addErrorMessage(dispatch)(err);
    });
  };

  const validate = ():boolean => {
    if (checkedRows.length <= 0) {
      setErrorMessage('No devices selected.');
      setErrorPopupOpen(true);
      return false;
    }
    if (!name || name === 'KEY' || name.length <= 0) {
      setErrorMessage('No settings key provided.');
      setErrorPopupOpen(true);
      return false;
    }
    return true;
  };

  const onCloseErrorPopup = () => {
    setErrorMessage('');
    setErrorPopupOpen(false);
  };

  const onLogsConfirm = () => {
    const updates = [] as Promise<void>[];
    checkedRows.forEach(key => {
      updates.push(obliterateLogsForDevice(dispatch)(key.toString()));
    });
    obliterateLogs(dispatch);
    setShowLogsConfirm(false);
    Promise.all(updates).then(() => {
      setShowDeleteConfirm(false);
      addSuccessMessage(dispatch)('Setting deleted.');
    }).catch(err => {
      setShowDeleteConfirm(false);
      addErrorMessage(dispatch)(err);
    });
  };

  const onDeleteConfirm = () => {
    const updates = [] as Promise<void>[];
    checkedRows.forEach(key => {
      updates.push(deleteDeviceOption(dispatch)(key.toString(), name));
    });
    Promise.all(updates).then(() => {
      setShowDeleteConfirm(false);
      addSuccessMessage(dispatch)('Setting deleted.');
    }).catch(err => {
      setShowDeleteConfirm(false);
      addErrorMessage(dispatch)(err);
    });
  };

  const reset = () => {
    setCheckedRows([]);
    setSetting(initialSetting);
  };

  const deleteSetting = () => {
    if (validate()) {
      setShowDeleteConfirm(true);
    }
  };

  const save = () => {
    try {
      if (validate()) {
        setShowSettingsConfirm(true);
      }
    } catch (error) {
      setErrorMessage(error as string);
      setErrorPopupOpen(true);
    }
  };

  const clearLogs = () => {
    if (checkedRows.length <= 0) {
      setErrorMessage('No devices selected.');
      setErrorPopupOpen(true);
      return false;
    }
    setShowLogsConfirm(true);
  };

  const restart = () => {
    if (checkedRows.length <= 0) {
      setErrorMessage('No devices selected.');
      setErrorPopupOpen(true);
      return false;
    }
    setShowRestartConfirm(true);
  };
  return (
    <Layout.Wrapper>
      <HeaderRow>
        <h2>Configure settings for all devices (use with caution)</h2>
      </HeaderRow>
      <Layout.Section flexGrow minHeight='70rem'>
        <DevicesFilter activated />

        <FlexRow>
          <StyledInputContainer><Input type='text' name='name' placeholder='Setting name' value={name} onChange={onInputChange} /></StyledInputContainer>
          <StyledInputContainer>{renderSwitch(tokenType)}</StyledInputContainer>
          <StyledSelect onChange={onSelectChange} options={getOptions()} defaultValue={JTokenType.String} />
          <FlexRow>
            <StyledButton type='button' onClick={reset}>Reset</StyledButton>
            <StyledButton type='button' onClick={deleteSetting}>Delete</StyledButton>
            <StyledButton type='button' onClick={save}>Save</StyledButton>
            <StyledButton $alignRight type='button' onClick={clearLogs}>Delete logs</StyledButton>
            <StyledButton type='button' onClick={restart}>Restart</StyledButton>

          </FlexRow>

        </FlexRow>
        <StyledRequest>
          <StyledRequestContent>
            {`inventoryoptions/{id}/${name}`}
            <br />
            <StyledRequestContent>
              {`
              {\n              
                value: ${value},\n
                tokenType: ${tokenType}\n
              }`}
            </StyledRequestContent>
            <br />
          </StyledRequestContent>
        </StyledRequest>

        <Table
          rowsPerPage={40}
          loading={loading}
          loadingMessage='Loading devices'
          onSelectAll={onSelectAll}
          onSelectSingle={onSelectSingle}
          checkbox
          checkedRows={checkedRows}
          tableDataColumns={tableColumns}
          tableDataRows={tableData}
        />
      </Layout.Section>
      <StyledErrorModal closeOnEscape closeOnDocumentClick onClose={onCloseErrorPopup} size='tiny' open={errorPopupOpen}>
        <Layout.Modal.Wrapper>
          <Layout.Modal.Content>
            <StyledErrorMessage>
              {errorMessage}
            </StyledErrorMessage>
          </Layout.Modal.Content>
          <Layout.Modal.ButtonContainer>
            <Button onClick={onCloseErrorPopup}>Close</Button>
          </Layout.Modal.ButtonContainer>
        </Layout.Modal.Wrapper>
      </StyledErrorModal>
      {/* Confirm modals */}
      <ConfirmModal
        open={showSettingsConfirm}
        onConfirm={onConfirm}
        onCancel={() => { setShowSettingsConfirm(false); }}
        header={`Update ${name} setting value to ${value} for selected devices`}
        content='Do you really want to update settings for selected devices?'
      />
      <ConfirmModal
        open={showLogsConfirm}
        onConfirm={onLogsConfirm}
        onCancel={() => { setShowLogsConfirm(false); }}
        header='Obliterate logs for selected devices'
        content='Do you really want to obliterate logs for selected devices?'
      />
      <ConfirmModal
        open={showDeleteConfirm}
        onConfirm={onDeleteConfirm}
        onCancel={() => setShowDeleteConfirm(false)}
        content='Do you really want to delete setting for selected devices?'
        header={`Delete ${name} setting for selected devices`}
      />
      <ConfirmModal
        open={showRestartconfirm}
        onConfirm={onRestartConfirm}
        onCancel={() => { setShowRestartConfirm(false); }}
        header='Restart selected devices'
        content='Do you really want to restart selected devices?'
      />
    </Layout.Wrapper>
  );
};
export default DeviceSettingsPane;
