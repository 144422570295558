export const nameSort = (dir, col) => {
  return (a, b) => {
    let aval = (a.sortData && a.sortData[col] != null ? a.sortData[col] : a.data[col]) || '';
    let bval = (b.sortData && b.sortData[col] != null ? b.sortData[col] : b.data[col]) || '';

    aval = aval.toString().trim();
    bval = bval.toString().trim();
    if (dir === 'asc') {
      return aval.toLocaleLowerCase().localeCompare(bval.toLocaleLowerCase(), 'sv');
    }
    if (dir === 'desc') {
      return bval.toLocaleLowerCase().localeCompare(aval.toLocaleLowerCase(), 'sv');
    }
  };
};

export const nameSortAlphaNum = (dir, col) => {
  return (a, b) => {
    let aval = (a.sortData && a.sortData[col] != null ? a.sortData[col] : a.data[col]) || '';
    let bval = (b.sortData && b.sortData[col] != null ? b.sortData[col] : b.data[col]) || '';

    aval = aval.toString().trim();
    bval = bval.toString().trim();

    if (dir === 'asc') {
      return aval.toLocaleLowerCase().localeCompare(bval.toLocaleLowerCase(), 'en', { numeric: true });
    }
    if (dir === 'desc') {
      return bval.toLocaleLowerCase().localeCompare(aval.toLocaleLowerCase(), 'en', { numeric: true });
    }
  };
};

export const dateSort = (dir, col) => {
  return (a, b) => {
    const aval = a.sortData && a.sortData[col] != null ? a.sortData[col] : a.data[col];
    const bval = b.sortData && b.sortData[col] != null ? b.sortData[col] : b.data[col];

    if (dir === 'asc') {
      return aval === bval ? 0 : aval > bval ? 1 : -1;
    }
    if (dir === 'desc') {
      return aval === bval ? 0 : aval < bval ? 1 : -1;
    }
    return 1;
  };
};
