import { passwordValidationMessages } from '../Common/messages';

const minLength = 8;
const maxLength = 256;
const lowerCase = 'a-z';
const upperCase = 'A-Z';
const numbers = '0-9';
const symbols = ' @#$%&*^\\-_!+=\\[\\]{}\\|\\:‘,\\.\\?\\/`~\\(\\);<>';
export const allowedCharacters = lowerCase + upperCase + numbers + symbols;

const countComposition = (password: string) => (
  [lowerCase, upperCase, numbers, symbols].reduce((acc, cur) =>
    acc + (password.match(new RegExp(`[${cur}]`)) ? 1 : 0)
  , 0)
);

export const validatePassword = (password: string, username: string) => {
  const validationMessages = [];

  if (!password.match(new RegExp(`^[${allowedCharacters}]+$`))) {
    validationMessages.push(passwordValidationMessages.PASSWORD_BAD_CHARACTERS);
  }

  if (password.length < minLength) {
    validationMessages.push(passwordValidationMessages.PASSWORD_TOO_SHORT.replace('{0}', String(minLength)));
  }

  if (password.length > maxLength) {
    validationMessages.push(passwordValidationMessages.PASSWORD_TOO_LONG.replace('{0}', String(maxLength)));
  }

  if (countComposition(password) < 3) {
    validationMessages.push(passwordValidationMessages.PASSWORD_NOT_COMPLEX_ENOUGH);
  }

  if (password.includes(username)) {
    validationMessages.push(passwordValidationMessages.PASSWORD_INCLUDES_USERNAME);
  }

  return {
    isValid: validationMessages.length === 0,
    messages: validationMessages
  };
};

export const validatePasswordWithConfirm = (password: string, passwordConfirm: string, username: string) => {
  const validationMessages = [];

  if (password !== '' && !password.match(new RegExp(`^[${allowedCharacters}]+$`))) {
    validationMessages.push(passwordValidationMessages.PASSWORD_BAD_CHARACTERS);
  }

  if (password.length < minLength) {
    validationMessages.push(passwordValidationMessages.PASSWORD_TOO_SHORT.replace('{0}', String(minLength)));
  }

  if (password.length > maxLength) {
    validationMessages.push(passwordValidationMessages.PASSWORD_TOO_LONG.replace('{0}', String(maxLength)));
  }

  if (countComposition(password) < 3) {
    validationMessages.push(passwordValidationMessages.PASSWORD_NOT_COMPLEX_ENOUGH);
  }

  if (password?.toLowerCase().includes(username.toLowerCase())) {
    validationMessages.push(passwordValidationMessages.PASSWORD_INCLUDES_USERNAME);
  }

  if (password !== passwordConfirm) {
    validationMessages.push(passwordValidationMessages.REPEATED_PASSWORD_DIFFERENT);
  }

  return {
    isValid: validationMessages.length === 0,
    messages: validationMessages,
    passwordConfirm
  };
};
