import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { setCurrentPage } from 'Store/Actions/appActions';

import { Layout } from 'access_ctrl-ui';
import { Page } from 'Interfaces';

const NotFoundText = styled.h2`
  color: #fff;
`;
interface Props{
  setCurrentPage: (pageinfo:Page) => void;
}
const NotFound:React.FC<Props> = ({ setCurrentPage }) => {
  useEffect(() => {
    setCurrentPage({ name: 'Not found' });
  }, [setCurrentPage]);

  return (
    <Layout.Wrapper>
      <Layout.Section flexGrow={false}>
        <NotFoundText>The requested content does not exist</NotFoundText>
      </Layout.Section>
    </Layout.Wrapper>
  );
};

export default connect(() => ({

}), dispatch => (
  {
    setCurrentPage: setCurrentPage(dispatch)
  }
))(NotFound);
