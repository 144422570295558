import { Dispatch } from 'redux';

import { accessControlApi, webApi } from '../../Common/api';
import { addErrorMessage, addSuccessMessage } from './feedbackMessage';
import { apiCallErrorMessages, statusMessages } from '../../Common/messages';
import { User } from 'Interfaces';
import { AxiosResponse } from 'axios';
import { UserActionType } from 'Store/ActionEnums';

/**
  Get information for the specified user
  @param userId The user id to fetch
  @return The request promise
  TODO: Should this be removed from global store?
*/
export const fetchUserInfoToCurrentUser = (dispatch: Dispatch) => (userId: string):Promise<void> => {
  dispatch({
    type: UserActionType.FETCHING_USER_INFO
  });
  return accessControlApi.get(`Directory/users/${userId}`).then(identityResponse => {
    selectUser(dispatch)(identityResponse.data);

    dispatch({
      type: UserActionType.GOT_USER_INFO,
      payload: identityResponse.data
    });
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.USER_INFO_ERROR_MESSAGE, error);
  });
};

/**
  Get user identityTokens for the specified user
  @param userId The user to get identitiy tokens for
  TODO: Should this be removed from global store?
*/
export const fetchUserIdentities = (dispatch: Dispatch) => (userId: string) => {
  dispatch({
    type: UserActionType.FETCHING_USER_IDENTITIES
  });
  accessControlApi.get(`identity/users/${userId}/identities`).then(identityResponse => {
    dispatch({
      type: UserActionType.GOT_USER_IDENTITIES,
      payload: identityResponse.data
    });
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.USER_IDENTITIES_ERROR_MESSAGE, error);

    dispatch({
      type: UserActionType.FAILED_FETCHING_USER_IDENTITIES
    });
  });
};

/**
  Add a new user
  @param user The new user to add
  @param identityToken An identity token to add to the new user
  @return The request promise
  TODO: Can we do this better? We only return one promise request
*/
export const addUser = (dispatch: Dispatch) => (user: User, identityToken: string | null = null):Promise<AxiosResponse<User>> => {
  dispatch({
    type: UserActionType.ADDING_USER
  });

  const request = accessControlApi.post('Directory/users', user);
  request.then(createUserResponse => {
    dispatch({
      type: UserActionType.USER_ADDED,
      payload: createUserResponse.data
    });

    if (identityToken !== null) {
      addUserIdentityToken(dispatch)(createUserResponse.data.Id, identityToken);
    }

    addSuccessMessage(dispatch)(statusMessages.USER_ADDED);
    resetAllUsers(dispatch);
  }).catch((error) => {
    dispatch({
      type: UserActionType.FAILED_ADDING_USER
    });
    addErrorMessage(dispatch)(apiCallErrorMessages.ADD_USER_ERROR_MESSAGE, error);
  });

  return request;
};

/**
  Delete the specified user
  @param userId The ID of the user to delete
  @return The request promise
*/
export const deleteUser = (dispatch: Dispatch) => (userId: string) => {
  dispatch({
    type: UserActionType.DELETING_USER
  });

  return accessControlApi.delete(`Directory/users/${userId}`).then(deleteResponse => {
    dispatch({
      type: UserActionType.USER_DELETED,
      payload: deleteResponse.data
    });
    resetAllUsers(dispatch);
    addSuccessMessage(dispatch)(statusMessages.USER_DELETED);
  }).catch((error) => {
    dispatch({
      type: UserActionType.FAILED_DELETING_USER
    });
    addErrorMessage(dispatch)(apiCallErrorMessages.DELETING_USER_ERROR_MESSAGE, error);
  });
};
export const resetAllUsers = (dispatch: Dispatch) => () => {
  dispatch({
    type: UserActionType.RESET_ALL_USERS
  });
};
/**
  Edit access for user to devices
  @param deviceIds An array of deviceIds to add to the user
  @param userId The user ID to add or delete access for
  @return The request promise
*/
// Todo: Is not deviceIds used in backend?
export const editUserDevices = (dispatch: Dispatch) => (deviceIds: string[], userId: string, showMessage = true) => {
  dispatch({
    type: UserActionType.EDIT_USER_DEVICES_START
  });
  return webApi.post(`inventory/user/${userId}/inventories`, deviceIds).then(() => {
    dispatch({
      type: UserActionType.EDIT_USER_DEVICES_SUCCESS
    });

    showMessage && addSuccessMessage(dispatch)(statusMessages.USER_ACCESS_CHANGED);
    getDeviceForUser(dispatch)(userId);
  }).catch((error) => {
    dispatch({
      type: UserActionType.EDIT_USER_DEVICES_FAILED
    });

    addErrorMessage(dispatch)(apiCallErrorMessages.FAILED_CHANGING_USER_ACCESS, error);
  });
};

/**
  Add a new identity token to the specified user
  @param userId The user to add identity token to
  @param identityToken The new identity token
  @return The request promise
  TODO: Delete this function?
*/
export const addUserIdentityToken = (dispatch: Dispatch) => (userId: string, identityToken: string) => {
  dispatch({
    type: UserActionType.ADDING_IDENTITY_TOKEN
  });
  return accessControlApi.post(`Identity/users/${userId}/identities/${identityToken}`).then(addUserIdentityResponse => {
    dispatch({
      type: UserActionType.IDENTITY_TOKEN_ADDED,
      payload: identityToken
    });

    addSuccessMessage(dispatch)(statusMessages.ADDED_IDENTITY_TOKEN);
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.ADD_IDENTITY_TOKEN_ERROR_MESSAGE, error);
  });
};

/**
  Delete many identity tokens from the user
  @param userId The user to remove identity tokens for
  @param identities An array of identity tokens to delete
  @return The request promise
*/
export const batchDeleteIdentityTokens = (dispatch: Dispatch) => (userId: string, identities: string[]) => {
  dispatch({
    type: UserActionType.DELETING_IDENTITY_TOKENS
  });

  const batch = identities.map((identity: string) => {
    return accessControlApi.delete(`Identity/users/${userId}/identities/${identity}`);
  });

  return Promise.all(batch).then(() => {
    dispatch({
      type: UserActionType.IDENTITY_TOKENS_DELETED
    });

    fetchUserIdentities(dispatch)(userId);
    addSuccessMessage(dispatch)(statusMessages.REMOVED_IDENTITY_TOKENS);
  }).catch(error => {
    addErrorMessage(dispatch)(apiCallErrorMessages.DELETE_IDENTITY_TOKEN_ERROR_MESSAGE, error);

    dispatch({
      type: UserActionType.FAILED_DELETING_IDENTITY_TOKENS
    });
  });
};

/**
  Get all users
*/
export const getAllUsers = (dispatch: Dispatch) => () => {
  dispatch({ type: UserActionType.FETCHING_ALL_USERS });
  accessControlApi.get<User[], AxiosResponse<User[]>>('directory/users').then(allUsersResponse => {
    const { data } = allUsersResponse;
    if (data) {
      dispatch({
        type: UserActionType.GOT_ALL_USERS,
        payload: data
      });
    }
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_ALL_USERS_ERROR_MESSAGE, error);
    dispatch({ type: UserActionType.FAILED_FETCHING_ALL_USERS });
  });
};

/**
 *  Get count of all users
 * @param dispatch
 * @returns count of users
 */
export const getUserCount = (dispatch: Dispatch) => () => {
  accessControlApi.get<number>('directory/users/count').then(response => {
    const { data } = response;
    if (data) {
      dispatch({
        type: UserActionType.GOT_USER_COUNT,
        payload: data
      });
    }
  }).catch(err => {
    addErrorMessage(dispatch)('Error getting user count', err);
  });
};

export const getAllUsersWithOptions = (dispatch: Dispatch) => () => {
  dispatch({ type: UserActionType.FETCHING_ALL_USERS });
  webApi.get<User[], AxiosResponse<User[]>>('useroptions/users').then(allUsersResponse => {
    const { data } = allUsersResponse;
    if (data) {
      dispatch({
        type: UserActionType.GOT_ALL_USERS,
        payload: data
      });
    }
  }).catch((error) => {
    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_ALL_USERS_ERROR_MESSAGE, error);
    dispatch({ type: UserActionType.FAILED_FETCHING_ALL_USERS });
  });
};
/**
  Select a user
  TODO: Remove this from global state?
*/
export const selectUser = (dispatch: Dispatch) => (user: User) => {
  dispatch({
    type: UserActionType.SELECTED_USER,
    payload: user
  });
};

/**
  Update a user
  @param user The user to update
  @return The request promise
*/
export const updateUser = (dispatch: Dispatch) => (user: User) => {
  dispatch({
    type: UserActionType.UPDATE_USER_START
  });
  return accessControlApi.put('directory/users', user).then(response => {
    dispatch({
      type: UserActionType.UPDATE_USER_SUCCESS,
      payload: response.data
    });

    addSuccessMessage(dispatch)(statusMessages.USER_UPDATED);
    return response.data;
  }).catch(error => {
    dispatch({
      type: UserActionType.FAILED_UPDATING_USER
    });

    addErrorMessage(dispatch)(apiCallErrorMessages.UPDATE_USER_ERROR_MESSAGE, error);
  });
};

/**
  Get device the user has access to
  @param userId The user to get device access for
  @return The request promise
*/
export const getDeviceForUser = (dispatch: Dispatch) => (userId: string) => {
  dispatch({
    type: UserActionType.FETCHING_DEVICES_FOR_USER
  });
  return webApi.get(`inventory/user/${userId}/inventories`).then(inventoryResponse => {
    dispatch({
      type: UserActionType.GOT_DEVICES_FOR_USER,
      payload: inventoryResponse.data
    });
  }).catch((error) => {
    dispatch({
      type: UserActionType.FAILED_FETCHING_USER_DEVICES
    });
    addErrorMessage(dispatch)(apiCallErrorMessages.FETCHING_DEVICES_FOR_USER_ERROR_MESSAGE, error, false);
  });
};
