import { ReactComponent as RFIDLogo } from 'Assets/rfid.svg';
import styled from 'styled-components';
const StyledImageContainer = styled.div<{ $active: boolean}>`
  height: 150px;
  width: 150px;
  margin: auto;

  svg {
    animation: ${({ $active }) => $active ? 'glow 1.5s ease-in-out infinite alternate' : 'none'};
  }

  @keyframes glow {
    from {
      filter: drop-shadow(0 0 0 ${({ theme }) => theme.textColor}) brightness(1);
    }

    to {
      filter: drop-shadow(0 0 3px ${({ theme }) => theme.textColor}) brightness(1.2);
    }
  }
`;

const AnimatedImage:React.FC<{ active: boolean, }> = ({ active }) => {
  return (
    <StyledImageContainer $active={active}>
      <RFIDLogo />
    </StyledImageContainer>
  );
};
export default AnimatedImage;
