import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { Dimmer, Loader } from 'semantic-ui-react';
import {
  appInit,
  RFIDReaderReadTag,
  RFIDLogin,
  getLoggedInUserData,
  MFALogin
} from './Store/Actions/appActions';
import { themes } from './Common/theme';
import { loadingMessages } from './Common/messages';

import {
  TopBar,
  TopBarMobile,
  StatusMessages,
  NavBar
} from './Components/Global';

import AllModals from './Components/Modals/AllModals';

// Main pages:
import LoginForm from './Components/Pages/Login';
import Dashboard from './Components/Pages/Dashboard';
import Devices from './Components/Pages/Devices';
import DeviceDetails from './Components/Pages/Devices/DeviceDetails';
import Users from './Components/Pages/Users';
import UserDetails from './Components/Pages/Users/UserDetails';
import AuditLog from './Components/Pages/AuditLog';
import ProductionData from './Components/Pages/ProductionData';
import NoPermission from './Components/Pages/NoPermission';
import FAQ from './Components/Pages/FAQ';
import NotFound from './Components/Pages/NotFound';

import {
  mediaStyles,
  StyledMedia as Media,
  MediaContextProvider
} from './Components/Fresnel';

import 'semantic-ui-css/semantic.min.css';
import 'App.css';
import { RootState } from './Store/Reducers';
import { ThemeTypes } from 'access_ctrl-ui';
import { ConnectedRouter } from 'connected-react-router';
import { browserHistory } from './Store/store';
import PowerUser from 'Components/Pages/PowerUser';
import { getSoftwareVersions } from 'Store/Actions/deviceActions';
import RoutinesPage from 'Features/Routines/Components/RoutinesPage/RoutinesPage';
import RoutinePage from 'Features/Routines/Components/RoutinePage/RoutinePage';
import ReceiptsPage from 'Features/Routines/Receipts/ReceiptsPage/ReceiptsPage';
import LicensePage from 'Components/Pages/License';
import useCheckInput from 'Hooks/useCheckInput';
import useScanRfid from 'Hooks/useScanRfid';

const ContentContainer = styled.div`
  margin: 0;
  display: flex;
  flex: 1;
`;

const AppContainer = styled.div`
  @keyframes svg-shadow {
    from {
      filter: drop-shadow(0 0 0 ${({ theme }) => theme.colorPrimary}) brightness(1);
    }

    to {
      filter: drop-shadow(0 0 3px ${({ theme }) => theme.colorPrimary}) brightness(1.1);
    }
  }

  background: ${({ theme }) => theme.appBg};
  color: ${({ theme }) => theme.textColor};
  display: flex;
  flex: 1;
  height: inherit;
  font-family: ${({ theme }) => theme.fontBase};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1 {
    font-size: 2.125rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: ${({ theme }) => theme.fontBase};
    font-weight: normal;
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.textColorLink};

    :hover {
      color: ${({ theme }) => theme.textColorLinkHover};
    }
  }

  *[data-tooltip] {
    position: relative;
  }

  *[data-tooltip]::after {
    content: attr(data-tooltip);
    position: absolute;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: block;
    color: ${({ theme }) => theme.textColor};
    line-height: 16px;
    background: ${({ theme }) => theme.appBg};
    border: 1px solid ${({ theme }) => theme.textColor};
  }

  *[data-tooltip]:hover::after {
    opacity: 1;
  }

  *[data-tooltip]:hover::before {
    /* needed - do not touch */
    opacity: 1;

    /* customizable */
    background: ${({ theme }) => theme.appBg};
    margin-top: -50px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

const MediaContainer = styled.div`
  display: flex;
  height: 100%;
`;

const MediaContainerMobile = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

interface Props {
  getLoggedInUserData: () => void;
  getSoftwareVersions: () => void;
  appInit: () => void;
  loggedIn: boolean;
  fetchingRFIDTag: boolean;
  theme: ThemeTypes;
}

const App: React.FC<Props> = ({
  getLoggedInUserData,
  getSoftwareVersions,
  appInit,
  loggedIn,
  fetchingRFIDTag,
  theme
}) => {
  const showPowerUser = useCheckInput('show', 500);
  useScanRfid();

  useEffect(() => {
    appInit();
    getSoftwareVersions();
  }, [appInit, getSoftwareVersions]);

  useEffect(() => {
    if (loggedIn) {
      getLoggedInUserData();
    }
  }, [getLoggedInUserData, loggedIn]);
  return (
    <>
      <style>{mediaStyles}</style>
      <ThemeProvider theme={themes[theme]}>
        <ConnectedRouter history={browserHistory}>
          <MediaContextProvider>
            <AppContainer className='App'>
              <StatusMessages />
              {loggedIn && (
                <>
                  <Media at='mobile'>
                    <MediaContainerMobile>
                      <TopBarMobile />
                      <ContentContainer>
                        <Switch>
                          <Route exact path='/' component={Dashboard} />
                          <Route path='/auditlog/' component={AuditLog} />
                          <Route path='/devices' exact component={Devices} />
                          <Route
                            path='/productiondata/'
                            component={ProductionData}
                          />
                          <Route
                            path='/devices/:deviceId'
                            component={DeviceDetails}
                          />
                          <Route
                            path='/users/:userId'
                            component={UserDetails}
                          />
                          <Route path='/users' exact component={Users} />
                          <Route path='/FAQ' component={FAQ} />
                          <Route path='/routines/:routineId' component={RoutinePage} />
                          <Route exact path='/routines' component={RoutinesPage} />
                          {
                            showPowerUser && <Route path='/poweruser' component={PowerUser} />
                          }
                          <Route path='/receipts' component={ReceiptsPage} />
                          <Route path='/settings' component={LicensePage} />
                          <Route
                            path='/unauthorized'
                            component={NoPermission}
                          />
                          <Route path='*' component={NotFound} />
                        </Switch>

                        <AllModals />

                        <Dimmer active={fetchingRFIDTag}>
                          <Loader active={fetchingRFIDTag}>
                            {loadingMessages.CHECKING_RFID_TAG}
                          </Loader>
                        </Dimmer>
                      </ContentContainer>
                    </MediaContainerMobile>
                  </Media>
                  <Media greaterThanOrEqual='computer'>
                    <MediaContainer>
                      <NavBar />
                      <Wrapper>
                        <TopBar />
                        <ContentContainer>
                          <Switch>
                            <Route path='/auditlog/' component={AuditLog} />
                            <Route
                              path='/productiondata/'
                              component={ProductionData}
                            />
                            <Route path='/devices' exact component={Devices} />
                            <Route
                              path='/devices/:deviceId'
                              component={DeviceDetails}
                            />
                            <Route
                              path='/users/:userId'
                              component={UserDetails}
                            />
                            <Route path='/users' exact component={Users} />
                            <Route path='/FAQ' component={FAQ} />
                            <Route path='/routines' exact component={RoutinesPage} />
                            <Route path='/routines/:routineId' component={RoutinePage} />
                            {
                              showPowerUser && <Route path='/poweruser' component={PowerUser} />
                            }
                            <Route
                              path='/unauthorized'
                              component={NoPermission}
                            />
                            <Route exact path='/' component={Dashboard} />
                            <Route path='/receipts' component={ReceiptsPage} />
                            <Route path='/settings' component={LicensePage} />
                            <Route path='*' component={NotFound} />
                          </Switch>
                        </ContentContainer>
                        <AllModals />

                        <Dimmer active={fetchingRFIDTag}>
                          <Loader active={fetchingRFIDTag}>
                            {loadingMessages.CHECKING_RFID_TAG}
                          </Loader>
                        </Dimmer>
                      </Wrapper>
                    </MediaContainer>
                  </Media>
                </>
              )}
              {!loggedIn && <LoginForm />}
            </AppContainer>
          </MediaContextProvider>
        </ConnectedRouter>
      </ThemeProvider>
    </>
  );
};

export default connect(
  ({ app }: RootState) => ({
    loggedIn: app.loggedIn,
    fetchingRFIDTag: app.fetchingRFIDTag,
    theme: app.theme
  }),
  (dispatch) => ({
    getLoggedInUserData: getLoggedInUserData(dispatch),
    getSoftwareVersions: getSoftwareVersions(dispatch),
    appInit: appInit(dispatch),
    RFIDReaderReadTag: RFIDReaderReadTag(dispatch),
    RFIDLogin: RFIDLogin(dispatch),
    MFALogin: MFALogin(dispatch)
  })
)(App);
