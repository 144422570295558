import { Button, HeaderRow, Table, TableLink, TableRowType } from 'access_ctrl-ui';
import { ModalTypes } from 'Common/Enums';
import { Device } from 'Interfaces';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllDevices } from 'Store/Actions/deviceActions';
import styled from 'styled-components';
import { useRoutineModal } from '../Hooks/useRoutineModal';
import { getRoutines } from '../routineSlice';

const StyledHeaderRow = styled(HeaderRow)`
  display: flex;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  width: auto;
  align-self: flex-end;
`;

interface Props {
  devices?: Device[];
  loading: boolean;
  heading: string;
  hideEditButton?: boolean;
}

const DeviceRoutineList:React.FC<Props> = ({ devices, loading, heading, hideEditButton }) => {
  const [rows, setRows] = useState<TableRowType[]>([]);
  const [modalOpen, setModalOpen] = useState<{ open: boolean, type: ModalTypes, edit: boolean, title: string, close:() => void } | undefined>();
  const editDevices = () => setModalOpen({ open: true, type: ModalTypes.EDIT_ROUTINE_DEVICES_MODAL, edit: false, title: 'Edit Devices', close });
  const close = () => { setModalOpen(undefined); };

  const modal = useRoutineModal(modalOpen);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRoutines());
    getAllDevices(dispatch)(false, true);
  }, [dispatch, modalOpen]);

  useEffect(() => {
    setRows(devices?.map(device => {
      const { Id, Name } = device;
      return {
        sortData: {
          name: Name
        },
        key: Name,
        data: {
          name: <TableLink key={Id} to={`/devices/${Id}`} text={Name} />
        }
      };
    }) ?? []);
  }, [devices]);

  return (
    <>
      <StyledHeaderRow>
        <h2>{heading}</h2>
        {!hideEditButton && <StyledButton onClick={editDevices}>Edit devices</StyledButton>}
      </StyledHeaderRow>
      <Table
        loading={loading}
        setQueryString={false}
        key='deviceRoutineList'
        tableDataColumns={[{ key: 'name', name: 'Name' }]}
        tableDataRows={rows}
        defaultSort='name'
        defaultSortDir='asc'
      />
      {modal}
    </>
  );
};
export default DeviceRoutineList;
