import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { webApi } from 'Common/api';
import { StatusMessageTypes } from 'Common/Enums';
import { statusMessages } from 'Common/messages';
import { StringIdPair } from 'Interfaces/StringIdPair';
import { addFeedbackMessage } from 'Store/Actions/feedbackMessage';
import { DailyRoutine } from './Interfaces';

export interface RoutineState {
  loading: boolean;
  currentRoutine?: DailyRoutine;
  routines?: DailyRoutine[];
  error?: Error
  types?: Record<number, string>
  statusTypes?: Record<number, string>
}
export const initialState:RoutineState = {
  loading: false
};
const getTypes = createAsyncThunk('dailyinspection/getTypes', async () => {
  const response = await webApi.get('/dailyinspection/types');
  return response.data;
});

const getStatusTypes = createAsyncThunk('dailyinspection/getStatusTypes', async () => {
  const response = await webApi.get('/dailyinspection/types');
  return response.data;
});
const setRoutinesForDevice = createAsyncThunk('dailyinspection/setRoutinesForDevice', async (object:StringIdPair, thunkApi) => {
  try {
    await webApi.post(`/dailyinspection/device/${object.id}`, { routineIds: object.idList });
  } catch (error:unknown) {
    addFeedbackMessage(thunkApi.dispatch)((error as AxiosError).message, StatusMessageTypes.ERROR);
    return undefined;
  }
});
const setRoutineForDevices = createAsyncThunk('dailyinspection/setRoutinesForDevice', async (object:StringIdPair, thunkApi) => {
  try {
    await webApi.post(`/dailyinspection/${object.id}/devices`, { deviceIds: object.idList });
  } catch (error:unknown) {
    addFeedbackMessage(thunkApi.dispatch)((error as AxiosError).message, StatusMessageTypes.ERROR);
    return undefined;
  }
});
const getRoutineById = createAsyncThunk('dailyinspection/getById',
  async (id:string, thunkApi) => {
    try {
      const routine = await webApi.get<DailyRoutine>(`/dailyinspection/${id}`);
      return routine.data;
    } catch (error:unknown) {
      addFeedbackMessage(thunkApi.dispatch)((error as AxiosError).message, StatusMessageTypes.ERROR);
      return undefined;
    }
  }
);
const getRoutines = createAsyncThunk('dailyinspection/get',
  async (_:void, thunkApi) => {
    try {
      const dailyinspection = await webApi.get<DailyRoutine[]>('/dailyinspection');
      return dailyinspection.data;
    } catch (error: unknown) {
      addFeedbackMessage(thunkApi.dispatch)((error as AxiosError).message, StatusMessageTypes.ERROR);
      return undefined;
    }
  }
);
const saveRoutine = createAsyncThunk('dailyinspection/save',
  async (routine:Omit<DailyRoutine, 'Id'>, thunkApi) => {
    try {
      const response = await webApi.post('/dailyinspection/insert', routine);
      addFeedbackMessage(thunkApi.dispatch)(statusMessages.ROUTINE_ADDED, StatusMessageTypes.SUCCESS);
      return response.data.Id;
    } catch (error) {
      addFeedbackMessage(thunkApi.dispatch)((error as AxiosError).message, StatusMessageTypes.ERROR);
      return undefined;
    }
  }
);
const updateRoutine = createAsyncThunk('dailyinspection/update',
  async (routine:DailyRoutine, thunkApi) => {
    try {
      const response = await webApi.put('/dailyinspection', routine);
      addFeedbackMessage(thunkApi.dispatch)(statusMessages.ROUTINE_UPDATED, StatusMessageTypes.SUCCESS);
      return response.status === 200 ? routine : undefined;
    } catch (error) {
      addFeedbackMessage(thunkApi.dispatch)((error as AxiosError).message, StatusMessageTypes.ERROR);
      return undefined;
    }
  }
);
const deleteRoutine = createAsyncThunk('dailyinspection/delete',
  async (id:string, thunkApi) => {
    try {
      const response = await webApi.delete(`/dailyinspection/${id}`);
      addFeedbackMessage(thunkApi.dispatch)(statusMessages.ROUTINE_DELETED, StatusMessageTypes.SUCCESS);
      return response.status === 200 ? response.data.Id : undefined;
    } catch (error:unknown) {
      addFeedbackMessage(thunkApi.dispatch)((error as AxiosError).message, StatusMessageTypes.ERROR);
      return undefined;
    }
  }
);
export const routineSlice = createSlice({
  name: 'routine',
  initialState,
  reducers: {
    resetCurrentRoutine: (state) => {
      state.currentRoutine = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRoutineById.pending, (state) => {
      state.loading = true;
    })
      .addCase(getRoutineById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentRoutine = action.payload;
      })
      .addCase(getRoutineById.rejected, (state) => {
        state.loading = false;
        state.currentRoutine = undefined;
      })
      .addCase(getTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.types = action.payload;
      })
      .addCase(getTypes.rejected, (state) => {
        state.loading = false;
        state.types = undefined;
      })
      .addCase(getStatusTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatusTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.statusTypes = action.payload;
      })
      .addCase(getStatusTypes.rejected, (state) => {
        state.loading = false;
        state.statusTypes = undefined;
      })
      .addCase(getRoutines.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoutines.fulfilled, (state, action) => {
        state.loading = false;
        state.routines = action.payload;
      })
      .addCase(getRoutines.rejected, (state) => {
        state.loading = false;
        state.routines = undefined;
      })
      .addCase(saveRoutine.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveRoutine.fulfilled, (state, action) => {
        state.loading = false;
        state.currentRoutine = undefined;
        state.routines = undefined;
      })
      .addCase(saveRoutine.rejected, (state) => {
        state.loading = false;
        state.currentRoutine = undefined;
      })
      .addCase(updateRoutine.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRoutine.fulfilled, (state, action) => {
        state.loading = false;
        state.routines = state.routines && action.payload ? [...state.routines, action.payload] : undefined;
        state.currentRoutine = action.payload;
      })
      .addCase(updateRoutine.rejected, (state) => {
        state.loading = false;
        state.currentRoutine = undefined;
      })
      .addCase(deleteRoutine.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRoutine.fulfilled, (state, action) => {
        state.loading = false;
        state.routines = undefined;
        state.currentRoutine = undefined;
      })
      .addCase(deleteRoutine.rejected, (state) => {
        state.loading = false;
        state.currentRoutine = undefined;
      })
      .addDefaultCase((state) => {
        state = initialState;
      });
  }

});
export { setRoutinesForDevice, setRoutineForDevices, getTypes, getStatusTypes, getRoutineById, getRoutines, saveRoutine, updateRoutine, deleteRoutine };
export const { resetCurrentRoutine } = routineSlice.actions;
export default routineSlice.reducer;
