import { RFIDReaderTagTypes } from '../Enums';
// TODO: Move to helper function/enum/obj with tag type and name
export const tagTypeToString = function (tagType : RFIDReaderTagTypes): string {
  switch (tagType) {
    case RFIDReaderTagTypes.NOTAG: return 'NOTAG';
    case RFIDReaderTagTypes.LFTAG_EM4102: return 'EM4x02/CASI-RUSCO';
    case RFIDReaderTagTypes.LFTAG_HITAG1S: return 'HITAG 1/HITAG S';
    case RFIDReaderTagTypes.LFTAG_HITAG2: return 'HITAG 2';
    case RFIDReaderTagTypes.LFTAG_EM4150: return 'EM4x50';
    case RFIDReaderTagTypes.LFTAG_AT5555: return 'T55x7';
    case RFIDReaderTagTypes.LFTAG_EM4026: return 'LFTAG_EM4026';
    case RFIDReaderTagTypes.LFTAG_HITAGU: return 'LFTAG_HITAGU';
    case RFIDReaderTagTypes.LFTAG_EM4305: return 'LFTAG_EM4305';
    case RFIDReaderTagTypes.LFTAG_HIDPROX: return 'HID Prox';
    case RFIDReaderTagTypes.LFTAG_TIRIS: return 'ISO HDX/TIRIS';
    case RFIDReaderTagTypes.LFTAG_COTAG: return 'Cotag';
    case RFIDReaderTagTypes.LFTAG_IOPROX: return 'ioProx';
    case RFIDReaderTagTypes.LFTAG_INDITAG: return 'Indala';
    case RFIDReaderTagTypes.LFTAG_HONEYTAG: return 'NexWatch';
    case RFIDReaderTagTypes.LFTAG_AWID: return 'AWID';
    case RFIDReaderTagTypes.LFTAG_GPROX: return 'G-Prox';
    case RFIDReaderTagTypes.LFTAG_PYRAMID: return 'Pyramid';
    case RFIDReaderTagTypes.LFTAG_KERI: return 'Keri';
    case RFIDReaderTagTypes.LFTAG_DEISTER: return 'LFTAG_DEISTER';
    case RFIDReaderTagTypes.HFTAG_MIFARE: return 'ISO14443A/MIFARE';
    case RFIDReaderTagTypes.HFTAG_ISO14443B: return 'ISO14443B';
    case RFIDReaderTagTypes.HFTAG_ISO15693: return 'ISO15693';
    case RFIDReaderTagTypes.HFTAG_LEGIC: return 'LEGIC';
    case RFIDReaderTagTypes.HFTAG_HIDICLASS: return 'HID iCLASS';
    case RFIDReaderTagTypes.HFTAG_FELICA: return 'FeliCa';
    case RFIDReaderTagTypes.HFTAG_SRX: return 'SRX';
    case RFIDReaderTagTypes.HFTAG_NFCP2P: return 'NFC Peer-to-Peer';

    default: return 'UNKNOWN';
  }
};
