import { IconTypes, ServerSideTableRowType, Table, TableKeyType, TableLink, TableRowType } from 'access_ctrl-ui';
import React, { useEffect, useState } from 'react';
import TableFilter from '../../Components/Global/TableFilter';
import { Device } from 'Interfaces';
import { columnBName, columnCName, loadingMessages } from 'Common/messages';
import { nameSort, nameSortAlphaNum } from 'Util/sortFn';
import { FilterTypes } from 'Common/Enums/FilterTypes';
import { FilterProps } from 'Interfaces/FilterProps';

const tableDataColumns = [
  {
    name: 'Name',
    key: 'deviceName',
    sortFn: (dir:TableKeyType, col:TableKeyType) => {
      return nameSortAlphaNum(dir, col);
    }
  },
  {
    name: columnBName,
    key: 'columnB',
    sortFn: (dir:TableKeyType, col:TableKeyType) => {
      return nameSort(dir, col);
    }
  },
  {
    name: columnCName,
    key: 'columnC',
    sortFn: (dir:TableKeyType, col:TableKeyType) => {
      return nameSort(dir, col);
    }
  }
];

const filters: FilterProps[] = [
  { filterKey: 'deviceName', label: 'Device', filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'All' },
  { filterKey: 'columnB', label: columnBName, filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'All' },
  { filterKey: 'columnC', label: columnCName, filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'All' }
];

/**
 * This component renders a filterable table with all receipts
 * @returns A filterable table with all receipts
 */
const DevicesTable:React.FC<{devices?: Device[], loading: boolean, permissionToViewContent: boolean, showFilters?: boolean, CustomButton?: JSX.Element}> = ({ devices, loading, permissionToViewContent, showFilters, CustomButton }) => {
  const [tableData, setTableData] = useState<TableRowType[]>([] as TableRowType[]);
  const [filteredTableData, setFilteredTableData] = useState<TableRowType[]>([] as TableRowType[]);

  /**
   * Set table data s
   */
  useEffect(() => {
    if (devices) {
      setTableData(devices.map(({ Name, Id, B, C }) => ({
        key: Id,
        sortData: {
          deviceName: Name
        },
        data: {
          deviceName: <TableLink to={`/devices/${Id}`} iconType={IconTypes.Device} text={Name} />,
          columnB: B,
          columnC: C
        }
      })));
    }
  }, [devices]);

  /**
   * Pull data and set filterTableData state
   * @param data filteredTableData
   */
  const pullFilteredData = (data: TableRowType[]) => {
    setFilteredTableData(data);
  };

  return (
    <>
      <TableFilter
        filters={filters}
        pullFilteredTableData={pullFilteredData}
        tableData={tableData}
        tableTitle='Devices'
        tableColumns={tableDataColumns}
        excelSheetName='Devices'
        CustomButton={CustomButton}
        permissionToViewContent={permissionToViewContent}
        showFilters={showFilters || false}
        exportable
      />
      <Table
        setQueryString={false}
        rowsPerPage={25}
        defaultSortDir='asc'
        defaultSort='deviceName'
        tableDataColumns={tableDataColumns}
        tableDataRows={permissionToViewContent ? filteredTableData : [] as ServerSideTableRowType[]}
        loading={loading}
        loadingMessage={loadingMessages.LOADING_DEVICES_TABLE}
        emptyText={permissionToViewContent ? 'No devices' : 'You don\'t have permission to view devices'}
        sortable
      />
    </>
  );
};

export default DevicesTable;
