import React from 'react';
import styled from 'styled-components';
import { Layout } from 'access_ctrl-ui';

const StyledChangeLog = styled.div`
  margin: 3.5rem;
  border: 2px solid rgb(239, 124, 0);
  background-color: #1e1d22;
  width: 50rem;
  font-size: 1.5rem;
  min-width: 10rem;
  padding: 1rem;
`;

const SystemPane:React.FC = () => {
  return (
    <Layout.Wrapper>
      <Layout.Section flexGrow={false}>
        <StyledChangeLog>Latest commit message: {process.env.REACT_APP_FRONTEND_VERSION}</StyledChangeLog>
      </Layout.Section>
    </Layout.Wrapper>
  );
};

export default SystemPane;
