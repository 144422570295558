import { Device, KeyStringDictionary } from 'Interfaces';
import styled from 'styled-components';
import React from 'react';
import { ChevronsUp, IconProps } from 'react-feather';

// Todo: rewrite this as hook

// Todo: Animate this
const StyledIcon = styled(ChevronsUp)<IconProps>`
  color: rgb(239, 124, 0);
  height: 1.5rem;
  width: auto;
  vertical-align: middle;
`;

/**
 * Get version number from package filename
 * @param {string} filename - the full package filename {ex: rfid-core_0.9.82-346.deb}
 * @returns {string} a formatted string containing only version number {0.9.82-346}
 */
export const getVersion = (filename:string):string => {
  return filename.split('.deb')[0]?.split('_')[1] ?? '';
};

/**
 * Check if current version is the latest possible
 * @param {string} currentVersion - current software version
 * @param newVersion - potential software update
 * @returns {boolean} update status of software
 */
export const versionNeedsUpdate = (currentVersion: string, newVersion: string):boolean => {
  if (!currentVersion || !newVersion) {
    return false;
  }
  if (currentVersion === newVersion) {
    return false;
  }
  return true;
};
/**
 * Get update icon if device need update
 * @param {Device} device - device to check
 * @param {KeyStringDictionary} softwares - available software versions
 * @returns {React.ReactFragment} update icon or undefined if device does not need update
 */
export const getUpdateIcon = (device: Device, softwares: KeyStringDictionary) => {
  if (device.Options && device.Options['SW.VERSION']?.Value && device.Options['LCU.TYPE']?.Value) {
    const newVersion = softwares[toLowerWithUnderScore(device.Options['LCU.TYPE'].Value)];
    return versionNeedsUpdate(device.Options['SW.VERSION']?.Value, newVersion) && <StyledIcon size='14' name='chevronsUp' />;
  }
};
export const translateSoftwareNames = (name:string) => {
  switch (name) {
    case 'dell':
      return 'software';
    case 'asus_pv100a':
      return 'pv100a';
    default:
      return name;
  }
};

const toLowerWithUnderScore = (value: string) => value.replace(' ', '_')?.toLowerCase();
