import axios from 'axios';
import { AppActionType } from 'Store/ActionEnums';
import store, { browserHistory as history } from 'Store/store';
import { SoftwareUpdateEndpoint } from './Constant';
import { session } from './session';

export const webApi = axios.create({
  baseURL: process.env.REACT_APP_WEB_ADMIN_BASE_URL
});
export const accessControlApi = axios.create({
  baseURL: process.env.REACT_APP_ACCESS_CONTROL_BASE_URL
});
export const softwareUpdateApi = axios.create({
  baseURL: SoftwareUpdateEndpoint
});
export const setApiAuthTokens = (accessToken: string) => {
  webApi.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
  accessControlApi.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
};

webApi.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 404) {
    history.replace('/404');
  } else if (error?.response?.status === 401 || error?.response?.status === 403) {
    const tokenError = error?.response?.headers?.['www-authenticate'] ?? error?.response?.headers?.['WWW-Authenticate'];
    if (tokenError && tokenError.includes('invalid_token')) {
      store.dispatch({ type: AppActionType.ADMIN_LOGOUT });
      session.logout();
      history.replace('/');
      return Promise;
    }
  }

  return Promise.reject(error);
});

accessControlApi.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 404) {
    history.replace('/404');
  }
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    const tokenError = error?.response?.headers?.['www-authenticate'] ?? error?.response?.headers?.['WWW-Authenticate'];
    if (tokenError && tokenError.includes('invalid_token')) {
      store.dispatch({ type: AppActionType.ADMIN_LOGOUT });
      session.logout();
      history.replace('/');
      return Promise;
    }
    history.replace('/unauthorized');
  }

  return Promise.reject(error);
});
