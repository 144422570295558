import { ServerSideTable, ServerSideTableRowType, TableRowType, TableSortDirectionType, PaginationProps } from 'access_ctrl-ui';
import _ from 'lodash';
import { formatDate } from '../../Util/formatDate';
import React, { useCallback, useEffect, useState } from 'react';
import TableFilter from '../../Components/Global/TableFilter';
import { AuditLogItem, Filter } from 'Interfaces';
import { getDeviceDisplayName, getDeviceNameLink, getUserDisplayName, getUserNameLink } from 'Util/auditLogHelpers';
import { logEventTypeLabels, logEventTypes } from 'Common/Constant';
import { LogAuthorizationTypes, LogEventType, LogOrderByColumn } from 'Common/Enums';
import { loadingMessages } from 'Common/messages';
import { FilterTypes } from 'Common/Enums/FilterTypes';
import { FilterProps } from 'Interfaces/FilterProps';
import { useCurrentEffect } from 'use-current-effect';
import { getAuditLog, getExcelAuditLog } from 'Store/Actions/auditLogActions';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getAllUsers } from 'Store/Actions/userActions';
import { getAllDevices } from 'Store/Actions/deviceActions';
import { useAppSelector } from 'Store/hooks';
import { DropdownItemProps } from 'semantic-ui-react';

const tableDataColumns = [
  {
    name: 'Timestamp',
    key: 'timestamp',
    order: { asc: LogOrderByColumn.TimestampAsc.toString(), desc: LogOrderByColumn.TimestampDesc.toString() }
  },
  {
    name: 'Device',
    key: 'deviceName',
    order: { asc: LogOrderByColumn.InventoryNameAsc.toString(), desc: LogOrderByColumn.InventoryNameDesc.toString() }
  },
  {
    name: 'User',
    key: 'userName',
    order: { asc: LogOrderByColumn.UserNameAsc.toString(), desc: LogOrderByColumn.UserNameDesc.toString() }
  },
  {
    name: 'Type',
    key: 'type',
    order: { asc: LogOrderByColumn.TypeAsc.toString(), desc: LogOrderByColumn.TypeDesc.toString() }
  },
  {
    name: 'Result',
    key: 'result',
    order: { asc: LogOrderByColumn.SuccessfulAsc.toString(), desc: LogOrderByColumn.SuccessfulDesc.toString() }
  }
];

const initialFilter : Filter = {
  inventoryIds: undefined,
  userIds: undefined,
  b: '',
  c: '',
  from: null,
  to: null,
  authorizationResult: +(LogAuthorizationTypes.NOT_SUCCESSFUL | LogAuthorizationTypes.SUCCESSFUL),
  events: [LogEventType.ALL],
  startIndex: 0,
  stopIndex: 25,
  orderBy: LogOrderByColumn.TimestampDesc
};

const rowsPerPage = 25;
const successfulFilter: DropdownItemProps[] = [{ key: 1, text: 'Successful', value: '1' }, { key: 2, text: 'Not Successful', value: '2' }];/**
 * This component renders a filterable table with all receipts
 * @returns A filterable table with all receipts
 */
const AuditLogTable:React.FC<{ loading: boolean, viewAuditLogsAction: boolean, showFilters?: boolean}> = ({ loading, viewAuditLogsAction, showFilters }) => {
  const [tableData, setTableData] = useState<TableRowType[]>([] as TableRowType[]);
  const [auditlogs, setAuditlogs] = useState<AuditLogItem[] | null>(null);
  const [fetchFilters, setFetchFilters] = useState<Filter>(initialFilter);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const { allUsers } = useAppSelector(state => state.user);
  const { devices } = useAppSelector(state => state.device);

  /**
   * This function gets all logEvents and return them as dropdownItems
   * @returns event types as DropdownItem
   */
  const getEventTypeOptions = () => {
    return Object.keys(logEventTypeLabels).map((key: string) => {
      return { text: logEventTypeLabels[+key]?.label, value: key };
    }).filter(label => Number(label.value) !== logEventTypes.ALL) as DropdownItemProps[];
  };

  /**
   * Filters props managing FILTER UX
   */
  const [filters, setFilters] = useState<FilterProps[]>([
    { filterKey: 'timestamp', label: 'Date', filterType: FilterTypes.DATE_RANGE },
    { filterKey: 'inventoryId', dropDownItems: [] as DropdownItemProps[], label: 'Device', filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'All devices' },
    { filterKey: 'userId', dropDownItems: [] as DropdownItemProps[], label: 'Users', filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'All users' },
    { filterKey: 'type', dropDownItems: getEventTypeOptions(), label: 'Type', filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'All types' },
    { filterKey: 'result', dropDownItems: successfulFilter, label: 'Results', filterType: FilterTypes.DROPDOWN_LIST, placeholder: 'All' }
  ]);

  /**
   * Update dropdownItems in device filter
   */
  useEffect(() => {
    const newFilters = [...filters];
    devices && (newFilters[1].dropDownItems = devices.map(device => {
      return { text: `${device.Name.toLowerCase()}`, value: device.Id };
    })).sort((a, b) => (a.text > b.text) ? 1 : -1);
    setFilters(newFilters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  /**
   * Update dropdownItems in users filter
   */
  useEffect(() => {
    const newFilters = [...filters];
    allUsers && (newFilters[2].dropDownItems = allUsers.map(user => {
      return { text: `${user.FirstName?.toLowerCase()} ${user.LastName?.toLowerCase()}`, value: user.Id };
    })).sort((a, b) => (a.text > b.text) ? 1 : -1);
    setFilters(newFilters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers]);

  /**
   * Set filters for fetching data
   */
  const setFilterFromParams = useCallback(() => {
    const params = queryString.parse(location.search);

    const activePage = +(params.activePage || 1);
    const startIndex = (+activePage - 1) * rowsPerPage;
    const stopIndex = activePage * rowsPerPage;
    if (!isNaN(activePage)) {
      setCurrentTablePage(activePage);
    }

    setFetchFilters({
      userIds: params.userId?.toString().split(',') ?? undefined,
      inventoryIds: params.inventoryId?.toString().split(',') ?? undefined,
      b: params.columnB?.toString() ?? '',
      c: params.columnC?.toString() ?? '',
      events: params.type ? (params.type as string).split(',').map(item => +item as LogEventType) : [LogEventType.ALL],
      authorizationResult: params.result ? +(params.result as string).split(',').reduce((partialSum, item) => partialSum + +item, 0) : (LogAuthorizationTypes.NOT_SUCCESSFUL | LogAuthorizationTypes.SUCCESSFUL),
      from: params.timestamp_from ? new Date(params.timestamp_from as string) : null,
      to: params.timestamp_to ? new Date(params.timestamp_to as string) : null,
      startIndex,
      stopIndex,
      orderBy: parseInt(params.orderBy as string) || LogOrderByColumn.TimestampDesc.toString()
    });
  }, [location]);

  /**
   * Fetch all users and devices
   */
  useEffect(() => {
    getAllUsers(dispatch)();
    getAllDevices(dispatch)();
  }, [dispatch]);

  /**
   * SetFilterFromParams
   */
  useEffect(() => {
    setFilterFromParams();
  }, [setFilterFromParams]);

  /**
   * Default sorting funciton used in table
   */
  const defaultSort = (() => {
    let obj = {};
    tableDataColumns.forEach((col) => {
      if (fetchFilters.orderBy.toString() === col.order.asc) {
        obj = { [col.key]: 'asc' };
      } else if (fetchFilters.orderBy.toString() === col.order.desc) {
        obj = { [col.key]: 'desc' };
      }
    });
    return obj as TableSortDirectionType;
  })();

  const handleColumnOrderChange = (_e:React.SyntheticEvent<HTMLElement, MouseEvent>, _sortDirection:TableSortDirectionType, columnOrder?:string) => {
    const query = new URLSearchParams(location.search);
    if (columnOrder) {
      query.set('orderBy', columnOrder);
      query.delete('activePage');
      history.replace({ ...history.location, search: query.toString() });
    }
  };

  const handlePageChange = (_e:React.SyntheticEvent<HTMLElement, MouseEvent>, page:PaginationProps) => {
    if (page && page.activePage && !isNaN(page.activePage as number)) {
      setCurrentTablePage(page.activePage as number);
      const query = new URLSearchParams(location.search);
      query.set('activePage', page.activePage.toString());
      history.replace({ ...history.location, search: query.toString() });
    }
  };

  /**
   * Fetching audit logs
   */
  useCurrentEffect((isCurrent) => {
    const fetchData = async () => {
      const data = await getAuditLog(dispatch)(fetchFilters);
      if (isCurrent()) {
        setAuditlogs(data && data.Items ? data.Items : []);
        setNumPages(data ? Math.ceil(data.TotalCount / rowsPerPage) : 1);
      }
    };

    if (viewAuditLogsAction) {
      fetchData();
    }
  }, [getAuditLog, viewAuditLogsAction, currentTablePage, fetchFilters]);

  /**
   * Set table data
   */
  useEffect(() => {
    if (auditlogs) {
      const _tdata = [];
      const end = Math.min(rowsPerPage, auditlogs.length);

      for (let i = 0; i < end; i++) {
        _tdata.push({
          key: i,
          successful: auditlogs[i].Successful,
          name: auditlogs[i].DeviceName ?? auditlogs[i].UserName,
          sortData: {
            deviceName: getDeviceDisplayName(auditlogs[i].InventoryId, auditlogs[i].InventoryDisplayName),
            userName: getUserDisplayName(auditlogs[i].UserId, auditlogs[i].UserDisplayName)
          },
          data: {
            timestamp: formatDate(auditlogs[i].Timestamp),
            deviceName: getDeviceNameLink(auditlogs[i]),
            userName: getUserNameLink(auditlogs[i]),
            type: (logEventTypeLabels[auditlogs[i].Event] ? logEventTypeLabels[auditlogs[i].Event].label : auditlogs[i].Event),
            result: auditlogs[i].Successful ? 'Successful' : 'Not Successful'
          }
        });
      }
      setTableData(_tdata);
    }
  }, [currentTablePage, auditlogs, location]);

  /**
   * Fetching all data when downloading excel data sheet
   * @returns TableRows as a promise
   */
  const getExcelData = async () => {
    const _filterReq = _.clone(fetchFilters);
    _filterReq.stopIndex = numPages * rowsPerPage;
    _filterReq.startIndex = 1;

    const auditLogData = await getExcelAuditLog(dispatch)(_filterReq);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _tdata: TableRowType[] = auditLogData.Items.map((item: { Successful: any; InventoryId: string; InventoryDisplayName: string; B: any; C: any; UserId: string; UserDisplayName: string; Event: string | number; Timestamp: string; }, i: any) => {
      return {
        key: i,
        successful: item.Successful,
        data: {
          deviceName: getDeviceDisplayName(item.InventoryId, item.InventoryDisplayName),
          columnB: item.B,
          columnC: item.C,
          userName: getUserDisplayName(item.UserId, item.UserDisplayName),
          type: logEventTypeLabels[item.Event].label,
          timestamp: formatDate(item.Timestamp),
          result: item.Successful ? 'Successful' : 'Not Successful'
        }
      };
    });

    return _tdata;
  };

  return (
    <>
      <TableFilter
        filters={filters}
        tableData={tableData}
        tableTitle='Audit Logs'
        tableColumns={tableDataColumns}
        exportTableFnc={getExcelData}
        excelSheetName='Audit Logs'
        permissionToViewContent={viewAuditLogsAction}
        showFilters={showFilters || false}
        exportable
      />
      <ServerSideTable
        onColumnOrderChange={handleColumnOrderChange}
        onPageChange={handlePageChange}
        activePage={currentTablePage}
        tableDataColumns={tableDataColumns}
        tableDataRows={viewAuditLogsAction ? tableData : [] as ServerSideTableRowType[]}
        numPages={numPages}
        loading={loading}
        loadingMessage={loadingMessages.LOADING_AUDIT_LOGS}
        emptyText={viewAuditLogsAction ? 'No audit logs' : 'You don\'t have permission to view audit logs'}
        defaultSort={defaultSort}
        sortable
      />
    </>
  );
};

export default AuditLogTable;
