/**
  Actiontypes dispatched from deviceActions
*/
export enum DeviceActionTypes {
  ADD_DEVICE_START = 'ADD_DEVICE_START',
  ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS',
  DELETE_DEVICE_START = 'DELETE_DEVICE_START',
  DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS',
  FAILED_ADDING_DEVICE = 'FAILED_ADDING_DEVICE',
  FAILED_DELETING_DEVICE = 'FAILED_DELETING_DEVICE',
  FAILED_FETCHING_ALL_DEVICES = 'FAILED_FETCHING_ALL_DEVICES',
  FAILED_FETCHING_SINGLE_DEVICE = 'FAILED_FETCHING_SINGLE_DEVICE',
  FAILED_GETTING_DISTINCT_COL_B_VALUES = 'FAILED_GETTING_DISTINCT_COL_B_VALUES',
  FAILED_GETTING_DISTINCT_COL_C_VALUES = 'FAILED_GETTING_DISTINCT_COL_C_VALUES',
  FAILED_UPDATING_DEVICE = 'FAILED_UPDATING_DEVICE',
  FETCHING_ALL_DEVICES = 'FETCHING_ALL_DEVICES',
  FETCHING_SINGLE_DEVICE = 'FETCHING_SINGLE_DEVICE',
  GETTING_DISTINCT_COL_B_VALUES = 'GETTING_DISTINCT_COL_B_VALUES',
  GETTING_DISTINCT_COL_C_VALUES = 'GETTING_DISTINCT_COL_C_VALUES',
  GOT_ALL_DEVICES = 'GOT_ALL_DEVICES',
  GOT_DISTINCT_COL_B_VALUES = 'GOT_DISTINCT_COL_B_VALUES',
  GOT_DISTINCT_COL_C_VALUES = 'GOT_DISTINCT_COL_C_VALUES',
  GOT_SINGLE_DEVICE = 'GOT_SINGLE_DEVICE',
  UPDATE_DEVICE_START = 'UPDATE_DEVICE_START',
  UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS',
  REFRESHED_CURRENT_DEVICE = 'REFRESHED_CURRENT_DEVICE',
  REFRESHING_CURRENT_DEVICE = 'REFRESHING_CURRENT_DEVICE',
  FAILED_REFRESHING_CURRENT_DEVICE = 'FAILED_REFRESHING_CURRENT_DEVICE',
  NO_CURRENT_DEVICE = 'NO_CURRENT_DEVICE',
  ADDING_MULTIPLE_USERS_START = 'ADDING_MULTIPLE_USERS_START',
  ADDING_MULTIPLE_USERS_END = 'ADDING_MULTIPLE_USERS_START',
  FETCHING_LATEST_SOFTWARE_VERSIONS_START = 'FETCHING_LATEST_SOFTWARE_VERSIONS_START',
  FETCHING_LATEST_SOFTWARE_VERSIONS_SUCCESS = 'FETCHING_LATEST_SOFTWARE_VERSIONS_SUCCESS',
  FAILED_FETCHING_LATEST_SOFTWARE_VERSIONS = 'FAILED_FETCHING_LATEST_SOFTWARE_VERSIONS',
  GOT_DEVICE_COUNT = 'GOT_DEVICE_COUNT'
}
