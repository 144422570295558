import React from 'react';
import { X } from 'react-feather';
import styled from 'styled-components';

const StatusLineWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 ${({ theme }) => theme.sizes.lg};
  box-sizing: content-box;

  .faulty-connection-symbol {
    padding: 0 ${({ theme }) => theme.sizes.sm};
    display: flex;
    align-items: center;

    & svg {
      color: ${({ theme }) => theme.textColorError};
    }
  }
`;

interface LineProps {
  active: boolean;
}
const Line = styled.div<LineProps>`
  width: 100%;
  border-bottom: 1px solid;
  border-color: ${({ active, theme }) => active ? theme.colorPrimary : theme.textColorSecondary};
`;

interface StatusLineProps {
  active: boolean
}
const StatusLine: React.FC<StatusLineProps> = ({ active }) => {
  return (
    <StatusLineWrapper className='line-wrapper'>
      {
        !active
          ? (
            <>
              <Line active={false} />
              <div className='faulty-connection-symbol'><X size={14} /></div>
              <Line active={false} />
            </>
          )
          : (
            <>
              <Line active style={{ paddingTop: '7px' }} />
            </>
          )
      }
    </StatusLineWrapper>
  );
};
export default StatusLine;
