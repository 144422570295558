import { PermissionText, PermissionOption, AppPermissions } from 'Interfaces';

export const permissions: AppPermissions = {
  all: 0,
  viewDashboard: 1,
  viewDevices: 2,
  deviceAdmin: 4,
  viewUsers: 8,
  userAdmin: 16,
  viewAuditLog: 32,
  // viewProductionData: 128, Add later when production data feature is added
  viewDailyInspection: 256
};

export const permissionsText: PermissionText = {
  all: 'All',
  deviceAdmin: 'Device Admin',
  userAdmin: 'User Admin',
  viewAuditLog: 'View AuditLog',
  viewDashboard: 'View Dashboard',
  viewDevices: 'View Devices',
  // viewProductionData: 'View Production Data', Add later when production data feature is added
  viewUsers: 'View Users',
  viewDailyInspection: 'View Daily Inspection'
};

export const permissionOptions = (): PermissionOption[] => {
  const sortedPermissions:PermissionOption[] = [];
  Object.keys(permissions).forEach((key: string) => {
    sortedPermissions.push({
      key: key,
      text: permissionsText[key],
      value: permissions[key]
    });
  });
  return sortedPermissions.sort((a, b) => a.key.localeCompare(b.key));
};
export const permissionsFromKey = (key:number) => {
  const permission = Object.entries(permissions).find(entry => entry[1] === key);
  return permission && permission[0];
};
