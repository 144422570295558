import { DeviceLog } from 'Interfaces';
import { OperationLogActionType } from 'Store/ActionEnums';
import { OperationLogActions } from 'Store/ActionTypes';
import { OperationLogState } from 'Store/Interfaces/States/OperationLogState';

const defaultState: OperationLogState = {
  operationlog: {},
  deviceLogs: [] as DeviceLog[],
  fetchingOperationLog: false,
  fetchingStatusForDevices: false,
  obliteratingLogs: false
};

const operationlog = (state = defaultState, action: OperationLogActions):OperationLogState => {
  switch (action.type) {
    case OperationLogActionType.FETCHING_OPERATION_LOG:
      return {
        ...state,
        fetchingOperationLog: true
      };
    case OperationLogActionType.GOT_OPERATION_LOG:
      return {
        ...state,
        fetchingOperationLog: false,
        operationlog: action.payload
      };
    case OperationLogActionType.FAILED_GETTING_OPERATION_LOG:
      return {
        ...state,
        fetchingOperationLog: false
      };
    case OperationLogActionType.FETCHING_DEVICE_LOGS:
      return {
        ...state,
        fetchingStatusForDevices: true
      };
    case OperationLogActionType.FAILED_FETCHING_DEVICE_LOGS:
      return {
        ...state,
        fetchingStatusForDevices: false
      };
    case OperationLogActionType.GOT_DEVICE_LOGS:
      return {
        ...state,
        deviceLogs: action.payload,
        fetchingStatusForDevices: false
      };
    case OperationLogActionType.OBLITERATE_LOGS_START:
      return {
        ...state,
        obliteratingLogs: true
      };
    case OperationLogActionType.OBLITERATE_LOGS_SUCCESS:
      return {
        ...state,
        obliteratingLogs: false
      };
    default:
      return state;
  }
};

export default operationlog;
