/**
  Actiontypes dispatched from optionsActions
*/
export enum OptionsActionType {
  DONE_EDITING_DEVICE_OPTION = 'DONE_EDITING_DEVICE_OPTION',
  DONE_EDITING_USER_OPTION = 'DONE_EDITING_USER_OPTION',
  EDITING_DEVICE_OPTION = 'EDITING_DEVICE_OPTION',
  EDITING_USER_OPTION = 'EDITING_USER_OPTION',
  FAILED_EDITING_DEVICE_OPTION = 'FAILED_EDITING_DEVICE_OPTION',
  FAILED_EDITING_USER_OPTION = 'FAILED_EDITING_USER_OPTION',
  FAILED_FETCHING_DEVICE_OPTIONS = 'FAILED_FETCHING_DEVICE_OPTIONS',
  FAILED_FETCHING_USER_OPTION = 'FAILED_FETCHING_USER_OPTION',
  FAILED_FETCHING_USER_OPTIONS = 'FAILED_FETCHING_USER_OPTIONS',
  FAILED_GETTING_DEVICE_OPTIONS = 'FAILED_GETTING_DEVICE_OPTIONS',
  FAILED_GETTING_LCU_POSITIONS = 'FAILED_GETTING_LCU_POSITIONS',
  FAILED_GETTING_USER_OPTIONS = 'FAILED_GETTING_USER_OPTIONS',
  FETCHING_DEVICE_OPTIONS = 'FETCHING_DEVICE_OPTIONS',
  FETCHING_LCU_POSITIONS = 'FETCHING_LCU_POSITIONS',
  FETCHING_USER_OPTION = 'FETCHING_USER_OPTION',
  FETCHING_USER_OPTIONS = 'FETCHING_USER_OPTIONS',
  GOT_DEVICE_OPTIONS = 'GOT_DEVICE_OPTIONS',
  GOT_LCU_POSITIONS = 'GOT_LCU_POSITIONS',
  GOT_USER_OPTION = 'GOT_USER_OPTION',
  GOT_USER_OPTIONS = 'GOT_USER_OPTIONS',
  DELETING_DEVICE_OPTION = 'DELETING_DEVICE_OPTION',
  DELETING_DEVICE_OPTION_SUCCESS = 'DELETING_DEVICE_OPTION_SUCCESS',
  FAILED_DELETING_DEVICE_OPTION = 'FAILED_DELETING_DEVICE_OPTION'
}
