/**
  Actiontypes dispatched from appActions
*/
export enum AppActionType {
  ADD_FEEDBACK_MESSAGE = 'ADD_FEEDBACK_MESSAGE',
  ADMIN_IS_LOGGED_IN = 'ADMIN_IS_LOGGED_IN',
  ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL',
  ADMIN_LOGOUT = 'ADMIN_LOGOUT',
  ADMIN_LOGOUT_FAIL = 'ADMIN_LOGOUT_FAIL',
  ADMIN_SUCCESSFUL_LOGIN = 'ADMIN_SUCCESSFUL_LOGIN',
  APP_INIT = 'APP_INIT',
  FAILED_GETTING_LOGGED_IN_USER_INFO = 'FAILED_GETTING_LOGGED_IN_USER_INFO',
  FETCH_LOGGED_IN_USER_INFO = 'FETCH_LOGGED_IN_USER_INFO',
  GOT_LOGGED_IN_USER_INFO = 'GOT_LOGGED_IN_USER_INFO',
  RFID_AUTO_SELECT_TAG = 'RFID_AUTO_SELECT_TAG',
  RFID_LOGIN_START = 'RFID_LOGIN_START',
  RFID_READER_FAILED_FETCHING_TAG = 'RFID_READER_FAILED_FETCHING_TAG',
  RFID_READER_FETCHING_TAG = 'RFID_READER_FETCHING_TAG',
  RFID_READER_READ_EXISTING_TAG = 'RFID_READER_READ_EXISTING_TAG',
  RFID_READER_READ_TAG = 'RFID_READER_READ_TAG',
  RFID_SELECTED_TAG = 'RFID_SELECTED_TAG',
  RFID_TAG_CLEAR = 'RFID_TAG_CLEAR',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  START_LOGIN = 'START_LOGIN',
  THEME_CHANGED = 'THEME_CHANGED',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  RESET = 'RESET',
  GET_LICENSE ='GET_LICENSE',
  GET_DOCUMENTS = 'GET_DOCUMENTS',
  GET_RESERVED_USERS = 'GET_RESERVED_USERS',
  MFA_LOGIN = 'MFA_LOGIN',
  RESET_MFA_LOGIN = 'RESET_MFA_LOGIN',
  SET_EXISTING_USER = 'SET_EXISTING_USER',
  RESET_EXISTING_USER = 'RESET_EXISTING_USER'
}
