import { Layout } from 'access_ctrl-ui';
import React from 'react';
import { Link } from 'react-router-dom';

const FeatureDisabled: React.FC<{ name: string }> = ({ name }) => {
  return (
    <div>
      <Layout.Wrapper>
        <Layout.Section minHeight='15rem'>
          <p>The {name} feature is not included in your <Link to='/settings'>License</Link>.</p>
        </Layout.Section>
      </Layout.Wrapper>
    </div>
  );
};
export default FeatureDisabled;
