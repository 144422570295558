import { ShortcutsInterface } from 'Components/Global/ShortcutList';
import { useMemo } from 'react';
import { Layers, Calendar } from 'react-feather';
import { License } from 'Store/Interfaces/States/License';
import { ShortCutType } from 'types/ShortCutTypes';

import useEnabledFeatures from './useEnabledFeatures';

export default function useAvailableShortcuts(type: ShortCutType, id?:string, license?: License):ShortcutsInterface[] {
  const enabledFeatures = useEnabledFeatures(license);
  const auditlogLink = type === 'device' ? `/auditlog?inventoryId=${id}` : `/auditlog?userId=${id}`;
  const receiptLink = type === 'device' ? `/receipts?deviceId=${id}` : `/receipts?createdBy=${id}`;
  const shortcuts = useMemo(
    () => [{ feature: 'auditlog', icon: Layers, link: auditlogLink, text: 'Audit logs' },
      { feature: 'dailyinspection', icon: Calendar, link: receiptLink, text: 'Daily Inspections' }], [auditlogLink, receiptLink]);
  return useMemo(() => shortcuts?.filter(x => enabledFeatures.includes(x.feature) ?? []), [shortcuts, enabledFeatures]);
}
