import { createMedia } from '@artsy/fresnel';
import styled from 'styled-components';

export const breakpoints = {
  mobile: 0,
  computer: 992
};

const AppMedia = createMedia({ breakpoints });

export const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

export const StyledMedia = styled(Media)`
  height: 100%;
  width: 100%;
`;

export { MediaContextProvider };
