import { DateTime } from 'luxon';

export const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const convertDate = (date:string) => {
  if (!date) {
    return DateTime.fromMillis(0);
  }
  return DateTime.fromISO(date, { zone: 'utc' });
};
export const convertDateToString = (date:Date | null):string | null => {
  if (!date) {
    return null;
  }
  return date.toISOString();
};

export const formatDate = (date:string) => {
  return convertDate(date).toLocal().toFormat(DATE_FORMAT);
};

export const formatNowDate = () => {
  return DateTime.local().toFormat(DATE_FORMAT);
};

export const durationFromTime = (seconds: number) => {
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor(seconds % (3600 * 24) / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + 'd ' : '';
  const hDisplay = h > 0 ? h + 'h ' : '';
  const mDisplay = m > 0 ? m + 'm ' : '';
  const sDisplay = s > 0 ? s + 's ' : '';
  return dDisplay + hDisplay + mDisplay + sDisplay;
};
export const getLocalTimeFromUtc = (time: string) => {
  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setUTCHours(Number(hours));
  date.setUTCMinutes(Number(minutes));
  return `${date.getHours()}:${date.getMinutes() === 0 ? '00' : date.getMinutes()}`;
};
