/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-globals */
import { TableRowType } from 'access_ctrl-ui';
import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DropdownProps } from 'semantic-ui-react';
import queryString from 'query-string';
import { FilterTypes } from 'Common/Enums/FilterTypes';
import { FilterProps } from 'Interfaces/FilterProps';

const initFilterValues = (filtersInit: FilterProps[]): {[key: string]: string | string[]} => {
  const params = queryString.parse(location.search);
  const filt = filtersInit.reduce((prevValue, currValue) => {
    const queryValue = params[currValue.filterKey];
    if (currValue.filterType === FilterTypes.INPUT_FIELD) {
      return ({ ...prevValue, [currValue.filterKey]: (queryValue || '') });
    } else if (currValue.filterType === FilterTypes.DROPDOWN_LIST) {
      return ({ ...prevValue, [currValue.filterKey]: (queryValue ? queryValue.toString().split(',') : []) });
    } else if (currValue.filterType === FilterTypes.DATE_RANGE) {
      return ({
        ...prevValue,
        [`${currValue.filterKey}_to`]: (params[`${currValue.filterKey}_to`] ? params[`${currValue.filterKey}_to`] : ''),
        [`${currValue.filterKey}_from`]: (params[`${currValue.filterKey}_from`] ? params[`${currValue.filterKey}_from`] : '')
      });
    } else {
      return { ...prevValue, [currValue.filterKey]: (queryValue || '') };
    }
  }, {});
  return filt;
};

export enum DateTypes {
  DATE_FROM = 'date_from',
  DATE_TO = 'date_to'
}

/**
 * Filter hook managing table filtering logic.
 * @param filters initialize filters
 * @param tableDataToFilter Table data in which will be filtered
 * @returns [A statefull value (filterTableData), updateFilter function, set state to search all fields in table data, all filter values]
 */
function useFilter(filters: FilterProps[], tableDataToFilter: TableRowType[]) {
  const [searchAllFields, setSearchAllFields] = useState('');
  const [filteredTableData, setFilteredTableData] = useState<TableRowType[]>(tableDataToFilter);
  const [filterValues, setFilterValues] = useState(initFilterValues(filters));

  const history = useHistory();
  const location = useLocation();

  /**
   * Filter based on URL search queries
   */
  useEffect(() => {
    const params = queryString.parse(location.search);

    let filtered = tableDataToFilter.filter(({ data, sortData }) => {
      return filters.every(({ filterKey, filterType, filterFnc }) => {
        const dataToFilter = (sortData && sortData[filterKey]) ? sortData : data;
        const paramValue = params[filterKey];

        // Custom filter function
        if (filterFnc !== undefined) {
          const condition = filterFnc(filterKey, filterValues[filterKey], data);
          return condition;

          // Filter input fields
        } else if (filterType === FilterTypes.INPUT_FIELD && paramValue && !isArray(paramValue) && paramValue) {
          const condition = JSON.stringify(dataToFilter[filterKey]).toString().includes(paramValue) === true;
          return condition;

          // Filter dropdown lists
        } else if (filterType === FilterTypes.DROPDOWN_LIST && paramValue) {
          if (dataToFilter[filterKey]) {
            // const condition = paramValue.toString().toLowerCase().split(',').some(item => dataToFilter[filterKey]?.toString().toLowerCase().includes(item));
            const condition = JSON.stringify(paramValue.toString().toLowerCase().split(',')).includes(JSON.stringify(dataToFilter[filterKey]).toString().toLowerCase());
            return condition;
          }
          return false;

          // Filter Dates
        } else if (filterType === FilterTypes.DATE_RANGE) {
          const dateFrom = (params[`${filterKey}_from`] ? new Date(params[`${filterKey}_from`] as string) : new Date(1920, 1, 1));
          const dateTo = (params[`${filterKey}_to`] ? new Date(params[`${filterKey}_to`] as string) : new Date());
          if (Object.prototype.toString.call(dataToFilter[filterKey]) === '[object String]') {
            if (!params[`${filterKey}_from`] && !params[`${filterKey}_to`]) return true;
            if (!dataToFilter[filterKey]) return true;
            const rowDate = new Date(dataToFilter[filterKey] as string);
            const condition = dateFrom <= rowDate && dateTo >= rowDate;
            return condition;
          }
          return true;

          // Filter Time or Duration
        } else if (filterType === FilterTypes.TIME) {
          if (!paramValue) return true;
          if (paramValue && !isArray(paramValue) && parseInt(paramValue)) {
            const durationFilter = parseInt(paramValue);
            const durationData = dataToFilter[filterKey] as unknown as number;
            return durationFilter >= durationData;
          }
          return false;
        } else {
          return true;
        }
      });
    });

    // Filter all fields
    if (searchAllFields && searchAllFields !== '') {
      filtered = filtered.filter(({ data }) => {
        if (searchAllFields) {
          return Object.keys(data)
            .some(key => JSON.stringify(data[key])?.toString().toLowerCase().includes(searchAllFields.toLowerCase()) === true);
        } else {
          return true;
        }
      });
    }

    setFilteredTableData(filtered);
  }, [location, tableDataToFilter, filters, searchAllFields, filterValues]);

  /**
   * Set search query by key and value
   * @param key query search key
   * @param value query serach value
   */
  const setQuery = (key: string, value: string | string[]) => {
    const query = new URLSearchParams(location.search);
    query.set(key, value.toString());
    if (value === '' || (value.length === 0) || (value.length === 1 && value[0] === '')) {
      query.delete(key);
    }
    history.replace({ ...history.location, search: query.toString() });
  };

  const OnChangeInputField = (value: string, key: string) => {
    setFilterValues(currentFilters => ({ ...currentFilters, [key]: value }));
    setQuery(key, value);
  };

  const onChangeDate = (selected: Date | null, dateType: DateTypes, key: string) => {
    const selectedDate = selected ? selected.toUTCString() : '';
    // Manage Date From
    if (dateType === DateTypes.DATE_FROM) {
      setQuery(`${key}_from`, selectedDate);
      setFilterValues(currentFilters => ({ ...currentFilters, [`${key}_from`]: selectedDate }));
    }

    // Manage Date To
    if (dateType === DateTypes.DATE_TO) {
      setQuery(`${key}_to`, selectedDate);
      setFilterValues(currentFilters => ({ ...currentFilters, [`${key}_to`]: selectedDate }));
    }
  };

  const onChangeDropDown = (data: DropdownProps | string | string[], key: string) => {
    let filterValue: string | string[];
    if (typeof data !== 'string' && !isArray(data) && 'value' in data) {
      filterValue = (data.value && typeof data.value === 'object' && data.value.length !== 0) ? data.value.join('_-s-_').split('_-s-_') : [];
    } else if (typeof data !== 'string' && isArray(data)) {
      filterValue = data;
    } else {
      filterValue = [];
    }
    setFilterValues(currentFilters => ({ ...currentFilters, [key]: filterValue }));
    setQuery(key, filterValue);
  };

  const onChangeTime = (value: string, key: string) => {
    OnChangeInputField(value, key);
  };

  /**
   * Clear all filters and set to default values
   */
  const clearAllFilters = () => {
    const query = new URLSearchParams(location.search);
    const clearedFilters = filters.reduce((prevValue, currValue) => {
      // Delete queries
      query.delete(currValue.filterKey);

      // Delete filter values
      if (currValue.filterType === FilterTypes.INPUT_FIELD) {
        return ({ ...prevValue, [currValue.filterKey]: '' });
      } else if (currValue.filterType === FilterTypes.DROPDOWN_LIST) {
        return ({ ...prevValue, [currValue.filterKey]: [] });
      } else if (currValue.filterType === FilterTypes.DATE_RANGE) {
        query.delete(`${currValue.filterKey}_to`);
        query.delete(`${currValue.filterKey}_from`);
        return ({
          ...prevValue,
          [`${currValue.filterKey}_to`]: '',
          [`${currValue.filterKey}_from`]: ''
        });
      } else {
        return { ...prevValue, [currValue.filterKey]: '' };
      }
    }, {});

    history.replace({ ...history.location, search: query.toString() });
    setFilterValues(clearedFilters);
  };

  /**
   * Update filter value by key
   * @param filterValue New filter value
   * @param filterKey The filter key in which the value will be changed
   */
  const updateFilterValue = (filterValue: string | string[] | DropdownProps | Date | null, filterKey: string, onDateDropDown?: DateTypes) => {
    const index = filters.findIndex((value) => value.filterKey === filterKey);
    switch (filters[index].filterType) {
      case FilterTypes.DROPDOWN_LIST:
        if (filterValue !== null) {
          onChangeDropDown(filterValue, filterKey);
        }
        break;
      case FilterTypes.INPUT_FIELD:
        if (typeof filterValue === 'string') {
          OnChangeInputField(filterValue, filterKey);
        }
        break;
      case FilterTypes.DATE_RANGE:
        if ((filterValue instanceof Date || filterValue === null) && onDateDropDown) {
          onChangeDate(filterValue, onDateDropDown, filterKey);
        }
        break;
      case FilterTypes.TIME:
        if (typeof filterValue === 'string') {
          onChangeTime(filterValue, filterKey);
        }
        break;
      default:
        break;
    }
  };

  return [filteredTableData, updateFilterValue, setSearchAllFields, clearAllFilters, filterValues] as const;
}

export default useFilter;
