import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { editUserDevices, getAllUsers } from 'Store/Actions/userActions';
import { loadingMessages } from '../../Common/messages';
import { ModalTypes } from '../../Common/Enums';
import { toggleModal } from 'Store/Actions/appActions';
import { Button, CheckboxProps, Layout, Modal, Table, TableColumnType, TableKeyType, TableRowType } from 'access_ctrl-ui';
import { RootState } from 'Store/Reducers';
import { Device, User } from 'Interfaces';
import UserFilter from 'Components/Pages/Users/UserFilter';
import { nameSort, nameSortAlphaNum } from 'Util/sortFn';
import { UserSearchfilter } from 'Interfaces/UserSearchFilter';
import { convertToString, parseStringFromParam } from 'Util/formatString';
import { addSuccessMessage } from 'Store/Actions/feedbackMessage';
import { addMultipleUsers, refreshCurrentDevice } from 'Store/Actions/deviceActions';

const Content = styled(Layout.Modal.Content)`
  padding: ${({ theme }) => theme.sizes.md};
  height: 80vh;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const tableDataColumns: TableColumnType[] = [
  {
    name: 'First name',
    key: 'FirstName',
    sortFn: (dir, col) => {
      return nameSort(dir, col);
    }
  },
  {
    name: 'Last name',
    key: 'LastName',
    sortFn: (dir, col) => {
      return nameSort(dir, col);
    }
  },
  {
    name: 'Employee number',
    key: 'employeeId',
    sortFn: (dir, col) => {
      return nameSortAlphaNum(dir, col);
    }
  },
  {
    name: 'IsAdmin',
    key: 'isAdmin',
    sortFn: (dir, col) => {
      return nameSort(dir, col);
    }
  }
];
const initialFilter:UserSearchfilter = {
  FirstName: '',
  LastName: '',
  employeeId: ''
};
interface Props {
  allUsers: User[];
  editUserDevices: (deviceIds: string[], userId: string, showMessage: boolean) => Promise<void>;
  getAllUsers: () => void;
  open: boolean;
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
  updatingUserAccess: boolean;
  currentDevice:Device | null;
  addSuccessMessage:(content: string, autoClose?: boolean) => void;
  refreshCurrentDevice: () => void;
}

export const DeviceUserAccessModal:React.FC<Props> = ({
  allUsers,
  editUserDevices,
  getAllUsers,
  open,
  toggleModal,
  currentDevice,
  updatingUserAccess,
  addSuccessMessage,
  refreshCurrentDevice
}) => {
  const [checkedList, setCheckedList] = useState<TableKeyType[]>([] as TableKeyType[]);
  const [filter, setFilter] = useState<UserSearchfilter>(initialFilter);
  const [filteredUsers, setFilteredUsers] = useState<TableRowType[]>([] as TableRowType[]);
  const [updatingUsers, setUpdatingUsers] = useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const requestClose = () => {
    setShowConfirmModal(true);
  };

  const onCloseConfirm = () => {
    setShowConfirmModal(false);
    refreshCurrentDevice();
    toggleModal(ModalTypes.EDIT_DEVICE_USER_ACCESS_MODAL, false);
    setList();
    history.replace({ ...history.location, search: '' });
  };

  const setList = useCallback(() => {
    if (currentDevice && allUsers && currentDevice.Users) {
      const checkedList = currentDevice.Users.map(user => user.Id).filter(str => typeof str === 'string');
      setCheckedList(checkedList);
    }
  }, [currentDevice, allUsers]);

  useEffect(() => {
    if (open) {
      getAllUsers();
    }
  }, [getAllUsers, open]);

  useEffect(() => {
    setList();
  }, [currentDevice, allUsers, setList]);

  const onSelectAll = (el: CheckboxProps) => {
    if (el.checked) {
      const all = filteredUsers.map((user) => user.key);
      setCheckedList((checkedList) => [...checkedList, ...all]);
    } else {
      const filterIdArr = filteredUsers.map((user) => user.key);
      const checkList = checkedList.filter((cl) => !filterIdArr.includes(cl.toString()));
      setCheckedList(checkList);
    }
  };

  const onSelectSingle = (el: CheckboxProps, _: number, row: TableRowType) => {
    if (el.checked) {
      setCheckedList((checkedList) => [...checkedList, row.key]);
    } else {
      setCheckedList(checkedList.filter((item) => item !== row.key));
    }
  };

  const update = () => {
    // This is a temporary implementation while we add correct endpoints in backend
    setUpdatingUsers(true);

    if (currentDevice) {
      const { Id } = currentDevice;
      const isChecked = checkedList.map(key => key.toString());

      addMultipleUsers(dispatch)(Id, isChecked).then(() => {
        const timer = setTimeout(() => {
          setUpdatingUsers(false);
          addSuccessMessage('All users updated successfully.', true);
          onCloseConfirm();
          clearTimeout(timer);
        }, 500);
      });
    }
  };
  useEffect(() => {
    const params = queryString.parse(location.search);
    const { FirstName, LastName, employeeId, isAdmin, activePage } = params;
    if (!FirstName && !LastName && !employeeId && isAdmin === undefined && activePage) {
      return;
    }

    setFilter({
      FirstName: convertToString(FirstName),
      LastName: convertToString(LastName),
      employeeId: convertToString(employeeId),
      isAdmin: parseStringFromParam(isAdmin)
    });
  }, [setFilter, location.search]);

  useEffect(() => {
    const applyFilter = (item:User) => {
      return (!filter.FirstName || (item.FirstName && item.FirstName?.toLocaleLowerCase().indexOf(filter.FirstName?.toLocaleLowerCase()) !== -1)) &&
      (!filter.LastName || (item.LastName && item.LastName?.toLocaleLowerCase().indexOf(filter.LastName?.toLocaleLowerCase()) !== -1)) &&
      (!filter.employeeId || (item.EmployeeId && item.EmployeeId.toLocaleLowerCase().indexOf(filter.employeeId.toLocaleLowerCase()) !== -1));
    };
    if (allUsers) {
      const sortedUsers = allUsers.sort((a, b) => {
        return a?.FirstName?.toLocaleLowerCase().localeCompare(b?.FirstName?.toLocaleLowerCase());
      });

      setFilteredUsers(
        sortedUsers.filter(user => user.FirstName && user.LastName).filter(applyFilter).map(({ Id, IsAdmin, FirstName, LastName, EmployeeId }) => ({
          key: Id,
          data: {
            id: Id,
            FirstName: FirstName,
            LastName: LastName,
            employeeId: EmployeeId,
            isAdmin: IsAdmin?.toString() ?? false
          }
        }))
      );
    }
  }, [allUsers, filter]);

  return (
    <Modal
      open={open}
      onClose={requestClose}
      confirmClose={onCloseConfirm}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      closeIcon
      size='large'
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>Edit user access</Layout.Modal.Header>
        <Content>
          <UserFilter activated hideAccess />
          <Table
            setQueryString={false}
            checkedRows={checkedList}
            rowsPerPage={0}
            tableDataRows={filteredUsers}
            tableDataColumns={tableDataColumns}
            checkbox
            onSelectSingle={onSelectSingle}
            onSelectAll={onSelectAll}
            loading={!(updatingUsers || allUsers)}
            loadingMessage={loadingMessages.LOADING_USERS}
          />
        </Content>
        <Layout.Modal.ButtonContainer>
          <Button loading={updatingUserAccess} onClick={update}>
            Update
          </Button>
        </Layout.Modal.ButtonContainer>
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default connect(
  ({ modalsOpen, user, device }: RootState) => ({
    open: modalsOpen[ModalTypes.EDIT_DEVICE_USER_ACCESS_MODAL],
    updatingUserAccess: user.updatingUserAccess,
    allUsers: user.allUsers,
    devices: device.devices,
    currentDevice: device.currentDevice
  }),
  (dispatch) => ({
    addSuccessMessage: addSuccessMessage(dispatch),
    toggleModal: toggleModal(dispatch),
    editUserDevices: editUserDevices(dispatch),
    getAllUsers: getAllUsers(dispatch),
    refreshCurrentDevice: refreshCurrentDevice(dispatch)
  }))(DeviceUserAccessModal);
