import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Plus } from 'react-feather';

import { permissions } from '../../../Common/Constant';
import { fetchUserInfoToCurrentUser, getAllUsersWithOptions } from 'Store/Actions/userActions';
import { toggleModal, setCurrentPage, clearRFIDReadTag } from 'Store/Actions/appActions';

import { Button, Layout } from 'access_ctrl-ui';
import { ModalTypes } from 'Common/Enums';
import { RootState } from 'Store/Reducers';
import { Page, User } from 'Interfaces';
import UsersTable from 'Features/Users/UsersTable';

interface Props {
  setCurrentPage: (pageInfo: Page) => void;
  toggleModal: (modalName: ModalTypes, forcedOpenState?: boolean | null) => void
  getAllUsers: () => void;
  allUsers: User[];
  fetchingUsers: boolean;
  fetchingLoggedInUser: boolean;
  loggedInUser: User;
  fetchUserInfo: (userId: string) => Promise<void>;
}
const Users:React.FC<Props> = ({ setCurrentPage, toggleModal, getAllUsers, allUsers, fetchingUsers, fetchingLoggedInUser, loggedInUser, fetchUserInfo }) => {
  const [viewUsersAction, setViewUsersAction] = useState(false);
  const [userAdminAction, setUserAdminAction] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => clearRFIDReadTag(dispatch)(), [dispatch]);
  useEffect(() => {
    setCurrentPage({ name: 'Users' });
  }, [setCurrentPage]);

  useEffect(() => {
    if (viewUsersAction) {
      getAllUsers();
    }
  }, [getAllUsers, viewUsersAction]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.Options && loggedInUser.Options['permissions.actions']) {
      const actions = JSON.parse(loggedInUser.Options['permissions.actions'].Value);

      setViewUsersAction((actions.includes(permissions.viewUsers) || actions.includes(permissions.userAdmin)) || actions.includes(permissions.all));

      setUserAdminAction(actions.includes(permissions.userAdmin) || actions.includes(permissions.all));
    } else if (loggedInUser) {
      setViewUsersAction(true);
      setUserAdminAction(true);
    }
  }, [loggedInUser]);

  const onAddUser = () => {
    toggleModal(ModalTypes.ADD_USER_MODAL, true);
  };

  return (
    <Layout.Wrapper>
      <Layout.Section>
        <UsersTable
          loading={fetchingUsers || fetchingLoggedInUser}
          users={allUsers && viewUsersAction ? allUsers : undefined}
          permissionToViewContent={viewUsersAction}
          CustomButton={userAdminAction ? <Button onClick={onAddUser}><Plus />Add user</Button> : undefined}
          showFilters
        />
      </Layout.Section>
    </Layout.Wrapper>
  );
};

export default connect(({ app, user }:RootState) => (
  {
    allUsers: user.allUsers,
    loggedInUser: app.loggedInUser,
    fetchingLoggedInUser: app.fetchingLoggedInUser,
    fetchingUsers: user.fetchingUsers
  }
), dispatch => ({
  toggleModal: toggleModal(dispatch),
  setCurrentPage: setCurrentPage(dispatch),
  getAllUsers: getAllUsersWithOptions(dispatch),
  fetchUserInfo: fetchUserInfoToCurrentUser(dispatch)
}))(Users);
