import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { webApi } from 'Common/api';
import { StatusMessageTypes } from 'Common/Enums';
import { addFeedbackMessage } from 'Store/Actions/feedbackMessage';
import { Receipt, ReceiptFilter } from './Interfaces';

export interface ReceiptState {
  loading: boolean;
  error?: Error
  receipts?: Receipt[];
  nbrOfReceipts?: number;
  currentReceipt?: Receipt;
}
export const initialState:ReceiptState = {
  loading: false
};
const getReceiptById = createAsyncThunk('receipt/getById',
  async (id:string, thunkApi) => {
    let fetchedReceipt;
    webApi.get(`/receipt/${id}`).then(trigger => {
      addFeedbackMessage(thunkApi.dispatch)('Receipt saved', StatusMessageTypes.SUCCESS);
      fetchedReceipt = trigger;
    }).catch((error) => {
      addFeedbackMessage(thunkApi.dispatch)(error.message, StatusMessageTypes.ERROR);
    });
    return fetchedReceipt;
  }
);
const getReceiptsWithFilter = createAsyncThunk('receipt/getWithFilter',
  async (filter:ReceiptFilter, thunkApi) => {
    try {
      const response = await webApi.post<{ Item1: Receipt[], Item2: number}>('/receipt', filter);
      return response.data;
    } catch (error: unknown) {
      addFeedbackMessage(thunkApi.dispatch)((error as AxiosError).message, StatusMessageTypes.ERROR);
      return undefined;
    }
  }
);
const getReceipts = createAsyncThunk('receipt/get',
  async (_:void, thunkApi) => {
    try {
      const response = await webApi.post<{ Item1: Receipt[], Item2: number}>('/receipt', {});
      return response.data;
    } catch (error: unknown) {
      addFeedbackMessage(thunkApi.dispatch)((error as AxiosError).message, StatusMessageTypes.ERROR);
      return undefined;
    }
  }
);
const saveReceipt = createAsyncThunk('receipt/save',
  async (receipt:Receipt, thunkApi) => {
    webApi.post('/receipt/insert', receipt).then(receiptId => {
      addFeedbackMessage(thunkApi.dispatch)('Receipt saved', StatusMessageTypes.SUCCESS);
      return receiptId;
    }).catch((error) => {
      addFeedbackMessage(thunkApi.dispatch)(error.message, StatusMessageTypes.ERROR);
    });
  }
);
const updateReceipt = createAsyncThunk('receipt/update',
  async (receipt:Receipt, thunkApi) => {
    webApi.put('/receipt').then(receipt => {
      addFeedbackMessage(thunkApi.dispatch)('Receipt saved', StatusMessageTypes.SUCCESS);
    }).catch((error) => {
      addFeedbackMessage(thunkApi.dispatch)(error.message, StatusMessageTypes.ERROR);
    });
  }
);
const deleteReceipt = createAsyncThunk('receipt/delete',
  async (id: string, thunkApi) => {
    webApi.delete(`/receipt/${id}`).then(receipt => {
      addFeedbackMessage(thunkApi.dispatch)('Receipt saved', StatusMessageTypes.SUCCESS);
    }).catch((error) => {
      addFeedbackMessage(thunkApi.dispatch)(error.message, StatusMessageTypes.ERROR);
    });
  }
);
export const receiptSlice = createSlice({
  name: 'receipt',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getReceiptById.pending, (state) => {
      state.loading = true;
    })
      .addCase(getReceiptById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentReceipt = action.payload;
      })
      .addCase(getReceiptById.rejected, (state) => {
        state.loading = false;
        state.currentReceipt = undefined;
      })
      .addCase(getReceipts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReceipts.fulfilled, (state, action) => {
        state.loading = false;
        state.receipts = action.payload?.Item1;
        state.nbrOfReceipts = action.payload?.Item2;
      })
      .addCase(getReceipts.rejected, (state) => {
        state.loading = false;
        state.receipts = undefined;
        state.nbrOfReceipts = undefined;
      })
      .addCase(getReceiptsWithFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReceiptsWithFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.receipts = action.payload?.Item1;
        state.nbrOfReceipts = action.payload?.Item2;
      })
      .addCase(getReceiptsWithFilter.rejected, (state) => {
        state.loading = false;
        state.receipts = undefined;
        state.nbrOfReceipts = undefined;
      })
      .addCase(saveReceipt.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveReceipt.fulfilled, (state, action) => {
        state.loading = false;
        state.currentReceipt = undefined;
      })
      .addCase(saveReceipt.rejected, (state) => {
        state.loading = false;
        state.currentReceipt = undefined;
      })
      .addCase(updateReceipt.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateReceipt.fulfilled, (state, action) => {
        state.loading = false;
        state.receipts = undefined;
        state.nbrOfReceipts = undefined;
        state.currentReceipt = undefined;
      })
      .addCase(updateReceipt.rejected, (state) => {
        state.loading = false;
        state.currentReceipt = undefined;
      })
      .addCase(deleteReceipt.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteReceipt.fulfilled, (state, action) => {
        state.loading = false;
        state.receipts = undefined;
        state.nbrOfReceipts = undefined;
        state.currentReceipt = undefined;
      })
      .addCase(deleteReceipt.rejected, (state) => {
        state.loading = false;
        state.currentReceipt = undefined;
      })
      .addDefaultCase((state) => {
        state = initialState;
      });
  }

});
export { getReceiptById, getReceipts, getReceiptsWithFilter, saveReceipt };
export default receiptSlice.reducer;
