import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Edit2 } from 'react-feather';

import { columnBName, columnCName } from '../../../Common/messages';
// import { permissionsText } from '../../../Common/Constant';
import { ModalTypes } from '../../../Common/Enums';
import { toggleModal } from 'Store/Actions/appActions';
import { formatDate } from 'Util/formatDate';

import { Button, HeaderRow, InfoBit, InfoPopup } from 'access_ctrl-ui';
import { User } from 'Interfaces';
import { permissionsFromKey, permissionsText } from 'Common/Constant/Permissions';
import ShortcutList from 'Components/Global/ShortcutList';
import useAvailableShortcuts from 'Hooks/useAvailableShortcuts';
import { useAppSelector } from 'Store/hooks';

const InfoBitWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ShortCutsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.sizes.md};
`;
interface Props{
  userAdmin: boolean;
  currentUser: User;
  toggleModal:(modalName: ModalTypes, forcedOpenState?: boolean | null) => void;
}
const UserInfoPane:React.FC<Props> = ({ userAdmin, currentUser, toggleModal }) => {
  const { license } = useAppSelector(state => state.app);
  const shortcuts = useAvailableShortcuts('user', currentUser?.Id, license);
  if (!currentUser) {
    return (<p>Loading...</p>);
  }

  const width = '33%';

  const onEditUser = () => {
    toggleModal(ModalTypes.EDIT_USER_MODAL);
  };

  const username = currentUser.Email ? currentUser.Email.split('@')[0] : '';
  const isAdmin = currentUser.IsAdmin ? 'Yes' : 'No';
  let accessFrom = '';
  let accessTo = '';
  let actions = '';
  let columnsb = '';
  let columnsc = '';

  if (currentUser.Options) {
    if (currentUser.Options['access.from'] && currentUser.Options['access.from'].Value) {
      accessFrom = formatDate(currentUser.Options['access.from'].Value);
    }

    if (currentUser.Options['access.to'] && currentUser.Options['access.to'].Value) {
      accessTo = formatDate(currentUser.Options['access.to'].Value);
    }

    const permissionArr = currentUser.Options?.['permissions.actions']?.Value ? JSON.parse(currentUser.Options['permissions.actions'].Value) : null;

    if (permissionArr) {
      actions = '';
      permissionArr.forEach((element:string) => {
        const permission = permissionsFromKey(parseInt(element));
        if (permission) {
          actions += `${permissionsText[permission].toString()}, `;
        }
      });
      if (actions.length > 2) {
        actions = actions.substring(0, actions.length - 2);
      }
    }

    const colbArr = currentUser.Options?.['permissions.columnsb']?.Value ? JSON.parse(currentUser.Options['permissions.columnsb'].Value) : null;

    if (colbArr) {
      columnsb = colbArr.toString();
    }

    const colcArr = currentUser.Options?.['permissions.columnsc']?.Value ? JSON.parse(currentUser.Options['permissions.columnsc'].Value) : null;

    if (colcArr) {
      columnsc = colcArr.toString();
    }
  }

  return (
    <>
      <HeaderRow>
        <h2>Info</h2>
        <InfoPopup descriptionText='Info about this user' />
        {
          userAdmin &&
            <Button onClick={onEditUser}><Edit2 />Edit</Button>
        }
      </HeaderRow>
      <InfoBitWrapper>
        <InfoBit
          header='First name'
          info={currentUser?.FirstName}
          width={width}
        />
        <InfoBit
          header='Last name'
          info={currentUser?.LastName}
          width={width}
        />
        <InfoBit
          header='Username'
          info={username}
          width={width}
        />
        <InfoBit
          header='Employee number'
          info={currentUser.EmployeeId}
          width={width}
        />
        <InfoBit
          header='Access from'
          info={accessFrom}
          width={width}
        />
        <InfoBit
          header='Access to'
          info={accessTo}
          width={width}
        />
        <InfoBit
          header='Admin dashboard user'
          info={isAdmin}
          width={width}
        />
        <InfoBit
          header='Permitted Actions'
          info={actions}
          width={width}
        />
        <InfoBit
          header={columnBName}
          info={columnsb}
          width={width}
        />
        <InfoBit
          header={columnCName}
          info={columnsc}
          width={width}
        />
      </InfoBitWrapper>
      <ShortCutsWrapper>
        <ShortcutList
          shortcuts={shortcuts}
        />
      </ShortCutsWrapper>
    </>
  );
};

export default connect(() => ({}), (dispatch) => ({
  toggleModal: toggleModal(dispatch)
}))(UserInfoPane);
