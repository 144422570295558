import React, { FC } from 'react';
import { connect } from 'react-redux';
import StatusMessage from './StatusMessage';
import styled from 'styled-components';
import { FeedbackMessage } from 'Interfaces';
import { RootState } from 'Store/Reducers';

export const MessagesWrapper = styled.div`
  position: fixed;
  top: 10px;
  left: 50%;
  width: 25rem;
  margin-left: -12.5rem;
  z-index: 5000;
`;

interface Props {
  messages: FeedbackMessage[];
}

export const StatusMessages: FC<Props> = ({ messages }) => {
  return (
    <div>
      <MessagesWrapper>
        {messages.map(message => (
          message && <StatusMessage key={message?.id} message={message} />
        ))}
      </MessagesWrapper>
    </div>
  );
};

export default connect(({ action }: RootState) => ({
  messages: action.messages
}))(StatusMessages);
