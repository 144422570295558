import { Button, Datepicker, Form, Label, Layout, Modal, TextArea } from 'access_ctrl-ui';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { DeviceRecord } from 'Interfaces/DeviceRecord';
import { Device } from 'Interfaces';
import { postDeviceOption } from 'Store/Actions/optionsActions';
import { refreshCurrentDevice } from 'Store/Actions/deviceActions';
import crypto from 'crypto';
import { addErrorMessage, addSuccessMessage } from 'Store/Actions/feedbackMessage';
import { apiCallErrorMessages, statusMessages } from 'Common/messages';
import { useDispatch } from 'react-redux';

enum TagType {
  SERVICE = 'Service',
  SERVICE_CALL = 'Service call',
  SOFTWARE_UPDATE = 'Software update',
  KIWA = 'Kiwa',
  NOTE = 'Note',
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
  modalHeader: string;
  userId: string;
  firstName: string;
  lastName: string;
  currentDevice: Device | null;
};

type ButtonProps = {
  tagName: TagType,
}

const OptionsContent = styled.div`
  padding: 0 ${({ theme }) => theme.sizes.md} ${({ theme }) => theme.sizes.md} ${({ theme }) => theme.sizes.md};
  display: flex;
  flex-direction: row;

  &&& button {
    margin: 0 ${({ theme }) => theme.sizes.sm};
  }

  &&& button:first-of-type {
    margin-left: 0;
  }
`;

const ActiveButton = styled(Button)`
  background-color: ${({ theme }) => theme.buttonBorder};
  color: ${({ theme }) => theme.buttonTextSecondary};
`;

const StyledForm = styled(Form)`
  padding: 0 ${({ theme }) => theme.sizes.md};
  padding-bottom: ${({ theme }) => theme.sizes.md};
  display: flex;
  flex-direction: column;

  &&& button[type='submit'] {
    width: fit-content;
    justify-content: end;
  }

  & .submit-button-wrapper {
    display: flex;
    justify-content: end;
    margin-top: ${({ theme }) => theme.sizes.md};
  }

  & .datepicker-wrapper {
    margin-top: ${({ theme }) => theme.sizes.md};
    width: fit-content;
  }

  & .name-field {
    display: flex;
    justify-content: end;
    margin-top: ${({ theme }) => theme.sizes.sm};
  }
`;

const AddDeviceRecordModal: React.FC<ModalProps> = ({ open, onClose, modalHeader, userId, firstName, lastName, currentDevice }) => {
  const [selectedTag, setSelectedTag] = useState<TagType>(TagType.NOTE);
  const [selectedDate, setSelectedDate] = useState<Date | undefined | null>(new Date());
  const [recordText, setRecordText] = useState<string | undefined>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dispatch = useDispatch();

  const ButtonType: React.FC<ButtonProps> = useCallback(function ButtonType({ tagName }): JSX.Element {
    return (
      <>
        {selectedTag === tagName
          ? <ActiveButton type='button' onClick={() => setSelectedTag(tagName)}>{tagName}</ActiveButton>
          : <Button type='button' onClick={() => setSelectedTag(tagName)}>{tagName}</Button>}
      </>
    );
  }, [selectedTag]);

  const requestClose = () => {
    setShowConfirmModal(true);
  };

  const onChangeDate = (e: React.SyntheticEvent<HTMLInputElement, Event> | undefined, selected: Date | null) => {
    e?.preventDefault();

    if (selected) {
      setSelectedDate(selected);
    } else {
      setSelectedDate(null);
    }
  };

  const handleSubmit = () => {
    const optionsId: string = crypto.randomBytes(16).toString('hex');

    const recordItem: DeviceRecord = {
      CreatedBy: userId,
      Date: selectedDate ? selectedDate.toISOString() : new Date().toISOString(),
      status: undefined,
      files: undefined,
      tag: selectedTag,
      text: recordText
    };

    currentDevice && postDeviceOption(dispatch)(currentDevice.Id, `.RECORDS.${optionsId}`, JSON.stringify(recordItem)).then(() => {
      refreshCurrentDevice(dispatch)();
      addSuccessMessage(dispatch)(statusMessages.ADD_DEVICE_RECORD_SUCCESS);
      onClose();
    }).catch(error => {
      addErrorMessage(dispatch)(apiCallErrorMessages.ADD_DEVICE_RECORD_ERROR_MESSAGE, error, false);
    });
  };

  const userInputIsValid = (tag: TagType): boolean => {
    let isValid = false;
    if (tag === TagType.NOTE) {
      if (tag && selectedDate && recordText) {
        isValid = true;
      }
    }
    return isValid;
  };

  const onConfirmClose = () => {
    setShowConfirmModal(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      size='small'
      closeIcon
      onClose={requestClose}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}
      confirmClose={onConfirmClose}
    >
      <Layout.Modal.Wrapper>
        <Layout.Modal.Header>{modalHeader}</Layout.Modal.Header>
        <Layout.Modal.Content>
          <OptionsContent>
            {
              Object.values(TagType).map(tagItem => {
                return tagItem !== TagType.SOFTWARE_UPDATE && <ButtonType key={tagItem} tagName={tagItem} />;
              })
            }
          </OptionsContent>
          <StyledForm onSubmit={handleSubmit}>
            <Label label='Record text' />
            <TextArea label='Note' onChange={(e, data) => setRecordText(e.currentTarget.value)} value={recordText?.toString()} rows={12} />
            <div className='name-field'>{firstName} {lastName}</div>
            <div className='datepicker-wrapper'>
              <Datepicker
                selected={selectedDate}
                onDateSelect={onChangeDate}
                isClearable
                dateFormat='yyyy-MM-dd'
                label='Date'
                showTimeSelect={false}
                placeholderText='Select a date'
              />
            </div>
            <div className='submit-button-wrapper'>
              <Button type='submit' disabled={!userInputIsValid(TagType.NOTE)}>Save</Button>
            </div>
          </StyledForm>
        </Layout.Modal.Content>
      </Layout.Modal.Wrapper>
    </Modal>
  );
};

export default AddDeviceRecordModal;
