import { Device } from 'Interfaces';
import { DailyRoutine, RoutinesOptionKey } from '../Features/Routines/Interfaces';

/**
 * Check if a device is assigned a specific routine
 * @param device - device to check
 * @param currentRoutine - routine to check
 * @returns {boolean} - If the device is assigned routine
 */
export const deviceHasRoutine = (device:Device, currentRoutine?: DailyRoutine) => {
  if (!device.Options || !device.Options[RoutinesOptionKey] || !currentRoutine) {
    return false;
  }
  return Object.values(device.Options[RoutinesOptionKey]).flatMap(x => (x as string).toString()).some(x => x.includes(currentRoutine.Id));
};
