import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { getAuditLog } from 'Store/Actions/auditLogActions';
import { loadingMessages } from '../../../Common/messages';
import { logEventTypeLabels } from '../../../Common/Constant';
import { LogAuthorizationTypes, LogEventType, LogOrderByColumn } from '../../../Common/Enums';
import { getUserNameLink } from 'Util/auditLogHelpers';
import { formatDate } from 'Util/formatDate';

import { HeaderRow, InfoPopup, Table, TableRowType } from 'access_ctrl-ui';
import { RootState } from 'Store/Reducers';
import { Device, Filter } from 'Interfaces';
import { AuditLogItem } from 'Interfaces/AuditLogItem';
import { AuditLogResponse } from 'Interfaces/AuditLogResponse';
import { Link } from 'react-router-dom';

const _defaultFilter = {
  authorizationResult: +(LogAuthorizationTypes.NOT_SUCCESSFUL | LogAuthorizationTypes.SUCCESSFUL),
  events: [LogEventType.ALL],
  startIndex: 0,
  stopIndex: 6,
  orderBy: LogOrderByColumn.TimestampDesc
};

const tableColumns = [
  { name: 'User', key: 'userName' },
  { name: 'Type', key: 'type' },
  { name: 'Timestamp', key: 'timestamp' }
];
interface Props{
  viewAuditLogs:boolean;
  currentDevice: Device | null;
  getAuditLog: (filter: Filter) => Promise<AuditLogResponse>;
  fetchingLogs: boolean;
}
const AuditLogPane:React.FC<Props> = ({ viewAuditLogs, currentDevice, getAuditLog, fetchingLogs }) => {
  const unmounted = useRef(false);
  const [logData, setLogData] = useState<AuditLogItem[]>([] as AuditLogItem[]);
  const [tableData, setTableData] = useState<TableRowType[]>([] as TableRowType[]);
  const [filter, setFilter] = useState<Filter>(_defaultFilter);

  useEffect(() => {
    if (currentDevice) {
      setFilter(newFilter => ({
        ...newFilter,
        inventoryId: currentDevice.Id
      }));
    }
    return () => { unmounted.current = true; };
  }, [currentDevice]);

  useEffect(() => {
    if (filter.inventoryIds && viewAuditLogs) {
      getAuditLog(filter).then(data => {
        if (!unmounted.current) {
          setLogData(data.Items);
        }
      });
    }
  }, [filter, getAuditLog, viewAuditLogs]);

  useEffect(() => {
    if (logData) {
      const _tdata = logData.map((item, index) => ({
        key: index,
        successful: item.Successful,
        data: {
          userName: getUserNameLink(item),
          type: logEventTypeLabels[item.Event].label,
          timestamp: formatDate(item.Timestamp)
        }
      }));
      setTableData(_tdata);
    }
  }, [logData]);

  return (
    <>
      <HeaderRow>
        <h2>Audit Log</h2>
        {
          viewAuditLogs && currentDevice &&
            <>
              <InfoPopup descriptionText='Latest access for this device' />
              <Link to={`/auditlog?inventoryId=${currentDevice.Id}&deviceName=${currentDevice.Name.toLocaleLowerCase()}`}>See all logs for this device</Link>
            </>
        }
      </HeaderRow>
      <Table
        rowsPerPage={5}
        emptyText={viewAuditLogs ? 'No audit logs for this device' : 'You don\'t have permission to view Audit Logs'}
        tableDataColumns={tableColumns}
        tableDataRows={viewAuditLogs ? tableData : []}
        loading={fetchingLogs}
        loadingMessage={loadingMessages.LOADING_AUDIT_LOGS}
      />
    </>
  );
};

export default connect(({ auditLog } : RootState) => ({
  fetchingLogs: auditLog.fetchingLogs
}), dispatch => (
  {
    getAuditLog: getAuditLog(dispatch)
  }
))(AuditLogPane);
