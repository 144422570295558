import { permissions } from 'Common/Constant';
import { User } from 'Interfaces/User';
import { useMemo } from 'react';

export default function useAdminAction(user:User):boolean {
  const adminActions = useMemo(() => {
    if (!user || !user.Options || !user.Options['permissions.actions'].Value) {
      return false;
    }
    const actions = JSON.parse(user.Options['permissions.actions'].Value);

    return actions.includes(permissions.userAdmin) || actions.includes(permissions.all);
  }, [user]);
  return adminActions;
}
